import makeStyles from "@mui/styles/makeStyles"
import Box from "@mui/material/Box";
import React, { useRef } from "react";

const useStyles = makeStyles(theme=>({
    root: {
        display: "flex",
        flexDirection: 'column',
        zIndex: 0, height: '100%',
        gap: theme.spacing(6)
    },
    navigatorRoot: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      gap: theme.spacing(5),
    },
    navigator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        zIndex: 1,
        cursor: "pointer",
        color: theme.palette.primary.main,
        "&:active": {
            width: "28px",
            height: "28px",
        },
        "&:hover": {
            boxShadow: "0px 0px 8px 0px #1961BF4D",

        },
    },
    contentContainer: {
        display: "flex", overflow: "auto", 
        gap: theme.spacing(6), 
        minHeight: 'fit-content',
        padding: theme.spacing(2),
        transition: "left 1s linear", scrollbarWidth: 'none',
        "&::-webkit-scrollbar": { width: 0, height: 0 },
        "&::-webkit-scrollbar-thumb": { width: 0, height: 0, },
    },
}));

function HorizontalScrollNavigator({
    children, NoResultComponent, NoResultComponentProps = {}, childrensLength, scroll,
    showNavigator=true, scrollWidth = 500, ...props
}) {
    const classes = useStyles();
    const scrollContainerRef = useRef();

    const handleNavigate = (direction) => {
        scrollContainerRef.current.scrollBy({
            top: 0,
            left: direction === "left" ? -scrollWidth : scrollWidth,
            behavior: 'smooth'
        });
    };

    React.useEffect(()=>{
      if(scroll && scroll.direction){
        handleNavigate(scroll.direction);
      }
    },[scroll])

    return (
      <Box className={classes.root}>
        {childrensLength > 0 ? (
          <Box 
            ref={scrollContainerRef} 
            className={classes.contentContainer} 
            style={props?.sx ? props.sx : {}}
          >
              {children}
          </Box>
        ) : (
            NoResultComponent ? (
                <NoResultComponent {...NoResultComponentProps} />
            ) : null
        )}
        {childrensLength > 0  && showNavigator && (
          <Box className={classes.navigatorRoot}>
            <Box
              className={classes.navigator}
              onClick={()=>handleNavigate("left")}
            >
              <img 
                src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/leftArrow.svg"
                alt="moveLeftIcon"
              />
            </Box>
            <Box
              className={classes.navigator}
              onClick={()=>handleNavigate("right")}
            >
              <img 
                src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/rightArrow.svg"
                alt="moveLeftIcon"
              />
            </Box>
          </Box>
        )}
      </Box>
    );
}

export default HorizontalScrollNavigator;