import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NextIcon from '@mui/icons-material/NavigateNext';
import BackIcon from '@mui/icons-material/NavigateBefore';
import TextField from "components/TextField";
import OnboadingHeader from "components/OnboadingHeader";
import PasswordField from "components/PasswordField";
import { useSnackbar } from "contexts/SnackbarProvider";

import {
  signIn, signOut, getUserClients, getBatchInfoByToken, joinBatch, generateOtp,
  signupWithOTP
} from "services";

import Session from "utils/Session";
import { getClientPrefix } from "utils";
import { CustomLoadingButton } from "components/CustomButton";

const ValidationSchema = yup.object({
  email: yup.string("Enter your email").email("Invalid email")
    .required("Required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  client: yup.string().required("Client not provided"),
  name: yup.string("Provide your name").test({
    name: 'is-new',
    test: async (value, context) => {
      if (context.parent.isNew && !value)
        return context.createError({ message: "Please provide your name" });
      else
        return true;
    }
  }),
  otp: yup.string("Please enter the OTP").min(6).max(6).test({
    name: 'otp-sent-check',
    test: async (value, context) => {
      if (context.parent.otpSent && !value)
        return context.createError({
          message: "Enter OTP sent to the email address..."
        });
      else
        return true;
    }
  }),
  isNew: yup.boolean(),
  otpSent: yup.boolean(),
  otpId: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  rightContainer: {
    height: "100%", width: "70%", justifyContent: "flex-end",
    alignItems: "center", overflow: 'hidden', paddingLeft: '20%',
  },
  buttonContainer: {
    display: "flex", alignItems: "center", justifyContent: "flex-end",
    width: "25vw", flexDirection: "row"
  },
  googleImg: { cursor: "pointer", },
  image: {
    height: "80%", margin: "auto", background: 'transparent',
    [theme.breakpoints.down(725)]: {
      position: "relative", height: "100%", width: "80%", marginRight: "auto",
      marginLeft: "auto", maxHeight: 330,
    }
  },
}));

function BatchInvite() {
  const classes = useStyles();

  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [emailChecked, setEmailChecked] = React.useState(false);
  const [batchInfo, setBatchInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const {
    touched, values, errors, handleBlur, handleChange, handleSubmit, resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "", password: "", client: "", name: "", otp: "", otpId: "",
      isNew: false, otpSent: false,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        let user;
        if (values.isNew) {
          values.userId = values.email;
          values.client = batchInfo.client._id;

          const data = await signupWithOTP(values);
          user = data.user;
        } else {
          const data = await signIn(values);
          user = data.user;
        }

        const deactivated = Session.checkDeactivatedStatus();

        if (deactivated) {
          console.error("DEBUG::PROFILE DEACTIVATED");

          await signOut();

          navigate("/error-admin");
        } else {
          console.log("DEBUG::onSubmit-> joining the batch");

          await joinBatch(batchInfo._id);

          console.log("DEBUG::onSubmit-> joined the batch");

          navigate(`/${getClientPrefix(user)}/home`);
        }
      } catch (error) {
        snackbar.error('Uh Oh! Something went wrong. Please try again!')
      } finally {
        setLoading(false);
      }
    },
  });

  const handleBack = () => {
    setEmailChecked(false);
    setFieldValue('isNew', false);
    resetForm();
  };

  const sendOtp = async () => {
    try {
      setLoading(true);
      const { logoUrl, inprepLogoTransform: transform } = Session.getClientMetadata() || {}
      const { id } = await generateOtp(values.email, logoUrl);

      setFieldValue('otpSent', true);
      setFieldValue('otpId', id);
    } catch (error) {
      console.error(error);
      snackbar.error('Uh Oh! Something went wrong. Please try again!')
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  const handleNext = async () => {
    try {
      if (!isValidEmail(values.email)) return;
      setLoading(true);

      setEmailChecked(true);

      const clientList = await getUserClients(values.email);

      const clientId = batchInfo?.client?._id;

      const clientFound = clientList.find(c => c._id === clientId);

      setFieldValue('isNew', clientList.length === 0 || !clientFound);
    } catch (error) {
      console.error(error);
      snackbar.error("Uh Oh! Something went wrong. Please try again!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const token = searchParams.get("at");

      if (token) {
        try {
          const batchInfo = await getBatchInfoByToken(token);

          Session.client = batchInfo.client;
          Session.clientId = batchInfo.client._id;

          setFieldValue('client', batchInfo.client._id);
          setBatchInfo(batchInfo);
        } catch (error) {
          console.error(error);
          snackbar.error("Uh Oh! Something went wrong. Please refresh the page!");
        }
      } else {
        snackbar.error("Uh Oh! Seems you are following a Invalid Invite");
      }

      setLoading(false);
    })();
  }, [searchParams, setFieldValue]);

  return (
    <>
      {batchInfo ? <OnboadingHeader /> : <><br /><br /><br /><br /><br /></>}
      <Box height="85vh" display="flex" flexDirection="row">
        <Box height="100%" width="30%" pl={15} py={3}>
          <Typography variant="h4" fontWeight="700" >
            Hello there,{batchInfo ? "You are invited to join " : "Welcome to Languify!"}
            {batchInfo && (
              <>
                <span style={{ color: "#02569D" }}>
                  {batchInfo.name}
                </span>
                &nbsp;batch
              </>
            )}
          </Typography>
          <br />
          <Typography variant="body1">
            Learn and upskill in your career everytime you take an assessment
          </Typography>
          {batchInfo && (
            <form onSubmit={handleSubmit} noValidate>
              <Box
                display="flex"
                flexDirection="column"
                width="fit-content"
                mt={2}
              >
                <TextField
                  disabled={emailChecked}
                  autoFocus
                  placeholder="Enter your email..."
                  style={{ "width": "25vw", "marginBottom": "20px" }}
                  id="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={errors.email}
                  onBlur={handleBlur}
                />

                {emailChecked && (
                  <>
                    {values.isNew && (
                      <TextField
                        disabled={values.otpSent}
                        autofill="name"
                        autoFocus
                        placeholder="Enter your name..."
                        style={{ "width": "25vw", "marginBottom": "20px" }}
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && !!errors.name}
                        helperText={errors.name}
                        onBlur={handleBlur}
                      />
                    )}

                    <PasswordField
                      disabled={values.otpSent}
                      placeholder={
                        values.isNew ? "Create password..." : "Enter your password..."
                      }
                      style={{ "width": "25vw", "marginBottom": "20px" }}
                      name="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      error={
                        touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                    />

                    {(values.isNew && values.otpSent) && (
                      <TextField
                        min={6}
                        max={6}
                        autoFocus
                        placeholder="Enter the OTP..."
                        style={{ "width": "25vw", "marginBottom": "20px" }}
                        id="otp"
                        name="otp"
                        label="OTP"
                        value={values.otp}
                        onChange={handleChange}
                        error={touched.otp && !!errors.otp}
                        helperText={errors.otp}
                        onBlur={handleBlur}
                      />
                    )}

                    {values.isNew && (
                      <Typography
                        variant="subtitle2"
                        alignSelf="flex-end"
                        mt={1}
                      >
                        <Link to="/forgot-password" style={{ color: "black" }}>
                          forgot password?
                        </Link>
                      </Typography>
                    )}
                  </>
                )}

              </Box>

              <Box className={classes.buttonContainer} my={2}>
                {emailChecked ? (
                  <>
                    <CustomLoadingButton
                      disabled={loading}
                      size="medium"
                      sx={{ height: "34px" }}
                      onClick={
                        (values.isNew && values.otpSent) ? sendOtp : handleBack
                      }
                      type="button"
                      variant="outlined"
                      startIcon={
                        (values.isNew && values.otpSent) ? null : <BackIcon />
                      }
                    >
                      {(values.isNew && values.otpSent) ? "Resend OTP" : "Back"}
                    </CustomLoadingButton>
                    &nbsp;&nbsp;
                    {values.isNew ?
                      <CustomLoadingButton
                        loading={loading}
                        size="medium"
                        sx={{ height: "34px" }}
                        type="button"
                        onClick={values.otpSent ? handleSubmit : sendOtp}
                      >
                        {values.otpSent ? "Join Batch" : "Send OTP"}
                      </CustomLoadingButton>
                      :
                      <CustomLoadingButton
                        loading={loading}
                        size="medium"
                        sx={{ height: "34px" }}
                        type="button"
                        onClick={handleSubmit}
                      >
                        Join Batch
                      </CustomLoadingButton>
                    }
                  </>
                ) : (
                  <CustomLoadingButton
                    size="medium"
                    loading={loading}
                    sx={{ height: "34px" }}
                    onClick={handleNext}
                    type="button"
                    endIcon={<NextIcon />}
                  >
                    Proceed
                  </CustomLoadingButton>
                )}
              </Box>
            </form>
          )}
        </Box>

        <Box className={classes.rightContainer} >
          <video
            autoPlay
            loop
            muted
            preload="auto"
            className={classes.image}
          >
            <source
              src="https://assets.languify.in/video/1.4.interview-walkthrough.mp4"
              type="video/mp4"
            />
          </video>
        </Box>

      </Box >
    </>
  );
}

export default BatchInvite;