import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import { DocumentOptions, InterviewSteps } from './constants';
import useStore from './store';

const useStyles = makeStyles((theme) => ({
  interviewStepsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '453px',
    position: 'relative'
  },
  interviewStepsContainerNarrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '244px',
    position: 'relative'
  },
  stepBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepCircleContainer: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    backgroundColor: theme.palette.primary['clr-50'],
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    zIndex: '2',
    cursor: 'pointer'
  },
  stepCircleContainerActive: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100px',
    zIndex: '2',
    cursor: 'pointer'
  },
  stepCircleContainerComplete: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.success['clr-100'],
    border: '1px solid',
    borderColor: theme.palette.success['clr-700'],
    borderRadius: '100px',
    zIndex: '2',
    cursor: 'pointer'
  },
  stepCircleContainerDisabled: {
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutral.disabled,
    borderRadius: '100px',
    zIndex: '2',
    cursor: 'pointer'
  },
  stepTextActive: {
    ...theme.typography['body01-link']
  },
  dashProgressBarFirst: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '205px',
    borderBottom: `1px dashed ${theme.palette.neutral['clr-500']}`,
    top: '16px',
    left: '36px'
  },
  dashProgressBarFirstComplete: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '205px',
    borderBottom: `2px solid ${theme.palette.success['clr-700']}`,
    top: '16px',
    left: '36px'
  },
  dashProgressBarFirstNarrow: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '190px',
    borderBottom: `1px dashed ${theme.palette.neutral['clr-500']}`,
    top: '16px',
    left: '36px'
  },
  dashProgressBarFirstNarrowComplete: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '190px',
    borderBottom: `2px solid ${theme.palette.success['clr-700']}`,
    top: '16px',
    left: '36px'
  },
  dashProgressBarSecond: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '205px',
    borderBottom: `1px dashed ${theme.palette.neutral['clr-500']}`,
    top: '16px',
    right: '36px'
  },
  dashProgressBarSecondComplete: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '205px',
    borderBottom: `2px solid ${theme.palette.success['clr-700']}`,
    top: '16px',
    right: '36px'
  }
}));

const Steps = ({ stepNumber, setStepNumber,setIsDataChanged , isDataChanged }) => {
  const classes = useStyles();

  const [
          activeStep,selectedDocument,jobDescription,
          selectCompany,companyName,selectJobRole,jobRole,
          domain,setActiveStep
        ] = useStore(store => [
              store.activeStep,store.selectedDocument,store.jobDescription,
              store.selectCompany,store.companyName,store.selectJobRole,
              store.jobRole,store.domain,store.setActiveStep
            ])

  const [steps, setSteps] = useState(Object.values(InterviewSteps));
  const [containerClass, setContainerClass] = useState(classes.interviewStepsContainer);
  const [progressBar, setProgressBar] = useState(classes.dashProgressBarFirst);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const [initialData, setInitialData] = useState({});
  const [hasVisitedBeyondStep0, setHasVisitedBeyondStep0] = useState(false);

  useEffect(() => {
    if (selectedDocument === DocumentOptions.JD._id) {
      setSteps(steps.filter(step => step._id !== InterviewSteps.SELECT_RESUME._id));
      setContainerClass(classes.interviewStepsContainerNarrow);
      setProgressBar(classes.dashProgressBarFirstNarrow);
    } else {
      setSteps(Object.values(InterviewSteps));
      setContainerClass(classes.interviewStepsContainer);
      setProgressBar(classes.dashProgressBarFirst);
    }
  }, [selectedDocument]);

  useEffect(() => {
    setActiveStep(steps[stepNumber]._id);
    setCompletedSteps((prevCompletedSteps) => {
      const newCompletedSteps = new Set(prevCompletedSteps);
      for (let i = 0; i <= stepNumber; i++) {
        newCompletedSteps.add(steps[i]._id);
      }
      return newCompletedSteps;
    });

    if (stepNumber === 0 && hasVisitedBeyondStep0 && initialData) {
      setIsDataChanged(
        jobDescription !== initialData.jobDescription ||
        selectCompany !== initialData.selectCompany ||
        companyName !== initialData.companyName ||
        selectJobRole !== initialData.selectJobRole ||
        jobRole !== initialData.jobRole ||
        domain !== initialData.domain
      );
    }

    if (stepNumber > 0) {
      setHasVisitedBeyondStep0(true);
    }

    if (stepNumber === 0) {
      setInitialData({
        jobDescription,
        selectCompany,
        companyName,
        selectJobRole,
        jobRole,
        domain,
      });
    }

  }, [stepNumber, jobDescription, selectCompany, companyName, selectJobRole, jobRole, domain]);

  const handleStepClick = (index) => {
    if (index === 0 || (!isDataChanged && (completedSteps.has(steps[index]._id) || index <= stepNumber))) {
      setActiveStep(steps[index]._id);
      setStepNumber(index); // Update the stepNumber prop
      setCompletedSteps((prevCompletedSteps) => {
        const newCompletedSteps = new Set(prevCompletedSteps);
        for (let i = 0; i <= index; i++) {
          newCompletedSteps.add(steps[i]._id);
        }
        return newCompletedSteps;
      });
    }
  };

  return (
    <Box className={containerClass}>
      {steps.map((step, index) => (
        <React.Fragment key={step._id}>
          <Box className={classes.stepBox}>
          <Box
            className={
              isDataChanged
                ? activeStep === step._id
                  ? classes.stepCircleContainerActive
                  : classes.stepCircleContainerDisabled
                : activeStep === step._id
                  ? classes.stepCircleContainerActive
                  : completedSteps.has(step._id)
                  ? classes.stepCircleContainerComplete
                  : classes.stepCircleContainer
            }
            onClick={() => handleStepClick(index)}
          >
          <Typography
            variant='body01-bold'
            color={
              isDataChanged
                ? (activeStep === step._id
                  ? 'primary.clr-50'
                  : 'neutral.clr-600') 
                : (activeStep === step._id
                  ? 'primary.clr-50'
                  : completedSteps.has(step._id)
                  ? 'success.clr-700'
                  : 'neutral.clr-900')
            }
          >
                {index + 1}
              </Typography>
            </Box>
            <Typography
              variant={
                isDataChanged
                  ? (activeStep === step._id
                    ? 'body01-link' 
                    : 'body01-semiBold') 
                  : (activeStep === step._id || completedSteps.has(step._id)
                    ? 'body01-link'
                    : 'body01-semiBold')
              }
              color={
                isDataChanged
                  ? (activeStep === step._id
                    ? 'primary.main'
                    : 'neutral.clr-500') 
                  : (activeStep === step._id
                    ? 'primary.main'
                    : completedSteps.has(step._id)
                    ? 'success.clr-700'
                    : 'neutral.clr-900')
              }
              sx={{cursor:'pointer'}}
              onClick={() => {
                  handleStepClick(index);
              }}
            >
              {step.name}
            </Typography>
          </Box>
          {index < steps.length - 1 && (
            <Box
              className={
                index === 0
                  ? completedSteps.has(steps[index + 1]._id)
                    ? selectedDocument === DocumentOptions.JD._id
                      ? classes.dashProgressBarFirstNarrowComplete
                      : classes.dashProgressBarFirstComplete
                    : progressBar
                  : completedSteps.has(steps[index + 1]._id)
                  ? classes.dashProgressBarSecondComplete
                  : classes.dashProgressBarSecond
              }
            ></Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Steps;
