import { styled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/HelpOutlineOutlined';

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'transparent'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral['clr-800'],
    color: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 10,
    maxWidth: 300,
    ...theme.typography['body01-bold'],
  },
}))

function InfoTooltip({ title, placement = 'bottom', ...props }) {
  return (
    <BootstrapTooltip title={title} placement={placement} {...props}>
      <InfoIcon
        sx={{
          fontSize: 12,
          cursor: 'pointer',
        }}
      />
    </BootstrapTooltip>
  );
}

export const CustomTooltip = ({ component, placement = 'top', children, ...props }) => {
  return (
    <BootstrapTooltip title={component} placement={placement} {...props}>
      {children}
    </BootstrapTooltip>
  )
}

export default InfoTooltip;