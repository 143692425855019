import React, { useState , useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { Box, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  CircularProgress, 
  Divider} from '@mui/material';
import pdfToText from "react-pdftotext";

import { ResumeUploadSteps } from 'components/Profile/constants';
import { Session } from 'utils';
import { useServices , useSnackbar } from 'contexts';
import { saveResume } from 'services';  
import DialogHeader from 'components/DialogHeader';
import CustomButton from 'components/CustomButton';
import TextField from 'components/CustomFields';
import ConfirmationDialog from './ConfirmationDialog';
import ImageViewer from 'components/ImageViewer';
import FileUpload from 'components/FileUpload';
import useStore from '../components/CreateInterview/store';

const useStyles = makeStyles(theme => ({
  headerBox: {
    padding: 0
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  uploadBox: {
    margin: theme.spacing(7, 0, 2, 0),
    width:'484px',
    height:'484px',
    backgroundColor: theme.palette.shades['clr-white-900'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(14),
    padding:theme.spacing(7)
  },
  saveBox: {
    margin: theme.spacing(7, 0, 2, 0),
    width:'484px',
    height:'484px',
    backgroundColor: theme.palette.shades['clr-white-900'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(14),
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-300'],
    borderRadius: '4px',
    padding:theme.spacing(7)
  },
  uploadHead: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center'
  },
  headContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center'
  },
  headTexts: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadChip: {
    height: '28px',
    display: 'flex',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.warning['clr-100'],
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 3)
  },
  fileInput: {
    display: 'none'
  },
  animationContainer: {
    height: '100%', position: 'relative', display: 'flex', flexDirection: 'column',
    overflow: 'hidden', width: 328, transition: 'all 0.5s ease-in-out', 
    justifyContent: 'center', alignItems: 'center', gap: theme.spacing(5)
  },
  progressBox: {
    position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',
  },
  progress: {
    color: "#9DC2F2",
    borderWidth: '9px', transition: 'all 1s ease-in-out', borderRadius :'50%'
  },
  progressText: {
    top: 0, left: 0, bottom: 0, right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  movingBoxes: {
    width: '100%', maxHeight: '150px', display: 'flex', overflow: 'hidden'
  },
  box: {
    display: 'flex', width: '100%', alignSelf: 'end',
    transition: 'all 1s ease-in',
  },
  saveResumeContainer:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:theme.spacing(6),
    width:'100%'
  },
  saveResumeHeadBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2)
  },
  resumeImg:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  resumeNameBox:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2),
    position:'relative'
  },
  saveResumeButtons:{
    display:'flex',
    gap:theme.spacing(3)
  },
  errorImage:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  limitText:{
    position:'absolute',
    right:'20px',
    top:'43px'
  },
  placeholderResume:{
    position:'absolute',
    left:'20px',
    top:'43px'
  }
}));

const SaveResume = ({ 
  onChangeResume, onClose, setError, scrapedData, url, setRefresh, setStep, resumeName, setResumeName 
}) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();

  const setSelectedResumeId = useStore(store => store.setSelectedResumeId);

  const [errorInput, setErrorInput] = useState(false);
  const [charLimitError, setCharLimitError] = useState(false);
  const [placeholders, setPlaceholders] = useState(true);

  useEffect(() => {
    if (resumeName) {
      setPlaceholders(false);
      if (resumeName.length > 150) {
        setCharLimitError(true);
      }
    }
  }, [resumeName]);

  const handleSaveResume = async () => {
    if (resumeName.trim() === '' || charLimitError) {
      setErrorInput(true);
      return;
    }
    const resumeData = {
      name: resumeName,
      url: url,
      parsedData: scrapedData,
      user: Session.userId,
    };
    try {
      const result = await service.callService(saveResume, resumeData);
      
      setSelectedResumeId(result._id);
      setRefresh(true);
      setStep(ResumeUploadSteps.UPLOAD);
      onClose();
      snackbar.success('Resume saved');
    } catch (error) {
      console.log(error);
      setError(true);
      snackbar.error("Uh Oh! Unable to save resume");
    }
  };

  const handleChange = (e) => {
    setPlaceholders(false);
    const { value } = e.target;
    
    // Allow editing if it's reducing or within the limit
    if (value.length <= 20 || resumeName.length > 20) {
      setResumeName(value);
      setErrorInput(false);  // Clear error if valid
      setCharLimitError(value.length > 20); // Set error if over 20
    } else {
      setCharLimitError(true);  // Show error if over 20 characters
    }
  };

  const inputActiveHandler = () => {
    setPlaceholders(false);
  };

  return (
    <Box className={classes.saveBox}>
      <Box className={classes.saveResumeContainer}>
        <Box className={classes.saveResumeHeadBox}>
          <Typography variant='h5-medium' color='neutral.clr-600'>Resume Name</Typography>
          <Divider />
        </Box>
        <Box className={classes.resumeImg}>
          <img
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/saveResumeUpdated.svg'
            alt='save'
            width={156}
          />
        </Box>
        <Box className={classes.resumeNameBox}>
          <Typography variant='body01-bold'>Enter resume name</Typography>
          <TextField
            value={resumeName}
            onChange={handleChange}
            error={errorInput || charLimitError}
            helperText={errorInput 
              ? <Typography sx={{ fontSize: '12px' }} color='danger.main'>
                  Resume name cannot be empty
                </Typography>
              : (charLimitError 
                ? <Typography sx={{ fontSize: '12px' }} color='danger.main'>
                    Resume name cannot exceed 20 characters
                  </Typography>
                : '')}
          />
          {placeholders && (
            <>
              <Typography 
                className={classes.limitText} 
                onClick={inputActiveHandler} 
                variant='body02-medium' 
                color='neutral.clr-400'
              >
                Max. 20 characters
              </Typography>
              <Typography 
                className={classes.placeholderResume}  
                onClick={inputActiveHandler} 
                variant='body02-medium' 
                color='neutral.clr-400'
              >
                Resume Name
              </Typography>
            </>
          )}
        </Box>
        <Box className={classes.saveResumeButtons}>
          <CustomButton
            variant='outlined'
            size="medium"
            sx={{ width: '100%' }}
            onClick={onChangeResume}
          >
            Change Resume
          </CustomButton>
          <CustomButton
            variant='contained'
            size="medium"
            sx={{ width: '100%' }}
            onClick={handleSaveResume}
            disabled={resumeName.length >= 20}
          >
            Save Resume
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

const CircularProgressBar = ({ value, size = 200, duration = 1000 }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let start;
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;
      setProgress(Math.min((elapsed / duration) * value, value));
      if (elapsed < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }, [value, duration]);

  return (
    <Box className={classes.progressBox}>
      <CircularProgress
        variant="determinate"
        className={classes.progress}
        value={progress}
        size={size}
        thickness={1.5}
      />
      <Box className={classes.progressText}>
        <Typography variant='h2-medium' color='primary.main'>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const UploadingProgressCounter = ({ duration = 15000, setStep, finishUpload ,isFileUploaded }) => {
  const classes = useStyles();
  const snackbar = useSnackbar()

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!finishUpload) {
      const interval = duration / 100;
      let increment = 0;
      const timer = setInterval(() => {
        increment += 1;
        setProgress(increment);
        if (increment >= 100) clearInterval(timer);
      }, interval);
      return () => clearInterval(timer);
    } else {
      setProgress(100);
      setTimeout(() => {
        if(isFileUploaded === true){
          snackbar.showSnackbar("File uploaded successfully!!", "success", true);
        }
        setStep(ResumeUploadSteps.SAVE);
      }, 1750);
    }
  }, [duration, finishUpload, setStep]);

  return (
    <Box className={classes.uploadBox}>
      <Box className={classes.animationContainer}>
        <CircularProgressBar value={progress} />
        <Typography variant='h6-medium' color='neutral.clr-700'>
          Please wait for a while...
        </Typography>
      </Box>
    </Box>
  );
};

const UploadResume = ({  
  setStep , setFinishUpload , setError , setScrapedData , setUrl, setIsFileUploaded , setResumeName
 }) => {
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState({ name: undefined, url: "" });
  const [errors, setErrors] = useState(false);
  const [text, setText] = useState("");

  const handlePdfScrape = async(selectedFile) => {
    setStep(ResumeUploadSteps.PROGRESS)
    const scrapedData = await extractResumeData(selectedFile);
    if(scrapedData){
      setScrapedData(scrapedData)
      return true;
    }
  }

  const extractResumeData = async (file) => {
    try {
      const extractedText = await pdfToText(file);
      if (!extractedText || extractedText.trim().length === 0) {
        setError(true)
        return null;
      }else{
        setText(extractedText);
        return extractedText;
      }
    } catch (error) {
      setError(true)
      console.error("Failed to extract text from PDF:", error);
      return null;
    }
  };
  
  const handleUpload = (uploadedFile) => {
    if (!uploadedFile) {
      setError(true); 
      return; 
    }
    setUrl(uploadedFile.url)
    setSelectedFile(uploadedFile);
    setFinishUpload(true)
  };

  const handleUploadCancelled = () => {
    setError(true)
    setSelectedFile({ name: undefined, url: "" });
  };

  return (
    <Box className={classes.uploadBox}>
      <Box className={classes.uploadHead}>
        <img
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/uploadResumeCloud.png'
          alt='cloud'
        />
        <Box className={classes.headContent}>
          <Box className={classes.headTexts}>
            <Typography variant='h4-medium'>Upload Your Resume</Typography>
            <Typography variant='h6-medium'>Upload your resume only in PDF format</Typography>
          </Box>
          <FileUpload
            name="thumbnail"
            label="Upload file"
            file={selectedFile}
            onUpload={handleUpload}
            onUploadCancelled={handleUploadCancelled}
            toPublic={true}
            uploadLocation="pdf/resume"
            accept={['.pdf']} 
            errors={errors}
            setErrors={setError}
            onFileSelect={handlePdfScrape}
            setIsFileUploaded={setIsFileUploaded}
            setResumeName={setResumeName}
          />
        </Box>
      </Box>
      <Box className={classes.uploadChip}>
        <img
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/info-icon-resume.svg'
          alt='chip'
        />
        <Typography variant='body01-semiBold' color='warning.clr-700'>
          PDF file converted from image does not work
        </Typography>
      </Box>
    </Box>
  )
}

const ResumeUploadDialog = ({ openDialog, onClose , setRefresh }) => {
  const classes = useStyles();
  const [finishUpload , setFinishUpload] = useState(false)
  const [error , setError] = useState(false)
  const [scrapedData , setScrapedData] = useState(null)
  const [url , setUrl] = useState(null)
  const [step, setStep] = useState(ResumeUploadSteps.UPLOAD); // 'upload', 'progress', 'save'
  const [isFileUploaded , setIsFileUploaded] = useState(false);
  const [resumeName, setResumeName] = useState('');

  const handleClose = () => {
    onClose(false);
    setStep(ResumeUploadSteps.UPLOAD);
  };

  const handleChangeResume = () => {
    setStep(ResumeUploadSteps.UPLOAD);
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      open={openDialog}
      PaperProps={{style:{maxWidth:'1000px'}}}
    >
      <DialogTitle className={classes.headerBox}>
        <DialogHeader
          handleClose={handleClose}
          title="Upload Resume"
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {step === ResumeUploadSteps.UPLOAD && (
          <UploadResume 
            setStep={setStep} 
            setFinishUpload={setFinishUpload} 
            setError={setError}
            setScrapedData={setScrapedData}
            setUrl={setUrl}
            setIsFileUploaded={setIsFileUploaded}
            setResumeName={setResumeName}
            />
        )}
        {step === ResumeUploadSteps.PROGRESS && (
          <UploadingProgressCounter 
            setStep={setStep} 
            finishUpload={finishUpload}
            setError={setError}
            isFileUploaded={isFileUploaded}
            />
         )}
        {step === ResumeUploadSteps.SAVE && (
          <SaveResume
            onChangeResume={handleChangeResume}
            onClose={onClose}
            setError={setError}
            scrapedData={scrapedData}
            url={url}  
            setRefresh={setRefresh}
            setStep={setStep}
            resumeName={resumeName}
            setResumeName={setResumeName}
          />
        )} 
      </DialogContent>
      <ConfirmationDialog
        open={error}
        title={<Box color='danger.clr-700'>Resume upload failed!</Box>}
        message={
          <Box className={classes.errorImage}>
           <ImageViewer 
             src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/Error_Resume_Save.png' 
             alt='delete' 
             width={170}
             height={170}
           />
          </Box>}
        secondaryAction="I don’t want to upload"
        primaryAction="Upload again"
        primaryActionType="contained"
        secondaryActionType="outlined" 
        buttonSize='medium'
        onPrimaryAction={() => {
          setStep(ResumeUploadSteps.UPLOAD);
          setError(null);
        }}
        onSecondaryAction={() => {
          onClose();
          setStep(ResumeUploadSteps.UPLOAD);
          setError(null);
        }}
      />
    </Dialog>
  )
}

export default ResumeUploadDialog;
