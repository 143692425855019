import AppShell from "components/AppShell";
import PerformanceReview from "components/PerformanceReview";

export default function InteractiveDemoPerformance() {

  return (
    <AppShell showSidebar={false} showDemoAppNavbar={true}>
      <PerformanceReview />
    </AppShell>
  );
}