
export const DashboardIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17 1C18.1046 1 19 1.88316 19 2.9726L19 6.33992C19 7.42936 18.1046 8.31252 17 8.31252H14C12.8954 8.31252 12 7.42936 12 6.33992L12 2.9726C12 1.88316 12.8954 1 14 1L17 1Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 1C1.89543 1 0.999999 1.88316 0.999999 2.9726L1.00001 6.33992C1.00001 7.42936 1.89544 8.31252 3.00001 8.31252H6.00001C7.10458 8.31252 8.00001 7.42936 8.00001 6.33992L8 2.9726C8 1.88316 7.10457 1 6 1L3 1Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17 11.6875C18.1046 11.6875 19 12.5707 19 13.6601V17.0274C19 18.1168 18.1046 19 17 19H14C12.8954 19 12 18.1168 12 17.0274L12 13.6601C12 12.5707 12.8954 11.6875 14 11.6875H17Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.00001 11.6875C1.89544 11.6875 1.00001 12.5707 1.00001 13.6601L1.00001 17.0274C1.00001 18.1168 1.89544 19 3.00001 19H6.00001C7.10458 19 8.00001 18.1168 8.00001 17.0274L8.00001 13.6601C8.00001 12.5707 7.10458 11.6875 6.00001 11.6875H3.00001Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const PerformanceIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M1.39844 1.39844V20.5984H20.5984M6.19844 13.3986L10.3984 9.19856L13.3984 12.1986L18.7986 6.79844" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const BookmarkIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const BlogIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M8.4035 7.19844H15.6035M8.4035 10.7984H15.6035M8.4035 14.3984H12.0035M6.60322 2.39844H17.4034C18.7289 2.39844 19.8035 3.47298 19.8034 4.79848L19.8032 19.1985C19.8031 20.524 18.7286 21.5984 17.4032 21.5984L6.60311 21.5984C5.27763 21.5984 4.20312 20.5239 4.20313 19.1984L4.20322 4.79842C4.20323 3.47295 5.27774 2.39844 6.60322 2.39844Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const ProfileIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5.39844 19.1984C5.85961 18.6819 8.01948 16.3051 8.65244 16.3051H15.3448C16.262 16.3051 18.1344 18.2753 18.5984 18.9698M21.5984 11.9984C21.5984 17.3004 17.3004 21.5984 11.9984 21.5984C6.6965 21.5984 2.39844 17.3004 2.39844 11.9984C2.39844 6.6965 6.6965 2.39844 11.9984 2.39844C17.3004 2.39844 21.5984 6.6965 21.5984 11.9984ZM15.4373 8.72639C15.4373 6.89488 13.8911 5.39844 11.9987 5.39844C10.1063 5.39844 8.56017 6.89488 8.56017 8.72639C8.56017 10.5579 10.1063 12.0544 11.9987 12.0544C13.8911 12.0544 15.4373 10.5579 15.4373 8.72639Z" stroke={color} strokeWidth="2"/>
    </svg>
  )
}

export const PlayIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M14.9751 9.11445C16.1138 9.74903 16.118 10.5473 14.9751 11.2647L6.14429 17.2205C5.03471 17.8126 4.28111 17.463 4.20201 16.1817L4.16453 3.71652C4.13955 2.53629 5.11174 2.20283 6.03812 2.7687L14.9751 9.11445Z" stroke={color} strokeWidth="2"/>
    </svg>
  )
}

export const LogoutIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M14.6471 7.80156V5.70156C14.6471 5.14461 14.424 4.61046 14.0268 4.21664C13.6297 3.82281 13.091 3.60156 12.5294 3.60156H5.11765C4.55601 3.60156 4.01738 3.82281 3.62024 4.21664C3.22311 4.61046 3 5.14461 3 5.70156V18.3016C3 18.8585 3.22311 19.3927 3.62024 19.7865C4.01738 20.1803 4.55601 20.4016 5.11765 20.4016H12.5294C13.091 20.4016 13.6297 20.1803 14.0268 19.7865C14.424 19.3927 14.6471 18.8585 14.6471 18.3016V16.2016M8.29412 12.0016H21M21 12.0016L17.8235 8.85156M21 12.0016L17.8235 15.1516" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const InterviewIcon = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M10.2239 14.4873L9.51276 21.5984L18.7572 11.6429L13.7794 8.79844L14.4905 2.39844L5.24609 12.354L10.2239 14.4873Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const OpenLock = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
      <path d="M8.99688 15.1984V12.7984M3.37188 6.88415C3.37188 3.84524 5.78045 1.39844 8.77188 1.39844C10.6353 1.39844 12.2726 2.34787 13.2424 3.79844M3.59688 7.79844C2.60688 7.79844 1.79688 8.62129 1.79688 9.62701V18.7699C1.79688 19.7756 2.60688 20.5984 3.59688 20.5984H14.3969C15.3869 20.5984 16.1969 19.7756 16.1969 18.7699V9.62701C16.1969 8.62129 15.3869 7.79844 14.3969 7.79844H3.59688Z" stroke={color} stroke-width="2" stroke-linecap="round"/>
    </svg>
  )
}

export const CloseLock = ({ color= 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
      <path d="M3.59688 7.79844V6.88415C3.59688 3.84524 6.00545 1.39844 8.99688 1.39844C11.9883 1.39844 14.3969 3.84524 14.3969 6.88415V7.79844M3.59688 7.79844C2.60688 7.79844 1.79688 8.62129 1.79688 9.62701V18.7699C1.79688 19.7756 2.60688 20.5984 3.59688 20.5984H14.3969C15.3869 20.5984 16.1969 19.7756 16.1969 18.7699V9.62701C16.1969 8.62129 15.3869 7.79844 14.3969 7.79844M3.59688 7.79844H14.3969M8.99688 15.1984V12.7984" stroke={color} stroke-width="2" stroke-linecap="round"/>
    </svg>
  )
}
