import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CustomButton from 'components/CustomButton';

import { useNavigateWithClient } from 'hooks';

export function AnalysisFailedDialog({
  open,
}) {
  const navigate = useNavigateWithClient();

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'md'} style={{ borderRadius: '16px' }}
    >
      <DialogContent>
        <DialogContentText>
          <Box
            display='flex' gap={6} maxWidth={580}
            padding={6} alignItems='center' flexDirection='column'
          >
            <Typography variant='h5-medium' color='neutral.clr-900' mx={2}>
              Oops! There is unknown technical glitch, It's rare! We're resolving it.
              Check out the feedback after some time.
            </Typography>

            <img
              src='https://assets.languify.in/images/signal-connectivity-icon.png'
              alt='technical-issues'
              style={{ width: 150, }}
            />

            <CustomButton
              sx={{ width: '100%' }}
              onClick={() => navigate('/')}
            >
              Okay, I understand!
            </CustomButton>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default AnalysisFailedDialog;