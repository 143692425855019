import { round } from "lodash";
const { CommentAndColor } = require("./constants");

export const getCommentAndColor = (score) => {
    score = round(score);
    if (score >= 90) return CommentAndColor.VERY_GOOD;
    if (score >= 70) return CommentAndColor.GOOD;
    if (score >= 50) return CommentAndColor.FAIR;

    return CommentAndColor.POOR;
};
