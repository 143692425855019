import * as React from 'react';
import { useState, useCallback, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from '@mui/material/Skeleton';

import { TourProviderContext, useServices, useSnackbar } from "../../contexts";
import { Session, searchStringInString } from 'utils';
import { getBookmarks } from 'services';
import { BookmarkTourSteps } from './constants';
import ExpandableTableRow from './DynamicDataRows';
import TourStartDialog from 'dialogs/TourStartDialog';
import AppShell, { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SearchTextField } from 'components/CustomFields';
import CustomButton from 'components/CustomButton';
import { useNavigateWithClient } from 'hooks';

const useStyles = makeStyles(theme=>({
  tableContainer: {
    borderRadius: theme.spacing(1),
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-200'],
  },
  tableHeadContainer: {
    height: "60px",
  },
  tableHeading: {
    ...theme.typography['h6-regular'],
    color: theme.palette.primary['clr-300'],
  },
  tableRow: {
    height: "40px",
    "& td": {
      ...theme.typography['h6-medium'],
      borderBottom: "none"
    }
  },
  skeletonRows: {
    width: "100%",
  },
  emptyComponent: {
    display: 'flex', flexDirection: 'column', textAlign: 'center',
    justifyContent: 'center', 
    alignItems: 'center',
    gap: theme.spacing(2), 
    padding: theme.spacing(8,0)
  },
  emptyGradientBox: {
    background: "linear-gradient(284.04deg, #BE93C5 0%, #7BC6CC 100%)",
    height: '200px',
    textAlign: 'center', 
    borderRadius: theme.spacing(1),
    display: 'flex', 
    justifyContent: 'center', alignItems: 'center',
  }
}));

const NoBookmarkComponent = () => {
  const classes = useStyles();
  const navigateWithClient = useNavigateWithClient();

  return (
    <>
      <Box className={classes.emptyGradientBox}>
        <img
            src={"https://assets.languify.in/images/bookmarkShadow.svg"}
            alt="not-bookmarks found"
            width={90}
        />
        <Typography color="shades.clr-white-900" variant="h2-medium">
          Find your bookmarked questions here!
        </Typography>
      </Box>
      <WhiteBox>
        <Box className={classes.emptyComponent}>
            <img
                src={"https://assets.languify.in/images/bookmark.svg"}
                alt="not-bookmarks found"
                height={140}
            />
            <Typography color="neutral.clr-500" variant="h5-medium">
              To bookmark important questions from assessments for reading, <br/>
              go to the performance page and select any assessment
            </Typography>
            <CustomButton
                onClick={()=>navigateWithClient(`/performance`)}
                variant="contained"
                size="small"
                sx={{ textTransform: "none", mt: '15px' }}
            >
              Performance Page
            </CustomButton>
        </Box>
      </WhiteBox>
    </>
  )
}

const BookmarkSkeleton = () => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
    {
      Array.from(new Array(3)).map((data, i)=>{
        return (
          <Skeleton key={i} variant="rectangular" width="100%" height='60px' />
        )
      })
    }
    </Box>
  )
}

const Bookmark = () => {
    const classes = useStyles();
    const service = useServices();
    const snackbar = useSnackbar();

    const { setSteps, play } = useContext(TourProviderContext);
    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false)

    const [loading, setLoading] = useState(false);
    const [activeRow, setActiveRow] = useState(-1);
    const [search, setSearch] = useState('');
    const [bookmarks, setBookmarks] = useState([]);
    const [filteredBookmarks, setFilteredBookmarks] = useState([]);

    useEffect(() => {
      (async () => {
        try {
          setLoading(true);

          const bookmarkFilters = {
              user: Session.userId,
              include: "interviewTemplate,interviewQuestion,interviewAttempt,interviewAnswer"
          };

          const { bookmarks } = await service.callService(
              getBookmarks, bookmarkFilters
          );
          
          setBookmarks(bookmarks);
          setFilteredBookmarks(bookmarks);
        } catch (error) {
            console.log(error);
            snackbar.error("Uh Oh! Unable to get the Bookmarks");
        } finally {
            setLoading(false);
        }
      })();
    }, []);

    useEffect(() => {
      const filterBookmark = bookmarks?.filter(bookmark => {
        const searchString =
          ` ${bookmark?.interviewQuestion?.question} ${bookmark?.interviewTemplate?.name} `;

        return search.length ?
          searchStringInString(search, searchString) :
          bookmarks
      });
      setFilteredBookmarks(filterBookmark);
    }, [search, bookmarks]);

    useEffect(() => {
      if (play) {
        const showDummyBatches = filteredBookmarks.length === 0;

        if (showDummyBatches) {
          setFilteredBookmarks(BookmarkTourSteps[1].data.bookmarks);
          setBookmarks(BookmarkTourSteps[1].data.bookmarks);
        }

        return () => {
          if (showDummyBatches) setFilteredBookmarks([]);
        };
      }
    }, [play])

    // useEffect(() => {
    //     if (!localStorage.getItem('v2.0-bookmark-tour')) {
    //         localStorage.setItem('v2.0-bookmark-tour', true.toString());
    //         setSteps(BookmarkTourSteps);
    //         setTimeout(() => setOpenWelcomeDialog(true), 2000);
    //     }
    // }, []);

    const handleRemoveRow = (id) => setBookmarks(
      bookmarks.filter(({ _id }) => (id !== _id))
    );

    const handleExpand = useCallback((id) => setActiveRow(id), []);

    return (
      <AppShell headerLabel='Bookmark'>
        {
          (!bookmarks.length && !loading) ? <NoBookmarkComponent/>
          :
          <WhiteBox>
            <WhiteBoxHeader
              heading='Bookmarked questions'
              subheading='We have compiled all of your bookmarked questions here'
            >
              <SearchTextField
                sx={{ width: '300px'}}
                placeholder="Search Question or Assessment"
                onChange={(e) => setSearch(e.target.value)}
              />
            </WhiteBoxHeader>

            <Box mt={6}>
              <TableContainer
                id="bookmark-questions-container"
                className={classes.tableContainer}
                component={Paper}
              >
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableHeadContainer}>
                      <TableCell width={"25%"} className={classes.tableHeading}>Assessment</TableCell>
                      <TableCell width={"40%"} className={classes.tableHeading}>Question</TableCell>
                      <TableCell width={"15%"} className={classes.tableHeading}>Attempt</TableCell>
                      <TableCell width={"5%"} className={classes.tableHeading}>Date</TableCell>
                      <TableCell className={classes.tableHeading}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                    filteredBookmarks.length && !loading ?
                      filteredBookmarks.map((item, index) => (
                        <ExpandableTableRow
                          expand={activeRow === index ? true : false}
                          key={index} data={item} onExpand={handleExpand}
                          index={index} onRowRemove={handleRemoveRow}
                        />
                      ))
                      :
                      <TableRow>
                        <TableCell className={classes.skeletonRows} colSpan={5}>
                            <BookmarkSkeleton/> 
                        </TableCell>
                      </TableRow>
                  }
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </WhiteBox>
        }
        <TourStartDialog
            open={openWelcomeDialog}
            onClose={setOpenWelcomeDialog}
            title="Introducing Bookmarks!!"
            description="Introducing all new feature to save your important questions for later read & practice. Let's take a quick tour of Bookmarks and see how it can help you land your dream job!"
        />
      </AppShell>
    )
}

export default Bookmark