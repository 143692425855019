import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import StatusChip from 'components/Chip';

import { LockedState } from 'components/Pathway/Module';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { isNumber } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 426,
    margin: theme.spacing(1),
    height: 240,
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    boxShadow: '0px 0px 2px 0px #000000BF',
    position: 'relative'
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    display: 'flex', justifyContent: 'space-between'
  },
  tip: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  progressBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: "70px",
    height: "10px",
    gap: theme.spacing(1)
  },
  progressBox: {
    height: "11px",
    width: "11px",
    borderRadius: '4px'
  },
  middleElements: {
    display: 'flex', flexDirection: 'column', justifyContent: 'center'
  },
  fillerWords: {
    display: "flex", gap: theme.spacing(2.5),
    overflow: 'auto',
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1, 0),
    '&::-webkit-scrollbar': {
      height: theme.spacing(1)
    },
  },
  subheadings: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "600",
    mb: "2px"
  },
  arrayParameterRoot: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    my: theme.spacing(1),
  },
  paraArray: {
    display: "inline-block",
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    marginRight: "5px",
  }
}));
export function ValueLabelComponent(props) {
  const { children, value } = props;
  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}
export const CustomSlider = styled(Slider)({
  color: '#02569D',
  height: 8,
  '& .MuiSlider-rail': {
    background: "linear-gradient(90deg, #FF2D55 0%, #FFCC00 50%, #41D7A1 100%)",
    opacity: 1
  },
  '& .MuiSlider-track': {
    border: 'none',
    background: "transparent",
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#02569D',
    border: '4px solid white',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
});

export function ArrayParameter({ data = [] }) {
  const classes = useStyles();
  return (Array.isArray(data) && data.length > 0) ? (
    <Box className={classes.arrayParameterRoot}>
      <Box>
        <Typography className={classes.subheadings}>
          Your Repeated Mistakes
        </Typography>
        {data?.map((value, i) => (
          <Box
            key={i}
            className={classes.paraArray}
          >
            {
              value?.word ?
                <>
                  {value.word}
                  <span style={{ color: "#FF2D55" }}>{" x " + value.count}</span>
                </>
                :
                <>
                  {value}
                </>
            }
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
}

export function LinearParameter({ data }) {
  const value = data !== undefined ? data : 0;
  const level = value >= 7 ? "High" : value >= 4 ? "Okay" : "Low";
  return (
    <Box my={1} position="relative">
      <CustomSlider
        value={value}
        min={0}
        max={10}
        valueLabelDisplay="auto"
        slots={{ valueLabel: ValueLabelComponent }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
      >
        {[
          { label: "Low", bgcolor: "#FFC7D2", color: "#FF0031" },
          { label: "Okay", bgcolor: "#FFF0BA", color: "#FFC500" },
          { label: "High", bgcolor: "#C1E9DB", color: "#00A469" },
        ].map(({ label, bgcolor, color }, i) => (
          <Box
            key={i}
            bgcolor={bgcolor}
            border={`1px solid ${color}`}
            px={1}
            py="4px"
            fontSize={10}
            fontWeight={600}
            borderRadius={1}
            visibility={level === label ? "visible" : "hidden"}
          >
            {label}
          </Box>
        ))}
        <span
          style={{
            position: "absolute",
            left: "calc(25% - 6px)",
            top: "10%",
            color: "#5B5C5C",
            fontWeight: 500,
          }}
        >
          4
        </span>
        <span
          style={{
            position: "absolute",
            left: "calc(75% - 6px)",
            top: "10%",
            color: "#5B5C5C",
            fontWeight: 500,
          }}
        >
          7
        </span>
      </Box>
      <Box
        width="50%"
        mx="auto"
        height={40}
        border="1px dashed #5B5C5C"
        sx={{ borderWidth: "0px 2px 0px 2px", pointerEvents: "none" }}
        position="absolute"
        top={0}
        left="25%"
      />
    </Box>
  );
}

export function LinearBoxesProgressBar({ data, tags = true }) {
  const classes = useStyles();
  const value = data || 0;
  const level = value >= 7 ? "High" : value >= 4 ? "Average" : "Low";
  const color = value >= 7 ? "#19C10A" : value >= 4 ? "#FFD600" : "#F80505";
  return (
    <Box mt="-4px">
      {
        tags ?
          <Box
            py="4px"
            fontSize={10}
            fontWeight={600}
          >
            {level}
          </Box>
          : null
      }
      <Box className={classes.progressBar}>
        {[1, 2, 3, 4, 5].map((boxLevel, index) => (
          <Box
            className={classes.progressBox} key={index}
            backgroundColor={boxLevel > value / 2 ? "#A3A3A3" : color}
            borderLeft={
              ((boxLevel === parseInt((value / 2)) + 1) && (value % 2) > 0)
                ? `6px solid ${color}` : ""
            }
          >
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export const ParameterScoreSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={{ justifyContent: 'space-between' }}>
      <Box className={classes.cardHeader}>
        <Skeleton variant="rectangular" width="50%" height='40px' />
        <Skeleton variant="rectangular" width="20%" height='40px' style={{ borderRadius: '20px' }} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height='40px' />
      <Skeleton variant="rectangular" width="40%" height='40px' />
      <Skeleton variant="rectangular" width="100%" height='30px' />
    </Box>
  )
}

export function ParameterScore({
  title,
  type,
  data,
  tip = "",
  info = "",
  valueParser = (v) => v,
  generateTip,
  legends,
  locked = false,
  loading = false
}) {
  const classes = useStyles();
  const theme = useTheme();
  const additionalText =
    title === "Pauses" ? "unnecessary pauses" :
      title === "Disfluencies" ? "breakdown" :
        "";
  const isFillerWords = title === 'Filler words' ? "filler words" : '';
  const { content, color, bgColor } = React.useMemo(() => {
    if (type === "meter") {
      return {
        content: valueParser(data.value),
        color: theme.palette.success['clr-700'],
        bgColor: theme.palette.success['clr-100']
      }
    }
    if (additionalText) {
      return {
        content: `${isNumber(data) ? data : data?.value} ${additionalText}`,
        color: theme.palette.secondary['clr-900'],
        bgColor: theme.palette.secondary['clr-100']
      }
    } else {
      return { content: null, color: null, bgColor: null }
    }
  }, [data, additionalText]);

  return (
    (loading) ? <ParameterScoreSkeleton /> :
      <Box className={classes.root}>
        <Box className={classes.cardHeader}>
          <Typography variant='h4-semiBold' color='neutral.clr-600'>
            {title}
          </Typography>
          {
            content &&
            <StatusChip
              content={content}
              color={color}
              bgColor={bgColor}
            />
          }
        </Box>
        <Typography variant='body02-medium' color='neutral.clr-600' mb={2}>
          {info}
        </Typography>
        {
          !isFillerWords &&
          <Divider />
        }
        <Box className={classes.middleElements}>
          {(isFillerWords && data?.value.length) ? (
            <Box className={classes.fillerWords}>
              {[...data?.value,...data?.value]?.map((item, index) => {
                return (
                  <StatusChip
                    key={index}
                    content={item}
                    bgColor={theme.palette.secondary['clr-50']}
                    sx={{
                      border: `1px solid ${theme.palette.secondary['clr-300']}`,
                      minWidth: 'fit-content'
                    }}
                  />
                );
              })}
            </Box> 
          ) : null}
        </Box>
        <Box mt={2}>
          <Typography variant='h6-semiBold' color='primary.main'>How to improve:</Typography>
          <Typography className={classes.tip} variant='body02-medium' color='neutral.clr-700'>
            {generateTip ? generateTip(data.value) : tip}
          </Typography>
        </Box>
        {locked ?
          <LockedState
            content={<span>After first attempt you can see your<br /> performance </span>}
            showIcon={true}
            iconWidth={70}
            styles={{ ...theme.typography['h5-medium'] }}
          />
          : null
        }
      </Box>
  );
}

export default ParameterScore;