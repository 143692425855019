import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { getCommentAndColor } from './PerformanceReview/helpers';
import { round } from 'utils';
import InfoIconWithTooltip from './InfoIconWithTooltip';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        position: 'relative',
        display: 'inline-flex',
        backgroundColor: theme.palette.neutral['clr-100'],
        borderRadius: "50%",
    },
    progressTextContainer: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.shades['clr-white-900'],
        borderRadius: "50%",
        margin: "10%",
    },
    percentageUp: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        borderRadius: 4,
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.success['clr-100'],
        color: theme.palette.success['clr-500'],
        ...theme.typography['body01-bold']
    },
    percentageDown: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        borderRadius: 4,
        backgroundColor: theme.palette.danger['clr-100'],
        color: theme.palette.danger['clr-500'],
        ...theme.typography['body01-bold']
    },
    label: {
        backgroundColor: theme.palette.secondary['clr-100'],
        padding: theme.spacing(1.5, 3),
        display: 'flex', alignItems: 'center', borderRadius: '16px',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2)
    }
}));
function CircularProgressWithLabel(props) {
    const classes = useStyles();

    return (
        <Box
            className={classes.progressContainer}
            width={props.size || 140} height={props.size || 140}
        >
            <CircularProgress
                variant="determinate"
                sx={{ color: props.bgcolor, }}
                {...props}
            />
            <Box className={classes.progressTextContainer}>
                <Typography variant={props.fontVariant} color={props.bgcolor}>
                    {`${round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircleProgressbarWithInfo(
    {
        value = 0, size = 180, fontVariant = "h4-medium", thickness = 4,
        tooltip = '', label = '', showComparison = false, percentageChange = 0, ...props
    }
) {
    const data = getCommentAndColor(value);
    const classes = useStyles();

    return (
        <Box
            display='flex' flexDirection='column'
            justifyContent={'center'} alignItems={'center'}
        >
            <CircularProgressWithLabel
                value={value}
                bgcolor={data.color}
                thickness={thickness}
                size={size}
                fontVariant={fontVariant}
                {...props}
            />

            {(showComparison) &&
                <Box
                    className={(
                        percentageChange >= 0 ?
                            classes.percentageUp :
                            classes.percentageDown
                    )}
                    color={percentageChange >= 0 ? "#00C7BE" : "#FF2D55"}
                >
                    {percentageChange >= 0 ? (
                        <ArrowUpwardIcon fontSize='inherit' />
                    ) : (
                        <ArrowDownwardIcon fontSize='inherit' />
                    )}
                    {percentageChange < 0 ? -round(percentageChange) :
                        round(percentageChange)
                    }%
                </Box>
            }

            {
                (label) &&
                <Box className={classes.label}>
                    <Typography variant='body01-bold' color='neutral.clr-600'>
                        {label}
                    </Typography>

                    {(tooltip) && <InfoIconWithTooltip
                        placement='right'
                        title={tooltip}
                        fontSize='inherit'
                    />}
                </Box>
            }
        </Box>
    );
}
