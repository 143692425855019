import { useNavigate, useParams } from 'react-router-dom';
import Session from "../utils/Session";
import { getClientPrefix } from "../utils";

/**
 *
 * @returns A hook which will be navigating to a path (e.g. '/path')
 *  with client name prefixed in the route
 */
export const useNavigateWithClient = () => {
  const { client } = useParams();
  const navigate = useNavigate();

  let clientPrefix;
  if (Session.isLoggedIn()) {
    clientPrefix = getClientPrefix();
  }

  if (!clientPrefix) clientPrefix = client;

  return (path, state = {}) => {
    const previousPathname = window.location.pathname;
    navigate(`/${clientPrefix}${path}`, { state: { ...state, from: previousPathname } });
  };
};

export default useNavigateWithClient;
