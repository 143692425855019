import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, Divider, FormControl, IconButton, Typography } from '@mui/material';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import DialogHeader from 'components/DialogHeader';
import { Field, Form, Formik } from 'formik';
import { useLoading, useSnackbar } from 'contexts';
import { createPathwayRequest } from 'services/pathwayService';
import { Session } from 'utils';
import TextField, { TextArea } from 'components/CustomFields';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=>({
  content: {
    height: '600px', overflow: 'auto',
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  dialogActions: {
    display: 'flex', justifyContent: 'space-between', 
    boxShadow: '0px -1px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  detailsBox: {
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)", 
    padding: theme.spacing(3,4), 
    borderRadius: '4px',
    width: '450px',
  },
  formFields: {
    marginTop: theme.spacing(4), display: 'flex', flexDirection: 'column', 
    gap: theme.spacing(3)
  },
  formControl: {
    display: 'flex', flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex', flexDirection: 'column', alignItems: 'center', 
    marginTop: theme.spacing(8), gap: theme.spacing(1),
  },
  submitBtn: { 
    textTransform: 'capitalize', height: '30px', width: '100%'
  },
  confirmationRoot: {
    padding: theme.spacing(2,5), 
    paddingBottom: theme.spacing(6), 
    position: 'relative'
  },
  confirmationHead: { 
    boxShadow: 'none', display: 'flex', alignItems: 'center',
    justifyContent: 'space-between', 
    borderBottom: '1px solid',
    borderColor: theme.palette.neutral['clr-200']
  },
  title: {
    display: 'flex', gap: '10px'
  },
  receivedIcon: {
    position: 'absolute', bottom: '0px', right: '0px'
  }
}));

const validationSchema = Yup.object({
  jobProfile: Yup.string().required('Job Profile is required'),
  company: Yup.string().required('Company Name is required'),
  description: Yup.string()
    .required('Job Description is required')
    .min(200, 'Job Description must be at least 200 words'),
});

export function RequestConfirmationDialog({
  openConfirmation, setOpenConfirmation=()=>{}
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openConfirmation);
  
  const handleClose = () => {
    setOpenConfirmation(false);
    setOpen(false);
  };

  React.useEffect(()=>{
    setOpen(openConfirmation);
  },[openConfirmation]);


  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      minHeight='400px'
      open={open}
    >
      <Box className={classes.confirmationRoot}>
        <Box className={classes.confirmationHead}>
          <Typography variant='h6-semiBold' className={classes.title}>
            <TaskAltIcon style={{color: '#237611'}}/>
            Request Received 
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon style={{ fontSize: '30px' }}/>
          </IconButton>
        </Box>
        {/* content */}
        <DialogContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', paddingBottom: '0px'}}>
          <Typography variant='h6-medium' mb='10px'>
            Hey {Session.user?.name}!
          </Typography>
          <Typography variant='body01-medium'>
            Your pathway request has been successfully received. Our team of curriculum experts will provide you with the requested information within 3-4 working days. Your pathway is on its way.
          </Typography>

          <CustomButton onClick={handleClose} 
            sx={{
              width: 'fit-content', alignSelf: 'center', mt: '20px'
            }}
            variant='outlined'
          >
            Close
          </CustomButton>

          <Box className={classes.receivedIcon}>
            <img src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/Rectangle.svg' alt='req_received'/>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}


export default function RequestPathwayDialog({ 
  openDialog, onClose, closeAddPathway, ...props 
}) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [open, setOpen] = React.useState(openDialog);
  const [openConfirmation,setOpenConfirmation] = React.useState(false);
  
  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async (data) => {
    try{
      const requestData = {
        user: Session.userId,
        metadata: data
      }
      const requestCreated = await createPathwayRequest(requestData);
      if(requestCreated){
        setOpenConfirmation(true);
        snackbar.success("Pathway Request has been created");
        closeAddPathway();
        handleClose();
      }
    }catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to update the Modules", true);
    } finally{
      loading.hide();
    }
  }

  React.useEffect(()=>{
    setOpen(openDialog);
  },[openDialog]);

  return (
    <>
    <Dialog
      onClose={handleClose}
      maxWidth={'lg'}
      fullWidth={true}
      open={open}
      PaperProps={{
        sx: {
          maxHeight: '95%'
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 0}}>
        <DialogHeader 
          handleClose={handleClose} 
          title="Request Pathway"
          subtitle="The journey to your dream job is just a few steps away."
        />
      </DialogTitle>

      {/* content */}
      <DialogContent className={classes.content}>
        <Box className={classes.detailsBox}>
          <Box display='flex' alignItems='center'>
            <IconButton sx={{ p: '5px 0px', mb: '4px', mr: '4px'}} onClick={handleClose}>
              <ArrowBackIcon style={{ color: '#1961BF'}}/>
            </IconButton>
            <Typography variant='h6-semiBold' mb='5px'>
              Share details for your personalized pathway
            </Typography>
          </Box>
          <Divider/>

          <Formik
            initialValues={{
              jobProfile: '',
              company: '',
              description: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Box className={classes.formFields}>
                  <FormControl className={classes.formControl} required>
                    <Field 
                      as={TextField} label='Tell us job profile' name='jobProfile' 
                      error={touched.jobProfile && errors.jobProfile}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} required>
                    <Field 
                      as={TextField} label='Company Name' name='company' 
                      error={touched.company && errors.company}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} required>
                    <Field 
                      label='Job description'
                      as={TextArea} rows={5} 
                      placeholder='Please note that a clear and detailed job description will help us create the best pathway for you (minimum 200 words)' 
                      name='description'
                      error={touched.description && errors.description} 
                    />
                  </FormControl>
                </Box>
                <Box className={classes.buttonContainer}>
                  <CustomButton variant='contained' type="submit" sx={{ width: '100%'}}>
                    Submit Request
                  </CustomButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
    <RequestConfirmationDialog
      openConfirmation={openConfirmation}
      setOpenConfirmation={setOpenConfirmation}
    />
    </>
  );
}