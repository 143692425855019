import React from 'react';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CustomButton from './CustomButton';

const useStyles = makeStyles(theme=>({
  root: {
    background: 'linear-gradient(284.04deg, #BE93C5 0%, #7BC6CC 100%)',
    height: '461px', width: '100%', borderRadius: '5px',
    marginBottom: theme.spacing(5), 
    padding: theme.spacing(5, 15),
    display: 'flex', 
  },
  leftSection: { 
    width: '60%', display: 'flex', flexDirection: 'column', 
    alignItem: 'center', justifyContent: 'center', 
    marginLeft: theme.spacing(3)
  },
  rightSection: { 
    width: '40%', textAlign: 'center', 
    paddingTop: theme.spacing(4),
    display: 'flex', flexDirection: 'column', 
    justifyContent: 'center', alignItems: 'center', gap: theme.spacing(4)
  },
}));

const Banner = () => {
  const classes = useStyles();

  const handlePreparation = () => {
    window.open(process.env.REACT_APP_LANGUIFY_PAYMENT_URL, '_blank');
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.leftSection}>
        <Typography variant='h1-medium' color='shades.clr-white-900'>
          Be prepared for your next interview with Languify’s <b>In-Prep</b>
        </Typography>
        <Typography variant='h4-medium' mt={2} color='shades.clr-white-900'>
          Interviews are now easier to crack!
        </Typography>

        <Box mt={15}>
          <CustomButton
            size='medium'
            onClick={handlePreparation}
          >
            Start your preparation
          </CustomButton>
        </Box>
      </Box>
      <Box className={classes.rightSection}>
        <img 
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/mascot2.png' alt='bot' 
          width='250px'
        />
        <Typography color='shades.clr-white-900' variant='h1-xl-regular' fontSize={70}>
          Get Hired
        </Typography>
      </Box>
    </Box>
  )
}

export default Banner;