import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material'
import * as yup from "yup";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';

import { useSnackbar } from "contexts/SnackbarProvider";
import { CustomLoadingButton } from "components/CustomButton";
import useNavigateWithClient from "hooks/useNavigateWithClient";
import Session from "utils/Session";
import { SignupShell } from "components/LoginShell";
import OtpVerification from "components/OtpVerification";
import { getBatchesWithDomains, getExisitingUser, signupAndEnrollInBatch } from "services";
import { getClientPrefix } from "utils";
import TextField, { PasswordField } from "components/CustomFields";
import { CountryCodeSelect, SimpleSelect } from "components/CustomSelectFields";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex", alignItems: "center", justifyContent: "flex-end",
    width: "25vw", flexDirection: "row"
  },
  fieldsContainer: {
    width: '100%',
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  backButton: {
    width: 100, height: 44, 
    ...theme.typography['body01-bold'],
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main, 
    backgroundColor: theme.palette.shades['clr-white-900'], 
    marginRight: 20,
    textTransform: 'none',
    "&:hover": { 
      background: theme.palette.shades['clr-white-900'], 
      border: '2px solid', borderColor: theme.palette.primary.main 
    }
  }, 
  nextButton: {
    minWidth: 80, height: 44,
    ...theme.typography['body01-bold'],
    textTransform: 'none',
  },
  label: {
    ...theme.typography['h6-medium'],
    textTransform: 'capitalize', marginBottom: theme.spacing(1)
  }
}));

const SignupValidationSchema = yup.object({
  email: yup.string().email("Please enter a correct email")
    .required("Please provide your email"),
  name: yup.string().required("Please enter you full name"),
  contact: yup.number().required("Please enter your contact"),
  batch: yup.string().required("Please select your job profile"),
});

function Signup() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const navigateWithClient = useNavigateWithClient();

  const [step, setStep] = React.useState(1);

  const [loading, setLoading] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("91");
  const [userDetails, setUserDetails] = React.useState({});
  const [otpVerified, setOtpVerified] = React.useState(false);
  const [batches, setBatches] = React.useState([]);

  const formik = useFormik({
    initialValues: { email: "", name: "", contact: "", batch: "" },
    validationSchema: SignupValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        setUserDetails(values);

        const filters = {
          email: encodeURIComponent(values.email),
          contact: encodeURIComponent(`+${countryCode.concat(values.contact)}`)
        }

        await getExisitingUser(filters);
        setStep(step+1);

      } catch (error) {
        formik.setFieldError('email', " "); 
        formik.setFieldError('contact', " "); 
        console.error(error);
        snackbar.error(error.message, true);
      } finally {
        setLoading(false);
      }
    },
  });

  const passwordForm = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: yup.object({
      password: yup.string().min(8).required("Please enter a password"),
      confirmPassword: yup.string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8).required("Please enter confirm password")
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);

        if(values.confirmPassword !== values.password){
          passwordForm.setFieldError('confirmPassword', "Password doesn't match");
        }else{
          // signup api call
          const data = {
            ...userDetails,
            contact: `+${countryCode.concat(userDetails.contact)}`,
            password: values.password
          }

          const {user} = await signupAndEnrollInBatch(data);
          if (user) {
            snackbar.success("Signup successful");
            navigate(`/${getClientPrefix(user)}/home`);
          }
        }
      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  React.useEffect(()=> {
    (async() => {
      try{
        const data = await getBatchesWithDomains();

        const domainBatches = data.map(d=> {
          return {
            _id: d._id,
            name: d?.domain?.alias || d?.domain?.name,
          }
        });
        setBatches(domainBatches);
        formik.setFieldValue('batch', domainBatches[0]._id);
      }catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get job profiles", true);
      }
    })();
  },[]);

  useEffect(() => {
    if (Boolean(sessionStorage.getItem("password_reset"))) {
      sessionStorage.removeItem("password_reset");
    }
  }, []);

  useEffect(() => {
    if (Session.isLoggedIn()) navigateWithClient("/home");
  }, []);

  return (
    <SignupShell title={step===2 ? 'OTP Verification' : 'Signup'}>
      {
        step === 1 &&
        <form onSubmit={formik.handleSubmit} style={{ width: '80%' }}>
          <Box className={classes.fieldsContainer} >
            <TextField
              id='email'
              name='email'
              label='Email'
              placeholder='example@gmail.com'
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={(formik.touched.email && !!formik.errors.email) ? formik.errors.email : ''}
              onBlur={formik.handleBlur}
            />

            <TextField
              id='name'
              name='name'
              label='Full Name'
              placeholder='Robert Atkinson'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={(formik.touched.name && !!formik.errors.name) ? formik.errors.name : ''}
              onBlur={formik.handleBlur}
            />
            
            <Box>
              <label htmlFor="contact" className={classes.label}>
                Contact No.
              </label>
              <Box display='flex' gap='10px' width='100%' id='contact'>
                <CountryCodeSelect
                  onChange={(e) => {setCountryCode(e.target.value)}}
                  name="countryCode"
                  placeholder=""
                  sx={{ width: '80px' }}
                  value={countryCode}
                />
                <TextField
                  id='contact'
                  sx={{ width: '100%' }}
                  name='contact'
                  label=''
                  placeholder='9713xxxxxx'
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  error={(formik.touched.contact && !!formik.errors.contact) ? formik.errors.contact : ''}
                  onBlur={formik.handleBlur}
                />
              </Box>
              <Typography style={{ color: '#02569D', fontSize: '16px' }}>
                We will connect you with like-minded People through our community
              </Typography>
            </Box>

            <SimpleSelect
              options={batches}
              name="batch"
              placeholder="Select Option"
              label="Target Job Profile"
              value={formik.values.batch}
              onChange={formik.handleChange}
              error={(formik.touched.batch && !!formik.errors.batch) ? formik.errors.batch : ''}
              onBlur={formik.handleBlur}
            />

            <Box width='fit-content' alignSelf='center'>
              <CustomLoadingButton
                size="medium"
                onClick={ formik.handleSubmit }
                type="button"
                className={classes.nextButton}
                loading={loading}
              >
                Next
              </CustomLoadingButton>
            </Box>
            <Box width='fit-content' alignSelf='center'>
              <Typography variant="body01-bold">
                Already have an account? &nbsp;
                <span 
                  style={{ color: theme.palette.primary['clr-300'], cursor: 'pointer', textDecoration: 'underline' }} 
                  onClick={()=> navigate('/languify/login?b2c=true')}
                >
                  Login here
                </span>
              </Typography>
            </Box>
          </Box>
        </form>
      }
      {
        step === 2 &&
        <Box style={{ width: '80%' }}>
          <Box className={classes.fieldsContainer} >

            <OtpVerification 
              countryCode={countryCode}
              phone={userDetails.contact}
              setStep={setStep}
              otpVerified={otpVerified}
              setOtpVerified={setOtpVerified}
              numberOfDigits={6}
            />

            <Box width='fit-content' alignSelf='center'>
              <CustomLoadingButton
                size="medium"
                onClick={() => {setStep(step-1)}}
                type="button"
                className={classes.backButton}
                loading={false}
              >
                Back
              </CustomLoadingButton>
              <CustomLoadingButton
                size="medium"
                onClick={ () => {
                  if(otpVerified) {
                    setStep(step+1);
                  }else{
                    snackbar.error('Enter valid OTP')
                  }
                }}
                type="button"
                className={classes.nextButton}
                loading={loading}
              >
                Next
              </CustomLoadingButton>
            </Box>
          </Box>
        </Box>
      }
      {
        step === 3 && 
        <form onSubmit={passwordForm.handleSubmit} style={{ width: '80%' }}>
          <Box className={classes.fieldsContainer} >
            <PasswordField
              id='password'
              name='password'
              label='Enter password'
              placeholder='xxxxxxx'
              value={passwordForm.values.password}
              onChange={passwordForm.handleChange}
              error={(passwordForm.touched.password && !!passwordForm.errors.password) ? passwordForm.errors.password : ''}
              onBlur={passwordForm.handleBlur}
            />
            <PasswordField
              id='confirmPassword'
              name='confirmPassword'
              label='Confirm password'
              placeholder='xxxxxxx'
              value={passwordForm.values.confirmPassword}
              onChange={passwordForm.handleChange}
              error={(passwordForm.touched.confirmPassword && !!passwordForm.errors.confirmPassword) ? passwordForm.errors.confirmPassword : ''}
              onBlur={passwordForm.handleBlur}
            />

            <Box width='fit-content' alignSelf='center'>
              <CustomLoadingButton
                onClick={() => {setStep(1)}}
                type="button"
                className={classes.backButton}
                loading={false}
              >
                Back
              </CustomLoadingButton>
              <CustomLoadingButton
                  onClick={ passwordForm.handleSubmit }
                  type="button"
                  className={classes.nextButton}
                  loading={loading}
                >
                  Save & Login
              </CustomLoadingButton>
            </Box>
          </Box>
        </form>
      }

    </SignupShell>
  );
}

export default Signup;