import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Screens from './screens';
import reportWebVitals from './reportWebVitals';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
  console.info = function () { };
}

if (process.env.REACT_APP_STAGE === "prod") {
  require("./analytics");
}

ReactDOM.render(
  <React.StrictMode>
    <Screens />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
