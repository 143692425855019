import { isNumber } from "lodash";
import theme from "theme";

export const CommentAndColor = {
    VERY_GOOD: {
        comment: 'Bravo!',
        tag: 'Bravo',
        color: '#00664A',
        bgColor: '#DCFCE7',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        content: 'Your content is excellent! Keep up the great work. Consider adding even more depth and breadth to your material to maintain your high standard. Continuously seek feedback to further refine your content.',
        speech: 'Your speech is excellent! Continue practicing to maintain your high standard. Try incorporating more dynamic gestures and expressions to enhance your delivery.',
        feedback: "Your overall score is very good, indicating that you have exceptional verbal communication skills that allow you to connect with others on a deeper level.",
    },
    GOOD: {
        comment: 'Good!',
        color: '#00664A',
        tag: 'Good',
        bgColor: '#DCFCE7',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        content: 'Your content is well-researched and informative. Now, work on adding your unique voice and perspective to make it truly stand out.',
        speech: 'Good job! Your speech is engaging and easy to follow. Now, work on incorporating more expressive elements like pauses and emphasis to truly captivate your audience.',
        feedback: 'Congratulations, your overall score is good! This means you have solid verbal communication skills that enable you to effectively express yourself and connect with others.'
    },
    FAIR: {
        comment: 'Satisfactory!',
        color: '#F5B847',
        tag: 'Avg',
        fontColor: '#664100',
        bgColor: '#FFF6E5',
        overallComment: 'You will be there soon',
        img: 'https://assets.languify.in/images/avg-emoji.svg',
        content: 'Your content is good, but it can be more engaging. Add interesting details, examples, and anecdotes to make your points more compelling. Ensure your content is well-structured and easy to follow.',
        speech: 'Your speech is good, but there is room for improvement. Focus on varying your tone and pace to keep the audience engaged. Practice speaking in front of others to gain confidence.',
        feedback: "Your overall score is fair, indicating that you have some strengths in verbal communication but also areas for improvement. Consider using clear language and practicing empathy."
    },
    POOR: {
        comment: 'Needs improvement!',
        color: '#C51407',
        tag: 'Low',
        bgColor: '#FFE6E3',
        overallComment: 'Lets do some hard work',
        img: 'https://assets.languify.in/images/bad-emoji.svg',
        content: 'Focus on organizing your thoughts better. Ensure your key points are clear and well-supported by evidence. Research thoroughly and practice structuring your content logically.',
        speech: 'Work on your pronunciation, pace, and clarity. Practice speaking slowly and enunciating your words. Record yourself and listen to identify areas for improvement.',
        feedback: "Your overall score is poor, indicating that you may struggle to communicate effectively with others. Consider seeking feedback and practicing active listening to improve your communication skills."
    },
};

export const getOverallPerformanceFeedback = (speech=0, content=0) => {
  if(speech < 50 && content < 50){
    return 'Focus on both content clarity and speech delivery. Practice organizing your thoughts better and work on your pronunciation and articulation.'
  }
  else if(speech < 70 && content < 50){
    return 'While your speech is clear, your content needs more structure and depth. Ensure your key points are well-defined and supported by evidence.'
  }
  else if(speech < 90 && content < 50){
    return 'Your presentation is engaging, but your ideas need more substance. Strengthen your arguments with research and examples.'
  }
  else if(speech <= 100 && content < 50){
    return 'Your delivery is excellent, but the content lacks depth. Spend more time researching and organizing your thoughts and content to match your speech quality.'
  }

  if(speech < 50 && content < 70){
    return 'Your content is decent, but your speech could use work. Practice speaking more clearly and confidently to improve your delivery.'
  }
  else if(speech < 70 && content < 70){
    return " You have a good foundation, but there's room for improvement. Continue practicing both your speech and content for a more polished performance."
  }
  else if(speech < 90 && content < 70){
    return 'Your speech is strong, but your content could use more depth. Expand on your ideas and provide more supporting evidence.'
  }
  else if(speech <= 100 && content < 70){
    return 'Your speech is excellent, but your content could be more engaging. Add more interesting details and examples to elevate your content.'
  }

  if(speech < 50 && content < 90){
    return 'Your content is strong, but your speech could use some improvement. Focus on clarity and delivery to enhance overall impact.'
  }
  else if(speech < 70 && content < 90){
    return 'Your content is outstanding and well-presented. Work on refining your speech to make it more engaging and polished.'
  }
  else if(speech < 90 && content < 90){
    return 'Well done! Both your speech and content are effective. Improvements are still needed. Keep refining both to maintain high standards of communication.'
  }
  else if(speech <= 100 && content < 90){
    return 'Excellent job! Your speech is outstanding, and your content is excellent. Continue to showcase your expertise with compelling delivery and insightful content.'
  }

  if(speech < 50 && content <= 100){
    return 'Your content is excellent, but your speech needs improvement. Focus on clarity and confidence in your delivery to match the quality of your content.'
  }
  else if(speech < 70 && content <= 100){
    return 'Your content is outstanding, and your speech is good. Fine-tune your delivery to ensure your excellent content is communicated effectively.'
  }
  else if(speech < 90 && content <= 100){
    return "Your content is excellent, and your speech is engaging. Please add expressive elements and depth to hold the audience's attention and maintain your high standard."
  }
  else if(speech <= 100 && content <= 100){
    return 'Great job! Both your content and speech are excellent. Keep up the good work and continue refining your skills.'
  }
}

export const ToggleOptions = [
    { label: 'Content', id: 'content' },
    { label: 'Speech', id: 'speech' }
];

export const Categories = {
    "FLUENCY": "Fluency",
    "WORD_CHOICE": "Vocabulary",
    "DELIVERY": "Vocal Delivery",
    "CONFIDENCE": "Confidence",
    "SIMILARITY": "Similarity",
    "RELEVANCE": "Relevance",
    "LOGIC_FLOW": "Logic flow",
};

export const CategoriesDescription = {
    "FLUENCY": "Ability to speak smoothly, with clear, continuous flow, and minimal pauses or disruptions",
    "WORD_CHOICE": "Collection of words and phrases a person uses when speaking or communicating",
    "DELIVERY": "How someone talks, focusing on how fast they speak & their energy level",
    "CONFIDENCE": "Clear, assertive communication with a positive tone, indicating self-assuredness and command of the topic",
    "SIMILARITY": "How much things are alike",
    "RELEVANCE": "How well the response addresses the topic or question's core requirement, staying on point",
    "LOGIC_FLOW": "Clear, sequential progression of ideas, connecting concepts cohesively to reach a well-supported conclusion"
};

export const SubCategories = {
    "PAUSES": "Pauses",
    "DISFLUENCY": "Disfluency",
    "PACE": "Pace",
    "ENERGY": "Energy",
    "JARGON_WORDS": "Jargon words",
    "INFORMAL_WORDS": "Informal words",
    "FILLER_WORDS": "Fillers words",
    "FILLER_PHRASES": "Fillers phrases",
    "FILLER_UTTERANCES": "Fillers utterances",
    "RARE_USED_WORDS": "Rare used words",
};

export const DummySampleAnswers = [
    "I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.",
    "My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.I am an experienced software engineer with over 5 years of experience in developing software applications."
];

export const DummyPersonalizedAnswer = " In my free time, I enjoy reading books on technology and programming. I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients.";

export const DummyContentFeedback = "Aman, your introduction was so impressive it left us speechless! We were absolutely blown away by your impressive communication skills. Not only were you able to express yourself clearly and precisely, but you also have knocked out of the park with numerous designing projects. Furthermore, your mastery of the use of language is something nearly all professionals strive for. Clearly you've already got a step up on the competition! Keep up the amazing work!";

export const SubCategoriesDescription = {
    "PAUSES": "Frequency and duration of pauses in speech",
    "DISFLUENCY": "Frequency of disruptions and irregularities in speech",
    "PACE": "Speed of speech",
    "ENERGY": "Level of enthusiasm and energy conveyed through language",
    "JARGON_WORDS": "Use of specialized language or jargon",
    "INFORMAL_WORDS": "Use of casual and informal language",
    "FILLER_WORDS": "Filler words are short pauses to fill up the gap but they usually don't have any meaning",
    "FILLER_PHRASES": "Frequent use of unnecessary phrases and filler words",
    "FILLER_UTTERANCES": "(um, uh) are sounds used in speech during pauses",
    "RARE_USED_WORDS": "Unearth gems for your vocabulary - unique meaning, evocative power",
};

export const Tips = {
    FLUENCY: {
        low: [
            "Practice pronunciation and use shorter sentences.",
        ],
        okay: [
            "Expand vocabulary and maintain a consistent pace.",
        ],
        high: [
            "Develop natural intonation and stress patterns, use complex sentence structures, and seek real-life conversations.",
        ],
    },
    WORD_CHOICE: {
        low: [
            "Expand your word bank and use context clues.",
        ],
        okay: [
            "Learn synonyms, word families, and common collocations.",
        ],
        high: [
            "Focus on precision, idiomatic expressions, and technical vocabulary.",
        ],
    },
    DELIVERY: {
        low: [
            "Focus on articulation, volume, and pace.",
        ],
        okay: [
            "Work on intonation, clarity, and expressiveness.",
        ],
        high: [
            "Master voice modulation, fluency, and nonverbal communication.",
        ],
    },
    CONFIDENCE: {
        low: [
            "Practice speaking in front of a mirror to boost self-assurance.",
        ],
        okay: [
            "Focus on positive self-talk and believe in your abilities.",
        ],
        high: [
            "Embrace challenges, celebrate your achievements, and maintain a positive mindset.",
        ],
    },
};


export const ContentTips = {
    SIMILARITY: {
        low: [
            "Stay on the main topic and avoid unrelated information.",
        ],
        okay: [
            "Provide relevant examples and maintain a consistent focus.",
        ],
        high: [
            "Explore different aspects, provide in-depth analysis, and present contrasting viewpoints.",
        ],
    },
    RELEVANCE: {
        low: [
            "Stay on topic and eliminate digressions.",
        ],
        okay: [
            "Align with the main theme, support your points, and check for consistency.",
        ],
        high: [
            "Offer depth of relevance, provide specific details, and show a nuanced understanding.",
        ],
    },
    LOGIC_FLOW: {
        low: [
            "Use clear and concise sentences, organize your content, and avoid abrupt transitions.",
        ],
        okay: [
            "Use logical connectors, present ideas sequentially, and provide smooth transitions.",
        ],
        high: [
            "Develop a logical argument, show cause-and-effect relationships, and create a well-structured narrative.",
        ],
    },
};

export const FeedbackTourSteps = [
    {
        id: "view-scores",
        title: "View Scores",
        message: "See your overall, speech & content score for the selected assessment. Click on “Detailed suggestions” to see your parameter wise score for each question.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "attempts-performance",
        title: "Your attempts performance",
        message: "See how did you perform in each question of selected attempt. Also, compare attempts performance for practice assessments in the right section.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "question-specific-feedback",
        title: "Question specific feedback",
        message: "Find question specific analysis of your answer on the content & speech parameters. Get parameter wise suggestions for the improvement.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "bookmark-question",
        title: "Bookmark question",
        message: "Save you important questions by clicking on bookmark icon, for future read & practice. Find your bookmarked question in the “Bookmarks page” in the side menu.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "improvement-improved-response",
        title: "Tips for improvement & Improved response",
        message: "Find what you should improve in your answer for getting better in content & speech.  Also, we are giving improved response of “your response”. So, you can get more clarity.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "tips-for-improvement",
        title: "Tips for improvement",
        message: "Find blog & activity to get improved in the particular parameter. So that next time you can score better.",
        action: "Finish Tour for this Page",
        data: {}
    },
    {
      id: "replay-tutorial",
      title: "Replay Tutorial",
      message: "You can replay the tutorial for this page if you get stuck anywhere!",
      action: "Finish",
      data: {}
  }
];

export const templateTypes = {
    ASSIGNMENT: "assignment",
    PRACTICE: "practice",
    FOLLOW_UP: "follow_up",
    PERSONALIZED: "personalized"
}

export const Tags = {
    WORD_CHOICE: "Vocabulary consists of words and terms used in communication, typically with specific meanings.",
    CONFIDENCE: "Confidence is the unwavering assurance in one's speech, conveying self-assuredness and conviction without unnecessary additions.",
    SIMILARITY: "Degree of resemblance or likeness between the content presented by you and the expected or desired content.",
    RELEVANCE: "The extent to which the information provided aligns with the expected or desired context.",
    LOGIC_FLOW: "The organized sequence of steps that lead to a desired outcome, ensuring a logical and coherent sentence.",
}

export const DemoApplications = {
    inprep: "inprep",
    teachAndTrain: "teach-n-train"
}

export const ScoreTypes = [
    { _id: 'overall', name: 'Overall' },
    { _id: 'communication', name: 'Speech' },
    { _id: 'content', name: 'Content' },
]

export const InterviewQuestionTypes = [
    {
        _id: "technical",
        name: "Technical"
    },
    {
        _id: "situational",
        name: "Situational"
    },
];

export const ParameterRangeTags = {
    IDEAL: "IDEAL",
    NON_IDEAL: "NON_IDEAL"
}

export const ComparisonTags = {
    GOOD: 'good',
    OKAY: 'okay',
    REQUIRES_ATTENTION: 'requires attention'
}

export const HomeCategories = [
    "FILLER_WORDS", "PAUSES", "DISFLUENCY", "PACE", "ENERGY"
]

export const GamificationCategories = [
    "FILLER_WORDS", "JARGON_WORDS", "RARE_USED_WORDS", "DISFLUENCY", "PACE", "ENERGY"
]

export const HighlightCategoriesMap = {
    FILLER_WORDS: {
        subCategory: 'FILLER_WORDS',
        title: 'Filler words',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        info: 'Unnecessary gap-fillers in speech; best to minimize use.',
        value: [{ count: 0, positions: [] }],
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    REPETITION: {
        subCategory: 'REPETITION',
        title: 'Repetition',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        info: 'Excessive repeat of words; disrupt communication flow.',
        value: [{ count: 0, positions: [] }],
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    JARGON_WORDS: {
        subCategory: 'JARGON_WORDS',
        title: 'Jargon',
        colors: { primary: theme.palette.success['clr-700'], secondary: theme.palette.success['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Specific terms enhancing professional communication.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    INFORMAL_WORDS: {
        subCategory: 'INFORMAL_WORDS',
        title: 'Informal words',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Casual language; lessen formality in certain settings.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    RARE_USED_WORDS: {
        subCategory: 'RARE_USED_WORDS',
        title: 'Rare words',
        colors: { primary: theme.palette.success['clr-700'], secondary: theme.palette.success['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Uncommon words rarely spoken, not often found in everyday language.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    HINDI_SHARE: {
        subCategory: 'HINDI_SHARE',
        title: 'Hindi',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: '',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    }
}

const TAGS = [
    {
        "category": "FLUENCY",
        "subCategory": "FILLER_WORDS",
        "value": [
            { "count": 2, "word": "so" }, { "count": 1, "word": "basically" }
        ]
    },
    {
        "category": "FLUENCY",
        "subCategory": "FILLER_PHRASES",
        "value": [
            {
                "count": 1,
                "word": "you know"
            }
        ]
    },
    {
        "category": "FLUENCY",
        "subCategory": "FILLER_UTTERANCES",
        "value": [
            { "count": 3, "word": "um" }, { "count": 2, "word": "uh" },
        ]
    },
    {
        "category": "FLUENCY",
        "subCategory": "PAUSES",
        "value": 96 // <70 or >70
    },
    {
        "category": "FLUENCY",
        "subCategory": "DISFLUENCY",
        "value": 5  // 0 or 1-100
    },
    {
        "category": "DELIVERY",
        "subCategory": "PACE",
        "value": 62 // >70 = high or 70<40 = ideal or 40< = low  
    },
    {
        "category": "DELIVERY",
        "subCategory": "ENERGY",
        "value": 1 // 1 or 2 (low or ideal)
    },
    {
        "category": "WORD_CHOICE",
        "subCategory": "INFORMAL_WORDS",
        "value": [
            { "count": 1, "word": "Something" },
        ]
    },
    {
        "category": "WORD_CHOICE",
        "subCategory": "RARE_USED_WORDS",
        "value": [
            { "count": 3, "word": "Something" },
        ]
    },
];
