import Session from "../utils/Session";
import Client from "./_client";

const apiUrl = process.env.REACT_APP_API_URL;

export const postFeedback = async (rating, comment, feature, feedbackData) => {
  let url = "/feedback";

  const result = await new Client({
    path: url,
    payload: {
      rating,
      comment,
      user: Session.userId,
      feature: feature || "",
      feedbackData
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to send feedback. Please try again."
    );
  }
  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};

export const MetadataFilters = new Set([
  "totalInterviews",
]);

export const getUsers = async (filters) => {
  let url = `/user?`;

  for (let filter of Object.keys(filters)) {
    if (filters[filter]) url += `${filter}=${filters[filter]}&`;

    if (MetadataFilters.has(filter)) url += "metadata=true&"
  }

  const result = await new Client({
    path: url
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get users. Please try again."
    );
  }

  return result?.data || [];
}

export const getUserById = async () => {
  const url = `/user/${Session.userId}`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get profile details. Please try again."
    );
  }

  // Session._setUser(result.data);
  return result.data;
}

export const updateUserById = async (profileDetails) => {
  let result = await new Client({
    path: `/user/${Session.userId}`,
    payload: profileDetails,
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save profile details. Please try again."
    );
  }
  return result.data;
}

export const updateUserProfileById = async (profileDetails) => {
  let result = await new Client({
    path: `/user/${Session.userId}/profile`,
    payload: profileDetails,
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save profile details. Please try again."
    );
  }

  Session._setUser(result.data);

  return result?.data;
}

export const updateProfileStatus = async ({ newStatus, userId }) => {
  let result = await new Client({
    path: `/user/${Session.userId}/profile-status`,
    payload: {
      profileStatus: newStatus,
      userId
    },
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to update profile status. Please try again."
    );
  }

  return result.data;
}

export const changePassword = async ({ currentPassword, newPassword }) => {
  let result = await new Client({
    path: `/user/${Session.userId}/reset-password`,
    payload: {
      currentPassword,
      newPassword
    },
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message);
  }

  return result?.data;
}

export const updateProfilePicture = async (imageBlob) => {
  const formData = new FormData();

  formData.append("profile", imageBlob);

  let result = await fetch(`${apiUrl}/user/${Session.userId}/profile-picture`, {
    headers: {
      userId: Session.userId,
      "languify-application": "interview",
      "languify-access-token": Session.accessToken,
      "languify-session-id": localStorage?.getItem("languifySessionId"),
    },
    method: "PUT",
    body: formData,
  });

  if (!(result.ok || result.status === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to upload the profile picture.");
  }

  result = await result.json();

  Session._setUser(result.data);

  return result?.data;
};

export const sendBulkInvites = async (clientId, usersData = []) => {
  const result = await new Client({
    path: `/client/interview/${clientId}/invite`,
    payload: { usersData },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw result;
  }

  return result?.data;
};

export const getUserClients = async (email) => {
  const url = `/user/client-list?email=${encodeURIComponent(email)}&clientRole=CLIENT`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get profile details. Please try again."
    );
  }

  return result.data;
}

export const sendFeedbackMail = async (feedbackUrl, email) => {

  const result = await new Client({
    path: `/user/send-demo-feedback-link`,
    payload: {
      feedbackUrl, email
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to send mail, Please try again"
    );
  }
  return result?.data;
};

export const getExisitingUser = async (filters) => {
  let url = `/signup/check-existing-user?`;

  for (let filter of Object.keys(filters)) {
    if (filters[filter]) url += `${filter}=${filters[filter]}&`;

    if (MetadataFilters.has(filter)) url += "metadata=true&"
  }

  const result = await new Client({
    path: url
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get users. Please try again."
    );
  }

  return result?.data;
}

