import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    card: {
        height: "15vw",
        width: "15vw",
        backgroundColor: "#014080",
        background: "#014080",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
        borderRadius: "24px",
        padding: 16,
        display: "flex",
        alignItems: "flex-end",
        wordBreak: "break-word",
        flex: "0 0 auto",
        "&:hover": {
            cursor: "pointer",
            border: "3px solid #0d99ff",
        },
    },
    selected: {
        cursor: "pointer",
        border: "4px solid #0d99ff",
    }
});

export default function OnboardingOptionCard(
    {
        content,
        selected,
        helperText,
        onSelect = () => { },
        id
    }
) {
    const classes = useStyles();
    const classnames = classNames({
        [classes.card]: true,
        [classes.selected]: (id === selected),
    });

    return (
        <Box
            className={classnames}
            p={1}
            onClick={(e) => onSelect(e, id)}
            position="relative"
        >

            <Typography
                fontWeight="600"
                fontStyle="normal"
                fontSize="1.3vmax"
                color="white"
                style={{
                    position: "absolute",
                    top: '16px',
                    right: '16px'
                }}
            >
                {helperText}
            </Typography>

            <Typography
                fontStyle="normal"
                fontSize="1.5vmax"
                fontWeight="600"
                color="white"
            >
                {content}
            </Typography>
        </Box>
    );
}