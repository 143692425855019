import React from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";

import { useServices, useSnackbar } from 'contexts';
import { CustomLoadingButton } from 'components/CustomButton';
import { changePassword } from 'services';
import { PasswordField } from 'components/CustomFields';

const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex', flexDirection: 'column', 
    gap: theme.spacing(3)
  },
  newPassword: {
    display: 'flex', 
    gap: theme.spacing(5)
  }
}));

function ChangePassword(props) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const service = useServices();

    const [loading, setLoading] = React.useState(false);

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        errors,
        touched
    } = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmedPassword: "",
        },
        validationSchema: new Yup.ObjectSchema({
            currentPassword: Yup.string()
                .required("Required"),
            newPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .notOneOf([Yup.ref('currentPassword'), null], "New Password has to be different than current Password")
                .required("Required"),
            confirmedPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .oneOf([Yup.ref('newPassword'), null], "Does not match with new password!")
                .required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);

                await service.callService(changePassword, values);

                snackbar.success("Password updated successfully!");

            } catch (error) {
                console.error(error);
                snackbar.error(error.message);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
      <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          className={classes.root}
      >
        <PasswordField
          sx={{ width: '300px' }}
          label='Current Password'
          name="currentPassword"
          value={values.currentPassword}
          onChange={handleChange}
          error={Boolean(touched.currentPassword && errors.currentPassword)}
          helperText={touched.currentPassword && errors.currentPassword}
        />
        <Box className={classes.newPassword}>
          <PasswordField
            sx={{ width: '300px' }}
            label='New Password'
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
          <PasswordField
            sx={{ width: '300px' }}
            label='Confirm Password'
            name="confirmedPassword"
            value={values.confirmedPassword}
            onChange={handleChange}
            error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}
            helperText={touched.confirmedPassword && errors.confirmedPassword}
          />
        </Box>
        <Box mt={2}>
            <CustomLoadingButton
                size="small"
                type="submit"
                loading={loading}
            >
                Update Password
            </CustomLoadingButton>
        </Box>
      </Box>
    );
}

export default ChangePassword;