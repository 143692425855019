import React from "react";
import Box from "@mui/system/Box";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';

import StartAssessmentDialog from "dialogs/StartAssessmentDialog";
import NewTag from "components/NewTag";

import { getTemplates } from "services";
import { useServices } from "contexts";
import { Session, getTwoDigitNo } from "utils";
import { TemplateCard } from "components/TemplateList";
import { Collapse } from "@mui/material";
import { WhiteBoxHeader } from "components/AppShell";
import HorizontalScrollNavigator from "components/HorizontalScrollNavigator";
import { NoResultComponent } from "./TodoList";

export default function FollowupList({ batch, removedFromBatch }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [followupTemplates, setFollowupTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState('');

    const service = useServices();

    const handleClick = () => {
        setOpen(!open)
    }

    React.useEffect(() => {
        (async () => {
            try {
                const filter = { assignedTo: Session.userId, type: 'follow_up', batch: batch };
                const { interviewTemplates } = await service.callService(getTemplates, filter);
                setFollowupTemplates(interviewTemplates);
            } catch (error) {
                console.error(error);
            }

        })();
    }, [batch, open]);

    return (
      (followupTemplates.length) ?
        <Box mt={3}>
          &nbsp;
          <Divider/>
          <WhiteBoxHeader
            heading={
              <>
                <NewTag />
                Follow Up Assessments
                {
                  (open) ?
                      <IconButton onClick={handleClick} >
                          <KeyboardArrowUpIcon fontSize='large' />
                      </IconButton>
                      :
                      <IconButton onClick={handleClick} >
                          <KeyboardArrowDownIcon fontSize='large' />
                      </IconButton>
                }

              </>
            }
            sx={{ marginTop: theme.spacing(4) }}
            subheading={
              <>
                Total Assessments: &nbsp;
                <span style={{color: theme.palette.primary.main}}>
                  {getTwoDigitNo(followupTemplates.length)}
                </span>
              </>
            }
          >
          </WhiteBoxHeader>
            
          <Collapse in={open}>
            <Box height={'100%'}>
              <HorizontalScrollNavigator
                NoResultComponent={NoResultComponent}
                childrensLength={followupTemplates.length}
              >
                {
                  followupTemplates?.map((template, i) =>
                      <TemplateCard
                          template={template} key={i}
                          onClick={(t) => setSelectedTemplate(t)}
                          disabled={removedFromBatch}
                      />
                  )
                }
              </HorizontalScrollNavigator>
            </Box>
          </Collapse>
          <StartAssessmentDialog
              onClose={() => setSelectedTemplate('')}
              open={!!selectedTemplate}
              template={selectedTemplate}
          />
        </Box> :
        null
    );
}