import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import { TourProviderContext } from 'contexts';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=>({
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
  title: {
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: theme.spacing(3,0),
    gap: theme.spacing(6),
    textAlign: 'start'
  }
}));

function TourStartDialog({
    open, onClose, title = '', description = ''
}) {
    const classes = useStyles();
    const { playTour } = React.useContext(TourProviderContext);

    const handleStartNow = () => {
        onClose(false);
        playTour();
    };

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{ style: { padding: '24px 20px', textAlign: "center" } }}
        >
            <Box className={classes.dialogHeader}>
              <DialogTitle id="responsive-dialog-title" className={classes.title}>
                  <Typography variant='h5-medium' color='primary.clr-300'>
                    {title}
                  </Typography>
              </DialogTitle>
              <IconButton size="medium" onClick={() => onClose(false)}>
                  <Close fontSize="medium" />
              </IconButton>
            </Box>

            <Divider/>

            <DialogContent className={classes.content}>
                <img
                    alt="Home"
                    src="https://assets.languify.in/images/start-tutorial-illustrator.png"
                    width={200}
                    style={{ margin: 'auto' }}
                />
                <DialogContentText>
                  <Typography variant='h5-medium' color='neutral.clr-700'>
                      {description}
                  </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                  variant="contained"
                  sx={{ width: '100%',  }}
                  onClick={handleStartNow}
                >
                  Take a Quick tour
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
}

export default TourStartDialog;