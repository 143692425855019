import LinearProgress from '@mui/material/LinearProgress';
import {
    DataGrid as MUIDataGrid
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from "@mui/styles/makeStyles";

import TableNoRowsOverlay from 'components/TableNoRowsOverlay';

const useStyles = makeStyles(theme=>({
  skeleton: {
    borderRadius: theme.spacing(3),
    width: '100%', height: '300px',
  }
}))

export const DataGridSkeleton = () => {
  const classes = useStyles();
  
  return (
    <Box style={{ width: '100%'}}>
      <Skeleton variant="rectangular" className={classes.skeleton} />
    </Box>
  )
}

function DataGrid({
    rows, columns, noRowsMessage, height = '64vh', ...props
}) {
    return (
      (props.loading) ? <DataGridSkeleton/> :
        <div style={{ height, width: '100%' }}>
            <MUIDataGrid
                pagination
                autoPageSize
                disableColumnFilter
                density='compact'
                paginationMode={'server'}
                filterMode={'server'}
                sortingMode={'server'}
                getRowId={(row) => row._id}
                rows={rows}
                columns={columns}
                {...props}
                components={{
                    Toolbar: null,
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: TableNoRowsOverlay,
                    NoResultsOverlay: TableNoRowsOverlay,
                    ...props.components,
                }}
                componentsProps={{
                    noResultsOverlay: { message: noRowsMessage },
                    noRowsOverlay: { message: noRowsMessage },
                    ...props.componentsProps
                }}
            />
        </div>
    );
}

export default DataGrid;
