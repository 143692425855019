import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { Divider } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useServices, useSnackbar } from "contexts";
import { getClientPrefix } from "utils";
import { getblogById, getblogs } from "services/blogService";
import AppShell, { WhiteBox } from "components/AppShell";
import CustomCard from "./Card";
import ImageViewer from "components/ImageViewer";

const useStyles = makeStyles((theme) => ({
  blogRoot: {
    width: '100%',
    display: 'flex', flexDirection: 'column',
    justifyContent: 'center', alignItems: 'center'
  },
  imgSection: {
    display: "flex",
    justifyContent: 'center',
    marginTop: theme.spacing(7)
  },
  blogContent: {
    display: 'flex', flexDirection: 'column',
    width: '100%',
    justifyContent: 'center', alignItems: 'center',
    marginTop: theme.spacing(5)
  },
  paragraphContainer: {
    marginTop: theme.spacing(3),
    '& p': {
      ...theme.typography['h6-regular'],
      lineHeight: 1.5, padding: '0px', margin: '0px',
    },
    '& h2': {
      ...theme.typography['h5-semiBold'],
      lineHeight: 2, padding: '0px', margin: '0px'
    }
  },
  cardGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: theme.spacing(5),
    marginTop: theme.spacing(3)
  },
}));

function BlogDetails() {
  const theme = useTheme();
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();
  const clientPrefix = getClientPrefix();

  const [loading, setLoading] = useState({ blogDetails: false, relatedBlogs: false })
  const [blogDetails, setBlogdetails] = useState({});
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const { blogId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, blogDetails: true });

        const data = await service.callService(
          getblogById, blogId
        );

        setBlogdetails(data);
      } catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get the Blog");
      } finally {
        setLoading({ ...loading, blogDetails: false });
      }
    })();
  }, [blogId]);

  useEffect(() => {
    (async () => {
      if (!blogDetails?.category) return;
      try {
        setLoading({ ...loading, relatedBlogs: true });

        const blogFilters = {
          orderBy: "createdAt",
          pageSize: 7,
        };

        const { blogs } = await service.callService(
          getblogs, blogFilters
        );

        const filteredBlogs = blogs.filter((data, index) => data._id !== blogDetails._id);
        setRelatedBlogs(filteredBlogs);
      } catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get the Related Blogs");
      } finally {
        setLoading({ ...loading, relatedBlogs: false });
      }
    })();
  }, [blogDetails]);

  return (
    <AppShell headerLabel="Blog" showBackButton={true}>
      <WhiteBox>
        <Box textAlign='center' mb={theme.spacing(4)}>
          <Typography variant="h3-medium">
            {blogDetails.title}
          </Typography>
        </Box>

        <Divider />

        <Box className={classes.blogRoot}>
          <Box style={{ width: '70%' }}>
            <Box className={classes.imgSection}>
              <ImageViewer
                src={blogDetails.imageUrl}
                alt={blogDetails.title}
                height={'350px'}
                width={'100%'}
                style={{ 
                  width: "100%",
                  minWidth: 630
                }}
              />
            </Box>

            <Box className={classes.blogContent}>
              <Box>
                <Typography variant="h6-regular">
                  {blogDetails.description}
                </Typography>
                <div className={classes.paragraphContainer}
                  dangerouslySetInnerHTML={{
                    __html: blogDetails.content
                  }}
                >
                </div>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ margin: theme.spacing(5, 0) }} />

        <Box style={{ width: '100%', marginTop: theme.spacing(5) }}>
          <Typography variant="h4-medium">
            What to read next
          </Typography>
          <Box className={classes.cardGrid}>
            {relatedBlogs.slice(0, 3).map((card, index) => (
              <Link to={`/${clientPrefix}/blog/${card._id}`} key={index}>
                <CustomCard
                  image={card?.imageUrl}
                  title={card?.title}
                  category={card?.category}
                  createdAt={card?.createdAt}
                  loading={loading?.relatedBlogs}
                />
              </Link>
            ))}
          </Box>
        </Box>
      </WhiteBox>
    </AppShell>
  )
}
export default BlogDetails;

