import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackRating from "components/FeedbackRating";

import { Divider, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RateExperienceDialog({ opendialog, setOpenFeedback }) {
    const [open, setOpen] = React.useState(opendialog);

    React.useEffect(() => {
        setOpen(opendialog)
    }, [opendialog]);

    const onClose = () => {
        setOpen(false);
        setOpenFeedback(false)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            style={{ overflow: 'hidden', }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 16,
                    top: 14,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent style={{ height: 590, width: 500, }}>
                <Typography variant='h5-medium' color='primary.main'>
                    Help us to improve for you
                </Typography>
                <br /><br />
                <Divider />


                <FeedbackRating onSubmitted={onClose} />
            </DialogContent>
        </Dialog>
    );
}

export default RateExperienceDialog;
