import { Client } from './_client';

export const subscribe = async (data) => {
  let url = `/subscriber`;

  let result = await new Client({
    path: url,
    payload: data,
}).post();
  
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to subscribe. Please try again."
        );
    }
  
    return result.data;
}