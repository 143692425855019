import React from 'react';
import { useField } from 'formik';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiTypography-root': {
      fontSize: '16px',
    },
  }
}));


export function CustomCheckbox({ label, ...props }) {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const { onChange, value } = field;
  const { touched, error } = meta;

  const handleChange = (event) => {
    const { checked } = event.target;
    onChange(checked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={value}
            onChange={handleChange}
            color="primary"
            className={classes.checkbox}
            size="small"
            variant="outlined"
            {...props}
          />
        }
        label={<Typography color="primary.main" variant="body02-medium">{label}</Typography>}
      />
      {touched && error && (
        <Typography color="error">{error}</Typography>
      )}
    </Box>
  );
}