import create from "zustand";
import { QuestionStates, AssessmentStates, RETAKES_AVAILABLE } from "./constants";
import { round } from "utils";

const defaults = {
    questions: [{
        question: "Tell something about yourself.",
        hint: "Speak about your educational background, skills, experience, etc.",
        allottedTime: 60,
    },
    {
        question: "Tell something about yourself.",
        hint: "Speak about your educational background, skills, experience, etc.",
        allottedTime: 60,
    }],
    questionsError: [undefined, undefined],
    questionsViewed: [false, false],
    uploadStates: [false, false],
    uploadProgresses: [0, 0],
    currentQuestion: 0,
    assessmentState: AssessmentStates.SHOWING_TUTORIAL,
    questionState: QuestionStates.WAITING,
    attempt: {},
    retake: RETAKES_AVAILABLE,
    totalTime: 60,
    timeLeft: 60,
    remainingInterviewTime: 60,
    isTimePaused: false,
    isTimeout: false,
    templateId: null,
    audioLevel: 0,
    template: { name: "Assessment" },
    isPersonalizedInterview: false,
    isInterviewerSpeaking: false,
    questionSwitch: false,
};

const useAssessmentStore = create(
    (set, get) => ({
        ...defaults,
        setTemplate: (template) => set({ template }),
        setIsInterviewerSpeaking: (isInterviewerSpeaking) => set({ isInterviewerSpeaking }),
        setIsPersonalizedInterview: (isPersonalizedInterview) => set({ isPersonalizedInterview }),
        setQuestionSwitch: (questionSwitch) => set({ questionSwitch }),
        setQuestions: (questions) => set({
            questions: questions.map((question) => ({
                ...question,
                submitted: false,
                visited: false,
                recorded: false,
                analysisRecieved: false,
                analysisData: null,
            })),
            questionsError: new Array(questions.length).fill(undefined),
            questionsViewed: new Array(questions.length).fill(false),
            uploadStates: new Array(questions.length).fill(false),
            uploadProgresses: new Array(questions.length).fill(0),
            totalTime: round(questions.reduce((prevValue, question) =>
                (question.allottedTime + prevValue), 0) / 60),
        }),
        setUploadProgress: (index, progress) => set((state) => {
            const uploadProgresses = [...state.uploadProgresses];
            uploadProgresses[index] = progress;

            return { uploadProgresses };
        }),
        setUploadState: (index, uploading) => set((state) => {
            const uploadStates = [...state.uploadStates];
            uploadStates[index] = uploading;

            return { uploadStates };
        }),
        setQuestionError: (index, error) => set((state) => {
            const questionsError = [...state.questionsError];
            questionsError[index] = error;

            return { questionsError };
        }),
        setQuestionsViewed: (index, viewed) => set((state) => {
            const questionsViewed = [...state.questionsViewed];
            questionsViewed[index] = viewed;

            return { questionsViewed };
        }),
        setQuestionsError: (questionsError) => set({ questionsError }),
        setCurrentQuestion: (currentQuestion) => set({
            currentQuestion,
            questionState: QuestionStates.WAITING,
        }),
        setAssessmentState: (assessmentState) => set({ assessmentState }),
        setQuestionState: (questionState) => set({ questionState }),
        setAttempt: (attempt) => set({
            attempt,
            templateId: attempt.interviewTemplate,
        }),
        setRetake: (retake) => set({ retake }),
        setDelay: (delay) => set({ delay }),
        setTotalTime: (totalTime) => set({ totalTime }),
        setTimeLeft: (timeLeft) => set({ timeLeft }),
        setRemainingInterviewTime: (remainingInterviewTime) => set({ remainingInterviewTime }),
        setIsTimePaused: (isTimePaused) => set({ isTimePaused }),
        setTimeInterval: (timeInterval) => set({ timeInterval }),
        setIsTimeout: (isTimeout) => set({ isTimeout }),
        setTemplateId: (templateId) => set({ templateId }),
        setAudioLevel: (audioLevel) => set({ audioLevel }),
        prev: () => set(state => ({
            currentQuestion: Math.max(state.currentQuestion - 1, 0),
        })),
        clearTimeInterval: () => set(state => {
            clearInterval(state.timeInterval);
            return { timeInterval: 0 };
        }),
        next: () => set(state => ({
            currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length - 1),
        })),
        startTutorial: () => set(({
            questionState: QuestionStates.SHOWING_TUTORIAL,
            assessmentState: AssessmentStates.SHOWING_TUTORIAL,
        })),
        endTutorial: () => set(({
            assessmentState: AssessmentStates.FULLSCREEN_PERMISSION,
        })),
        fetchQuestion: () => set(({
            questionState: QuestionStates.WAITING,
            assessmentState: AssessmentStates.FETCHING_QUESTIONS,
        })),
        stop: () => set(state => ({ questionState: QuestionStates.RECORDING_STOPPED })),
        start: () => set(state => {
            const questionsError = state.questionsError.map(error => error);
            questionsError[state.currentQuestion] = false;
            return {
                questionState: QuestionStates.RECORDING,
                questionsError,
                delay: true
            }
        }),
        submit: () => set(state => ({ questionState: QuestionStates.SUBMITTED })),
        endAssessment: (isTimeout) => set((state) => {
            state.clearTimeInterval();
            return {
                isTimeout,
                assessmentState: AssessmentStates.WAITING_FOR_RESULT,
            }
        }),
        startAssessment: () => set(state => ({
            assessmentState: AssessmentStates.RUNNING,
            questionState: QuestionStates.WAITING,
            setRetake: RETAKES_AVAILABLE,
            isTimeout: false,
        })),
        restartAssessment: () => set(state => ({
            attempt: {},
            currentQuestion: 0,
            assessmentState: AssessmentStates.FETCHING_QUESTIONS,
            questionState: QuestionStates.WAITING,
            setRetake: RETAKES_AVAILABLE,
            isTimeout: false,
            questions: state.questions.map((curr) => ({
                ...curr,
                submitted: false,
                visited: false,
                recorded: false,
                analysisRecieved: false,
                analysisData: null,
            }), {}),
        })),
        reset: () => set(defaults),
    }),
);

export default useAssessmentStore;