import { useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { useNavigateWithClient } from "hooks";

import useAssessmentStore from "./assessmentStore";
import { AssessmentStates } from "./constants";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(({
    waveRoot: {
        height: '250px', flexGrow: 1, display: 'flex', alignItems: "center",
        backgroundColor: 'transparent', opacity: 0.4,
    },
    waveLine: {
        display: "inline-block", backgroundColor: "#02569D", width: "20px",
        height: "5px", animation: "wave 2s linear infinite",
        borderRadius: "50%",
    },
    root: {
        width: '100vw', height: '250px', backgroundColor: 'transparent',
        display: 'flex', justifyContent: 'center',
    },
    messageContainer: {
        width: '500px', height: '250px', backgroundColor: 'white',
        borderRadius: '12px', alignSelf: 'center', alignItems: "center",
        boxShadow: '0px 0px 7px 0px rgb(255 255 255)', display: "flex",
        justifyContent: 'center', flexDirection: 'column',
    }
}));

function Wave({ position }) {
    const classes = useStyles();

    return (
        <Box className={classes.waveRoot} justifyContent={position}>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((x) => (
                <span
                    key={x}
                    className={classes.waveLine}
                    style={{
                        animationDelay: ((position === "start" ? 1 : -1) * x * 0.2) + "s",
                    }}
                />
            ))}
        </Box>
    );
}

function PostAssessment({ assessmentState }) {
    const classes = useStyles();
    const navigate = useNavigateWithClient();
    const location = useLocation();
    
    const attempt = useAssessmentStore(state => state.attempt);
    const reset = useAssessmentStore(state => state.reset);

    useEffect(() => {
        if (assessmentState === AssessmentStates.COMPLETED) {
            const attemptId = attempt._id;
            reset();
            navigate(`/performance/${attemptId}`,{ state: { from: location.pathname }});
        }
    }, [assessmentState, attempt, reset, navigate]);

    return (
        <Box className={classes.root}>
            <Wave position="end" />
            <Box className={classes.messageContainer}>
                <Typography
                    variant="body1" color="primary" fontSize="1.2vw"
                    textAlign="center"
                >
                    Please wait...<br />
                    Our AI is analysing your answer.<br />
                    Do not close the tab or browser window.
                </Typography>
            </Box>
            <Wave position="start" />
        </Box>
    );
}

export default PostAssessment;