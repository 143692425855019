import * as React from 'react';
import useStore from './store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-500'],
        borderRadius: '4px',
        backgroundColor: theme.palette.neutral['clr-50'],
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
    }
}));

export default function SampleAnswerForm() {
    const classes = useStyles();
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);

    const labelType = activeQuestion?.type === "situational";

    return (
        <Box className={classes.root}>
            <Typography variant="h6-semiBold" color='primary.clr-200'>
                {labelType ? 'Example Response' : 'Ideal Response'}
            </Typography>
            <Typography variant='body01-medium' color='neutral.clr-900' mt={1}>
                {activeQuestion?.expectedAnswer}
            </Typography>
        </Box>
    );
}