import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';

import { isNumber } from 'lodash';
import Tabs from 'components/Tabs';
import { calculateErrorCount, getAllPositions, splitParagraphWithHighLights }
  from 'utils/highlighting';
import { CustomTooltip } from 'components/InfoTooltip';
import Chip from 'components/Chip';
import useStore from './store';
import shallow from 'zustand/shallow';

const useStyles = makeStyles(theme => ({
  categoryButtons: {
    display: 'flex', justifyContent: 'center',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  answer: {
    mb: 1,
    borderRadius: "4px",
    ...theme.typography['body01-medium']
  },
  tooltipHead: {
    display: 'flex', alignItems: 'center', gap: theme.spacing(2),
  },
  tooltipTitle: {
    padding: theme.spacing(1, 2),
    textTransform: 'capitalize',
    borderRadius: '4px'
  },
}));

const categoryMap = {
  FLUENCY: {
    _id: 'FLUENCY',
    label: 'Fluency',
    field: 'FLUENCY',
    icon: 'https://assets.languify.in/images/waves.svg',
    warningMessage: 'Your response was fluent!',
    warningColor: '#009970'
  },
  JARGON_WORDS: {
    _id: 'JARGON_WORDS',
    label: 'Jargon words',
    field: 'JARGON_WORDS',
    icon: 'https://assets.languify.in/images/flash.svg',
    warningMessage: 'Include jargon words in your answer',
    warningColor: '#E2483C'
  },
  VOCABULARY: {
    _id: 'VOCABULARY',
    label: 'Vocabulary',
    field: 'VOCABULARY',
    icon: 'https://assets.languify.in/images/book.svg',
    warningMessage: 'Focus on enhancing your vocabulary',
    warningColor: '#E2483C'
  },
}

export const TooltipBox = ({
  title, info, value, colors, error = () => { }, ...props
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.tooltipHead}>
        <Typography variant='body01-bold' className={classes.tooltipTitle}
          style={{ backgroundColor: colors.secondary, color: colors.primary }}
        >
          {title}
        </Typography>
        <Typography variant='body01-semiBold' style={{ color: colors.primary }}>
          {
            error(isNumber(value) ? value : value)
          }
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant='body01-bold' color='shades.clr-black-900'>{info}</Typography>
      </Box>
    </Box>
  )
}

const AnswerWithHighlights = ({ question, show = true }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [transcriptArray, setTranscriptArray] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(categoryMap.FLUENCY.field);

  const { positions, totalErrors, positionExist } = React.useMemo(() => {
    const tags = [...question?.communication?.tags, ...question?.content?.tags];

    const positions = getAllPositions(tags, selectedCategory);

    let {totalErrors, positionExist} = calculateErrorCount(tags);

    return { positions, totalErrors, positionExist };
  }, [question, selectedCategory]);

  React.useEffect(() => {
    if (question?.isError) {
      setTranscriptArray([{ transcript: 'Not Answered' }]);
    }
    else {
      let splitedArray = splitParagraphWithHighLights(positions, question)
      setTranscriptArray(splitedArray)
    }
  }, [selectedCategory, positions, question]);

  return (
    <>
      <Box display='flex' mb={1} gap={2} alignItems={'center'}>
        <Typography variant="h6-semiBold" color='primary.clr-200'>
          Your Response
        </Typography>

        {
          (show && positionExist && totalErrors[selectedCategory] === 0)
          ? <Chip 
              sx={{ padding: theme.spacing(1,3)}}
              content={categoryMap[selectedCategory].warningMessage}
              color={theme.palette.shades['clr-white-900']}
              bgColor={categoryMap[selectedCategory].warningColor}
            />
          : null
        }

      </Box>
    {show && positionExist
      ? <Box>
        <Box className={classes.answer}>
          {
            transcriptArray?.map((data, i) => {
              if (data?.positions) {
                return (
                  <CustomTooltip key={i} component={<TooltipBox {...data} />} >
                    <mark
                      style={{
                        backgroundColor: `${data?.colors?.secondary}`
                      }}
                    >
                      {data?.transcript}
                    </mark>
                  </CustomTooltip>
                )
              } else {
                return data?.transcript;
              }
            })
          }
        </Box>
        <Box className={classes.categoryButtons}>
          {
            <Tabs
              options={Object.values(categoryMap)}
              value={selectedCategory}
              onChange={(_id) => setSelectedCategory(_id)}
            />
          }
        </Box>
      </Box>
      : <Typography variant="body01-medium" color='neutral.clr-900'>
        {question.transcript || '---'}
      </Typography>
    }
    </>
  )
};

export default AnswerWithHighlights;