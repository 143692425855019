import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import { TourProviderContext, useServices, useSnackbar } from 'contexts';
import { getInterviewAttempts } from 'services';
import AttemptsTable from './AttemptsTable';
import { PerformanceGraph } from 'components/PerformanceGraph';
import { getAverageScore, getPercentageChange, Session } from 'utils';
import AnalyticsBrief from 'components/AnalyticsBrief';
import AssessmentsTable from './AssessmentsTable';
import TourStartDialog from 'dialogs/TourStartDialog';
import { OverallData, PathwayOverallData, PerformanceTourSteps } from './constants';
import { getPathwayUserAnalytics, getTodoPathways } from 'services/pathwayService';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SimpleSelect } from 'components/CustomSelectFields';
import SelectBatch from 'components/SelectBatch';
import ReplayTutorial from 'components/ReplayTutorial';
import Tabs from 'components/Tabs';

const useStyles = makeStyles(theme=>({
    scoreCards: {
      display: "flex",
      gap: theme.spacing(4),
    },
    headerFields: {
      display: 'flex', 
      gap: theme.spacing(8)
    },
    batchField: {
      borderRadius: theme.spacing(1),
      border: "2px solid",
      borderColor: theme.palette.neutral['clr-700'],
      height: '44px', width: '150px',
      display: 'flex', alignItems: 'center',
      padding: theme.spacing(0,3),
    },
}));

function OverallPerformance(props) {
    const classes = useStyles();
    const service = useServices();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const { setSteps, play, playTour } = React.useContext(TourProviderContext);

    const [overallDataLoading, setOverallDataLoading] = React.useState(false);
    const [attemptsLoading, setAttemptsLoading] = React.useState(false);
    const [attempts, setAttempts] = React.useState([]);
    const [batches, setBatches] = React.useState([]);
    const [selectedBatch, setSelectedBatch] = React.useState('');
    const [pageSize, setPageSize] = React.useState(12);
    const [overallData, setOverallData] = React.useState(OverallData);
    const [assessmentType, setAssessmentType] = React.useState('allAssessments');
    const [parameter, setParameter] = React.useState('overall');
    const [openWelcomeDialog, setOpenWelcomeDialog] = React.useState(false);
    const [pathways, setPathways] = React.useState([]);
    const [selectedPathway, setSelectedPathway] = React.useState('');
    const [searchParams] = useSearchParams();

    const batchData = React.useMemo(()=>{
      const selecteBatchData = batches.find(batch=> batch._id === selectedBatch);
      return selecteBatchData;
    },[batches, selectedBatch]);

    React.useEffect(() => {
      if (!batchData?.forPathway){
        setPathways([]);
        setSelectedPathway('');
      }
      else{
        (async () => {
          try {
            setOverallDataLoading(true);
            const userPathway =  (await getTodoPathways(selectedBatch)).map(p=> p.pathway);

            setPathways(userPathway);
          } catch (error) {
            snackbar.error("Unable to load pathways");
            console.error(error);
          } finally {
            setOverallDataLoading(false);
          }
        })();
      }
    }, [selectedBatch, batchData]);

    React.useEffect(() => {
        if (pathways.length) {
            setSelectedPathway(
                searchParams.get('pathway') ? searchParams.get('pathway') :
                (sessionStorage.getItem("selectedPathway") || pathways[0]._id)
            );
        }
    }, [pathways]);

    React.useEffect(() => {
      if (!selectedBatch) return;

      (async () => {
        try {
          setAttemptsLoading(true);

          let attemptFilters = {
              batch: selectedBatch,
              user: Session.userId,
              finishedAt: null,
              pageSize,
              order: "desc",
              orderBy: "createdAt",
              include: "interviewTemplate"
          };

          if(batchData?.forPathway && selectedPathway){
            attemptFilters.pathway = selectedPathway;
          }

          if (assessmentType !== 'allAssessments') {
              attemptFilters.type = assessmentType.toLowerCase();
          }

          const { interviewAttempts } = await service.callService(
              getInterviewAttempts, attemptFilters
          );

          const _attempts = [];
          interviewAttempts.forEach(attempt => {
              const content = attempt.analysis.content.ratings.OVERALL;
              const communication = attempt.analysis.communication.ratings.OVERALL;
              _attempts.unshift({
                  _id: attempt._id,
                  content,
                  communication,
                  type: attempt?.interviewTemplate?.type,
                  overall: getAverageScore(communication, content),
                  templateName: attempt?.interviewTemplate?.name,
              });
          });

          setAttempts(_attempts);
        } catch (error) {
            console.log(error);
            snackbar.error("Uh Oh! Unable to get the performance");
        } finally {
          setAttemptsLoading(false);
        }
      })();
    }, [selectedBatch, pageSize, assessmentType, selectedPathway]);

    React.useEffect(() => {
        const batch = batchData;
        if (batches.length > 0 && batch && !batch.forPathway) {
            setOverallData((currentData) => {
                const newData = [...currentData];

                const user = batch.user;
                const userCommDiff = getPercentageChange(user.communication, user.prevCommunication);
                const userContentDiff = getPercentageChange(user.content, user.prevContent);

                const currUserAverage = getAverageScore(user.communication, user.content);
                const prevUserAverage = getAverageScore(user.prevCommunication, user.prevContent);
                const userAvgDifference = getPercentageChange(currUserAverage, prevUserAverage);

                const currBatchAverage = getAverageScore(batch.communication, batch.content);
                const prevBatchAverage = getAverageScore(batch.prevCommunication, batch.prevContent);
                const batchAvgDifference = getPercentageChange(currBatchAverage, prevBatchAverage);

                newData[0].data = currUserAverage;
                newData[0].percentage = userAvgDifference;
                newData[0].increment = userAvgDifference >= 0;

                newData[1].data = user.communication;
                newData[1].percentage = userCommDiff;
                newData[1].increment = userCommDiff >= 0;

                newData[2].data = user.content;
                newData[2].percentage = userContentDiff;
                newData[2].increment = userContentDiff >= 0;

                newData[3].data = currBatchAverage;
                newData[3].percentage = batchAvgDifference;
                newData[3].increment = batchAvgDifference >= 0;

                return newData;
            });
        }
    }, [selectedBatch, batches]);

    React.useEffect(() => {
      const pathway = pathways.find(p=> p._id === selectedPathway);
        if (pathways.length > 0 && pathway) {
          (async () => {
            try {
              setOverallDataLoading(true);
              
              const userAnalytics = 
                await getPathwayUserAnalytics({pathwayId: selectedPathway, userId: Session.userId});

              let newData = [...PathwayOverallData];
              newData[0].data = userAnalytics.pathwayScore ?? 0;
              newData[1].data = userAnalytics.communicationOverall ?? 0;
              newData[2].data = userAnalytics.contentOverall ?? 0;
              newData[3].data = userAnalytics.batchScore ?? 0;
              setOverallData(newData);
              
            } catch (error) {
              snackbar.error("Unable to load pathways");
            } finally {
              setOverallDataLoading(false)
            }
          })();
        }
    }, [selectedPathway, pathways]);

    const handleChange = (event, newValue) => setPageSize(newValue);

    const handlePathwayChange = (e) => {
      setSelectedPathway(e.target.value);
      sessionStorage.setItem("selectedPathway", e.target.value);
    };

    React.useEffect(() => {
        if (!localStorage?.getItem('v1.6-performance-tour')) {
            localStorage?.setItem('v1.6-performance-tour', true.toString());
            setSteps(PerformanceTourSteps);
            setTimeout(() => playTour(), 3000);
        }
    }, []);

    React.useEffect(() => {
        if (play) {
            const showDummyBatches = batches.length === 0;
            const showDummyAttempts = attempts.length === 0;

            if (showDummyBatches) {
                setBatches(PerformanceTourSteps[0].data.batches);
            }
            if (showDummyAttempts) {
                setAttempts(PerformanceTourSteps[0].data.attempts);
            }

            return () => {
                if (showDummyBatches) setBatches([]);
                if (showDummyAttempts) setAttempts([]);
            };
        }
    }, [play])

    return (
      <>
        <Box>
          <WhiteBox id="detailed-analysis-container">
            <WhiteBoxHeader 
              id="detailed-analysis"
              heading='Score card'
              subheading='Cumulative average scores of assessments & your batch performance'
            >
              <Box className={classes.headerFields}>
                <SelectBatch
                  batches={batches}
                  setBatches={setBatches}
                  selectedBatch={selectedBatch}
                  setSelectedBatch={setSelectedBatch}
                />
                {(pathways && pathways?.length > 0) ? (
                  <SimpleSelect
                    label="Pathway"
                    size="small"
                    value={selectedPathway}
                    options={pathways}
                    onChange={handlePathwayChange}
                  />
                ): null}
              </Box>
            </WhiteBoxHeader>
            <Box className={classes.scoreCards}>
              {overallData?.map((data, i) => (
                  <AnalyticsBrief key={i} {...data} loading={overallDataLoading}/>
              ))}
            </Box>
          </WhiteBox>

          <WhiteBox mt={4} id={'recent-attempts-analysis-container'}>
            {
              <>
                <PerformanceGraph
                    assessmentType={assessmentType}
                    setAssessmentType={setAssessmentType}
                    parameter={parameter}
                    setParameter={setParameter}
                    onClick={(index) => navigate(attempts[index]._id)}
                    labelPrefix=""
                    data={attempts}
                    aspectRatio={4}
                    hideAssessmentTypeFilter={batchData?.forPathway}
                    tooltipCallbacks={(index) => {
                        return `Assessment: ${attempts[index].templateName}`
                    }}
                    loading={attemptsLoading}
                />
                {
                  attempts?.length > 0 ? 
                    <Box mt={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Tabs
                        options={[{label: 12, _id: 12}, {label: 24, _id: 24}, {label: 36, _id: 36}]}
                        value={pageSize}
                        onChange={(id) => setPageSize(id)}
                      />
                    </Box>
                    : null
                }
              </>
            }
          </WhiteBox>
        </Box>

        <AttemptsTable batch={batchData} pathway={selectedPathway}/>
        {
          batchData?.forPathway 
            ? null
            : <AssessmentsTable batch={batchData} pathway={selectedPathway} />
        }

        <ReplayTutorial start={()=> setOpenWelcomeDialog(true)} steps={PerformanceTourSteps}/>
        <TourStartDialog
            open={openWelcomeDialog}
            onClose={setOpenWelcomeDialog}
            title="Welcome to the platform!"
            description="Let's take a quick tour of our platform and see how it can help you land your dream job!"
        />
      </>
    );
}

export default OverallPerformance;