import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CenterFlexBox from "./CenterFlexBox";

import { CustomLoadingButton } from "./CustomButton";
import { useServices } from "contexts/SingleSessionProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import { postFeedback } from "services/userService";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: 80,
    borderRadius: 4,
    backgroundColor: "#049CFF",
    alignSelf: 'center',
    textTransform: 'capitalize',
    fontSize: 14, fontWeight: 500
  },
  textArea: {
    boxSizing: "border-box",
    width: 'auto',
    margin: "0 0 1em 0",
    borderRadius: 4,
    padding: 10,

    marginRight: 4,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:focus": {
      outline: "none !important",
      boxShadow: "0 0 14px 0px #36678f4d"
    },
  },
  tag: {
    display: "flex",
    backgroundColor: "#BFBFBF",
    color: "#000000",
    width: "fit-content",
    borderRadius: 3,
    padding: "4px 8px",
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#02569D",
    color: "white",

  }
}));

function NumberRating({ selected = 3, updateNumberRating }) {

  return (
    <Box display='flex' flexDirection='column' width='fit-content'>
      <Box display='flex' gap={2}>
        {
          [1, 2, 3, 4, 5].map((value) => (
            <Box
              key={value}
              onClick={() => updateNumberRating(value)}
              sx={{
                width: '54px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 18,
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundColor: selected === value ? '#9DE4F2' : '#BFBFBF',
                color: 'black',
                fontWeight: 'bold',
                transition: 'background-color 0.3s, color 0.3s, font-weight 0.3s',
                '&:hover': {
                  backgroundColor: '#9DE4F2',
                },
              }}
            >
              <Typography variant='h6-medium' color='inherit' >
                {value}
              </Typography>
            </Box>
          ))
        }
      </Box>
      <Box
        display='flex' justifyContent='space-between' marginTop={'4px'}
        fontSize={12} fontWeight={500} color={'#636F7F'}
      >
        <Typography variant='body01-semiBold' color='neutral.clr-500' >
          Not satisfied
        </Typography>
        <Typography variant='body01-semiBold' color='neutral.clr-500' >
          Very satisfied
        </Typography>

      </Box>
    </Box>
  );
}

function FeedbackRating({ onSubmitted }) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const service = useServices();
  const [hover, setHover] = useState(3);
  const [ratingValue, setRatingValue] = useState(0);
  const [comment, setComment] = useState("");
  const [easyRating, setEasyRating] = useState(3);
  const [satisfiedRating, setSatisfiedRating] = useState(3);
  const [loading, setLoading] = useState(false);

  const handleRatingChange = (e) => {
    setRatingValue(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const commentValue = `${comment}`;
      const feedbackData = { easyRating, satisfiedRating };

      await service.callService(
        postFeedback,
        ratingValue,
        commentValue || "",
        '',
        feedbackData
      );

      setComment("");
      setEasyRating(3);
      setSatisfiedRating(3);
      setRatingValue(0);
      onSubmitted();

      snackbar.success("Thanks for your valuable feedback!");
    } catch (error) {
      console.error(error);
      snackbar.error(
        "Uh Oh! Unable to submit your feedback. Please try again!", true
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">

        <CenterFlexBox flexDirection='column' gap={2} my={4}>
          <Rating
            value={Number(ratingValue)}
            precision={1}
            onChange={(e) => handleRatingChange(e)}
            size="large"
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />

          <Typography variant="h6-medium" color='tertiary.main'>
            Rate your overall experience
          </Typography>
        </CenterFlexBox>

        <Divider />

        <Typography variant="h6-medium" color='neutral.clr-800' mb={2} mt={5}>
          1. User-friendliness of our platform
        </Typography>
        <NumberRating selected={easyRating} updateNumberRating={(v) => setEasyRating(v)} />

        <Typography variant="h6-medium" color='neutral.clr-800' mb={2} mt={5}>
          2. Do you find tips & feedback useful?
        </Typography>
        <NumberRating selected={satisfiedRating} updateNumberRating={(v) => setSatisfiedRating(v)} />

        <Typography variant="h6-medium" color='neutral.clr-800' mb={2} mt={5}>
          3. What else should we improve?
        </Typography>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={1}
          placeholder="Write your suggestion here"
          className={classes.textArea}
          value={comment}
          onChange={(e) => handleCommentChange(e)}
        />

        <CustomLoadingButton loading={loading} onClick={handleSubmit}>
          Submit Feedback
        </CustomLoadingButton>
      </Box>
    </>
  );
}

export default FeedbackRating;
