import { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import {
  TourProviderContext,
  useServices,
  useSnackbar,
} from "contexts";
import { useNavigateWithClient } from "hooks";
import ParameterScore, { ParameterScoreSkeleton } from "./ParameterScore";
import { getInterviewAttempts, getParameterScores } from "services";
import { PerformanceGraph } from "components/PerformanceGraph";
import { Session, getAverageScore } from "utils";
import { filterByRequiredParameter } from "utils/features";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { DashboardTourSteps } from "./constants";
import CustomButton from "components/CustomButton";
import HorizontalScrollNavigator from "components/HorizontalScrollNavigator";
import { HomeCategories } from "components/PerformanceReview/constants";

const useStyles = makeStyles(theme=>({
  recentPerformance: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: 'column',
  },
}));

function ParameterScores({
  selectedBatch = "",
  parameterScores = [],
  batches = [],
  totalAttempts = 0,
  loading = false
}) {
  const theme = useTheme();

  const [userAverage, setUserAverage] = useState(null);
  const [batchAverage, setBatchAverage] = useState(null);
  const [isPathwayBatch, setIsPathwayBatch] = useState(false);

  useEffect(() => {
    const batch = batches.find((v) => v._id === selectedBatch);
    if (batches.length > 0 && batch) {
      setIsPathwayBatch(batch?.forPathway);
      const user = batch.user;

      const currUserAverage = getAverageScore(user.communication, user.content);
      const prevUserAverage = getAverageScore(
        user.prevCommunication,
        user.prevContent
      );
      const userAvgDifference = currUserAverage - prevUserAverage;

      const currBatchAverage = getAverageScore(
        batch.communication,
        batch.content
      );
      const prevBatchAverage = getAverageScore(
        batch.prevCommunication,
        batch.prevContent
      );
      const batchAvgDifference = currBatchAverage - prevBatchAverage;

      setUserAverage({
        value: currUserAverage,
        percentage:
          userAvgDifference < 0 ? userAvgDifference * -1 : userAvgDifference,
        increment: userAvgDifference >= 0,
      });
      setBatchAverage({
        value: currBatchAverage,
        percentage:
          batchAvgDifference < 0 ? batchAvgDifference * -1 : batchAvgDifference,
        increment: batchAvgDifference >= 0,
      });
    }
  }, [batches, selectedBatch]);

  const handleScroll = () => {
    let toolTipElement = document.getElementById(isPathwayBatch ? 'pathway-list-container' : 'to-do-list-container');
    toolTipElement?.scrollIntoView({ block: "center" });
  };

  return (
    <WhiteBox id="third-home-tip">
      <WhiteBoxHeader
        heading="Speech overview"
        subheading="Overview of your speech performance for recent 10 attempts"
        sx={{ marginBottom: theme.spacing(6) }}
      >
        <CustomButton variant="contained" size="medium" onClick={handleScroll}>
        {
          isPathwayBatch ? 'Attempt Pathway' : 'Take assessment'
        }
        </CustomButton>
      </WhiteBoxHeader>

      <HorizontalScrollNavigator
        childrensLength={(!parameterScores?.length || loading) ? 5 : parameterScores?.length}
        scrollWidth={800}
      >
        {
          (!parameterScores?.length || loading) ? 
            Array.from(new Array(5)).map((d, i) => {
              return <ParameterScoreSkeleton key={i}/>
            })
          :
            parameterScores.map((parameterScore, i) => (
              <div key={i}>
                <ParameterScore {...parameterScore} locked={!totalAttempts > 0} loading={loading} />
              </div>
            ))
        }
      </HorizontalScrollNavigator>

    </WhiteBox>
  );
}

function RecentPerformance({ batches, selectedBatch, batchData }) {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();
  const navigate = useNavigateWithClient();

  const { play } = useContext(TourProviderContext);
  const [loading, setLoading] = useState(false);
  const [assessmentType, setAssessmentType] = useState("allAssessments");
  const [attempts, setAttempts] = useState([]);
  const [parameter, setParameter] = useState("overall");
  const [parameterScores, setParameterScores] = useState([]);

  useEffect(() => {
    if (!selectedBatch) return;

    (async () => {
      try {
        setLoading(true);

        const attemptFilters = {
          batch: selectedBatch,
          user: Session.userId,
          finishedAt: null,
          pageSize: 10,
          order: "desc",
          orderBy: "createdAt",
          include: "interviewTemplate",
        };

        if (assessmentType !== "allAssessments") {
          attemptFilters.type = assessmentType.toLowerCase();
        }

        const { interviewAttempts } = await service.callService(
          getInterviewAttempts,
          attemptFilters
        );
        const _attempts = [];
        interviewAttempts.forEach((attempt) => {
          const content = attempt.analysis.content.ratings.OVERALL;
          const communication = attempt.analysis.communication.ratings.OVERALL;
          _attempts.unshift({
            _id: attempt._id,
            content,
            communication,
            type: attempt?.interviewTemplate?.type,
            overall: getAverageScore(communication, content),
            tags: attempt?.analysis?.communication?.features || [],
            templateName: attempt?.interviewTemplate?.name,
          });
        });
        setAttempts(_attempts);
      } catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get the performance");
      } finally {
        setLoading(false);
      }
    })();
  }, [selectedBatch, assessmentType]);

  useEffect(()=>{
    (async () => {
      try {
        const filters = {
          userId: Session.userId
        }

        const parameters = await service.callService(
          getParameterScores,
          filters
        );

        const filteredParameters = filterByRequiredParameter(parameters, HomeCategories);

        setParameterScores(filteredParameters)
      } catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get the performance");
      }
    })();
  },[attempts])

  useEffect(() => {
    if (play) {
      const showDummyAttempts = attempts.length === 0;

      if (showDummyAttempts) {
        setAttempts(DashboardTourSteps[1].data.attempts);
      }

      return () => {
        if (showDummyAttempts) setAttempts([]);
      };
    }
  }, [play]);

  return (
    <Box
      className={classes.recentPerformance}
      id="recent-attempts-analysis-container"
    >
      <Box flexGrow={2} id="graph-container">
        <WhiteBox>
          {
            <PerformanceGraph
              hideAssessmentTypeFilter={batchData?.forPathway}
              assessmentType={assessmentType}
              setAssessmentType={setAssessmentType}
              parameter={parameter}
              setParameter={setParameter}
              data={attempts}
              aspectRatio={4}
              tooltipCallbacks={(index) => {
                return (attempts?.[index]?.templateName || "Assessment")
              }}
              onClick={(index) => {
                navigate(`/performance/${attempts[index]._id}`);
              }}
              loading={loading}
            />
          }
        </WhiteBox>
        <br />
        <ParameterScores
          batches={batches}
          selectedBatch={selectedBatch}
          parameterScores={parameterScores}
          totalAttempts={attempts?.length}
          loading={loading}
        />
      </Box>

    </Box>
  );
}

export default RecentPerformance;
