import React from "react";
import Typography from "@mui/material/Typography";

import useAssessmentStore from "./assessmentStore";

function CountDown({ state, question }) {
    const submit = useAssessmentStore(state => state.submit);
    const [timeLeft, setTimeLeft] = useAssessmentStore(state => [state.timeLeft, state.setTimeLeft]);
    const [totalTime, setTotalTime] = React.useState('00');

    React.useEffect(() => {
        let timeoutId;
        if ("RECORDING" === state) {
            if (timeLeft === 0) {
                submit();
            } else if (timeLeft > 0) {
                timeoutId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            }
        }
        return () => clearTimeout(timeoutId);
    }, [timeLeft, state]);

    React.useEffect(() => {
        if (question) setTimeLeft(question.allottedTime);
        const totalMins = (question.allottedTime || 0);
        if (totalMins === 0) setTotalTime('00');
        if (totalMins < 10) setTotalTime(`0${totalMins}`);
        else setTotalTime(totalMins);
    }, [question]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <Typography
            fontSize={18}
            fontWeight={600}
            fontStyle="normal"
            fontFamily='Inter'
        >
            {minutes < 10 ? "0" : ""}{Math.floor(timeLeft / 60)}
            :
            {seconds < 10 ? "0" : ""}{Math.floor(seconds)} 
            / 
            {(totalTime/60 )< 10 ? "0" : ""}{Math.floor(totalTime/60)}
            :
            {(totalTime%60) < 10 ? "0" : ""}{Math.floor(totalTime % 60)}
        </Typography>
    )
}

export default CountDown;