import React from 'react';

import CopiedIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import Button from '@mui/material/Button';
import MuiIconButton from "@mui/material/IconButton/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import LoadingButton from '@mui/lab/LoadingButton';

import { copyToClipboard } from 'utils';

const useStyles = makeStyles(theme=>({
  root: {
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    gap: theme.spacing(1),
    textTransform: "none",
    '& .MuiButton-endIcon': {
      marginLeft: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: '0px'
    }
  },
  iconBtn: {
    padding: theme.spacing(1,2),
    minWidth: 'fit-content',
    '& .Mui-disabled': {
    }
  },
  disabledBtn: {
    backgroundColor: theme.palette.neutral.disabled,
  },
  loadingButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-400']
    },
    ...theme.typography['body01-bold'],
  },
  small: {
    height: '36px !important',
    padding: theme.spacing(2,4),
    ...theme.typography['body01-bold'],
  },
  medium: {
    height: '44px !important',
    padding: theme.spacing(2.5,6),
    ...theme.typography['h6-medium'],
  },
  large: {
    height: '48px !important',
    padding: theme.spacing(2.5,7),
    ...theme.typography['h6-medium'],
  },
  contained: {
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-400']
    }
  },
  outlined: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderWidth: '1px',
      backgroundColor: theme.palette.primary['clr-50']
    }
  },
  outlinedIconBtn: {
    borderColor: theme.palette.neutral['clr-200'],
    color: theme.palette.shades['clr-black-900'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50']
    }
  },
  linkButton: {
    width: 'fit-content',
    border: 'none',
    ...theme.typography['body01-link'],
    color: theme.palette.primary['clr-300'],
    '&:hover': {
      border: 'none',
      background: 'none',
      textDecoration: 'underline'
    },
  }
}));

export function CopyButton({
  content, title = 'Copy', textRef, iconProps = {}, ...props
}) {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    if (copied) return;

    copyToClipboard(content, textRef);

    setCopied(true);

    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <MuiIconButton onClick={handleCopy} title={title} {...props}>
      {copied ? <CopiedIcon color='success' /> : <CopyIcon {...iconProps} />}
    </MuiIconButton>
  )
}

/**
 * CustomLoadingButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {object} props.sx - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {'small' | 'medium' | 'large'} props.size - The size of the button, must be one of 'small', 'medium', or 'large'.
 * @param {function} [props.onClick] - The click event handler.
 * @param {boolean} [props.loading=false] - If `true`, the button shows a loading spinner.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export function CustomLoadingButton({
  disabled = false, onClick = () => {}, loading=false, size='small', variant='contained', ...props
}) {
  const classes = useStyles();

  return (
    <LoadingButton
      disableElevation
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      variant={variant}
      className={`${classes.root} ${classes[variant]} ${classes.loadingButton} ${classes[size]}`}
      {...props}
    />
  );
}

/**
 * PrimaryButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {object} props.sx - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {'small' | 'medium' | 'large'} props.size - The size of the button, must be one of 'small', 'medium', or 'large'.
 * @param {'contained' | 'outlined'} props.variant - The variant of the button, must be one of 'contained' or 'outlined'.
 * @param {function} [props.onClick] - The click event handler.
 * @param {React.ReactNode} [props.startIcon] - Element to be placed before the children.
 * @param {React.ReactNode} [props.endIcon] - Element to be placed after the children.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export default function CustomButton({ 
  disabled = false, onClick = () => {}, size='small', variant='contained', ...props 
}) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      className={`${classes.root} ${classes[variant]} ${classes[size]}`}
      {...props}
    />
  );
}

export function IconButton({ 
  disabled = false, onClick = () => {}, variant='contained', ...props 
}) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      className={
        ` ${classes.root} ${classes[variant]} ${classes.iconBtn} ${ disabled ? classes.disabledBtn : ''}
          ${variant==='outlined' && classes.outlinedIconBtn}
        `
        }
      {...props}
    />
  );
}

/**
 * LinkButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {object} props.sx - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {function} [props.onClick] - The click event handler.
 * @param {string} [props.href='#'] - The URL that the button links to.
 * @param {string} [props.target='_blank'] - Specifies where to open the linked document.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */
export function LinkButton({
  disabled=false, target='_blank', href= '', buttonRef = null, ...props
}) {
  const classes = useStyles();

  return (
    <Button
      ref={buttonRef}
      disableElevation
      disabled={disabled}
      target={target}
      href={href}
      variant="outlined"
      className={`${classes.root} ${classes.linkButton}`}
      {...props}
    />
  );
}