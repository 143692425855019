import * as yup from 'yup';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';

import { CustomLoadingButton, LinkButton } from 'components/CustomButton';
import { generateResetPasswordLink, getUserClients } from 'services';
import { useSnackbar } from 'contexts';
import { useNavigate } from 'react-router-dom';
import LoginShell from 'components/LoginShell';
import TextField from 'components/CustomFields';
import { SimpleSelect } from 'components/CustomSelectFields';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Please enter a correct email')
        .required('Please provide a registered email'),
    client: yup.string().required("Please select a Organization"),
});

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex", alignItems: "center", justifyContent: "flex-end",
        width: "25vw", flexDirection: "row"
    },
    fieldsContainer: {
        width: '100%',
        marginTop: '3rem',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    backButton: {
      width: 100, height: 44, 
      ...theme.typography['body01-bold'],
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main, 
      backgroundColor: theme.palette.shades['clr-white-900'], 
      marginRight: 20,
      textTransform: 'none',
      "&:hover": { 
        background: theme.palette.shades['clr-white-900'], 
        border: '2px solid', borderColor: theme.palette.primary.main 
      }
    }, 
    nextButton: {
      width: 100, height: 44,
      ...theme.typography['body01-bold'],
      textTransform: 'none',
    },
    buttons: {
      display: 'flex', gap: theme.spacing(3),
      justifyContent: 'center'
    }
}));

function ForgotPassword() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [clients, setClients] = React.useState([]);
    const [logo, setLogo] = React.useState(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            client: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                let result = await generateResetPasswordLink(values.email, values.client);
                snackbar.success(result);
                setLinkSent(true);
            }
            catch (error) {
                snackbar.error(error.message);
            } finally {
                setLoading(false);
            }
        },
    });

    const handleNext = async () => {
        try {
            setLoading(true);

            const clientList = await getUserClients(formik.values.email);

            clientList.forEach(c => c.label = c.name);

            if (clientList.length) {
                setClients(clientList);
                formik.setFieldValue('client', clientList[0]._id);
            } else formik.setFieldError('email', 'Please provide a registered email');
        } catch (error) {
            formik.setFieldError('email', 'Please provide a registered email');
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        const client = clients.find((client) => (client._id === formik.values.client));
        setLogo({ url: client?.metadata?.logoUrl, transform: client?.metadata?.inprepLogoTransform });
    }, [formik.values.client, clients]);

    return (
        <LoginShell title='Forgot password?' clientLogoUrl={logo}>

            <form style={{ width: '80%' }} noValidate>
                <Box className={classes.fieldsContainer} >
                    <TextField
                        id='email'
                        name='email'
                        label='Enter your registered email'
                        placeholder='example@gmail.com'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={(formik.touched.email && !!formik.errors.email) ? formik.errors.email : ''}
                        disabled={clients.length > 0}
                        onBlur={formik.handleBlur}
                    />

                    {
                        (clients.length > 1) &&
                        <SimpleSelect
                            options={clients}
                            name="client"
                            placeholder="Select Organization"
                            label="Select Organization"
                            onChange={formik.handleChange}
                            value={formik.values.client}
                            error={(formik.touched.client && !!formik.errors.client) ? formik.errors.client : ''}
                        />
                    }

                    <Box className={classes.buttons}>

                        {
                          (clients.length !== 0) &&
                            <CustomLoadingButton
                                onClick={() => setClients([])}
                                type="button"
                                variant='outlined'
                                loading={false}
                                disabled={loading}
                            >
                                Back
                            </CustomLoadingButton>
                        }

                        <CustomLoadingButton
                            onClick={(clients.length !== 0) ? formik.handleSubmit : handleNext}
                            type="button"
                            loading={loading}
                        >
                            {
                                (clients.length === 0) ? 
                                  'Next' : (linkSent) 
                                    ? <>Resend&nbsp;link</> : <>Send&nbsp;link</>
                            }
                        </CustomLoadingButton>
                    </Box>

                    {
                    <LinkButton
                      href={"/guest/login"}
                      sx={{ alignSelf: 'center' }}
                    >
                      Return to login
                    </LinkButton>
                    }
                </Box>
            </form>

        </LoginShell>
    );
}

export default ForgotPassword;
