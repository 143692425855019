import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

import { getTwoDigitNo } from 'utils';

const useStyles = makeStyles(theme=>({
  lockedState: {
    position: 'absolute', top: 0, left: 0, 
    width: '100%', height: '100%', 
    padding: theme.spacing(2),
    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    gap: theme.spacing(2), 
    background: 'rgba(255, 255, 255, 0.40)', 
    backdropFilter: 'blur(8px)', 
    '&:hover': { cursor: 'default' }
  },
  progressBox: {
    width: '100%', height: '8px', borderRadius: '5px',
  },
  card: {
    minWidth: '294px', maxWidth: '294px', 
    borderRadius: '4px', 
    padding: '0px', position: 'relative', 
    height: '160px', 
    border: '3px solid transparent',
    transition: "all 20ms ease-in-out", overflow: 'hidden', cursor: 'pointer',
    marginTop: theme.spacing(3),
    zIndex: 1,
    boxShadow: '0px 0px 2px 0px #000000BF',
    "&:hover": {
      boxShadow: "0px 0px 8px 0px #1961BF4D !important",
    },
  },
  masteryCard: {
    "&:before, &:after": {
      content: '""',
      position: 'absolute',
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      filter: 'blur(50px)',
      webkitFilter: 'blur(50px)',
      zIndex: -1
    },
    "&:before": {
      top: '-50px',
      left: '-50px',
      background: 'linear-gradient(284.04deg, #A1FFCE 0%, #D1FFD0 53.43%, #FAFFD1 100%, #F5CE47 100%)',
      
    },
    "&:after": {
      bottom: '-50px',
      right: '-50px',
      background: 'linear-gradient(284.04deg, #A1FFCE 0%, #D1FFD0 53.43%, #FAFFD1 100%, #F5CE47 100%)',
    },
  },
  activeCard: {
    outline: '3px solid',
    outlineColor: theme.palette.primary.main
  },
  masteryActiveCard: {
    outline: '3px solid',
    outlineColor: theme.palette.primary.main
  },
  cardHeadSection: {
    display: 'flex', justifyContent: 'space-between', 
    padding: theme.spacing(0,2), 
    alignItems: 'center',
    height: '40px'
  },
  cardContentSection: {
    display: 'flex', 
    flexDirection: 'column', justifyContent: 'space-between',
    padding: theme.spacing(2), 
    height: '105px',
  },
  score: {
    ...theme.typography['body01-bold'], 
    padding: theme.spacing(0.5,3),
    borderRadius: '30px'
  },
  assessments: {
    display: 'flex', gap: theme.spacing(1), 
    alignItems: 'center', 
    marginTop: theme.spacing(1), 
    marginBottom: theme.spacing(2)
  },
  footHead: {
    width: '280px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
}));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'transparent'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral['clr-800'],
    color: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 7,
    maxWidth: 215,
    ...theme.typography['body02-bold'],
  },
  [`&.${tooltipClasses.popper}`]: {
    top: '-85px !important', 
    left:'25px !important'
  },
}))

const sx = {
  completedIcon: { fontSize: '16px', display: 'flex', color: '#237611' },
  lockState: {textAlign:'center', fontSize: '16px'}
};


export const MultiBoxProgressBar = ({ total, completed}) => {
  const classes = useStyles();
  const theme = useTheme();

  return(
    <Box display='flex' gap='5px'>
      {
        Array.from({length: total},(value,i)=>(
          <Box 
            className={classes.progressBox}
            style={{
              backgroundColor: 
                i < completed 
                ? completed === total
                  ? theme.palette.success['clr-700'] : theme.palette.primary.main 
                : theme.palette.neutral['clr-300']
              }}
          >
          </Box>
        ))
      }
    </Box>
  )
}

export const LockedState = ({content, showIcon = true, iconWidth = 50, styles={}}) => {
  const classes = useStyles();
  return(
    <Box className={classes.lockedState}>
      {
        showIcon ? 
        <svg xmlns="http://www.w3.org/2000/svg" width={iconWidth} height={iconWidth} viewBox="0 0 100 100" fill="none">
          <path d="M79.5729 45.8359H21.2396C16.6372 45.8359 12.9062 49.5669 12.9062 54.1693V83.3359C12.9062 87.9383 16.6372 91.6693 21.2396 91.6693H79.5729C84.1753 91.6693 87.9062 87.9383 87.9062 83.3359V54.1693C87.9062 49.5669 84.1753 45.8359 79.5729 45.8359Z" stroke="#4E5661" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M28.9062 45.8359V29.1693C28.9063 23.6439 31.1012 18.3449 35.0082 14.4379C38.9152 10.5309 44.2142 8.33594 49.7396 8.33594C55.2649 8.33594 60.564 10.5309 64.471 14.4379C68.378 18.3449 70.5729 23.6439 70.5729 29.1693V45.8359" stroke="#4E5661" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        : null
      }
      <Typography variant='h6-medium' color='neutral.clr-600' textAlign='center' sx={styles}>
        {content}
      </Typography>
    </Box>
  )
};

const getColors = (score, theme) => {
  if(score>=75) return {
    primary: theme.palette.success['clr-700'], 
    secondary: theme.palette.success['clr-100']
  };
  else if(score>=50 && score <75) return {
    primary: theme.palette.warning['clr-700'], 
    secondary: theme.palette.warning['clr-100']
  };
  else if(score>0 && score<50) return {
    primary: theme.palette.danger['clr-700'], 
    secondary: theme.palette.danger['clr-100']
  };
  else if(score===0) return {
    primary: theme.palette.shades['clr-black-900'], 
    secondary: theme.palette.neutral['clr-200']
  }
}

const TemplateToolTip = ({
  component, placement = 'top', activeModule ,children,positionStyle = {}, ...props 
}) => {
  return (
    <Box sx={{ position: 'relative', }} onClick={activeModule}>
      <BootstrapTooltip title={component} positionStyle={positionStyle} placement={placement} {...props}>
        {children}
      </BootstrapTooltip>
    </Box>
  )
}

const ModuleSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <Box className={classes.cardHeadSection}>
        <Skeleton variant="rectangular" width="100%" height={20} />
      </Box>
      <Box className={classes.cardContentSection}>
        <Skeleton variant="rectangular" width="100%" height={20} />
        <Skeleton variant="rectangular" width="100%" height={20} />
      </Box>
    </Box>
  )
}

const Module = ({ 
  data, moduleIndex, activeModule, setActiveModule, loading=false, ...props 
}) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const scoreColors = React.useMemo(()=>{
    return getColors(data?.moduleAverage || 0, theme);
  },[data]);

  return (
    loading ? <ModuleSkeleton/> : 
    <TemplateToolTip
      placement='bottom' 
      component=  {data?.name}
      disableHoverListener= {data?.name?.length <= 25}
      activeModule={()=> setActiveModule(moduleIndex)}
    > 
      <Box 
          className={
            `
              ${classes.card}
              ${
                moduleIndex === activeModule 
                ? data?.masterModule 
                  ? `${classes.masteryCard} ${classes.masteryActiveCard}`
                  : classes.activeCard
                : data?.masterModule ? classes.masteryCard : ''
              }
            `
          }
          onClick={()=> setActiveModule(moduleIndex)}
          id={`module${moduleIndex}`}
        >
          <Box className={classes.cardHeadSection}>
            <Typography variant='h6-medium' color='primary.main'>
            {
              data?.masterModule ? 'Pathway Mastery' :
              getTwoDigitNo(moduleIndex + 1)
            }
            </Typography>
            <Typography variant='body01-bold'>Score&nbsp;
              <span
                className={classes.score}
                style={{ 
                    backgroundColor: scoreColors?.secondary, color: scoreColors?.primary
                  }}
              >
                {
                  data?.moduleAverage ? `${data?.moduleAverage}%` : "--"
                }
              </span>
            </Typography>
          </Box>
          <Divider flexItem orientation='horizontal' light color='#DADBDB' />
          <Box className={classes.cardContentSection}>
            <Box sx={{height:'46px',display:'flex' , alignItems:'center'}}>
              { data?.masterModule ?
                <Typography variant='body01-semiBold'>
                  Score above {data?.criteria?.score || 75}% in this module to unlock certificate
                </Typography>
                :
               <Box className={classes.footHead}>
                   <Typography variant='h6-semiBold'>
                    {data?.name}
                  </Typography>
               </Box>
              }
            </Box>
            <Box>
              <Box className={classes.assessments}>
                <Typography variant='body01-semiBold'>
                  Assessment completed: &nbsp;
                  <span style={{ color: theme.palette.primary.main}}>
                    {getTwoDigitNo(data?.templatesCompleted)}/{getTwoDigitNo(data?.templates?.length)}
                  </span>
                </Typography>
                { data?.templatesCompleted === data?.templates?.length
                  ? <span> <CheckCircleIcon style={sx.completedIcon}/> </span>
                  : null
                }
              </Box>
              <span>
                <MultiBoxProgressBar 
                  completed={data?.templatesCompleted} 
                  total={data?.templates?.length}
                />
              </span>
            </Box>
          </Box>
        </Box>
      </TemplateToolTip>
  );
};


export const CertificateCard = ({ 
  moduleIndex, locked, setOpenCertificate, loading=false, ...props 
}) => {
  const classes = useStyles();

  return (
    loading ? <ModuleSkeleton/> : 
      <Box
        className={`${classes.card} ${classes.masteryCard}`}
        onClick={()=> !locked && setOpenCertificate(moduleIndex)}
        id={`module${moduleIndex}`}
      >
        <Box style={{ display:'flex', width: '100%', height: '100%',}}>
          <img 
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/certificate1.png' 
            alt='certificate-thumbnail' width='100%'
          />
        </Box>
        {/* locked state */}
        {locked ?
          <LockedState 
            iconWidth={70}
            content={'Certificate'} 
          />
          : null
        }
      </Box>
  );
};

export default Module;