import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomButton from '../components/CustomButton';

const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: '46%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        width: 245,
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.8
    }
});

function NotFound() {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <Box className={classes.container}
            >
                <Typography
                    fontSize={16}
                    color='#2C3840'
                    fontWeight={600}
                    mb='3em'
                >
                    The page you are looking for, is Not Found!!
                </Typography>
                <Box textAlign='center'>
                    <Typography
                        variant='h5'
                        color='#D8D8D8'
                        fontWeight={600}
                        mb={-5}
                    >
                        Error
                    </Typography>
                    <Typography
                        variant='h1'
                        color='#D8D8D8'
                        fontFamily='Sora'
                        fontSize={300}
                    >
                        404
                    </Typography>
                </Box>
                <CustomButton
                    variant='contained'
                    onClick={() => navigate('/guest/login')}
                    className={classes.button}
                >
                    Go Back to Homepage
                </CustomButton>
            </Box>
        </>
    );
}

export default NotFound;
