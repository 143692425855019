import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    iconButton: {
        width: 'fit-content',
        padding: '10px',
        boxShadow: '0 0 7px rgba(0,0,0,0.25)',
        marginLeft: '16px',
        marginRight: '16px',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        color: 'black',
        cursor: 'pointer',
        fontSize: "3em",
        border: "none",
        transition: "box-shadow 0.1s linear",
        "&:disabled": {
            boxShadow: '0 0 2px rgba(0,0,0,0.25)',
            cursor: "not-allowed",
            opacity: 0.7,
        },
        "&:hover:enabled": {
            backgroundColor: "#f3f3f3",
        },
        "&:active:enabled": {
            backgroundColor: "#f9f9f9",
            boxShadow: "0 0 15px 0px rgba(0,0,0,0.35)",
        },
    },
});

function WhiteIconButton({ children, className, ...props }) {
    const classes = useStyles();

    return (
        <button
            className={`${classes.iconButton} ${className ? className : ""}`}
            {...props}
        >
            {children}
        </button>
    );
}

export default WhiteIconButton;