import { Box } from "@mui/material";

function CenterFlexBox({ children, column = false, ...props }) {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={column ? "column" : "row"}
            {...props}
        >
            {children}
        </Box>
    );
}

export default CenterFlexBox;