import Client from "./_client";
import Session from "utils/Session";

export const getDraftForUser = async (draftFilters = {}) => {
    let url = `/draft/${Session.userId}?`;

    Object.keys(draftFilters).forEach((key) => {
        if (draftFilters[key]) url += `${key}=${draftFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
};

export const saveDraftForUser = async (draft = {}) => {
    const result = await new Client({
        path: `/draft/${Session.userId}?`,
        payload: draft
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
};

export const saveTemplateFromDraft = async (templateId) => {
    const result = await new Client({
        path: `/draft/${Session.userId}/${templateId}`,
        payload: {}
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to save the template. Please try again."
        );
    }

    return result?.data;
};

export const getDrafts = async (draftFilters) => {
    let url = `/draft?`;

    Object.keys(draftFilters).forEach((key) => {
        if (draftFilters[key]) url += `${key}=${draftFilters[key]}`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get drafts. Please try again."
        );
    }

    return result?.data;
};   

export const getDraftById = async (draftId) => {
    let url = `/draft/getDraft/${draftId}`;

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
}


