import { Box } from "@mui/material";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    scrollBar: {
        overflowX: "auto",
        overflowY: "hidden",
        scrollBehavior: "smooth",
        width: "fit-content",
        "&::-webkit-scrollbar": { display: "none" },
    },
});

export default function HorizontalScrollWrapper({ children, sx = {} }) {
    const container = useRef();
    const classes = useStyles();

    const handleScroll = (event) => {
        container.current.scrollLeft += event.deltaY;
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{ ...sx }}
        >
            <Box
                display="flex"
                gap={5}
                p={3}
                onWheel={handleScroll}
                ref={container}
                className={classes.scrollBar}
            >
                {children}
            </Box>
        </Box>
    );
}