/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import SpeechRecorder from "languify-speech-recorder";
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import MicIcon from '@mui/icons-material/Mic';
import CircularProgressCountDown from 'components/CircularProgressCountDown';
import CustomButton from 'components/CustomButton';
import TroubleshootDialog from './TroubleshootDialog';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { useSnackbar } from "contexts/SnackbarProvider";
import { useNavigateWithClient } from 'hooks';
import { Session, askPermission, formatDate, subscribeToAudioLevel } from 'utils';

const useStyles = makeStyles(theme => ({
    resumeActions: {
        display: 'flex', width: "100%",
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    reset: {
        width: "50%", fontFamily: 'Montserrat', fontWeight: 500, fontSize: 15,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        textTransform: "none"
    },
    saveBtn: {
        width: "50%", marginRight: theme.spacing(5),
        textTransform: "none"
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 6)
    },
    closeIcon: {
        color: 'black', padding: theme.spacing(1),
        '&:hover': {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        }
    },
    autoClosingText: {
        ...theme.typography['body02-bold'],
        background: theme.palette.danger['clr-100'],
        borderRadius: theme.spacing(4),
        height: 'fit-content', padding: theme.spacing(2, 3),
        color: theme.palette.danger['clr-700'],
        display: 'flex', alignItems: 'center'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', padding: theme.spacing(4, 6),
        marginTop: '15px'
    },
    label: {
        ...theme.typography['body01-semiBold']
    },
    value: {
        ...theme.typography['body01-bold'],
        color: theme.palette.primary['clr-300']
    },
    instructions: {
        ...theme.typography['body01-semiBold'],
        marginBottom: theme.spacing(2)
    },
    micRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    personalizedContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        gap:theme.spacing(5)
    },
    contentBox:{
        display:'flex',
        flexDirection:'column',
        gap:theme.spacing(1)
    },
    microphoneTest:{
        display:'flex',
        gap:theme.spacing(4),
        height:'28px',
        justifyContent:'center',
        alignItems:'center',
        border:`1px solid ${theme.palette.neutral['clr-200']}`,
        borderRadius:theme.spacing(3)
    },
    micBox:{
        display:'flex',
        gap:theme.spacing(3),
        alignItems:'center'
    },
    levelBar:{
        width:'110px'
    }
}));

const CLOSE_TIME = 90;

function AutoCloseTimer({ on = false, onTimerEnd = () => { }, onEachSecond = () => { } }) {

    const [closeTime, setCloseTime] = React.useState(CLOSE_TIME);
    const closeTimeIntervalRef = React.useRef(null);

    const timer = () => {
        setCloseTime((currentTime) => currentTime - 1);
    }

    const onEnd = () => {
        setCloseTime(CLOSE_TIME);
        if (closeTimeIntervalRef.current) clearInterval(closeTimeIntervalRef.current);
        onTimerEnd();
    }

    useEffect(() => {
        if (on) {
            closeTimeIntervalRef.current = setInterval(timer, 1000);
        } else {
            if (closeTimeIntervalRef.current) clearInterval(closeTimeIntervalRef.current);
        }

        setCloseTime(CLOSE_TIME);
    }, [on]);

    useEffect(() => {
        if (closeTime <= 0) onEnd();
        onEachSecond(`Closing in : ${closeTime}sec`);
    }, [closeTime]);

    return null;
}

const PaperProps = { style: { borderRadius: '8px', position: 'relative', minHeight: 430 } }

const neverExpiry = dayjs().set('year', 9999).endOf('year');

function StartAssessmentDialog({
    open = true,
    template = {
        _id: "", name: "", expiresOn: new Date(),
        metadata: { testTime: 55 }, type: "practice"
    },
    isPersonalizedInterview = false,
    setCountdownStart,
    clientMock = false,
    userMock = false,
    onClose = () => { },
}) {
    const classes = useStyles();
    const navigate = useNavigateWithClient();
    const snackbar = useSnackbar();
    const theme = useTheme();

    const [state, setState] = React.useState("INITIAL");
    const [audioLevel, setAudioLevel] = React.useState(0);
    const [matched, setMatched] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openTroubleshootDialog, setOpenTroubleshootDialog] = React.useState(false);
    const [autoCloseMessage, setAutoCloseMessage] = React.useState('');

    const unsubscribeRef = React.useRef();
    const speechRecorderRef = React.useRef();
    const { testTime } = template?.metadata || {};

    const instructions = {
        "assignment": [
            <>You <b>can only attempt this assessment once</b></>,
            <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
            <>If you don’t want to attempt this assessment now, click on ‘x’ icon on the top right corner.</>
        ],
        "practice": [
            <>This is a <b>practice</b> assessment, you can attempt it <b>
                {
                    (template?.maxAllowedAttempts > 0) ?
                        `${template?.maxAllowedAttempts} times.` :
                        'as many times you want.'}
            </b></>,
            <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
            <>If you don’t want to attempt this assessment now, click on ‘x’ icon on the top right corner.</>
        ],
        "follow_up": [
            <>This is a <b>follow up</b> assessment, you can attempt it <b>
                {
                    (template?.maxAllowedAttempts > 0) ?
                        `${template?.maxAllowedAttempts} times.` :
                        'as many times you want.'}
            </b></>,
            <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
            <>If you don’t want to attempt this assessment now, click on ‘x’ icon on the top right corner..</>
        ],
        "pathway": [
            <>This is a <b>pathway</b> assessment, you can attempt it <b>
                {
                    (template?.maxAllowedAttempts > 0) ?
                        `${template?.maxAllowedAttempts} times.` :
                        'as many times you want.'}
            </b></>,
            <>You will have a certain amount of time to complete each question, so manage your time carefully.</>,
            <>If you don’t want to attempt this assessment now, click on ‘x’ icon on the top right corner.</>
        ],
        "personalized": clientMock ? [
            <>This is a <b>mock interview</b>. You can practice it multiple times.</>,
            <> Carefully listen to the interviewer’s question.</>,
            <b> Wearing an external microphone would help you to record answers at a High Quality. </b>
        ] : [
            <>This is a <b>personalized interview</b>. You can practice it multiple times.</>,
            <> Carefully listen to the interviewer’s question.</>,
            <b> Wearing an external microphone would help you to record answers at a High Quality. </b>
        ]
    };

    const proceedToTest = async () => {
        try {
            setLoading(true);

            const { _id, maxAllowedAttempts, attempts, type } = template;

            if (
                type === 'practice' &&
                maxAllowedAttempts &&
                maxAllowedAttempts !== -1 &&
                attempts >= maxAllowedAttempts
            ) {
                throw new Error(
                    'You have reached max limit of attempts for this assessment!'
                );
            }

            navigate(`/assessment?tid=${_id}`);
        } catch (error) {
            console.error(error);
            snackbar.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const openPermissionPrompt = async () => {
        const isPermitted = await askPermission({ audio: true, video: true });

        if (isPermitted) {
            subscribe();
            setState("GRANTED");
        } else {
            setState("ASK_PERMISSION");
        }
    }

    useEffect(() => {
        if (isPersonalizedInterview){
            if(matched){
                setCountdownStart(true) 
            }
        }
    },[matched])

    useEffect(() => {
        if (open) {
            setMatched(false);

            (async () => {
                const isPermitted = await askPermission({ audio: true, video: true });

                if (isPermitted) {
                    subscribe();
                    setState("GRANTED");
                } else {
                    setState("ASK_PERMISSION");
                }
            })();
        }
    }, [open]);

    React.useEffect(() => {
        if (!open) return;

        if (!speechRecorderRef.current) {
            const isMobile = true;
            const serverUrl = process.env.REACT_APP_WEB_SOCKET_URL;
            const query = { token: Session.accessToken, application: 'none' };

            speechRecorderRef.current = new SpeechRecorder({
                isMobile, serverUrl, query, onInterimTranscript: (transcript) => {
                    if (transcript.toLowerCase().search('lets go') !== -1) setMatched(true);
                }
            });

            startRecording();
        }
    }, [open]);

    const startRecording = React.useCallback(async () => {
        if (speechRecorderRef.current) {
            await speechRecorderRef.current.start();
            console.log('Recording started.');
        }
    }, []);

    const subscribe = React.useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            });

            unsubscribeRef.current = subscribeToAudioLevel(stream, (level) => setAudioLevel(level));
            console.log("subscribed.");
        } catch (error) {
            console.error(error);
        }
    }, []);

    const reset = React.useCallback(async () => {
        if (unsubscribeRef.current && typeof unsubscribeRef.current === 'function') {
            unsubscribeRef.current();
            unsubscribeRef.current = null;
            console.log('unsubscribed.');
        }
        if (speechRecorderRef.current) {
            speechRecorderRef.current.stop();
            speechRecorderRef.current.destructor();
            speechRecorderRef.current = null;
            console.log('Recording stopped.');
        }
        setMatched(false);
        setAutoCloseMessage('');
    }, []);

    const handleClose = () => {
        reset();
        onClose();
    }

    return (
        <>
           {isPersonalizedInterview 
           ? (
                <Box className={classes.personalizedContainer}>
                    <Typography  variant='h4-medium' color='neutral.clr-700'>
                        Are you ready for the interview?
                    </Typography>
                    <Box className={classes.contentBox}>
                        <Typography variant='h5-semiBold' color='neutral.clr-400'>
                            Just say
                        </Typography>
                        <Typography variant='h2-medium' color='primary.clr-300'>
                            Let’s Go
                        </Typography>
                        <Typography variant='h5-semiBold' color='neutral.clr-400'>
                            to continue...
                        </Typography>
                    </Box>
                    <Box className={classes.microphoneTest}>
                        <Box className={classes.micBox}>
                            <MicIcon style={{ fontSize: '18px' }} />
                            <Box className={classes.levelBar}>
                                <LinearProgress
                                  variant="determinate" value={audioLevel}
                                  style={{ borderRadius: '10px' }}
                                />
                            </Box>
                        </Box>
                        <Typography 
                            onClick={() => setOpenTroubleshootDialog(true)} 
                            variant='body01-link' 
                            color='primary.clr-300'
                            sx={{cursor:'pointer'}}>
                            Troubleshoot microphone
                        </Typography>
                    </Box>
                    <TroubleshootDialog
                        open={openTroubleshootDialog}
                        onClose={() => setOpenTroubleshootDialog(false)}
                    />
                </Box>
             )
            : (
                <>
                     <Dialog
                        open={open} PaperProps={PaperProps} maxWidth={'lg'}
                        onClose={(e, reason) => (reason === 'backdropClick') ? null : handleClose()}
                        BackdropProps={{
                            className: classes.backdrop
                        }}
                    >
                        {
                            !matched ?
                                <>
                                    <Box className={classes.header}>
                                        <Typography id="customized-dialog-title" variant='h6-medium'>
                                            {clientMock ? 'Mock Interview Details' : userMock ? 'Personalized Interview Details' : 'Assessment'} 
                                        </Typography>
                                        <Box display='flex' gap={2} alignItems='center'>
                                            {autoCloseMessage && <Box className={classes.autoClosingText}>
                                                {autoCloseMessage}
                                            </Box>}

                                            <IconButton className={classes.closeIcon} onClick={handleClose}>
                                                <ClearIcon fontSize='small' />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    <Divider />

                                    <DialogContent className={classes.contentContainer}>
                                        <Box display='flex' gap={1} alignItems='center' mb={1}>
                                            <img
                                                src='https://assets.languify.in/images/assessment-icon.svg'
                                                alt='Assessment'
                                            />
                                            <Typography className={classes.label}>{clientMock ? 'Mock Interview:' : userMock ? 'Personalized Interview:' : 'Assessment:'}  </Typography>
                                            <Typography className={classes.value} color='primary'>
                                                {template?.name}
                                            </Typography>
                                        </Box>
                                        <Box display='flex' gap={1} alignItems='center' mb={1}>
                                            <img
                                                src='https://assets.languify.in/images/time-icon.svg'
                                                alt='Assessment'
                                            />
                                            <Typography className={classes.label}>
                                                {
                                                    template?.type === 'pathway' ?
                                                        'Test Time :' : 'Maximum Time Allowed :'
                                                }
                                            </Typography>
                                            <Typography className={classes.value} color='primary'>
                                                {testTime ?
                                                    (testTime > 60) ?
                                                        `${Math.ceil((testTime) / 60)} minutes` :
                                                        `${template.metadata.testTime} secs`
                                                    : "5-10 mins"
                                                }
                                            </Typography>
                                        </Box>
                                        <Box display='flex' gap={1} alignItems='center'>
                                            <img
                                                src='https://assets.languify.in/images/deadline-icon.svg'
                                                alt='Assessment'
                                            />
                                            <Typography className={classes.label}>Deadline : </Typography>
                                            <Typography className={classes.value} color='#C51407'>
                                                {template.expiresOn && !neverExpiry.isSame(template.expiresOn) ?
                                                    formatDate(template?.expiresOn, "DD MMMM, hA") :
                                                    "No expiry"}
                                            </Typography>
                                        </Box>

                                        <Typography variant='body01-bold' mb={1} mt={3}>
                                            General Instructions
                                        </Typography>
                                        {instructions[template?.type]?.map((instruction, i) => (
                                            <li key={i} className={classes.instructions}>
                                                {instruction}
                                            </li>
                                        ))}

                                    </DialogContent>
                                    <Divider />

                                    <Typography align='center' variant='body02-bold' my={2} color='success.clr-700'>
                                        Remember to sit in a noise free environment
                                    </Typography>

                                    <Typography align='center' mb={2} mt={1} variant='h6-regular'>
                                        Speak &nbsp;
                                        <b
                                            style={{
                                                color: matched ? theme.palette.neutral['clr-700']
                                                    : theme.palette.primary.main
                                            }}
                                        >
                                            “Let’s Go”</b>
                                        &nbsp; to start your assessment
                                    </Typography>

                                    <Box className={classes.micRow}>
                                        <MicIcon style={{ fontSize: '18px' }} />
                                        <Box sx={{ width: '120px' }}>
                                            <LinearProgress
                                                variant="determinate" value={audioLevel}
                                                style={{ borderRadius: '10px' }}
                                            />
                                        </Box>
                                    </Box>

                                    {
                                      (state === 'ASK_PERMISSION') &&
                                        <Box
                                            display='flex' alignItems='center' gap={2}
                                            width={'100%'} justifyContent='center'
                                        >
                                            <Typography fontSize={15} color='error' fontWeight={500}>
                                                It seems you have blocked mic or camera permissions!
                                            </Typography>

                                            <CustomButton
                                                variant='contained'
                                                onClick={openPermissionPrompt}
                                            >
                                                Give Permission
                                            </CustomButton>
                                        </Box>
                                    }

                                    <Typography variant='body02-semiBold' textAlign='center' mb={2}>
                                        Facing any issue with mic? &nbsp;
                                        <a
                                            href='#' style={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
                                            onClick={() => setOpenTroubleshootDialog(true)}
                                        >
                                            Troubleshoot here
                                        </a>
                                    </Typography>
                                </>

                                : <CircularProgressCountDown onDialogClose={handleClose} proceedToTest={proceedToTest} />
                        }
                    </Dialog>
                    <AutoCloseTimer on={open} onTimerEnd={handleClose} onEachSecond={(msg) => setAutoCloseMessage(msg)} />
                    <TroubleshootDialog
                        open={openTroubleshootDialog}
                        onClose={() => setOpenTroubleshootDialog(false)}
                    />
                </>
              )
            }
        </>
    );
}

export default StartAssessmentDialog;