import React from "react";

import { v4 as uuid } from "uuid";
import { useCallback } from "react";
import { useRef, useState } from "react";
import { checkPermission } from "utils";
import { uploadToS3Api } from "services/uploadService";
import { useStackSnackbar } from "./StackSnackbarProvider";

export const ProctoringContext = React.createContext({
    moveAwayCount: 0,
    tabSwitchCount: 0,

    /**
     * 
     * @returns {void}
     */
    activateGeneralProctoring: () => { },
    activateFullScreenProctoring: () => { },

    /**
      * 
      * @param {number} captureRate
      * @param {MediaStream} stream
      * @param {HTMLVideoElement} videoElement
      * @returns {Promise<void>}
      */
    activateImageProctoring: async (captureRate, streamRef, videoElementRef) => { },

    /**
     * 
     * @returns {Promise<void>}
     */
    deactivateImageProctoring: () => { },

    /**
     * 
     * @returns {Promise<Array>} proctoringRecords
     */
    endProctoring: () => { },

    /**
     * 
     * @returns {Array} proctoringRecords
     */
    getProctoringData: () => { },


    /**
     * 
     *@returns {void}
     */
    enableFullscreenMode: () => { },


    /**
     * 
     *@returns {void}
     */
    disableFullscreenMode: () => { },
});


export default function ProctoringProvider({ children }) {

    const [moveAwayCount, setMoveAwayCount] = useState(0);
    const [tabSwitchCount, setTabSwitchCount] = useState(0);
    const [proctoringRecords, setProctoringRecords] = useState([]);
    const [generalProctoringEnabled, setGeneralProctoringEnabled] = useState(false);
    const [fullScreenProctoringEnabled, setFullScreenProctoringEnabled] = useState(false);
    const [imageProctoringEnabled, setImageProctoringEnabled] = useState(false);
    const { showStackSnackbar, closeStackSnackbar } = useStackSnackbar();
    const [fullScreenActive, setFullScreenActive] = useState(false);

    const streamRef = useRef(null);
    const videoElementRef = useRef(null);
    const imageCaptureInterval = useRef(null);
    const lastProctoringRecords = useRef([]);

    const enableFullscreenMode = async () => {
        try {
            if (document.body.requestFullscreen) {
                await document.body.requestFullscreen();
            } else if (document.body.webkitRequestFullscreen) { /* Safari */
                await document.body.webkitRequestFullscreen();
            } else if (document.body.msRequestFullscreen) { /* IE11 */
                await document.body.msRequestFullscreen();
            }
            setFullScreenActive(true);
            console.log('Fullscreen mode is active');
        } catch (error) {
            console.error(error);
        }
    }

    const disableFullscreenMode = async () => {
        try {
            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                if (document.exitFullscreen) {
                    await document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    await document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    await document.msExitFullscreen();
                }

                console.log('Fullscreen mode is inactive');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onCameraDisable = useCallback(() => {
        if (!imageProctoringEnabled) return;

        showStackSnackbar('Camera Off: Caught! Enable Now', { variant: 'warning' });
    }, [imageProctoringEnabled]);

    const handleBlur = useCallback((e) => {
        console.log('blur:: moving away');
        setMoveAwayCount((moveAwayCount) => {
            const updatedCount = moveAwayCount + 1;

            showStackSnackbar('Stay in Window: Violation Detected!', { variant: 'warning' });
            return updatedCount;
        });
    }, []);

    const handleVisibilityChange = useCallback((e) => {
        console.log('visibilityChange:: tab switch', window.document.visibilityState);
        if (window.document.visibilityState === 'visible') {
            setTabSwitchCount((tabSwitchCount) => {
                const updatedCount = tabSwitchCount + 1;

                showStackSnackbar('No Tab Switching: Alert! Stay Focused!', { variant: 'warning' });
                return updatedCount;
            });
        }
    }, []);

    const handleFullscreenChange = useCallback((e) => {
        console.log('handleFullscreenChange:: fullscreen change');
        if (!document.fullscreenElement) {
            setFullScreenActive(false);
            showStackSnackbar('No Fullscreen Exit: Warning! Stay Engaged', { variant: 'warning' });
        }
    }, []);

    /* 
        const startVideoStreaming = useCallback(async () => {
            streamRef.current = await navigator.mediaDevices.getUserMedia({ video: true });
            const videoElement = document.createElement('video');
            videoElement.srcObject = streamRef.current;
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.playsInline = true;
            videoElement.width = 400;
            videoElement.height = 400;
            videoElement.style.position = 'fixed';
            videoElement.style.left = 0;
            videoElement.style.top = 0;
            videoElement.style.zIndex = -1;
    
            window.document.body.appendChild(videoElement);
            videoElementRef.current = videoElement;
            console.log('Video streaming started.');
        }, []);
    
        const stopVideoStreaming = useCallback(() => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => track.stop());
                console.log('Video streaming stopped.');
            }
        }, []);
     */

    const startImageCapturing = useCallback(async (_streamRef, _videoElementRef) => {
        if (_streamRef.current && _videoElementRef.current instanceof HTMLVideoElement) {
            console.log('ProctoringProvider:Image captured');
            const imageBitmap = await window.createImageBitmap(_videoElementRef.current);
            const canvas = document.createElement('canvas');
            canvas.width = imageBitmap.width;
            canvas.height = imageBitmap.height;
            const context = canvas.getContext('bitmaprenderer');
            context.transferFromImageBitmap(imageBitmap);
            canvas.toBlob(async (blob) => {
                const timestamp = new Date().toISOString();
                const filename = uuid() + '.png';
                const location = 'uploads/capturedPhotos/inprep/' + filename;
                if (blob) {
                    const uploadedData = await uploadToS3Api({ file: blob, location });
                    setProctoringRecords((proctoringRecords) =>
                        [...proctoringRecords,
                        {
                            filename,
                            timestamp,
                            type: 'IMAGE_PROCTOR',
                            location: uploadedData?.Location
                        }]
                    );
                }
            });
        }
    }, []);

    const activateFullScreenProctoring = useCallback(()=>{
      if (fullScreenProctoringEnabled) return;

      console.log('ProctoringProvider:: activateFullScreenProctoring');
      window.document.addEventListener('fullscreenchange', handleFullscreenChange);
      setFullScreenProctoringEnabled(true);
    },[fullScreenProctoringEnabled, handleFullscreenChange]);

    const activateGeneralProctoring = useCallback(() => {
        if (generalProctoringEnabled) return;

        console.log('ProctoringProvider:: activateGeneralProctoring');
        setMoveAwayCount(0);
        setTabSwitchCount(0);
        enableFullscreenMode();
        window.addEventListener('blur', handleBlur);
        window.addEventListener('visibilitychange', handleVisibilityChange);
        setGeneralProctoringEnabled(true);
    }, [
        generalProctoringEnabled,
        handleBlur,
        handleVisibilityChange
    ]);

    const activateImageProctoring = useCallback(async (
        captureRate = 20000, _streamRef, _videoElementRef
    ) => {
        if (imageProctoringEnabled) return;

        const hasPermission = await checkPermission('camera');
        if (!hasPermission) {
            console.error('ProctoringProvider:: Camera permission not provided!');
            return
        };

        console.log('ProctoringProvider:: activateImageProctoring with captureRate ');

        // await startVideoStreaming();
        streamRef.current = _streamRef.current;
        videoElementRef.current = _videoElementRef.current;

        setImageProctoringEnabled(true);

        imageCaptureInterval.current = setInterval(
            () => startImageCapturing(_streamRef, _videoElementRef),
            20000
        );

    }, [imageProctoringEnabled, startImageCapturing]);

    const deactivateImageProctoring = useCallback((violation) => {
        if (imageProctoringEnabled) {
            clearInterval(imageCaptureInterval.current);
            streamRef.current = null;
            videoElementRef.current = null;
            setImageProctoringEnabled(false);
            // stopVideoStreaming();
            if (violation) {
                setProctoringRecords((proctoringRecords) => [
                    ...proctoringRecords,
                    {
                        type: 'CAMERA_DISABLED',
                        timestamp: new Date().toISOString(),
                    }
                ]);
            }
        }
    }, [imageProctoringEnabled]);

    const endProctoring = () => {
        console.log('ProctoringProvider:: endProctoring');

        closeStackSnackbar();

        lastProctoringRecords.current = [...proctoringRecords];

        disableFullscreenMode();

        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('visibilitychange', handleVisibilityChange);
        window.document.removeEventListener('fullscreenchange', handleFullscreenChange);

        if (imageProctoringEnabled) deactivateImageProctoring();

        setGeneralProctoringEnabled(false);
        setFullScreenProctoringEnabled(false);
        setProctoringRecords([]);
    };

    const getProctoringData = () => {
        console.log('ProctoringProvider:: getProctoringData');

        const generalProctoringRecords = [];
        if (generalProctoringEnabled) {
            generalProctoringRecords.push({
                type: 'GENERAL_PROCTOR',
                moveAwayCount,
                tabSwitchCount,
            });
        }

        if (imageProctoringEnabled) {
            clearInterval(imageCaptureInterval.current);
            // stopVideoStreaming();
            streamRef.current = null;
            videoElementRef.current = null;
            setImageProctoringEnabled(false);
        }

        return [...generalProctoringRecords, ...lastProctoringRecords.current];
    };

    return (
        <ProctoringContext.Provider value={
            {
                moveAwayCount,
                tabSwitchCount,
                activateGeneralProctoring,
                activateFullScreenProctoring,
                activateImageProctoring,
                deactivateImageProctoring,
                endProctoring,
                getProctoringData,
                onCameraDisable,
                enableFullscreenMode,
                disableFullscreenMode,
                fullScreenActive
            }
        }>
            {children}
        </ProctoringContext.Provider>
    )
}