import dayjs from "dayjs";
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

export const getLabels = (interval, startDate, endDate) => {
    const labels = [];
    if (interval === "day") {
        labels.push(
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23,
        );
    } else if (interval === "month" || interval === "week") {
        let day = dayjs(startDate);
        while (day.isSameOrBefore(endDate, "second")) {
            labels.push(day.format("DD/MMM"));
            day = day.add(1, 'day');
        }
    }

    return labels;
};