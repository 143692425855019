import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

import { getTwoDigitNo } from 'utils';
import { TagCategories } from './Pathway/constants';
import StatusChip from './Chip';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    outline: '3px solid',
    outlineColor: theme.palette.neutral['clr-300'],
    borderRadius: theme.spacing(1),
    minWidth: '400px', maxWidth: '400px', height: '180px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.3)'
    }
  },
  cardSelected: {
    outline: '2px solid', boxShadow: 'none',
    outlineColor: theme.palette.primary.main,
  },
  cardHeader: {
    background: theme.palette.secondary['clr-100'],
    padding: theme.spacing(0, 5),
    display: 'flex', alignItems: 'center',
    height: '50px', borderRadius: theme.spacing(2, 2, 0, 0)
  },
  cardBody: {
    padding: theme.spacing(3, 5),
    display: 'flex', flexDirection: 'column', justifyContent: 'center'
  },
  name: {
    display: 'flex', justifyContent: 'space-between',
    marginBottom: theme.spacing(1), alignItems: 'center',
  }
}));

export function PathwaySelectionCardSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.cardRoot}>
      <Box display="flex" className={classes.cardHeader}>
        <Skeleton variant="rectangular" width="20%" height='20px' style={{ borderRadius: '20px' }} />
      </Box>
      <Box className={classes.cardBody}>
        <Box>
          <Skeleton variant="rectangular" width="100%" height='30px' />
        </Box>
        <Box mt='10px'>
          <Skeleton variant="rectangular" width="60%" height='30px' />
        </Box>
      </Box>
    </Box>
  );
}

const PathwaySelectionCards = ({ pathway, handlePathwaySelect, selected, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [companyTag, setCompanyTag] = React.useState(null);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    setData(pathway);
    const tags = pathway?.tags || [];
    setCompanyTag((tags.filter(tag => tag.category === TagCategories.COMPANY)[0]))
  }, [pathway, selected]);

  return (
    <Box
      className={`${classes.cardRoot} ${selected ? classes.cardSelected : ''}`}
      onClick={() => handlePathwaySelect(data._id)}
    >
      <Box
        className={classes.cardHeader}
        style={{
          background: selected ?
            'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)' :
            theme.palette.secondary['clr-100']
        }}
      >
        {
          companyTag &&
          <StatusChip
            content={companyTag?.name}
            color={theme.palette.primary.main}
            bgColor={theme.palette.shades['clr-white-900']}
          />
        }
      </Box>
      <Box className={classes.cardBody}>
        <Box className={classes.name}>
          <Typography variant='h4-semiBold'>{data.name}</Typography>
          <Checkbox checked={selected} />
        </Box>
        <Typography variant='body01-semiBold'>
          Total Modules: <span style={{ color: theme.palette.primary.main }}>
            {getTwoDigitNo(data?.totalModules)}
          </span>
        </Typography>
        <Typography variant='body01-semiBold'>
          Total Assessments: <span style={{ color: theme.palette.primary.main }}>
            {getTwoDigitNo(data?.totalTemplates)}
          </span>
        </Typography>
      </Box>
    </Box>
  )
}

export default PathwaySelectionCards