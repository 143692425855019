import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Box  from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import theme from 'theme';
import countries from 'assets/countries';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import { ListSubheader } from '@mui/material';
import { BootstrapTooltip } from './InfoIconWithTooltip';


export const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize', marginBottom: theme.spacing(2)
  },
  ".MuiSelect-nativeInput": {
    height: 40,
  },
  ".MuiSelect-root": {
    ...theme.typography['body01-medium'],
    height: '44px',
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50']
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.neutral['clr-200'],
      '& fieldset': {
        border: '2px solid',
      },
    }
  },
  ".MuiSelect-select": {
    "& fieldset": {
      border: '1px solid',
      borderColor: theme.palette.neutral['clr-500']
    },
    "&:hover": {
      "& fieldset": {
        border: '1px solid',
      },
    },
  },
  paper: {
    boxShadow: "0px 2px 8px 0px #00000029",
  },
  groupList: {
    maxHeight: 300,
    overflowY: "auto",
    padding: '0px',
    "& li": {
      ...theme.typography['body01-medium'],
      height: '40px',
      borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`
    },
    "& li:hover": {
      backgroundColor: theme.palette.primary['clr-50'],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        borderColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.primary['clr-600'],
        },
      }
    },
    "& li.Mui-selected": {
      backgroundColor: `${theme.palette.primary['clr-400']} !important`,
      color: theme.palette.shades['clr-white-900']
    },
    "& li.Mui-selected:hover": {
      backgroundColor: theme.palette.primary['clr-400'],
      color: theme.palette.shades['clr-white-900']
    }
  },
  category: {
    backgroundColor: '#fcf8f8', 
    height: 40, display: 'flex', alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: '600', 
    border: '2px solid',
    borderColor: theme.palette.neutral.main,
    fontSize: '16px !important',
    borderWidth: '2px 0px 2px 0px',
    "&:hover": {
      backgroundColor: "#fcf8f8 !important",
    },
  },
  radioSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  radioSelectOption: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `2px solid #CCD4DE`,
    color: theme.palette.neutral['clr-600'],
    transition: 'transform 0.3s ease, opacity 0.3s ease', 
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary['clr-300'],
      opacity: 1, 
    },
    '&:not(.selected)': {
      opacity: 0.8,
      '&:hover': {
        transform: 'translateY(-7px)',
      },
    },
  },
  radioSelectLabel: {
    ...theme.typography['body01-bold'],
    padding:theme.spacing(3,1),
    minWidth:'192px',
    height:'64px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    color: theme.palette.neutral['clr-600'], 
    '&.selected': {
      color: theme.palette.primary['clr-300'], 
    },
  },
  radioLabelConatiner:{
    display:'flex',
    flexDirection:'column',
    width:'192px'
  },
  radioImage:{
    padding:theme.spacing(4,2.5),
    borderBottom:`1px solid ${theme.palette.neutral['clr-200']}`,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
}));

export function SimpleSelect({
  options, defaultValue, value, onSelect = () => { }, label = null, placeholder = 'Select option',
  loading = false, ...props
}) {
  const classes = useStyles();

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    MenuListProps: {
      style: {
        ...props.menuStyle
      }
    }
  }), []);

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon className={props.className + " " + classes.icon} />
    )
  };

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {
        label && <Typography className={classes.label}>{label}</Typography>
      }
      <>
        {
          (loading) ?
            <SelectSkeleton /> :
            <MuiSelect
              displayEmpty
              className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
              sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
              onChange={onSelect}
              renderValue={(selected) => {
                const data = options.find((option) => option._id === selected)
                if (!selected?.length) {
                  return placeholder;
                } else {
                  return data?.name;
                }
              }}
              value={value}
              defaultValue={defaultValue}
              inputProps={{ className: classes.input }}
              MenuProps={menuProps}
              IconComponent={iconComponent}
              {...props}
              id="select"
            >
              {
                options?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))
              }
            </MuiSelect>
        }
      </>
    </FormControl>
  );
};

const SelectSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width="200px" height='44px' style={{ borderRadius: '4px' }} />
    </Box>
  )
}

export function GroupSelect({
  data, value, onChange = () => { }, label = 'Select', hasNotification = false,
  showNotification = false, loading = false,
  ...props
}) {
  const classes = useStyles();

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
  }), []);

  const { cat1, cat2 } = React.useMemo(() => ({
    cat1: data[0],
    cat2: data[1]
  }), [data]);

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      <Box display='flex'>
        <Typography className={classes.label}>
          {label}
        </Typography>
        &nbsp;
        {
          hasNotification && showNotification ?
            <BootstrapTooltip title={'There is a new assessment added'} placement='bottom'>
              <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} />
            </BootstrapTooltip>
            : null
        }
      </Box>
      {
        loading ? <SelectSkeleton /> :
          <MuiSelect
            onChange={onChange}
            value={value}
            sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
            inputProps={{ className: classes.input }}
            MenuProps={menuProps}
            className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
            {...props}
            id="grouped-select"
          >
            {
              cat1?.options?.length && cat2?.options?.length &&
              <ListSubheader className={classes.category}>{cat1?.category}</ListSubheader>
            }
            {
              cat1?.options?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}&nbsp;
                  {
                    option?.hasNotification && showNotification
                      ? <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} /> : null
                  }
                </MenuItem>
              ))
            }
            {
              cat1?.options?.length && cat2?.options?.length &&
              <ListSubheader className={classes.category}>{cat2?.category}</ListSubheader>
            }
            {
              cat2?.options?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}&nbsp;
                  {
                    option?.hasNotification && showNotification
                      ? <CircleIcon style={{ color: '#FFC500', fontSize: '12px' }} /> : null
                  }
                </MenuItem>
              ))
            }
          </MuiSelect>
      }
    </FormControl>
  );
};


export function CountryCodeSelect({ value, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.groupList
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
  };

  return (
    <Box>
      <Typography className={classes.largeFieldLabel}>
        {props.label || ''}
      </Typography>
      <MuiSelect
        className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
        sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
        inputProps={{ className: classes.input }}
        MenuProps={menuProps}
        variant='outlined'
        value={value}
        renderValue={(selected) => {
          return `+${selected}`
        }}
        {...props}
      >
        {
          countries.map((option) => (
            <MenuItem key={option.id} value={option.code}>
              {option.label}
            </MenuItem>
          ))
        }
      </MuiSelect>
    </Box>

  );
}

export function RadioSelect({
  options, value, onChange = () => { }, label = null, optionWidth = 240,
  radioSize = 'medium', labelVariant = 'body01-bold', ...props
}) {
  const classes = useStyles();

  return (
    <FormControl>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        className={classes.radioSelectContainer}
        {...props}
      >
        {options.map(option => (
          <FormControlLabel
            key={option._id}
            value={option._id}
            control={
                      <Radio 
                        size={radioSize} 
                        sx={{height:'100%', backgroundColor:theme.palette.neutral['clr-50'] , 
                            width:'48px' , borderRadius:0}}
                      />
                    }
            label={
             <Box className={classes.radioLabelConatiner}>
                <Box className={classes.radioImage}>
                  <img width={160} height={140} src={option.img} alt={option.label} />
                </Box>
                 <Typography
                  variant={labelVariant}
                  className={`${classes.radioSelectLabel} ${value === option._id ? 'selected' : ''}`}
                >
                  {option.label}
                </Typography>
             </Box>
            }
            className={`${classes.radioSelectOption} ${value === option._id ? 'selected' : ''}`}
            style={{ width: optionWidth, margin: 0 , padding:0 }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}