import React from 'react';
import { Skeleton } from '@mui/material';

const ImageViewer = ({ src, alt, width='100%', height='100%', ...props}) => {
  const [loading, setLoading] = React.useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
  };

  return (
    <>
      {
        !loading ? null :
          <Skeleton variant="rectangular" width={width} height={height} />
      }
      <img 
        src={src}
        alt={alt}
        height={height}
        width={width}
        style={{ display: loading ? 'none' : 'block' }}
        onLoad={handleImageLoad}
        onError={handleImageError}
        {...props}
      />
    </>
  )
}

export default ImageViewer;