import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from 'components/TextField';
import { makeStyles } from '@mui/styles';
import CustomButton from 'components/CustomButton';
import { useState } from 'react';

const useStyles = makeStyles({
    textFiled: {
        margin: "10px 0px",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "1px solid #014A8F",
                borderRadius: "6px",
            },
        }
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function OtherFieldDialog(
    {
        open = false,
        onClose = () => { },
        onSave = () => { },
    }) {
    const classes = useStyles();
    const [other, setOther] = useState("");

    const handleSave = () => {
        onSave(other);
        setOther("");
    }

    const handleChange = (e) => {
        setOther(e.target.value);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            TransitionComponent={Transition}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 10,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{
                background: "#E4E3E8",
                display: "flex",
                flexDirection: "column"
            }}
            >
                <Typography variant="subtitle3" fontWeight="500">
                    Can’t find it on the list? then add it on the list
                </Typography>
                <TextField
                    className={classes.textFiled}
                    placeholder="other"
                    value={other}
                    onChange={handleChange}
                />
                <CustomButton
                    size="small"
                    sx={{ width: "fit-content", alignSelf: "flex-end" }}
                    onClick={handleSave}
                >
                    Save
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
}

export default OtherFieldDialog;
