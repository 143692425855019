import React, { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { getViewportDimensions } from 'utils';
import CustomButton from './CustomButton';

const useStyles = makeStyles(theme => ({
  button: {
    width: 'fit-content',
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    height: '36px !important',
    padding: theme.spacing(2,4),
    ...theme.typography['body01-bold'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50'],
    }

  },
  container: {
    padding: theme.spacing(3,5),
    position: 'absolute',
    // minWidth: 200,
    maxWidth: 500,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary['clr-300'],
    boxShadow: '0px 0px 20px 0px #00000029',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ToolTipPositionTransform = {
  "bottom-middle": { left: "50%", top: -50 },
  "top-middle": { left: "50%", bottom: -50 },
  "top-left": { bottom: 50, right: -50 },
  "top-right": { bottom: 50, left: -50 },
  "bottom-left": { top: 20, right: -50 },
  "bottom-right": { top: 20, left: -50 }
};

const getToolTipPoisition = (
  tipId, toolTipElement, tooltip, toolTipElementContainer
) => {
  const toolTipElementDomRect = toolTipElement?.getBoundingClientRect();
  const containerDomRect = toolTipElementContainer?.getBoundingClientRect();

  let toolTipPosition = {};

  const {
    width: tWidth,
    height: tHeight
  } = tooltip.current?.getBoundingClientRect() || {};

  const { width: vW, height: vH } = getViewportDimensions();

  let { left, top, bottom, right } = toolTipElementDomRect;
  const width = right - left;
  const height = bottom - top;
  bottom = vH - bottom;
  right = vW - right;

  let ttHorizontalPosition;

  if (left > tWidth) {
    toolTipPosition.left = left - tWidth - 10;
    ttHorizontalPosition = "left";
  }

  if (!ttHorizontalPosition && right > tWidth) {
    toolTipPosition.right = right - tWidth - 10;
    ttHorizontalPosition = "right";
  }

  if (!ttHorizontalPosition) {
    ttHorizontalPosition = `middle`;
    toolTipPosition.left = left + (width / 2) - (tWidth / 2);
  }

  let ttVerticalPosition;

  if (bottom > tHeight) {
    toolTipPosition.bottom =
      bottom - tHeight + height - (ttHorizontalPosition === 'middle' ? 35 : -35);
    ttVerticalPosition = "bottom";
  }

  if (!ttVerticalPosition && top > tHeight) {
    toolTipPosition.top = top - tHeight + 20 + height;
    ttVerticalPosition = "top"
  }

  if (!ttVerticalPosition) {
    ttVerticalPosition = `bottom`;
    toolTipPosition.bottom = bottom - tHeight - 20;
  }

  return {
    toolTipPosition,
    arrowPosition: `${ttVerticalPosition}-${ttHorizontalPosition}`,
    above: containerDomRect.top,
    container: containerDomRect.height,
    below: vH - (containerDomRect.top + containerDomRect.height),
  };
};

function ToolTipArrow({ position }) {
  const theme = useTheme();

  const ArrowIcon = React.useMemo(() => {
    if (position === "top-middle") return ArrowDropDownIcon;
    if (position === "bottom-middle") return ArrowDropUpIcon;
    if (position === "top-right" || position === "bottom-right") return ArrowLeftIcon;
    if (position === "top-left" || position === "bottom-left") return ArrowRightIcon;
    return ArrowDropDownIcon;
  }, [position]);

  return (
    <ArrowIcon
      sx={{
        position: 'absolute',
        ...ToolTipPositionTransform[position],
        color: theme.palette.primary['clr-300'],
        border: 'none',
        fontSize: '60px',
      }}
    />
  );
};

function TourTip({
  tip, onSkip = (e) => { }, onNext = (e) => { } }
) {
  const classes = useStyles();
  const [position, setPosition] = useState({
    arrowPosition: "top-middle",
    toolTipPosition: {},
    above: 0,
    container: 0,
    below: 0
  });
  const tooltip = useRef();

  useEffect(() => {
    let toolTipElement = document.getElementById(tip.id);
    let toolTipElementContainer = document.getElementById(tip.id.concat("-container"));
    if (toolTipElement) {
      toolTipElement?.scrollIntoView({ block: "center" });

      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setTimeout(() => {
            setPosition(
              getToolTipPoisition(
                tip.id, toolTipElement, tooltip, toolTipElementContainer
              )
            );
            observer.disconnect();
          }, 200);
        }
      }, {
        root: null,
        threshold: 1.0
      });

      observer.observe(toolTipElement);

      const windowResizeListener = () => {
        setPosition(
          getToolTipPoisition(
            tip.id, toolTipElement, tooltip, toolTipElementContainer
          )
        );
      }

      window.addEventListener('resize', windowResizeListener);

      return () => {
        window.removeEventListener('resize', windowResizeListener);
      }
    } else onNext();
  }, [tip]);

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box flexGrow={2} height={position.container} />
      </Box>
      <Box
        className={classes.container}
        {...position.toolTipPosition}
        ref={tooltip}
      >
        <Box position="relative">
          <ToolTipArrow position={position.arrowPosition} />
          <Box className={classes.header}>
            <Typography variant='h5-medium' color='primary.clr-300'>
            </Typography>
            {!tip?.hideClose && (
              <IconButton onClick={onSkip}>
                <Close style={{ color: '#fff' }}/>
              </IconButton>
            )}
          </Box>

          <Divider/>

          <Box display='flex' justifyContent='space-between' mt={2}>
            <Typography variant='h6-semiBold' color='shades.clr-white-900' mb={2}>
              {tip?.title}
            </Typography>
          </Box>
          <Typography variant='body01-bold' color='shades.clr-white-900'>
            {tip?.message}
          </Typography>
          <Box textAlign="right" mt={3}>
            <CustomButton
              variant="contained"
              onClick={onNext}
              className={classes.button}
            >
              {tip?.action}
            </CustomButton>
            <br />
            {tip?.skipText && (
              <CustomButton
                variant='text'
                onClick={onSkip}
                className={classes.button}
                style={{ textDecoration: "underline" }}
              >
                {tip.skipText}
              </CustomButton>)}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TourTip;
