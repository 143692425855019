import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import CustomButton from './CustomButton';
import { uploadFile } from "services/uploadService";
import { useSnackbar } from "contexts";

const ChooseFileButton = ({ name='btn-upload', onFileSelected, accept = [], disabled, label }) => {
  return (
    <label htmlFor={name}>
      <input
        id={name}
        name={name}
        style={{ display: 'none' }}
        type="file"
        onChange={onFileSelected}
        accept={accept.join(",")}
        disabled={disabled}
      />
      <CustomButton
        variant="contained"
        component="span"
        disabled={disabled}
      >
        {label}
      </CustomButton>
    </label>
  );
};

export default function FileUpload({
  name,
  uploadLocation,
  file = { name: undefined, url: "" },
  toPublic = false,
  accept = [],
  onUpload = () => { },
  onUploadCancelled = () => { },
  errors = {},
  setErrors = () => { },
  readOnly = false,
  label = 'Choose file',
  setIsFileUploaded=false,
  setResumeName,
  onFileSelect = () => {
    return true;
  }
}) {
  const snackbar = useSnackbar();

  const [state, setState] = React.useState(file.name ? "LOADED" : "NO_FILE");
  const [uploading, setUploading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(file);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [fileData, setFileData] = React.useState();
  const [shouldUpload, setShouldUpload] = React.useState(false); 

  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      snackbar.error('File size exceeds the limit of 2MB.');
      setState('NO_FILE');
      return;
    }

    setState("NEW_FILE_SELECTED");
    setSelectedFile(file);

    const fileNameWithoutExtension = file.name.replace(/\.pdf$/i, '');
    setResumeName(fileNameWithoutExtension)
    
    let reader = new FileReader();
    reader.onload = (e) => {
      setFileData(e.target.result);
    };
    reader.readAsDataURL(file);
    setShouldUpload(true);
  };

  const handleUploadSuccess = (uploadedFile) => {
    setUploading(false);
    setState('UPLOADED');
    setMessage("Uploaded");
    setIsFileUploaded(true)
    setSelectedFile(uploadedFile);
    if (onUpload && typeof onUpload === 'function') onUpload(uploadedFile);
  };

  useEffect(() => {
    const uploadFileIfNeeded = async () => {
      try {
        if (shouldUpload && fileData && selectedFile && !selectedFile.url) {
          setState("UPLOADING");
          setUploading(true);
          setMessage("Uploading...");
          const dataFromOnFileSelect = await onFileSelect(selectedFile);
          
          if (dataFromOnFileSelect) {
            try {
              const url = await uploadFile(selectedFile, fileData, uploadLocation, toPublic);
              const uploadedFile = {
                name: selectedFile.name,
                url,
              };
              handleUploadSuccess(uploadedFile);
            } catch (uploadError) {
              setState('FAILED');
              setMessage("Upload failed");
              setErrors(true);
              throw new Error(`Upload failed: ${uploadError.message}`);
            }
          } else {
            setState('FAILED');
            setMessage("Upload failed");
            setErrors(true);
          }
          setUploading(false);
          setMessage("Uploaded");
          setShouldUpload(false); 
        }
      } catch (e) {
        setState('FAILED');
        setMessage("Upload failed");
        console.error(e);
      }
    };

    if (shouldUpload) {
      uploadFileIfNeeded();
    }
  }, [fileData, selectedFile]);

  useEffect(() => {
    if (file.name) {
      setSelectedFile(file);
      setState('UPLOADED');
    } else {
      setSelectedFile(file);
      setState('NO_FILE');
    }
  }, [file]);

  const hasUploadLocation = useMemo(() => {
    return !!uploadLocation;
  }, [uploadLocation]);

  return (
    <Box>
      {(state === 'NO_FILE' || state === 'FAILED') && (
        <ChooseFileButton
          onFileSelected={selectFile}
          accept={accept}
          disabled={readOnly || !hasUploadLocation}
          label={label}
          name={name}
        />
      )}
    </Box>
  );
}
