import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme=>({
  content: {
    height: '600px', padding: '0px', overflow: 'hidden'
  },
  closeBtn: {
    position: 'absolute',
    right: 8,
    top: 15,
    color: theme.palette.neutral['clr-300'],
  },
  head: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  }
}));


export default function ModuleIntroductionDialog({ 
  openIntroduction, setOpenIntroduction, url, onSubmit 
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openIntroduction);
  
  const handleClose = () => {
    setOpenIntroduction(false);
  };

  React.useEffect(()=>{
    setOpen(openIntroduction);
  },[openIntroduction]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'lg'}
      fullWidth={true}
      className={classes.root}
      open={open}
    >
      <Box className={classes.head}>
        <DialogTitle sx={{ m: 0, p: 5 }} id="customized-dialog-title">
          Module Introduction
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeBtn}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* content */}
      <DialogContent className={classes.content}>
          <iframe
            title="Module Introduction"
            src={url}
            style={{ width: "100%", height: '100%' }}
            allow="camera;microphone;fullscreen;"
          />
      </DialogContent>
    </Dialog>
  );
}