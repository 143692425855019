import Client from "./_client";

export const generateOtp = async (userId, clientLogoUrl) => {
    const result = await new Client({
        path: "/otp/generate", payload: { userId, clientLogoUrl },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to generate otp.");
    } else {
        return result.data;
    }
};

export const verifyOtp = async (id, otp) => {
    const result = await new Client({
        path: "/otp/verify", payload: { id, otp },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to verify otp.");
    } else {
        return result.data;
    }
};