import { Route, Routes, Outlet } from "react-router-dom";

import React from 'react';
import PerformanceReview from "components/PerformanceReview";
import OverallPerformance from "components/OverallPerformance";
import AppShell from "components/AppShell";

export function Performance() {
    return (

        <Routes>
            <Route path="/" element={<Outlet />}>
                <Route index element={
                    <AppShell headerLabel="Performance">
                        <OverallPerformance />
                    </AppShell>
                } />
                <Route
                    path="/assessment/:tid"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <AppShell headerLabel="Feedback" showBackButton={true}>
                                <PerformanceReview />
                            </AppShell>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/:aid"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <AppShell headerLabel="Feedback" showBackButton={true}>
                                <PerformanceReview />
                            </AppShell>
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

export default Performance;