import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/system/Box';
import makeStyles from "@mui/styles/makeStyles";

import ProfileUploadDialog from 'dialogs/ProfileUploadDialog';
import { Session } from 'utils';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=> ({
  root: {
    margin: theme.spacing(2,0),
    display: "flex", flexDirection: "column", 
    justifyContent: 'center', alignItems: 'center',
    position: "relative", gap: theme.spacing(4),
    marginRight: theme.spacing(8)
  }
}));

const sx = {
    editButton: {
      width: 'fit-content'
    },
    profileButton: {
        width: 150,
        height: 150,
        minWidth: 150,
        minHeight: 150,
        maxWidth: 150,
        maxHeight: 150,
        background: "#E4E3E8",
        borderRadius: "50%",
        border: "1px solid #ffffff73",
        overflow: "hidden",
        p: 0
    },
    avatar: { width: 150, height: 150 },
};

function ProfilePicture() {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState();

    const handleClose = (croppedImage) => {
        if (typeof croppedImage === "string") {
            setImage(croppedImage);
        }
        setOpen(false);
    };

    useEffect(() => {
        setImage(Session.getUser().profileUrl);
    }, [])

    return (
        <Box className={classes.root}>
          <IconButton
            title="Click Upload Profile Picture"
            sx={sx.profileButton}
            onClick={(e) => setOpen(true)}
          >
            <Avatar src={image} style={sx.avatar} variant="rounded" />
          </IconButton>
          <CustomButton
            style={sx.editButton}
            variant="outlined"
            onClick={(e) => setOpen(true)}
          >
            Upload Image
          </CustomButton>
          <ProfileUploadDialog open={open} onClose={handleClose} />
        </Box >
    );
}

export default ProfilePicture;
