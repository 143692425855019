import Client from "./_client";

export const getPathways = async (filters) => {
  let url = "/pathway?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get pathway. Please try again."
    );
  }
  return result?.data;
}

export const getFilteredPathways = async (filters) => {
  let url = "/pathway/filter?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get pathways. Please try again."
    );
  }
  return result?.data;
}

export const getPathwayById = async (id) => {
  let url = `/pathway/${id}`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to get pathway. Please try again"
    );
  }

  return result?.data;
};

export const getModules = async (filters) => {
  let url = "/module/filter?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get pathway. Please try again."
    );
  }
  return result?.data;
}

export const getUserPathwayMapping = async (filters) => {
  let url = "/userPathwayMapping?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get pathway. Please try again."
    );
  }
  return result?.data;
}
export const getPathwayUserAnalytics = async (filters) => {
  let url = "/pathway/user-analytics?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get pathway user analytics. Please try again."
    );
  }
  return result?.data;
}

export const updateUserPathwayMapping = async (id, userPathwayMapping) => {

  let result = await new Client({
    path: `/userPathwayMapping/${id}`,
    payload: userPathwayMapping,
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to update Bookmark. Please try again."
    );
  }
  return result.data;
}

export const upsertUserPathwayMapping = async (userPathwayMapping) => {

  let result = await new Client({
    path: `/userPathwayMapping/upsert`,
    payload: userPathwayMapping,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to update Bookmark. Please try again."
    );
  }
  return result.data;
}

export const getTodoPathways = async (batch) => {

  const result = await new Client({
    path: `/pathway/todo?&batchId=${batch}`
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get Pathways. Please try again."
    );
  }

  return result.data;
};

export const createCertificate = async (data) => {

  let result = await new Client({
    path: `/certificate`,
    payload: data,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to update Bookmark. Please try again."
    );
  }
  return result.data;
}

export const createPathwayRequest = async (data) => {
  let url = `/resourceRequest`;

  let result = await new Client({
      path: url,
      payload: data,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to create pathway request. Please try again."
      );
  }

  return result.data;
}

export const addPathwaysToUser = async (data) => {
  let url = `/userPathwayMapping/add-pathways-to-user`;

  let result = await new Client({
      path: url,
      payload: data,
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to add Pathway(s). Please try again."
      );
  }

  return result.data;
}