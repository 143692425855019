import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import CustomButton from "./CustomButton";

const useStyles = makeStyles(theme=>({
  root: {
    display:'flex', flexDirection:'column', textAlign:'center',
    justifyContent:'center', alignItems:'center', 
    gap:theme.spacing(1), 
    height:300,
  }
}));

function NoAssessmentsFound({ icon, label, filters, clearFilters }) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img
                src={icon || "https://assets.languify.in/images/no-ssessments.svg"}
                alt="not-found"
                height={140}
            />
            <Typography color="neutral.clr-500" variant="h5-medium">
              {
                label || 
                <>You have not attempted any assessment so far!<br/>once attempted you can see analytics</>
              }
            </Typography>
            {filters ? (
                <CustomButton
                    onClick={clearFilters}
                    variant="contained"
                    size="small"
                    sx={{ textTransform: "none", mt: '10px' }}
                >
                    Clear filters
                </CustomButton>
            ) : null }
        </Box>
    );
}

export default NoAssessmentsFound;