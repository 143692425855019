import React from 'react';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";

import { sendCrashReport } from 'services/errorReportingService';
import CustomButton from './CustomButton';

const sx = {
  'container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    height:"fit-content",
    width: '100vw',
    backgroundImage: 'url(https://assets.languify.in/images/error-bg-img.png)',
    backgroundSize: 'cover',
    flexWrap:"wrap",
    flexWrap:"wrap",
    '@media (max-width: 1200px)': {
      padding:"40px 0px"
    },
    '@media (max-width: 700px)': {
      padding:"0px"
    },
  },
  box:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height:"fit-content",
    width: '100%',
    flexWrap:"wrap",
    '@media (max-width: 700px)': {
      gap:"40px"
    },
    '@media (max-width: 700px)': {
      gap:"30px"
    },
  },
  'leftbox': {
    width: '54%',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1200px)': {
      width: '50%',
      overflow: 'hidden',
    },
    '@media (max-width: 900px)': {
      width: '45%',
      overflow: 'hidden',
    },
    '@media (max-width: 700px)': {
      width: '70%',
      overflow: 'hidden',
    },
    '& img': {
      '@media (max-width: 1200px)': {
        width: '400px',
        height:"auto"
      },
      '@media (max-width: 900px)': {
        width: '350px',
      },
      '@media (max-width: 750px)': {
        width: '300px',
      },
      '@media (max-width: 700px)': {
        width: '100%',
      },
    },
  },
  'rightbox': {
    width: '46%',
    height: 'fit-content',
    display: 'flex', flexDirection: 'column',
    '@media (max-width: 700px)': {
      width: '70%',
    },
  },
  img: {},
}

const ReportErrorContent = <>Could you please share the issue with us? <br /> Because we don’t want to loose you again  <br /> :(</>;
const ReportedErrorContent = <>We appreciate your effort helping us in <br /> improvement   <br /> :)</>;

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, reported: false };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  reportError = async () => {
    try {
      const componentStack = this.state.errorInfo.componentStack;
      const componentNameMatch = componentStack.match(/at (\w+)/);
      const componentName = componentNameMatch ? componentNameMatch[1] : 'Unknown Component';
      console.log(JSON.stringify(this.state.error.stack))
      const _error = {
        error: JSON.stringify(this.state.error.message),
        stack: JSON.stringify(this.state.error.stack),
        location: window.location.href,
        componentName:componentName
      };

      sendCrashReport(_error, this.state.errorInfo);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ reported: true });
    }
  };

  navigateToHomePage = () => {
    window.open('/', '_self');
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Box sx={sx.container}>
          <Box sx={sx.box}>
          <Box sx={sx.leftbox}>
            <img
              src="https://assets.languify.in/images/maze.png"
              alt="Something went wrong"
              style={sx.img}
            />
          </Box>
          <Box sx={sx.rightbox}>
            <Typography variant='h1-semiBold' mb={1}>
              {(this.state.reported) ? 'Thanksss!' : 'WHOOPS!'}
            </Typography>
            <Typography variant='h3-medium' mb={1}>
              {(this.state.reported) ? 'You are awesomee!!' : 'We lost you!'}
            </Typography>
            <Typography variant='h4-regular' mb={3}>
              {(this.state.reported) ?
                ReportedErrorContent :
                ReportErrorContent
              }
            </Typography>
            <CustomButton
              onClick={(this.state.reported) ? this.navigateToHomePage : this.reportError}
              sx={{ width: 'fit-content' }}
            >
              {(this.state.reported) ?
                'Home Page' :
                'Report Problem'
              }
            </CustomButton>
          </Box>
          </Box>
        </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;