import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import OnboadingHeader from "components/OnboadingHeader";
import InfoMessage from "components/InfoMessage";

import { useLoading } from "contexts/LoadingProvider";

import Session from "utils/Session";

const Message = "We could not authenticate your request, please contact your administrator.";

function Error() {
  const loading = useLoading();
  const navigate = useNavigate();

  loading.hide();

  const handleBack = () => {
    if (JSON.stringify(window?.localStorage?.getItem("error"))) {
      window?.localStorage?.setItem("error", false.toString());
      navigate("/general/login", { replace: true });
    } else {
      window?.localStorage?.setItem("error", true.toString());
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
  };

  return (
    <>
      <OnboadingHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={3}
      >
        <img
          alt="error"
          src="https://assets.languify.in/images/error.svg"
        />
        <a href={`mailto::${Session.client ? Session.client.email : "info@languify.in"}`} style={{ textDecoration: 'none' }} >
          <Button variant="contained" >Contact Administrator</Button>
        </a>
        <br />
        <Button variant="contained" onClick={handleBack} >
          Go Back
        </Button>
        <br />
        <InfoMessage variant="error" >
          {Message}
        </InfoMessage>
      </Box>
    </>
  );
};

export default Error;