import { useMemo, useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StarIcon from '@mui/icons-material/Star';

import { useSnackbar } from "contexts";
import { postFeedback } from "services";
import { AssessmentStates } from "./constants";

const sx = {
    root: {
        position: 'absolute',
        width: "100%",
        overflow: "hidden",
        bottom: 0,
        left: 0,
        transition: "height 0.5s linear",
    },
    container: {
        mx: "auto",
        background: "#494949",
        width: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 1,
        borderRadius: "8px 8px 0 0"
    },
    rating: {
        my: 1,
    },
    message: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        textAlign: "center",
        color: "#FFFFFF",
    },
};

function RateExperience({ assessmentState }) {
    const snackbar = useSnackbar();
    const [ratingValue, setRatingValue] = useState(3);
    const [submitted, setSubmited] = useState(false);

    const handleSubmit = async (e) => {
        try {
            setRatingValue(e.target.value);

            await postFeedback(ratingValue, "");

            setSubmited(true);

            snackbar.success("Thanks for your valuable feedback!");
        } catch (error) {
            console.error(error);
            snackbar.error(
                "Uh Oh! Unable to submit your feedback. Please try again!"
            );
        }
    };

    const show = useMemo(() => (
        assessmentState === AssessmentStates.COMPLETED && !submitted
    ),
        [assessmentState, submitted]
    );

    return (
        <Box
            sx={sx.root}
            style={{ height: show ? 88 : 0 }}
        >
            <Box sx={sx.container}>
                <Typography
                    variant="body1"
                    sx={sx.message}
                >
                    we’d like your feedback
                </Typography>
                <Rating
                    value={Number(ratingValue)}
                    precision={1}
                    onChange={handleSubmit}
                    size="large"
                    sx={sx.rating}
                    emptyIcon={<StarIcon style={{ opacity: 0.55, color: "#D9D9D9" }} fontSize="inherit" />}
                />
            </Box>
        </Box>
    );
}

export default RateExperience;