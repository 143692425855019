import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Typography } from "@mui/material";

export const SnackbarContext = React.createContext({
  _open: false,
  _message: "",
  _variant: "",
  _autoHide: true,
  _setOpen: (value) => { },
  _setMessage: (value) => { },
  _setVariant: (value) => { },
  _setAutoHide: (value) => { }
});

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackbar = ({ onClose, autoHide, ...props }) => {

  const { color, bgColor, shadow } = React.useMemo(()=>{
    const getBgColor = (severity) => {
      switch (severity) {
        case 'error':
          return {color: '#C51407' ,bgColor: '#FFE6E3', shadow: '0px 2px 13px 1px #E2483C80'};
        case 'warning':
          return {color: '#664100' ,bgColor: '#FFF6E5', shadow: '0px 2px 13px 1px #E2483C80'};
        case 'info':
          return {color: '#003E8C' ,bgColor: '#9DC2F2', shadow: ''};
        case 'success':
            return {color: '#00664A' ,bgColor: '#DCFCE7', shadow: '0px 2px 13px 1px #00997080'};
        default:
          return {color: '#003E8C' ,bgColor: '#9DC2F2', shadow: ''};
      }
    };

    return getBgColor(props.variant);
  },[props])

  
  return (
    <Snackbar
      open={props.open}
      {...(autoHide ? { autoHideDuration: 3000 } : {})}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...props}
    >
      <Alert 
        onClose={onClose} 
        severity={props.variant} 
        sx={{ 
          width: '100%',
          backgroundColor: bgColor,
          color: color,
          boxShadow: shadow
        }}
      >
        <Typography variant="h6-medium">
          {props.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export const SnackbarProvider = ({ children }) => {
  const [_open, _setOpen] = React.useState(false);
  const [_message, _setMessage] = React.useState("");
  const [_variant, _setVariant] = React.useState("");
  const [_autoHide, _setAutoHide] = React.useState(true);

  const handleClose = () => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  };

  return (
    <SnackbarContext.Provider
      value={
        {
          _open,
          _setOpen,
          _message,
          _setMessage,
          _variant,
          _setVariant,
          _autoHide,
          _setAutoHide,
        }
      }
    >
      <CustomSnackbar
        open={_open}
        message={_message}
        variant={_variant}
        onClose={handleClose}
        autoHide={_autoHide}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { _setOpen, _setMessage, _setVariant, _setAutoHide } = React.useContext(SnackbarContext);

  const showSnackbar = React.useCallback(
    (_message = "", _variant = "success", _autoHide = true) => {
      if (_message) {
        _setVariant(_variant);
        _setMessage(_message);
        _setAutoHide(_autoHide);
        _setOpen(true);
      }
    }, [_setAutoHide, _setVariant, _setOpen, _setMessage]);

  const hideSnackbar = React.useCallback(() => {
    _setOpen(false);
    _setAutoHide(true);
    _setMessage("");
  }, [_setOpen, _setAutoHide, _setMessage]);

  const error = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'error', !disableAutoHide);
  }, [showSnackbar]);

  const success = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'success', !disableAutoHide);
  }, [showSnackbar]);

  const warn = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'warning', !disableAutoHide);
  }, [showSnackbar]);

  const info = React.useCallback((message = "", disableAutoHide) => {
    showSnackbar(message, 'info', !disableAutoHide);
  }, [showSnackbar]);

  return { showSnackbar, hideSnackbar, error, success, warn, info };
};

export default CustomSnackbar;