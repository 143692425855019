import React, { useRef, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import OTPAuth from 'utils/OTPAuth';
import { useLoading, useSnackbar } from 'contexts';

const useStyles = makeStyles(theme=>({
  otpInput: {
    border: '0px solid transparent',
    width: '60px',
    height: '60px', 
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: '4px',
    display: 'block',
    boxShadow: '0px 0px 4px 0px #00000040',
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: theme.spacing(3)
  },
  resendOtp: {
    marginTop: theme.spacing(5),
    cursor: 'pointer', width: 'fit-content'
  },
  errorTxt: {
    color: 'red', fontSize: '14px'
  }
}))

export const OtpInput = ({ 
  numberOfDigits = 6, otp, setOtp, sendOTP, otpError=null, phone,
  ...props 
}) => {
  const otpBoxReference = useRef([]);
  const classes = useStyles();

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if(value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  return (
    <Box textAlign='center' mt='10px'>
      <Box style={{ display: 'flex', justifyContent: 'center', gap: '10px'}}>
        {otp.map((digit, index)=>(
          <input 
            key={index} value={digit} maxLength={1}  
            onChange={(e)=> handleChange(e.target.value, index)}
            onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
            className={classes.otpInput}
            style={{ borderColor: (otpError ? 'red': '')}}
            onFocus={(e) => e.target.style.outlineColor = '#02569D'}
          />
        ))}
      </Box>

      {
        otpError && 
        <Typography className={classes.errorTxt}>
          {otpError}
        </Typography>
      }

      <Typography variant='body01-bold' color='#8E8F8F' pt={2}>
        We have sent OTP on +91 {phone.slice(0,4)}xxxxxx
      </Typography>

      <Box style={{ display: 'flex', justifyContent: 'center'}}>
        <Typography 
          variant='body01-link' color='primary.clr-300' 
          className={classes.resendOtp} 
          onClick={()=> sendOTP()}
        >
          Resend OTP
        </Typography>
      </Box>
    </Box>
  );
}


const OtpVerification = ({
  countryCode='91', phone='', setStep, setOtpVerified, numberOfDigits, ...props 
}) => {
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(null);

  const otpAuth = useRef(new OTPAuth());

  const onOTPSubmit = async (otp) => {
    try {
        const otpToVerify = otp;
        loading.show();

        try {
          await otpAuth.current.verifyOTP(otpToVerify);
          setOtpVerified(true);
          snackbar.success("OTP verified");
        } catch (error) {
          console.error(error);
          setOtpError("Wrong OTP Please Check Again")
          snackbar.error("Uh Oh! You have entered an incorrect OTP");
        }
    } catch (error) {
        console.error(error);
    } finally {
        loading.hide();
    }
};

  const sendOTP = React.useCallback(async () => {
    const contact = countryCode.concat(phone);

    const invokeSendOTP = () => {
        console.log("Sending otp to...", contact);

        otpAuth.current.sendOTP("+".concat(contact))
            .then(() => {
                snackbar.success("OTP sent successfully")
            })
            .catch((e) => {
                console.error(e);
                snackbar.error("Something went wrong. Try sending Again!!")
            }).finally(() => loading.hide());
    };

    if (otpAuth.current.appVerifier) invokeSendOTP();
    else {
        otpAuth.current.setRecaptchaVerifier();
        invokeSendOTP();
    }
  }, [countryCode, phone]);

  React.useEffect(()=>{
    sendOTP();
  },[countryCode, phone]);

  useEffect(() => { 
    if(otp.join("").length === numberOfDigits){
      onOTPSubmit(otp.join(""));
    }
    if(otp.join("").length !== numberOfDigits){
      setOtpError(null);
      setOtpVerified(false);
    }
  }, [otp]);

  return (
    <>
      <OtpInput
        numberOfDigits={numberOfDigits}
        otp={otp}
        setOtp={setOtp}
        sendOTP={sendOTP}
        otpError={otpError}
        phone={phone}
      />

      <div id="recaptcha-container"></div>
    </>
  )
}

export default OtpVerification;