export const RETAKES_AVAILABLE = 1;

export const AssessmentStates = {
    LOADED: "LOADED",
    STARTING: "STARTING",
    RUNNING: "RUNNING",
    FINISHING: "FINISHING",
    COMPLETED: "COMPLETED",
    WAITING_FOR_RESULT: "WAITING_FOR_RESULT",
    SHOWING_TUTORIAL: "SHOWING_TUTORIAL",
    FETCHING_QUESTIONS: "FETCHING_QUESTIONS",
    FULLSCREEN_PERMISSION: "FULLSCREEN_PERMISSION"
};

export const TutorialTips = [
    {
        id: "first-tip-assessment",
        message:
            'To begin your answering simply click "Record Answer"',
    },
    {
        id: "interviewQuestions",
        message:
            "See the progress of your assessment and how many questions you have left here",
    },
    {
        id: "last-tip-assessment",
        message:
            "End the session after you finish answering",
    },
];

export const QuestionStates = {
    WAITING: "WAITING",
    RECORDING: "RECORDING",
    RECORDING_STOPPED: "RECORDING_STOPPED",
    SUBMITTED: "SUBMITTED",
    SHOWING_TUTORIAL: "SHOWING_TUTORIAL",
};

export const interviewerStateTags = {
  SPEAKING: "Emma is Speaking",
  WAITING: "Emma is waiting for your response",
  RECORDING: "Emma is Listening",
  NEXT: () => {
    const Tags = [
      "Emma is coming up with the next question",
      "Emma is thinking about the next question",
      "Emma is coming up with the following question",
      "Emma is pondering the next question",
      "Emma is preparing the next question",
      "Emma is crafting the next question",
      "Emma is brainstorming the next question",
      "Emma is getting ready with the next question",
      "Emma is developing the next question",
      "Emma is working on the next question",
    ]
    
    return Tags[Math.floor(Math.random() * Tags.length)];
  },
}