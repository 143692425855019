

export const BookmarkTourSteps = [
    {
        id: "search-bookmarks",
        title: "Search",
        message: "Search any question directly or by assessment name to view any saved question.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {}
    },
    {
        id: "bookmark-questions",
        title: "Bookmarked Questions",
        message: "View your saved questions with its attempt and assessment for easy search.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {
            bookmarks: [
                {
                    "_id": "650ae3cad95436a28e559c55",
                    "user": "64cb9951b71cb70872a81398",
                    "interviewQuestion": {
                        "_id": "64d202acb7a9b87d319a3b32",
                        "serialNumber": 0,
                        "question": "What brought you to product management domain?"
                    },
                    "interviewAttempt": {
                        "_id": "650836d2a6edc3ff5e0e090d",
                        "createdAt": "2023-09-18T11:38:58.729Z"
                    },
                    "interviewTemplate": {
                        "_id": "64d202acb7a9b87d319a3b30",
                        "name": "Product Management Interview."
                    },
                    "attemptNumber": 5,
                    "isActive": true,
                    "createdAt": "2023-09-20T12:21:30.404Z",
                    "updatedAt": "2023-09-20T12:21:30.404Z",
                    "__v": 0
                },
                {
                    "_id": "650ae3cad95436a28e559c51",
                    "user": "64cb9951b71cb70872a81391",
                    "interviewQuestion": {
                        "_id": "64d202acb7a9b87d319a3b31",
                        "serialNumber": 0,
                        "question": "What brought you to product management domain?"
                    },
                    "interviewAttempt": {
                        "_id": "650836d2a6edc3ff5e0e0901",
                        "createdAt": "2023-09-18T11:38:58.729Z"
                    },
                    "interviewTemplate": {
                        "_id": "64d202acb7a9b87d319a3b31",
                        "name": "Product Management Interview."
                    },
                    "attemptNumber": 5,
                    "isActive": true,
                    "createdAt": "2023-09-20T12:21:30.404Z",
                    "updatedAt": "2023-09-20T12:21:30.404Z",
                    "__v": 0
                }
            ]
        }
    },
    {
        id: "edit-notes",
        title: "Notes",
        message: "Write down your important points to remember for later read and practice. Click to write your important points to remember later.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {}
    },
    {
        id: "view-assessment",
        title: "View Question",
        message: "View feedback of your saved question by directly clicking onto this view icon.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {}
    },
]