import dayjs from "dayjs";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

import { Categories } from "../../components/PerformanceReview/constants";
import StatusChip from "components/Chip";
import ImageViewer from "components/ImageViewer";

const useStyles = makeStyles(theme => ({
  card: {
    margin: "0 1% 16px",
    boxShadow: "0px 0px 4px 0px #00000026",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  cardMedia: {
    objectFit: "cover",
    width: "100%",
    height: "190px",
    background: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.10)'
  },
  cardContent: {
    display: 'flex', flexDirection: 'column',
    gap: theme.spacing(2), padding: '0px !important',
    margin: theme.spacing(3, 0, 1, 0),
  },
  title: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
  },
}));

export const CustomCardSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card} >
      <Skeleton variant="rectangular" width="100%" height={190} />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="rectangular" width="100%" height={20} />
        <Skeleton variant="rectangular" width="100%" height={20} />

        <Skeleton variant="rectangular" width="30%" height={30} />

      </CardContent>
    </Card>
  )
}

const CustomCard = ({ image, title, category, createdAt }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
      <Card className={classes.card} >
        <CardMedia>
          <ImageViewer
            width={"100%"}
            height={"190px"}
            src={image}
            alt="Card image"
          />
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="h6-semiBold">
            {title}
          </Typography>
          <Typography variant="body01-semiBold" color='neutral.clr-600'>
            {dayjs(createdAt).format('MMMM D, YYYY')}
          </Typography>
          <StatusChip
            content={Categories[category]}
            color={theme.palette.tertiary['clr-700']}
            bgColor={theme.palette.tertiary['clr-50']}
          />
        </CardContent>
      </Card>
  );
};

export default CustomCard;