import create from "zustand";
import { getAverageScore } from "utils";

export const defaults = {
    percentile: 0,
    content: 0,
    speech: 0,
    removedFromBatch: false,
    template: {
        name: "Assessment NA",
    },
    forceCategoryChange: undefined,
    questions: [
        {
            type: "background",
            question: "-",
            bookmark: null,
            hint: "-",
            expectedAnswer: "-",
            resources: [],
            subjectiveUnfilled: true,
            allotedTime: 180,
            communication: {
                "ratings": {
                    "FLUENCY": 0,
                    "WORD_CHOICE": 0,
                    "DELIVERY": 0,
                    "CONFIDENCE": 0,
                    "OVERALL": 0
                },
                tags: [],
            },
            content: {
                ratings: {
                    "SIMILARITY": 0,
                    "RELEVANCE": 0,
                    "LOGIC_FLOW": 0,
                    "OVERALL": 0
                },
                tags: [],
            }
        },
    ],
    currentQuestion: 0,
    templateId: null,
    templateAttempts: 0,
    attemptNumber: 0,
    attemptId: null,
    isDemoApp: false,
    allAttempts: [],
    demoApplication: null,
};

const useStore = create(
    (set, get) => ({
        ...defaults,
        setTemplate: (template) => set({ template }),
        setQuestions: (questions) => set({ questions }),
        setCurrentQuestion: (currentQuestion) => set({ currentQuestion }),
        setTemplateId: (templateId) => set({ templateId }),
        setAttemptId: (attemptId) => set({ attemptId }),
        setAttemptNumber: (attemptNumber) => set({ attemptNumber }),
        setAllAttempts: (allAttempts) => set({ allAttempts }),
        setIsDemoApp: (isDemoApp) => set({ isDemoApp }),
        setForceCategoryChange: (forceCategoryChange) => set({ forceCategoryChange }),
        next: () => set(state => ({
            currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length - 1),
        })),
        prev: () => set(state => ({
            currentQuestion: Math.max(state.currentQuestion - 1, 0),
        })),
        init: ({
            percentile = 80,
            template,
            questions,
            content = 82,
            speech = 65,
            templateAttempts = 0,
            removedFromBatch = false,
            demoApplication = null,
            currentQuestion=0
        }) => {
            const score = getAverageScore(speech, content);

            set({
                percentile,
                questions,
                templateAttempts,
                removedFromBatch,
                templateId: template._id,
                content,
                speech,
                score,
                demoApplication,
                currentQuestion
            })
        },
        reset: () => set(defaults),
    }),
);

export default useStore;