import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getNumberWithSuffix } from "utils";
import { AnalyticsBriefSkeleton } from "./AnalyticsBrief";

const useStyles = makeStyles(theme=>({
  container: {
    width: 208, height: 120,
    display: "flex",
    justifyContent: 'space-between',
    position: "relative",
    padding: theme.spacing(5),
    border: '2px solid',
    borderRadius: theme.spacing(2),
  },
  gold: {
    backgroundColor: theme.palette.warning['clr-100'],
    borderColor: theme.palette.warning['clr-300']
  },
  silver: {
    backgroundColor: theme.palette.neutral['clr-100'],
    borderColor: theme.palette.neutral['clr-400'],
  },
  bronze: {
    backgroundColor: theme.palette.tertiary['clr-50'],
    borderColor: theme.palette.tertiary['clr-300'],
  },
  content: {
    display: 'flex', flexDirection: 'column',
    justifyContent: 'space-between', alignItems: 'flex-end',
    height: '100%',
  },
  rank: {
    display: "flex",
    alignItems: 'center', height: '30px', 
  }
}));

const BadgeMap = {
  'gold': 'https://assets.languify.in/images/badge_rank_01.svg',
  'silver': 'https://assets.languify.in/images/badge_rank_02.svg',
  'bronze': 'https://assets.languify.in/images/badge_rank_03.svg',
};

export default function RankCard({ rank, loading }) {
  const classes = useStyles();

  return (
    (loading) ? <AnalyticsBriefSkeleton/> :
    (rank && rank?.rank > 0) ? 
    <Box className={`${classes.container} ${classes[rank?.badge?.toLowerCase()]}`}>
      <Box>
        <img
          src={BadgeMap[rank?.badge?.toLowerCase()]}
          className={classes.badge}
          alt={rank?.badge?.toLowerCase()}
        />
      </Box>
      <Box className={classes.content}>
        <Typography variant='h6-semiBold' color='shades.clr-black-900'>
          Your Rank
        </Typography>
        <Typography variant='h2-bold' color='shades.clr-black-900' className={classes.rank}>
          {getNumberWithSuffix(rank?.rank)}
        </Typography>
      </Box>
    </Box>
      : null
  );
}
