import { useEffect, useState, useContext } from "react";
import { useSnackbar, useServices, TourProviderContext } from "contexts";
import { getInterviewAttempts } from "services";
import { Session, getAverageScore, round } from "utils";
import { useNavigateWithClient } from "hooks";
import { ProctorCell } from "./ProctorCell";
import { PerformanceTourSteps } from "./constants";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SearchTextField } from "components/CustomFields";
import { FilterChips } from "components/Filter";
import { debounce } from 'lodash';

import dayjs from "dayjs";
import Box from "@mui/material/Box";
import DataGrid from "components/DataGrid";
import Divider from '@mui/material/Divider';
import Pagination from "components/Pagination";
import makeStyles from "@mui/styles/makeStyles";
import ImageProctorDialog from "dialogs/ImageProctorDialog";
import NoAssessmentsFound from "components/NoAssessmentsFound";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary['clr-50'],
    ...theme.typography['body01-bold'],
    textAlign: "center", lineHeight: 'none',
    color: theme.palette.shades['clr-black-900']
  },
  row: {
    color: theme.palette.neutral['clr-900'],
    ...theme.typography['body01-medium'],
    cursor: "pointer",
    '&:hover': {
      backgroundColor: `${theme.palette.neutral['clr-50']} !important`,
    },
  },
}));

const filters = [
  { field: 'assignment', type: 'bool', label: 'Assignment', },
  { field: 'practice', type: 'bool', label: 'Practice', },
];

export default function AttemptsTable({ batch, pathway }) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();

  const { play } = useContext(TourProviderContext);

  const [loading, setLoading] = useState(false);
  const [allAttempts, setAllAttempts] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [page, setPage] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({})
  const [proctoringRecords, setProctoringRecords] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleViewCapturedImage = (pr) => {
    setProctoringRecords(pr);
    setShowImageModal(true);
  }

  const columns = [
    {
      field: "interviewTemplate",
      headerName: "Assessment",
      valueFormatter: (rowData) => rowData?.value?.name,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 200,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Assessment Type",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => { 
        if( row?.interviewTemplate?.type === 'personalized'){
          return "Mock Interview";
        }else{
          return row.type.replace(/_/g, " ");
        }
      }
    },
    {
      field: "duration",
      headerName: "Time Taken",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        let { finishedAt, startedAt } = row;

        if (finishedAt && startedAt) {
          const seconds = dayjs(finishedAt).diff(startedAt, "seconds");
          return seconds < 60 ? `${seconds} secs` : `${round(seconds / 60)} mins`;
        } else {
          return "N/A";
        }
      }
    },
    {
      field: "createdAt",
      headerName: "Attemped On",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
    },
    {
      field: "proctorship",
      headerName: "Proctor",
      align: "center",
      maxWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ProctorCell
          proctoringRecords={row.proctoringRecords}
          onViewCapturedImage={handleViewCapturedImage}
        />
      ),
      headerClassName: classes.tableHeader,
    },
    {
      field: "overallScore",
      headerName: "Score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => {
        const comm = round(row.analysis?.communication?.ratings?.OVERALL) || 0;
        const content = round(row.analysis?.content?.ratings?.OVERALL) || 0;

        return round(getAverageScore(comm, content));
      },
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "communication",
      headerName: "Speech",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        round(row.analysis?.communication?.ratings?.OVERALL) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "content",
      headerName: "Content",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        round(row.analysis?.content?.ratings?.OVERALL) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },

  ];

  useEffect(() => {
    if (!batch?._id) return;

    (async () => {
      try {
        setLoading(true);

        let allFilters = {
          user: Session.userId,
          batch: batch?._id,
          finishedAt: null,
          include: ["interviewTemplate", "user"],
          orderBy: "createdAt",
          order: 'desc'
        };

        if (pathway.length) allFilters.pathway = pathway;

        const { interviewAttempts } = await service.callService(
          getInterviewAttempts, allFilters
        );

        setAllAttempts(interviewAttempts);

      } catch (error) {
        console.log(error);
        snackbar.error('Unable to load attempts');
      } finally {
        setLoading(false);
      }
    })();
  }, [batch, pathway]);

  useEffect(() => {
    let { name, practice, assignment } = appliedFilters;

    name = name && name.toLowerCase();

    setAttempts(allAttempts.filter((a) => {
      let result = Object.keys(appliedFilters).length === 0;

      if (name && name !== '') {
        result = a.interviewTemplate.name.toLowerCase().includes(name)
      }

      if (practice !== assignment) {
        result = result || (practice && 'practice' === a.interviewTemplate.type);
        result = result || (assignment && 'assignment' === a.interviewTemplate.type);
      }

      return result;
    }))
  }, [appliedFilters, allAttempts]);

  useEffect(() => {
    if (play) {
      const showDummyBatches = allAttempts.length === 0;

      if (showDummyBatches) {
        setAllAttempts(PerformanceTourSteps[1].data.attempts);
      }

      return () => {
        if (showDummyBatches) setAllAttempts([]);
      };
    }
  }, [play])

  const handleFilterChange = ({ field, value }) => {
    setAppliedFilters({});
    setAppliedFilters((afs) => {
      const newFilters = { ...afs };

      if (value && !Object.keys(appliedFilters).includes(field)) newFilters[field] = value;
      else delete newFilters[field];

      return newFilters;
    });
  };

  const handleChange = (e) => {
    const value = e.target.value.trim();
    if (value.length > 3 || value.length === 0)
      handleFilterChange({ field: "name", value: value });
    setSearchQuery(value);
  };

  const handleInput = debounce(handleChange, 500);

  return (
    <>
      <WhiteBox id="attempts-container">
        <WhiteBoxHeader
          heading="Your attempted assessments"
          subheading="History of all the assessments attempted by you"
        >
          <SearchTextField
            placeholder="Search assessment..."
            onChange={handleInput}
            disabled={
              (!attempts.length && !searchQuery?.length > 0) || !allAttempts?.length
            }
          />
        </WhiteBoxHeader>

        <Divider />

        {
          !(allAttempts.length) ? null :
            <FilterChips
              title="Assessment filters"
              filters={filters}
              appliedFilters={appliedFilters}
              onChange={handleFilterChange}
              disabeled={
                !attempts.length && 
                (Object.keys(appliedFilters).length && searchQuery.length)
              }
            />
        }

        {
          attempts.length ?
            <DataGrid
              hideFooter
              loading={loading}
              paginationMode={'client'}
              filterMode={'client'}
              sortingMode={'client'}
              height="320px"
              rows={attempts}
              columns={columns}
              rowCount={attempts.length}
              pageSize={5}
              page={page}
              onPageChange={setPage}
              components={{ Pagination: null }}
              getRowClassName={(params) => classes.row}
              density="standard"
              noRowsMessage={"Seems like, you haven't taken any assessment yet!"}
              onRowClick={(params) => navigateWithClient(`/performance/${params.id}`)}
            />
            :
            allAttempts.length ?
              <NoAssessmentsFound
                icon={'https://assets.languify.in/images/no-attempt.svg'}
                label={
                  searchQuery?.length
                    ? 'No assessment found' : 'No result found for the applied filter'
                }
                filters={searchQuery?.length ? undefined : filters}
                clearFilters={() => setAppliedFilters({})}
              />
              :
              <NoAssessmentsFound
                icon={'https://assets.languify.in/images/no-attempt.svg'}
                label={<>You have not attempted any assessment so far!<br />once attempted you can see attempt details here</>}
              />
        }
        {
          !attempts.length ? null :
            <Box p={2} display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil(attempts.length / 5)}
                page={page}
                setPage={setPage}
              />
            </Box>
        }
      </WhiteBox>
      <ImageProctorDialog
        open={showImageModal}
        proctoringRecords={proctoringRecords}
        onClose={() => setShowImageModal(false)}
      />
    </>
  );
}