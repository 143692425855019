import React from "react";
import { SnackbarProvider, useSnackbar, } from 'notistack';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme=>({
  snackbar: {
    ...theme.typography['h6-medium'],
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
    '& .MuiSnackbarContent-root': {
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
  },
}));

export const StackSnackbarProvider = ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        containerRoot: classes.snackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  )
};


export const useStackSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showStackSnackbar = React.useCallback((message, options) => {

    enqueueSnackbar(message, options);

  }, [enqueueSnackbar]);

  const closeStackSnackbar = React.useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return { showStackSnackbar, closeStackSnackbar };
}