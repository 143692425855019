import { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

import { useNavigateWithClient } from "hooks";
import { getTwoDigitNo, round } from "utils";
import { MultiBoxProgressBar } from "./Pathway/Module";
import StatusChip from "./Chip";
import { TemplateCardSkeleton } from "./TemplateList";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between',
    width: "394px",
    minWidth: "394px",
    height: "200px",
    maxHeight: "200px",
    boxShadow: "0px 0px 2px 0px #000000BF",
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    transition: "box-shadow 10ms linear",
    "&:hover": {
      boxShadow: "0px 0px 8px 0px #1961BF4D",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1)
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tags: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
}));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'transparent'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral['clr-800'],
    color: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 10,
    maxWidth: 250,
    ...theme.typography['body02-bold'],
  },
  [`&.${tooltipClasses.popper}`]: {
    top: '-180px !important', 
    left:'60px !important'
  },
}))

const TemplateToolTip = ({
  component, placement = 'top',handleCardClick, children,positionStyle = {}, ...props 
}) => {
  return (
    <Box onClick={handleCardClick} sx={{ position: 'relative'}}>
      <BootstrapTooltip title={component} positionStyle={positionStyle} placement={placement} {...props}>
        {children}
      </BootstrapTooltip>
    </Box>
  )
}

export function PathwayCard({ pathway, onClick }) {
  const classes = useStyles();
  const theme = useTheme();

  const handleCardClick = useCallback(
    (e) => {
      onClick(pathway);
    },
    [pathway, onClick]
  );

  return (
    <TemplateToolTip 
      placement='bottom' 
      component= {pathway?.name || ""} 
      disableHoverListener={pathway?.name.length <= 28}
      handleCardClick={handleCardClick}
    >
      <Box className={classes.cardContainer} onClick={handleCardClick}>
        <Box className={classes.tags}>
          {
            (pathway.new) &&
            <StatusChip
              content={'new'}
              color={theme.palette.tertiary['clr-700']}
              bgColor={theme.palette.tertiary['clr-50']}
            />
          }
          {
            (pathway?.tag) &&
            <StatusChip
              content={pathway?.tag?.name}
              color={theme.palette.secondary['clr-900']}
              bgColor={theme.palette.secondary['clr-100']}
            />
          }
        </Box>
        <Box className={classes.cardContent}>
          <Typography variant='h4-semiBold' color='neutral.clr-900' className={classes.title}>
            {pathway?.name || ""}
          </Typography>
          <Typography variant='body01-semiBold' color='neutral.clr-700'>
            Pathway Score:&nbsp;<span style={{ color: theme.palette.primary.main }}>
              {getTwoDigitNo(round(pathway.pathwayScore))}
            </span>
          </Typography>
          <Typography variant='body01-semiBold' color='neutral.clr-700'>
            Modules completed:&nbsp;<span style={{ color: theme.palette.primary.main }}>
              {getTwoDigitNo(pathway?.modulesCompleted)}/{getTwoDigitNo(pathway?.totalModules)}
            </span>
          </Typography>
          <MultiBoxProgressBar
            completed={pathway?.modulesCompleted}
            total={pathway?.totalModules}
            bgColor={theme.palette.neutral['clr-300']}
            filledColor={theme.palette.primary.main}
          />
        </Box>
      </Box>
    </TemplateToolTip>
  );
}

export default function PathwayList({ pathways = [], batch, loading }) {
  const navigateWithClient = useNavigateWithClient();

  return (
    <>
      {
        loading ?
          Array.from(new Array(3)).map((d, i) => {
            return <TemplateCardSkeleton key={i}/>
          })
          :
          pathways.map((pathway, index) => (
            <PathwayCard
              key={index}
              pathway={pathway}
              onClick={(t) => { navigateWithClient(`/pathway/${t._id}`) }}
            />
          ))}
    </>
  );
}
