export function getSpeed(callback = ({ kbps, mbps }) => { }) {
    const userImageLink =
        "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200714180638/CIP_Launch-banner.png";

    let time_start, end_time;

    // The size in bytes
    const downloadSize = 5616998;
    const downloadImgSrc = new Image();

    downloadImgSrc.onload = function () {
        end_time = new Date().getTime();

        const timeDuration = (end_time - time_start) / 1000;
        const loadedBits = downloadSize * 8;

        /* Converts a number into string
        using toFixed(2) rounding to 2 */
        const bps = (loadedBits / timeDuration).toFixed(2);
        const speedInKbps = (bps / 1024).toFixed(2);
        const speedInMbps = (speedInKbps / 1024).toFixed(2);

        callback({ kbps: speedInKbps, mbps: speedInMbps })
    };

    time_start = new Date().getTime();

    downloadImgSrc.src = userImageLink;
}

export default getSpeed;