import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { round } from 'utils';

const useStyles = makeStyles((theme) => ({
    multiTag: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 2),
        borderRadius: '4px',
        width: 'fit-content',
        minWidth: 'fit-content',
        margin: theme.spacing(2, 0),
        ...theme.typography['body01-semiBold'],
    },

}));

export function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

export const CustomSlider = styled(Slider)({
    color: '#02569D',
    height: 8,
    '& .MuiSlider-rail': {
        background: "linear-gradient(90deg, #FF2D55 0%, #FFCC00 50%, #41D7A1 100%)",
        opacity: 1
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: "transparent",
        opacity: 1,
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#02569D',
        border: '4px solid white',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
});

const ColorCodes = {
    'good': { color: '#00664A', bgColor: '#DCFCE7' },
    'avg': { color: '#BF7900', bgColor: '#FFF6E5' },
    'low': { color: '#C51407', bgColor: '#FFE6E3' },
};

function ArrayTag({ value, subCategory }) {
    const classes = useStyles();

    const { color, bgColor, } = React.useMemo(() => {

        let colorCodes = ColorCodes.low;

        if ('RARE_USED_WORDS' === subCategory) {
            colorCodes = ColorCodes.good;
        }

        return colorCodes;
    }, [value, subCategory]);

    return <Box display='flex' gap={2} style={{ overflowX: 'scroll' }}>
        {
            value.map((v) => (
                <Box
                    className={classes.multiTag}
                    style={{ backgroundColor: bgColor, color: color }}
                >
                    {v.word} x {v.count}
                </Box>
            ))
        }
    </Box>;
}

function NumberTag({ value, subCategory }) {
    const classes = useStyles();
    const filterValue = round(value);

    const { color, label, hasChip, bgColor, showTag } = React.useMemo(() => {

        let data = {
            color: ColorCodes.low.color,
            bgColor: ColorCodes.low.bgColor,
            label: 'NA',
            hasChip: false,
            showTag: true
        };

        switch (subCategory) {
            case 'PAUSES':

                if (filterValue < 70) {
                    data.hasChip = true;
                    data.label = `Pauses encountered : ${filterValue}`;
                } else {
                    data.hasChip = true;
                    data.label = `Pauses encountered : ${filterValue}`;
                }
                break;
            case 'DISFLUENCY':

                data.hasChip = true;
                data.label = `Breakdowns encountered : ${filterValue}`;
                if (filterValue === 0) {
                    data.color = ColorCodes.good.color;
                    data.bgColor = ColorCodes.good.bgColor;
                }
                break;
            case 'PACE':

                if (filterValue >= 70) {
                    data.label = 'High';
                } else {
                    if (filterValue < 70 && filterValue >= 40) {
                        data.label = 'Ideal';
                        data.color = ColorCodes.good.color;
                    } else {
                        data.label = 'Low';
                        data.color = ColorCodes.avg.color;
                    }
                }
                break;
            case 'ENERGY':

                if (filterValue === 1) {
                    data.label = 'Low';
                    data.color = ColorCodes.avg.color;
                }
                if (filterValue === 2) {
                    data.label = 'Ideal';
                    data.color = ColorCodes.good.color;
                }
                break;

            default:
                break;
        }

        return data;
    }, [value, subCategory]);

    if (!showTag) return null;

    return (
        hasChip ?
            <>
                <Box
                    className={classes.multiTag}
                    style={{ backgroundColor: bgColor, color: color }}
                >
                    {label}
                </Box>

            </> :
            <>
                <Typography variant='body01-bold' color={color} my={2}>
                    {label}
                </Typography>
            </>
    )
}

export function ParameterTag({ tag }) {

    const { value, subCategory } = tag || {};


    return (
        <>
            {
                Array.isArray(value) ? <ArrayTag value={value} subCategory={subCategory} /> : null
            }

            {
                Number.isInteger(value) ?
                    <NumberTag value={value} subCategory={subCategory} /> :
                    null
            }
        </>
    );
}

export default ParameterTag;