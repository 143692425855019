import { Session } from "utils";
import Client from "./_client";

export const addBookmark = async (bookmarkData) => {
    let url = "/bookmark";

    const result = await new Client({
        path: url,
        payload: {
        user: Session.userId,
        ...bookmarkData
    },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! Unable to save bookmark. Please try again."
        );
    }
    if (result.name === "DuplicateSession") {
        return result;
    } else {
        return result.data;
    }
};

export const getBookmarks = async (filters) => {
    let url = "/bookmark?";

    Object.keys(filters).forEach((key) => {
        if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
    });

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! unable to get Bookmarks. Please try again"
        );
    }

    return result?.data;
};

export const updateBookmark = async (id,blogDetails) => {

    let result = await new Client({
        path: `/bookmark/${id}`,
        payload: blogDetails,
        }).put();

        if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to update Bookmark. Please try again."
        );
    }
    return result.data;
}

export const deleteBookmark = async (id) => {

    let result = await new Client({
        path: `/bookmark/${id}`,
        }).delete();

        if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to delete Bookmark. Please try again."
        );
    }
    return result.data;
}