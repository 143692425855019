import Client from "./_client";

export const QuestionCategories = {
  technical: "OBJECTIVE",
  background: "SUBJECTIVE",
  situational: "SUBJECTIVE",
};

export const getQuestionById = async (questionId) => {
  const url = `/interviewQuestion/${questionId}`;

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get the question. Please try again."
    );
  }

  return result.data;
};

export const saveQuestion = async (questionId, questionData) => {
  let result;
  if (questionId) {
    // Invoke API to update template
    result = await new Client({
      path: `/interviewQuestion/${questionId}`,
      payload: {
        question: questionData.question,
        expectedAnswer: questionData.expectedAnswer,
        interviewTemplate: questionData.templateId,
        type: questionData.type,
        parentQuestionId: questionData.parentQuestionId,
        user: questionData.user,
        allottedTime: questionData.allottedTime,
        resources: questionData.resources,
      },
    }).put();
  } else {
    // Invoke API to create template
    result = await new Client({
      path: `/interviewQuestion`,
      payload: {
        question: questionData.question,
        expectedAnswer: questionData.expectedAnswer,
        interviewTemplate: questionData.templateId,
        type: questionData.type,
        parentQuestionId: questionData.parentQuestionId,
        user: questionData.user,
        allottedTime: questionData.allottedTime,
        resources: questionData.resources,
      },
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save question. Please try again."
    );
  }

  return result?.data;
};

export const getQuestions = async (templateFilters = {}) => {
  let url = "/interviewQuestion?";

  Object.keys(templateFilters).forEach((key) => {
    if (templateFilters[key]) url += `${key}=${templateFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to fetch questions. Please try again."
    );
  }

  return result.data;
};

export const getRandomPathwayQuestions = async (filters = {}) => {
  let url = "/interviewQuestion/random/pathway-questions?";

  Object.keys(filters).forEach((key) => {
    if (filters[key]) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to fetch questions. Please try again."
    );
  }

  return result.data;
};

export const deleteQuestionById = async (questionId) => {
  if (!questionId) return false;

  const result = await new Client({
    path: `/interviewQuestion/${questionId}`,
  }).delete();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to delete the question. Please try again."
    );
  }

  return result?.data?.isDeleted;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getQuestionById,
  saveQuestion,
  getQuestions,
  deleteQuestionById,
};
