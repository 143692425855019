import { round } from "utils";

export const OverallData = [{
    title: "Your Average",
    data: 0,
    percentage: 0,
    increment: true,
    subTitle: 'Your Cumulative Assessment Average Score',
    info: `Reflects changes in overall average score after your last attempt`
}, {
    title: "Speech Average",
    data: 0,
    percentage: 0,
    increment: true,
    subTitle: 'Your Aggregate Speech Evaluation Score',
    info: `Reflects changes in average speech score after your last attempt.`
}, {
    title: "Content Average",
    data: 0,
    percentage: 0,
    increment: true,
    subTitle: 'Your collective content score on average',
    info: `Reflects changes in average content score after your last attempt.`
}, {
    title: "Batch Average",
    data: 0,
    percentage: 0,
    increment: true,
    subTitle: "Average score representing your batch's performance",
    info: `Reflects changes in batch average after last attempt of any batch student.`
}];

export const PathwayOverallData = [{
    title: "Pathway Score",
    data: 0,
    showComparision: false,
    subTitle: 'Your Cumulative Pathway Average Score',
  }, {
    title: "Speech Average",
    data: 0,
    showComparision: false,
    subTitle: 'Your Aggregate Speech Evaluation Score',
  }, {
    title: "Content Average",
    data: 0,
    showComparision: false,
    subTitle: 'Your collective content score on average',
  }, {
    title: "Batch Average",
    data: 0,
    showComparision: false,
    subTitle: "Average score representing your batch's performance",
}];

export const PerformanceTourSteps = [
    {
        id: "detailed-analysis",
        title: "View detailed parameter analysis",
        message: "View overall perfomance of all the attempts till date including speech, content, your and batch average.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {
            attempts: [
                {
                    _id: 1,
                    communication: 67,
                    content: 72,
                    type: "assignment",
                    overall: round((67 + 72) / 2),
                    templateName: "B1 - Assessment 4",
                },
                {
                    _id: 2,
                    communication: 57,
                    content: 74,
                    overall: round((57 + 74) / 2),
                    type: "assignment",
                    templateName: "B2 - Assessment 3",
                },
                {
                    _id: 3,
                    templateName: "B1 - Assessment 2",
                    type: "practice",
                    communication: 67,
                    content: 64,
                    overall: round((67 + 64) / 2),
                },
                {
                    _id: 4,
                    templateName: "B3 - Assessment 5",
                    type: "practice",
                    communication: 77,
                    content: 84,
                    overall: round((77 + 84) / 2),
                },
                {
                    _id: 5,
                    templateName: "B4 - Assessment 11",
                    type: "assignment",
                    communication: 71,
                    content: 88,
                    overall: round((71 + 88) / 2),
                },
            ],
            batches: [{
                _id: "dummy_batch",
                name: "Batch",
                user: {
                    prevCommunication: 67,
                    prevContent: 57,
                    communication: 61,
                    content: 60
                },
            }]
        },
    },
    {
        id: "recent-attempts-analysis",
        title: "Detailed Attempt Feedback",
        message: "Click on any attempt row to access attempt-specific feedback.",
        action: "Got it",
        hideClose: false,
        data: {
            attempts: [
                {
                    "proctoringRecords": [],
                    "_id": "63ecab13d97c2ec26ef57fa3",
                    "startedAt": "2023-02-15T09:51:15.435Z",
                    "finishedAt": "2023-02-15T09:55:22.427Z",
                    "interviewTemplate": {
                        "_id": "63b6a1419a32b9cc5dab895b",
                        "name": "Never Expiry Template",
                        "domain": "620b396840ff4f51ef8e5979",
                        "type": "practice",
                        "metadata": {
                            "numberOfQuestions": 4,
                            "testTime": 240,
                            "usedBy": 22,
                            "averageTime": 205
                        }
                    },
                    "user": "625cf594a6793b424f731f9f",
                    "experienceLevel": "INTERNSHIP",
                    "percentile": 100,
                    "analysis": {
                        "count": 1,
                        "communication": {
                            "ratings": {
                                "OVERALL": 80,
                                "WORD_CHOICE": 55,
                                "FLUENCY": 100,
                                "DELIVERY": 69,
                                "CONFIDENCE": 81
                            },
                            "features": [
                                {
                                    "category": "WORD_CHOICE",
                                    "subCategory": "HINDI_SHARE",
                                    "value": 0
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "PAUSES",
                                    "value": 100
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "DISFLUENCY",
                                    "value": 0
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "PACE",
                                    "value": 90
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "ENERGY",
                                    "value": 2
                                }
                            ]
                        },
                        "content": {
                            "ratings": {
                                "OVERALL": 0,
                                "SIMILARITY": 0,
                                "RELEVANCE": 0,
                                "LOGIC_FLOW": 0
                            },
                            "features": []
                        }
                    },
                    "timeout": true,
                    "totalQuestions": 4,
                    "isActive": true,
                    "createdAt": "2023-02-15T09:51:15.861Z",
                    "updatedAt": "2023-04-14T05:49:52.385Z",
                    "__v": 0,
                    "attemptedQuestions": [
                        "63b6a1419a32b9cc5dab895d"
                    ],
                    "batch": "63a9264c8264c75f9b24eacb",
                    "type": "practice"
                },
                {
                    "proctoringRecords": [],
                    "_id": "63eca7cfd97c2ec26ef57f1b",
                    "startedAt": "2023-02-15T09:37:19.111Z",
                    "finishedAt": "2023-02-15T09:41:08.816Z",
                    "interviewTemplate": {
                        "_id": "63b6a1419a32b9cc5dab895b",
                        "name": "Never Expiry Template",
                        "domain": "620b396840ff4f51ef8e5979",
                        "type": "practice",
                        "metadata": {
                            "numberOfQuestions": 4,
                            "testTime": 240,
                            "usedBy": 22,
                            "averageTime": 205
                        }
                    },
                    "user": "625cf594a6793b424f731f9f",
                    "experienceLevel": "INTERNSHIP",
                    "percentile": 83,
                    "analysis": {
                        "count": 1,
                        "communication": {
                            "ratings": {
                                "OVERALL": 57,
                                "WORD_CHOICE": 21,
                                "FLUENCY": 56,
                                "DELIVERY": 65,
                                "CONFIDENCE": 51
                            },
                            "features": [
                                {
                                    "category": "WORD_CHOICE",
                                    "subCategory": "HINDI_SHARE",
                                    "value": 15
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "PAUSES",
                                    "value": 85
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "DISFLUENCY",
                                    "value": 1
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "PACE",
                                    "value": 85
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "ENERGY",
                                    "value": 2
                                }
                            ]
                        },
                        "content": {
                            "ratings": {
                                "OVERALL": 9,
                                "SIMILARITY": 0,
                                "RELEVANCE": 20,
                                "LOGIC_FLOW": 8
                            },
                            "features": []
                        }
                    },
                    "timeout": false,
                    "totalQuestions": 4,
                    "isActive": true,
                    "createdAt": "2023-02-15T09:37:19.564Z",
                    "updatedAt": "2023-04-14T05:49:52.331Z",
                    "__v": 0,
                    "attemptedQuestions": [
                        "63b6a1419a32b9cc5dab895d"
                    ],
                    "batch": "63a9264c8264c75f9b24eacb",
                    "type": "practice"
                },
                {
                    "proctoringRecords": [],
                    "_id": "63eca51cd97c2ec26ef57eb7",
                    "startedAt": "2023-02-15T09:25:47.857Z",
                    "finishedAt": "2023-02-15T09:29:49.930Z",
                    "interviewTemplate": {
                        "_id": "63b6a1419a32b9cc5dab895b",
                        "name": "Never Expiry Template",
                        "domain": "620b396840ff4f51ef8e5979",
                        "type": "practice",
                        "metadata": {
                            "numberOfQuestions": 4,
                            "testTime": 240,
                            "usedBy": 22,
                            "averageTime": 205
                        }
                    },
                    "user": "625cf594a6793b424f731f9f",
                    "experienceLevel": "INTERNSHIP",
                    "percentile": 100,
                    "analysis": {
                        "count": 2,
                        "communication": {
                            "ratings": {
                                "OVERALL": 64,
                                "WORD_CHOICE": 34,
                                "FLUENCY": 76,
                                "DELIVERY": 70,
                                "CONFIDENCE": 68
                            },
                            "features": [
                                {
                                    "category": "WORD_CHOICE",
                                    "subCategory": "HINDI_SHARE",
                                    "value": 0
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "PAUSES",
                                    "value": 86
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "DISFLUENCY",
                                    "value": 0
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "PACE",
                                    "value": 75
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "ENERGY",
                                    "value": 2
                                },
                                {
                                    "category": "WORD_CHOICE",
                                    "subCategory": "HINDI_SHARE",
                                    "value": 18
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "PAUSES",
                                    "value": 89
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "DISFLUENCY",
                                    "value": 0
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "PACE",
                                    "value": 106
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "ENERGY",
                                    "value": 2
                                }
                            ]
                        },
                        "content": {
                            "ratings": {
                                "OVERALL": 4,
                                "SIMILARITY": 0,
                                "RELEVANCE": 10,
                                "LOGIC_FLOW": 1
                            },
                            "features": []
                        }
                    },
                    "timeout": true,
                    "totalQuestions": 4,
                    "isActive": true,
                    "createdAt": "2023-02-15T09:25:48.309Z",
                    "updatedAt": "2023-04-14T05:49:52.305Z",
                    "__v": 0,
                    "attemptedQuestions": [
                        "63b6a1419a32b9cc5dab895d",
                        "63b6a1419a32b9cc5dab895e"
                    ],
                    "batch": "63a9264c8264c75f9b24eacb",
                    "type": "practice"
                },
                {
                    "proctoringRecords": [],
                    "_id": "63ec6472d97c2ec26ef57cf0",
                    "startedAt": "2023-02-15T04:49:54.458Z",
                    "finishedAt": "2023-02-15T04:52:42.963Z",
                    "interviewTemplate": {
                        "_id": "63b6a1419a32b9cc5dab895b",
                        "name": "Never Expiry Template",
                        "domain": "620b396840ff4f51ef8e5979",
                        "type": "practice",
                        "metadata": {
                            "numberOfQuestions": 4,
                            "testTime": 240,
                            "usedBy": 22,
                            "averageTime": 205
                        }
                    },
                    "user": "625cf594a6793b424f731f9f",
                    "experienceLevel": "INTERNSHIP",
                    "percentile": 25,
                    "analysis": {
                        "count": 1,
                        "communication": {
                            "ratings": {
                                "OVERALL": 36,
                                "WORD_CHOICE": 48,
                                "FLUENCY": 21,
                                "DELIVERY": 32,
                                "CONFIDENCE": 20
                            },
                            "features": [
                                {
                                    "category": "WORD_CHOICE",
                                    "subCategory": "HINDI_SHARE",
                                    "value": 0
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "PAUSES",
                                    "value": 90
                                },
                                {
                                    "category": "FLUENCY",
                                    "subCategory": "DISFLUENCY",
                                    "value": 4
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "PACE",
                                    "value": 42
                                },
                                {
                                    "category": "DELIVERY",
                                    "subCategory": "ENERGY",
                                    "value": 2
                                }
                            ]
                        },
                        "content": {
                            "ratings": {
                                "OVERALL": 0,
                                "SIMILARITY": 0,
                                "RELEVANCE": 0,
                                "LOGIC_FLOW": 0
                            },
                            "features": []
                        }
                    },
                    "timeout": false,
                    "totalQuestions": 4,
                    "isActive": true,
                    "createdAt": "2023-02-15T04:49:54.930Z",
                    "updatedAt": "2023-04-14T05:49:52.096Z",
                    "__v": 0,
                    "attemptedQuestions": [
                        "63b6a1419a32b9cc5dab895d"
                    ],
                    "batch": "63a9264c8264c75f9b24eacb",
                    "type": "practice"
                }
            ]
        },
    },
    {
        id: "assessments",
        title: "All Assessments",
        message: "Navigate through all assessments of all the states like finished, unfinished or expired.",
        action: "Finish",
        data: {
            assessments: [
                {
                    "communication": 50,
                    "content": 40,
                    "totalAttempts": 14,
                    "commChange": 10,
                    "contentChange": 6,
                    "attemptChange": 2,
                    "_id": "644369d88fd8ea0e77103377",
                    "templateName": "Assessment 3",
                    "type": "practice",
                    "publishOn": "2023-04-23T04:54:23.389Z",
                    "expiresOn": "2023-04-29T18:29:59.999Z",
                    "createdAt": "2023-04-22T05:00:08.911Z"
                },
                {
                    "communication": 70,
                    "content": 60,
                    "totalAttempts": 1,
                    "commChange": 7,
                    "contentChange": 12,
                    "attemptChange": 4,
                    "_id": "6421970396dfa0e1509cccda",
                    "templateName": "Assessment 2",
                    "type": "assignment",
                    "publishOn": "2023-03-28T13:15:30.538Z",
                    "expiresOn": "2023-04-03T18:29:59.999Z",
                    "createdAt": "2023-03-27T13:15:47.601Z"
                },
                {
                    "communication": 65,
                    "content": 45,
                    "totalAttempts": 1,
                    "commChange": 11,
                    "contentChange": 7,
                    "attemptChange": 0,
                    "_id": "64214ca040967282a24491d8",
                    "templateName": "Assessment 4",
                    "type": "assignment",
                    "publishOn": "2023-03-28T07:58:11.112Z",
                    "expiresOn": "2023-04-03T18:29:59.999Z",
                    "createdAt": "2023-03-27T07:58:24.717Z"
                },
                {
                    "communication": 75,
                    "content": 60,
                    "totalAttempts": 13,
                    "commChange": 7,
                    "contentChange": 4,
                    "attemptChange": 2,
                    "_id": "6421972496dfa0e1509cccf2",
                    "templateName": "Assessment 1",
                    "type": "practice",
                    "publishOn": "2022-09-02T05:06:08.410Z",
                    "expiresOn": "2022-09-29T05:06:08.410Z",
                    "createdAt": "2023-03-27T13:16:20.062Z"
                }
            ]
        }
    },
    {
      id: "replay-tutorial",
      title: "Replay Tutorial",
      message: "You can replay the tutorial for this page if you get stuck anywhere!",
      action: "Finish",
      data: {}
  }
];