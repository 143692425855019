import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';

import { calculateBarValue } from 'utils';

const useStyles = makeStyles(theme => ({
  figure: {
    width: '80%',
    position: 'relative',
  },
  yAxisHeading: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    top: '30%',
    left: '-50px',
  },
  barChartColorGradient: {
    '& tbody td': {
      backgroundImage: `linear-gradient(to Right, ${theme.palette.success['clr-100']} 33.3%, ${theme.palette.danger['clr-100']} 33.3%, ${theme.palette.danger['clr-100']} 100%)`,
    }
  },
  barChartColorGradient2: {
    '& tbody td': {
      backgroundImage: `linear-gradient(to Right, ${theme.palette.danger['clr-100']} 33.3%, ${theme.palette.success['clr-100']} 33.3%, ${theme.palette.success['clr-100']} 100%)`,
    }
  },
  barChartH: {
    tableLayout: 'fixed',
    width: '100%',
    overflowWrap: 'break-word',
    borderSpacing: 0,
    '& tbody tr.firstRow th, & tbody tr.firstRow td': {
      padding: '60px 0px 10px 0px',
    },
    '& tbody tr.lastRow th, & tbody tr.lastRow td': {
      padding: '10px 0px 20px 0px',
    },
    '& tbody td': {
      borderLeft: '2px solid',
      borderColor: theme.palette.neutral['clr-300'],
      '& span': {
        position: 'relative',
        display: 'block',
        height: '20px',
        background: '#99ffff',
        '& b': {
          position: 'absolute',
          left: '100%',
          top: 0,
          right: 'auto',
          bottom: 0,
          display: 'block',
          padding: '0 0 0 0.5rem',
          fontWeight: 'normal',
        },
      },
    },
    '& th.y-axis': {
      position: 'relative',
      paddingBottom: '20px',
      borderTop: '2px solid',
      borderColor: theme.palette.neutral['clr-300'],
    },
    '& ol.y-axis-labels': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      fontSize: '0.9rem',
      '& li.zero': {
        position: 'absolute',
        left: 0,
        right: 'auto',
        bottom: 'auto',
        top: 0,
        '& b': {
          transform: 'translate(-50%, 0%)',
        },
      },
      '& li': {
        flex: '1 0 0',
        textAlign: 'right',
        '& b': {
          display: 'inline-flex',
          transform: 'translate(50%, 0%)',
          fontWeight: 'normal',
        },
      },
    },
    '& .x-axis-heading': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
}));

const HorizontalGraph = ({
  range={min: 0, max: 5}, 
  value1=0, 
  value2=0
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { barValue1, barValue2, min, max } = React.useMemo(()=>{
    let min=0, max=range.max;
    if(range.min > 0){
      max= range.min;
      min=0;
    }
    return (
      {
        barValue1 : calculateBarValue(value1,max),
        barValue2 : calculateBarValue(value2,max),
        min: min,
        max: max
      }
    )
  },[value1, value2, range]);

  return (
    <figure className={classes.figure}>
      <Typography variant='body02-bold' className={classes.yAxisHeading}>Attempts</Typography>
      <table 
        className={
          `${classes.barChartH} ${range.min > 0 
            ? classes.barChartColorGradient2 : classes.barChartColorGradient}`
        }
      >
        <tbody>
          {/* Data Rows */}
          <tr className='firstRow'>
            <td>
              <span style={{ width: `${barValue1}%`, backgroundColor: theme.palette.primary['clr-300'] }}>
                <b>{value1}%</b>
              </span>
            </td>
          </tr>
          <tr className='lastRow'>
            <td>
              <span style={{ width: `${barValue2}%`, backgroundColor: theme.palette.secondary['clr-700'] }}>
                <b>{value2}%</b>
              </span>
            </td>
          </tr>

          {/* Y-axis */}
          <tr>
            <th className='y-axis'>
              <ol className='y-axis-labels'>
                <li className='zero'><b>0</b></li>
                <li><b>{max}</b></li>
                <li><b></b></li>
                <li><b>100</b></li>
              </ol>
            </th>
          </tr>
          <tr>
            <Typography variant='body02-bold' className='x-axis-heading'>
              {"Percentage(%)"}
            </Typography>
          </tr>
        </tbody>
      </table>
    </figure>
  );
};

export default HorizontalGraph;
