import * as yup from 'yup';
import React from 'react';
import Box from '@mui/material/Box';
import LoginShell from 'components/LoginShell';

import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { resetPassword } from 'services/auth';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { CustomLoadingButton } from 'components/CustomButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordField } from 'components/CustomFields';

const validationSchema = yup.object({
    password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Please provide a password'),
    confirmedPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "Does not match with above password")
        .required('Please provide a confirmed password'),
});

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex", alignItems: "center", justifyContent: "flex-end",
        width: "25vw", flexDirection: "row"
    },
    fieldsContainer: {
        width: '100%',
        marginTop: '3rem',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
}));

function ResetPassword() {
    const navigate = useNavigate();
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmedPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                await resetPassword(values.password, searchParams.get('at'));
                sessionStorage.setItem('password_reset', true);
                snackbar.success("Password Saved!");
                navigate('/guest/login');
            } catch (error) {
                console.error(error);
                snackbar.error(error.message);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
      <LoginShell title='Reset Your Password'>
        <form onSubmit={formik.handleSubmit} style={{ width: '80%' }}>
          <Box className={classes.fieldsContainer} >
              <PasswordField
                  id='password'
                  name='password'
                  label='Enter new password'
                  placeholder='xxxxxxx'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={(!!formik.errors.password) ? formik.errors.password : ''}
              />
              <PasswordField
                  id='confirmedPassword'
                  name='confirmedPassword'
                  label='Re-enter new password'
                  placeholder='xxxxxxx'
                  value={formik.values.confirmedPassword}
                  onChange={formik.handleChange}
                  error={(!!formik.errors.confirmedPassword) ? formik.errors.confirmedPassword : ''}
              />
              <Box width='fit-content' alignSelf='center'>
                  <CustomLoadingButton
                      type='submit'
                      loading={loading}
                      disabled={!formik.dirty || !formik.isValid}
                  >
                      Save
                  </CustomLoadingButton>
              </Box>
          </Box>
        </form>
      </LoginShell>
    );
}

export default ResetPassword;
