import React from 'react';
import MUIPagination from '@mui/material/Pagination';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaginationItem-root': {
      margin: theme.spacing(0,1),
      '&:hover': {
        backgroundColor: theme.palette.primary['clr-50'],
      }
    },
    '& .MuiPaginationItem-page': {
      backgroundColor: theme.palette.neutral['clr-100'],
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary['clr-300'],
      color: theme.palette.shades['clr-white-900'],
      '&:hover': {
        backgroundColor: `${theme.palette.primary['clr-300']} !important`,
      }
    },
  }
}));

const Pagination = ({ count, page, setPage }) => {
  const classes = useStyles();

  return (
    <MUIPagination
        size="medium"
        count={count}
        page={page + 1}
        onChange={(e, value) => setPage(value - 1)}
        variant="text"
        className={classes.root}
    />
  )
}

export default Pagination;