import Session from "../utils/Session";
import Client from "./_client";

export const restoreSession = async () => {
  const result = await new Client({
    path: "/auth/restore",
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to restore you session. Please sign in."
    );
  } else {
    Session.onRestoreSession(result.data);
    return result;
  }
};

export const verifyToken = async (token) => {
  const result = await new Client({
    path: "signin/verify-token",
    payload: { token },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
};

export const signOut = async () => {
  Session.clearSession();
};

export const signIn = async ({
  withGoogle, googleIdToken, email, password, client = null
}) => {
  let url = '/signin?';

  if (withGoogle) url += `withGoogle=true`;

  const result = await new Client({
    path: url,
    payload: {
      email,
      password,
      googleIdToken,
      client,
      role: 'STUDENT',
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  } else {
    Session.onCreateSession(result.data);
    return result.data;
  }
};


export const signupAndEnrollInBatch = async (usersData = {}) => {
  const result = await new Client({
    path: `/signup/enroll-in-batch`,
    payload: usersData,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get users. Please try again."
    );
  } else {
    Session.onCreateSession(result.data);
    return result?.data;
  }
};

export const signUpWithGoogle = async (googleIdToken) => {
  const result = await new Client({
    path: "/signup?withGoogle=true",
    payload: {
      googleIdToken: googleIdToken,
      role: 'BETA_USER'
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}

export const overwriteSession = async () => {

  const result = await new Client({
    path: `/auth/overwrite-session`,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  }

  localStorage?.setItem("languifySessionId", result?.data?.sessionId);
};

export const generateResetPasswordLink = async (email, clientId) => {
  const result = await new Client({
    path: "/auth/reset-password-link",
    payload: {
      email: email,
      redirectUrl: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
      clientId,
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  }
  return result.message;
}

export const resetPassword = async (password, token) => {
  const result = await new Client({
    path: "/auth/reset-password",
    payload: {
      token,
      password
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  }
  return result.message;
}

export const sendActivationLink = async (email) => {
  const result = await new Client({
    path: "/user/send-activation-link",
    payload: {
      email,
      activationUrl: process.env.REACT_APP_VERIFY_ACCOUNT_URL,
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  }
}

export const verifyAccount = async (token) => {
  const result = await new Client({
    path: `/signin/verify-account?at=${token}`
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}