import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import InfoMessage from 'components/InfoMessage';
import OnboadingHeader from 'components/OnboadingHeader';

import { verifyAccount } from 'services';

const useStyles = makeStyles(theme => ({
    container: {
        height: "100%",
        margin: '5em auto',
        overflow: 'hidden',
        maxWidth: "45vw",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: '70%'
        },
        [theme.breakpoints.down("sm")]: {
            padding: '1em',
            width: '90%'
        }
    },
    buttonDiv: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1em',
        [theme.breakpoints.down("sm")]: {
            '&>*': {
                width: '100%'
            },
            flexWrap: 'wrap'
        }
    },
    activationIcon: {
        color: '#41D7A1',
        fontSize: '5rem',
        margin: '0.3em auto',
        [theme.breakpoints.down("md")]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down("sm")]: {
            margin: '0.1em auto',
        }
    }
}));

export default function VerifyAccount() {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!searchParams.get('at'))
            navigate('/beta/signup');
        else {
            const verify = async () => {
                try {
                    await verifyAccount(searchParams.get('at'));
                    navigate('/beta/onboarding');
                }
                catch (error) {
                    setError(true);
                }
            }
            verify();
        }
    }, []);

    return (
        <>
            <OnboadingHeader />
            <Box elevation={3} className={classes.container}>
                {!error ? (
                    <>
                        <InfoMessage variant='info'>
                            Verifying your account, please wait...
                        </InfoMessage>
                        <CircularProgress sx={{ margin: '1em auto' }} />
                    </>
                ) : (
                    <InfoMessage variant='info'>
                        Unable to verify account, please generate another link.
                    </InfoMessage>
                )}
            </Box>
        </>
    )
}
