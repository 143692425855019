export const DocumentOptions = {
  JD_RESUME :{
    _id : 'JD_&_RESUME',
    name : 'Job Description and Resume',
    label: 'Create using Job Description & Resume',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/JD_RESUME_UPDATED.svg'
  },
  JD : {
    _id : 'JD',
    name : 'Job Description',
    label: 'Create using a Job Description',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/JD_CI.svg'
  },
  RESUME : {
    _id : 'RESUME',
    name : 'Resume',
    label: 'Create using a Resume',
    img:'https://languify-assets.s3.ap-south-1.amazonaws.com/images/RESUME_CI.svg'
  }
}

export const InterviewSteps = {
    JOB_DETAILS : {
      _id: 'JOB_DETAILS',
      name : 'Job Details'
    },
    SELECT_RESUME : {
      _id : 'SELECT_RESUME',
      name : 'Select Resume'
    },
    PREVIEW : {
      _id : 'PREVIEW',
      name : 'Preview'
    }
}