import Client from "./_client";

export const SubCategoryTips = {
  FLUENCY: "Quantitative assessment of the flow of speaking.",
  DELIVERY:
    "Quantitative assessment of the vocab delivery as perceived by a listener.",
  CONFIDENCE:
    "Quantitative assessment of the tonal confidence exhibited by the user, while taking in consideration their fluency and delivery.",
  WORD_CHOICE:
    "Quantitative assessment of the choice of words and phrases used by the speaker.",
  SIMILARITY:
    "Similarity score tells how close your answer was to the actual answer which is there in the template.",
  RELEVANCE:
    "Relevency score tell if your answer deviated from the actual answer in the template.",
  LOGIC_FLOW:
    "Logial flow checks the flow of your answer and gives your higher marks if you follow a good flow",
  VOCABULARY:
    "Vocabulary is all about words — the words in a language or a special set of words you are trying to learn.",
  ENGAGEMENT:
    "Vocabulary is all about words — the words in a language or a special set of words you are trying to learn.",
};

// const tempStarAnswers = [[
//   { "word": "A", "s": false, "t": false, "a": false, "r": false },
//   { "word": "paragraph", "s": false, "t": false, "a": false, "r": false },
//   { "word": "is", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   { "word": "collection", "s": false, "t": false, "a": false, "r": false },
//   { "word": "of", "s": false, "t": false, "a": false, "r": false },
//   { "word": "words", "s": false, "t": false, "a": false, "r": false },
//   { "word": "strung", "s": false, "t": false, "a": false, "r": false },
//   { "word": "together", "s": false, "t": false, "a": false, "r": false },
//   { "word": "to", "s": false, "t": false, "a": false, "r": "W" },
//   { "word": "make", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   { "word": "longer", "s": false, "t": false, "a": false, "r": false },
//   { "word": "unit", "s": false, "t": false, "a": false, "r": false },
//   { "word": "than", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   { "word": "sentence.", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "Several",

//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   {
//     "word": "sentences",

//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   { "word": "often", "t": false, "a": false, "r": false, "s": "S" },
//   { "word": "make", "t": false, "a": false, "r": false, "s": "S" },
//   {
//     "word": "to",

//     "t": false,
//     "a": false,

//     "s": "S",
//     "r": "W"
//   },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "paragraph.",
//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   { "word": "There", "s": false, "t": false, "a": false, "r": false },
//   { "word": "are", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "normally",
//     "s": false,
//     "a": false,
//     "r": false,
//     "t": "S"
//   },
//   { "word": "three", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "to",
//     "s": false,
//     "a": false,
//     "t": "S",
//     "r": "W"
//   },
//   { "word": "eight", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "sentences",
//     "s": false,
//     "a": false,
//     "r": false,
//     "t": "S"
//   },
//   { "word": "in", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "paragraph.",
//     "s": false,
//     "a": false,
//     "r": false,
//     "t": "S"
//   },
//   { "word": "Paragraphs", "s": false, "t": false, "a": false, "r": false },
//   { "word": "can", "s": false, "t": false, "a": false, "r": false },
//   { "word": "start", "s": false, "t": false, "a": false, "r": false },
//   { "word": "with", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   { "word": "five-space", "s": false, "t": false, "a": false, "r": false },
//   { "word": "indentation", "s": false, "t": false, "a": false, "r": false },
//   { "word": "or", "s": false, "t": false, "a": false, "r": false },
//   { "word": "by", "s": false, "t": false, "a": false, "r": false },
//   { "word": "skipping", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   { "word": "line", "s": false, "t": false, "a": false, "r": false },
//   { "word": "and", "s": false, "t": false, "a": false, "r": false },
//   { "word": "then", "s": false, "t": false, "a": false, "r": false },
//   { "word": "starting", "s": false, "t": false, "a": false, "r": false },
//   { "word": "over.", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "Paragraphs",
//     "s": false,
//     "t": false,
//     "r": false,
//     "a": "S"
//   },
//   { "word": "may", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "signal",
//     "s": false,
//     "t": false,
//     "r": false,
//     "a": "S"
//   },
//   { "word": "when", "s": false, "t": false, "a": false, "r": false },
//   { "word": "the", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "writer",
//     "s": false,
//     "t": false,
//     "r": false,
//     "a": "S"
//   },
//   {
//     "word": "changes",
//     "s": false,
//     "t": false,
//     "r": false,
//     "a": "S"
//   },
//   {
//     "word": "topics.",
//     "s": false,
//     "t": false,
//     "r": false,
//     "a": "S"
//   },
//   { "word": "Each", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "paragraph",
//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   { "word": "may", "s": false, "t": false, "a": false, "r": false },
//   { "word": "have", "s": false, "t": false, "a": false, "r": false },
//   { "word": "a", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "number",
//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   { "word": "of", "s": false, "t": false, "a": false, "r": false },
//   {
//     "word": "sentences,",
//     "t": false,
//     "a": false,
//     "r": false,
//     "s": "S"
//   },
//   {
//     "word": "depending",
//     "s": false,
//     "t": false,
//     "a": false,
//     "r": "W"
//   },
//   { "word": "on", "s": false, "t": false, "a": false, "r": false },
//   { "word": "the", "s": false, "t": false, "a": false, "r": false },
//   { "word": "topic.", "s": false, "t": false, "a": false, "r": false }
// ]];

export const getInterviewFeedbackByAttemptId = async (attemptId) => {
  let url = `/interviewAttempt/interviewSummary/${attemptId}`;

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get feedback. Please try again."
    );
  }

  // result?.data?.questions?.forEach(q => {
  //   q.starAnswers = tempStarAnswers;
  // });

  return result?.data;
};

export const getInteractiveDemoFeedbackByToken = async (accessToken) => {
  let url = `/interviewAttempt/interactive-demo/token?at=${accessToken}`;
  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get feedback. Please try again."
    );
  }

  // result?.data?.questions?.forEach(q => {
  //   q.starAnswers = tempStarAnswers;
  // });

  return result?.data;
};

export const getPersonalizedAnswer = async (answerId) => {
  const result = await new Client({
    path: `/interviewAnswer/${answerId}/personalized-answer`
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get feedback. Please try again."
    );
  }

  return result?.data;
};


export const getFeedbackAndAnswer = async (answerId, domain, forPathway) => {
  const result = await new Client({
    path: `/interviewAnswer/${answerId}/generate-feedback-n-answer`,
    payload: {
      domain,
      forPathway
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get feedback. Please try again."
    );
  }

  return result?.data;
};
