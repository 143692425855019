import React from "react";
import Session from "../utils/Session";

import { Navigate, useLocation } from "react-router-dom";

const TabLogoUrl = 'https://assets.languify.in/images/favicon-languify.ico';
const TabTitle = 'Languify | AI Prep';

function PrivateRoute({ component: RouteComponent, componentProps = {} }) {
  const location = useLocation();

  React.useEffect(() => {
    if (Session.isLoggedIn()) {

      const clientMetadata = Session.getClientMetadata() || {};
      const title = clientMetadata.tabTitle || TabTitle;
      const tabLogo = clientMetadata.tabLogoUrl || TabLogoUrl;

      window.document.getElementById('tab-title-languify').innerText = title;
      window.document.getElementById('tab-icon-languify').href = tabLogo;
    }
  }, [location]);

  return Session.isLoggedIn() ? (
    <RouteComponent {...componentProps} />) : (
    <Navigate to="/guest/login" />
  );
}

export default PrivateRoute;