import React, { useEffect, useRef } from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classNames from 'classnames';
import useStore from './store';
import { useSearchParams } from 'react-router-dom';
import { WhiteBoxHeader } from 'components/AppShell';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    questionButton: {
        boxShadow: '0px 2px 4px 0px #00000040',
        minWidth: '62px',
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
    },
    enableState: {
        backgroundColor: theme.palette.success['clr-100'],
        color: theme.palette.success['clr-700'],
        "&:hover": {
            color: theme.palette.shades['clr-white-900'],
            backgroundColor: theme.palette.success['clr-500'],
        }
    },
    errorState: {
        backgroundColor: theme.palette.danger['clr-100'],
        color: theme.palette.danger['clr-700'],
        "&:hover": {
            color: theme.palette.shades['clr-white-900'],
            backgroundColor: theme.palette.danger['clr-500'],
        }
    },
    activeEnableState: {
        color: theme.palette.shades['clr-white-900'],
        backgroundColor: theme.palette.success['clr-700'],
    },
    activeErrorState: {
        color: theme.palette.shades['clr-white-900'],
        backgroundColor: theme.palette.danger['clr-700'],
    },
    arrowButtonLeft: {
        boxShadow: '0px 2px 4px 0px #00000040',
        width: '36px',
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        position: 'absolute',
        left: 0,
        top: 0,
        "&:hover": {
            backgroundColor: theme.palette.primary['clr-50']
        },
        [theme.breakpoints.down(725)]: {
            display: 'none',
        }
    },
    arrowButtonRight: {
        boxShadow: '0px 2px 4px 0px #00000040',
        width: '36px',
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        position: 'absolute',
        right: 0,
        top: 0,
        "&:hover": {
            backgroundColor: theme.palette.primary['clr-50']
        },
        [theme.breakpoints.down(725)]: {
            display: 'none',
        }
    },
    queContainer: {
        display: 'flex',
        gap: '15px',
        width: 'fit-content',
        [theme.breakpoints.down(725)]: {
            flexWrap: 'wrap',
            gap: '8px',
        },
        position: 'relative'
    },
    questionsBox: {
        display: 'flex',
        gap: theme.spacing(4),
        margin: theme.spacing(0, 13),
        maxWidth: 'calc(100vw - 480px)',
        overflow: 'auto',
        padding: theme.spacing(0.5, 0),
        "&::-webkit-scrollbar": { display: "none", width: 0, height: 0 },
    }
}));

function Item({ active, index, disabled, error, onClick, itemRef }) {
    const classes = useStyles();

    const handleClick = () => {
        onClick();
        if (itemRef && itemRef.current) {
            itemRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'nearest',
            });
        }
    };

    const normalButton = classNames({
        [classes.questionButton]: true,
        [classes.enableState]: !active,
        [classes.activeEnableState]: active,
    });

    const errorButton = classNames({
        [classes.questionButton]: true,
        [classes.errorState]: !active,
        [classes.activeErrorState]: active,
    });

    return (
        <Box
            className={error ? errorButton : normalButton}
            onClick={handleClick}
            ref={itemRef}
        >
            <Typography variant='h5-medium' color='inherit'>
                Q{index + 1}
            </Typography>
        </Box>
    );
}

function TopNavigation(props) {
    const classes = useStyles();
    const next = useStore(state => state.next, shallow);
    const prev = useStore(state => state.prev, shallow);
    const questions = useStore(state => state.questions, shallow);
    const [currentQuestion, setCurrentQuestion] = useStore(state =>
        [state.currentQuestion, state.setCurrentQuestion]
        , shallow
    );
    const [searchParams] = useSearchParams();
    const viewQuestion = searchParams.get('question') || 0;
    const itemRefs = React.useRef([]);

    useEffect(() => {
        questions && questions.forEach((data, index) => {
            if (viewQuestion === data._id) {
                setCurrentQuestion(index);
            }
        });
    }, [viewQuestion]);

    const handleScrollToCurrent = (index) => {
        if (itemRefs.current[index]) {
            itemRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'nearest',
            });
        }
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            const nextQuestion = currentQuestion + 1;
            setCurrentQuestion(nextQuestion);
            handleScrollToCurrent(nextQuestion);
        }
    };

    const handlePrev = () => {
        if (currentQuestion > 0) {
            const prevQuestion = currentQuestion - 1;
            setCurrentQuestion(prevQuestion);
            handleScrollToCurrent(prevQuestion);
        }
    };

    return (
        <Box
            width="fit-content"
            borderRadius={3}
            mb={2}
            display="flex"
            flexDirection="column"
            gap='10px'
            id='question-specific-feedback-container'
        >
            <WhiteBoxHeader
                id='question-specific-feedback'
                heading='Question specific feedback'
                subheading='Question wise detailed feedback & improvement suggestions'
                className={classes}
            />

            <Box display='flex' alignItems='center' mt={4} mb={1} gap={1}>
                <Typography variant='h6-medium'>
                    Total questions :
                </Typography>
                <Typography variant='h6-medium' color='primary.clr-400'>
                    {(questions.length < 10) ? `0${questions.length}` : questions.length}
                </Typography>
            </Box>

            <Box className={classes.queContainer}>
                <Box className={classes.arrowButtonLeft} onClick={handlePrev} style={{ paddingLeft: '6px' }}>
                    <ArrowBackIosIcon fontSize='small' />
                </Box>
                <Box className={classes.questionsBox}>
                    {questions.map((x, index) => (
                        <Item
                            key={index}
                            index={index}
                            onClick={() => {
                                setCurrentQuestion(index);
                                handleScrollToCurrent(index);
                            }}
                            active={(index) === currentQuestion}
                            error={questions[index]?.isError}
                            itemRef={el => itemRefs.current[index] = el}
                        />
                    ))}
                </Box>
                <Box className={classes.arrowButtonRight} onClick={handleNext}>
                    <ArrowForwardIosIcon fontSize='small' />
                </Box>
            </Box>
        </Box>
    );
}

export default TopNavigation;
