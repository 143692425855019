import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import TutorialTip from './TutorialTip';

function Tutorial({ open, onSkip, tips }) {
    const [currentTip, setCurrentTip] = useState(0);

    const onHandleNext = () => {
        if (tips[currentTip + 1]) {
            setCurrentTip(currentTip + 1);
        }
        else
            onSkip(true);
    }

    const onHandleSkip = () => {
        onSkip(false);
    }

    return (
        <Backdrop open={open}
            sx={{
                color: 'red',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            {open && <TutorialTip
                number={currentTip + 1}
                tip={tips[currentTip]}
                total={tips.length}
                onSkip={onHandleSkip}
                onNext={onHandleNext}
            />}
        </Backdrop>
    )
}

export default Tutorial;
