import * as React from 'react';
import { styled } from '@mui/styles';
import MUIAutocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import TextField from 'components/TextField';
import { useServices } from 'contexts';
import { getFieldOptions } from 'services';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "8px",
        marginTop: 10,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: "#F1F2F6",
        padding: "0px 10px",

        [`& .${autocompleteClasses.groupUl}`]: {},
        [`& .${autocompleteClasses.groupLabel}`]: {
            backgroundColor: "#F1F2F6",
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            color: '#999999',
        },

        [`& .${autocompleteClasses.option}`]: {
            fontSize: 12,
            fontFamily: 'inter',
            fontWeight: 500,
            margin: "10px 0px",
            "&:hover": {
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
            },
            '&[aria-selected="true"]': {},
            [`&.${autocompleteClasses.focused},
       &.${autocompleteClasses.focused}[aria-selected="true"]`]: {},
        }
    },
});

const defaultOption = { id: 'na', option: 'NA' };

export function AutoComplete({
    defaultValue = 'na',
    options = [],
    value,
    onChange = () => { },
    size = 250,
    label = '',
    sx={},
    ...props
}) {
    const [_value, setValue] = React.useState(defaultValue);

    const handleChange = (e, newValue) => {
        onChange(e, newValue);
        setValue(newValue?.id);
    };

    React.useEffect(()=>{
      setValue(defaultValue);
    },[defaultValue])

    return (
        <MUIAutocomplete
            options={options}
            value={options.find((option) => (
                value || _value) !== undefined &&
                option?.id === (value || _value)) || null
            }
            PopperComponent={StyledPopper}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.option || option.label}
            sx={{ width: size, ...sx }}
            size='small'
            onChange={handleChange}
            renderInput={(params) =>
                <TextField {...params}
                    label={label}
                    onKeyDown={(e) => e.stopPropagation()}
                    style={{ background: '#F1F2F6', }}
                />
            }
            {...props}
        />
    );
}

export function FieldAutoComplete({
    name,
    defaultValue,
    readOnly,
    startIcon = false,
    endIcon = false,
    addDefaultOption = false,
    onChange = () => { },
    defaultOptions = [],
    ...props
}) {
    const service = useServices();

    const [value, setValue] = React.useState(null);

    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        if (defaultOptions.length > 0) setOptions(defaultOptions);
        else {
            (async () => {
                const options = addDefaultOption ? [{ ...defaultOption, field: name }] : [];
                if (name) {
                    const {
                        fieldOptions
                    } = await service.callService(getFieldOptions, { field: name });
                    fieldOptions.push({ _id: 'other', option: "other", field: name });

                    options.push(...fieldOptions);

                }

                setOptions(options);
            })();
        }
    }, [name]);

    React.useEffect(() => {
        const option = options.find(o => o._id === defaultValue);

        setValue(option || "na");
    }, [options, defaultValue]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue._id !== defaultValue) onChange(event, newValue._id);
    }

    return readOnly ? (
        <Typography variant="subtitle1" color="#02569D">
            {options.find((option) => option._id === value)?.option || "Select"}
        </Typography >
    ) : (
        <AutoComplete
            value={value}
            options={options}
            onChange={handleChange}
            getOptionLabel={(option) => option?.option || ""}
            startIcon={startIcon}
            endIcon={endIcon}
            {...props}
        />
    );
}

export default AutoComplete;