import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

import CustomButton from 'components/CustomButton';
import GoogleSignButton from 'components/GoogleSignButton';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { sendActivationLink } from 'services/auth';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: '2em',
        borderRadius: 8,
        overflow: 'hidden',
        maxWidth: 550,
        [theme.breakpoints.down("md")]: {
            width: '70%'
        },
        [theme.breakpoints.down("sm")]: {
            padding: '1em',
            width: '90%'
        }
    },
    buttonDiv: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '1em',
        [theme.breakpoints.down("sm")]: {
            '&>*': {
                width: '100%'
            },
            flexWrap: 'wrap'
        }
    },
    activationIcon: {
        color: '#41D7A1',
        fontSize: '5rem',
        margin: '0.3em auto',
        [theme.breakpoints.down("md")]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down("sm")]: {
            margin: '0.1em auto',
        }
    }
}));

function Activation() {
    const classes = useStyles();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const email = sessionStorage.getItem('email');

    useEffect(() => {
        if (!email)
            navigate('/signup');
    }, [email, navigate]);

    const handleResendLink = async () => {
        try {
            await sendActivationLink(email);
            snackbar.success('Account Activation Link Sent');
        } catch (error) {
            snackbar.error('Unable to send Activation Link');
        }
    }

    const handleSignUpUsingAnotherEmail = () => {
        navigate('/beta/register')
    }

    return (
        <Paper elevation={3} className={classes.container}>
            <Typography variant='h3' textAlign={"center"}>
                Activation link sent!
            </Typography>

            <Typography
                variant='h6'
                fontWeight={400}
                color='#666666'
                margin='1em 0'
            >
                A link has been sent to your registered email{" "}
                <b>
                    <i>
                        {`${email?.substring(0, 5)}******@${email?.split('@')[1]}`}.
                    </i>
                </b>
                {" "}If you did not receive the email, please check spam, or junk folder.
            </Typography>
            <Box textAlign='center'>
                <CheckCircleTwoToneIcon className={classes.activationIcon} />
            </Box>
            <Typography
                variant='h6'
                fontWeight={400}
                color='#666666'
                margin='1em 0'
                textAlign='left'
            >
                Didn’t receive activation link?
            </Typography>
            <Box className={classes.buttonDiv}>
                <CustomButton
                    variant='contained'
                    onClick={handleResendLink}
                    width='50%'
                >
                    Resend Link
                </CustomButton>
                <CustomButton
                    variant='text'
                    width='50%'
                    onClick={handleSignUpUsingAnotherEmail}
                >
                    Signup using another email.
                </CustomButton>
            </Box>

            <Box display='flex' alignItems='center' margin='1.5em 0' >
                <hr width='40%' height={1} />
                <Typography
                    variant='h6'
                    fontWeight={400}
                    color='#CCCCCC'
                >
                    OR
                </Typography>
                <hr width='40%' height={1} />
            </Box>
            <GoogleSignButton
                buttonText='Sign up using Google'
            // handleGoogleAuthResponse={}
            />
            <Box
                margin='1.5em -2em -2em -2em'
                height={10}
                backgroundColor='#41D7A1'
            />
        </Paper>
    );
}

export default Activation;
