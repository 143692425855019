import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";

import ConfirmationDialog from 'dialogs/ConfirmationDialog';
import useAssessmentStore from "./assessmentStore";
import { AssessmentStates } from './constants';

const useStyles = makeStyles(theme=>({
  endInterviewButton: {
    borderRadius: 1,
    padding: theme.spacing(3,6),
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    cursor: "pointer", gap: theme.spacing(1),
    transition: "all 200ms",
  },
  popupButton: {
    animation: "$zoomInOut 1.7s infinite",
  },
  "@keyframes zoomInOut": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)", 
    },
  },
  EndButton: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 0px 4px 0px rgba(2, 86, 157, 0.5)',
    "&:hover": {
      backgroundColor: theme.palette.danger['clr-100'],
      outline: '1px solid',
      outlineColor: theme.palette.danger['clr-700'],
      '&> *':{
        color: theme.palette.danger['clr-700'],
      },
      '.btnContent': {
        color: theme.palette.shades['clr-white-900']
      }
    }
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.shades['clr-white-900'],
    '&> *':{
      color: theme.palette.shades['clr-white-900'],
    },
    "&:hover": {
      backgroundColor: theme.palette.primary['clr-400'],
      outline: '1px solid',
      outlineColor: theme.palette.primary.main,
      '&> *':{
        color: theme.palette.shades['clr-white-900'],
      },
    }
  },
  disabledEnd: {
    backgroundColor: theme.palette.neutral['clr-200'],
    outline: '1px solid',
    '&> *':{
      color: theme.palette.neutral['clr-600'],
    },
    "&:hover": {
      backgroundColor: theme.palette.neutral['clr-200'],
      outlineColor: theme.palette.neutral['clr-700'],
      '&> *': {
        color: theme.palette.neutral['clr-600'],
      }
    },
  }
}));

function EndInterview({ assessmentState }) {
  const classes = useStyles();

  const endAssessment = useAssessmentStore(state => state.endAssessment);
  const questionsError = useAssessmentStore(state => state.questionsError);
  const uploadStates = useAssessmentStore(state => state.uploadStates);
  const questionState = useAssessmentStore(state => state.questionState);
  const questions = useAssessmentStore(state => state.questions);
  const [openDialog, setOpenDialog] = React.useState(false);

  const show = React.useMemo(() => [
    AssessmentStates.RUNNING, AssessmentStates.SHOWING_TUTORIAL
  ].includes(assessmentState),
    [assessmentState]
  );

  const toggleDialogOpen = () => setOpenDialog(!openDialog);

  const disabledEnd = React.useMemo(() => {
    return (
      questionState === "RECORDING" ||
      uploadStates.find(x => x === true)
    );
  }, [questionState, uploadStates]);

  const withErrors = questionsError.filter(e => e).length;
  const unattempted = questions.filter(q => !q.submitted).length;

  const dialogMessage = React.useMemo(() => {
    if (!openDialog) return "";

    if (withErrors > 0)
      return <>{withErrors} answer(s) are not recorded properly, 
            <br/>Do you still want to submit?`</>;

    if (unattempted > 0)
      return (<>
        You have not attempted {unattempted} question(s).<br />
        {unattempted === questions.length && (<>
          No answers recorded, this attempt will not be considered.
          <br />
        </>
        )}
        Do you still want to submit?
      </>);

    return <>Great! You have answered all the question. <br/>Are you sure to end this interview?</>;
  }, [openDialog, withErrors, unattempted, questions]);

  return show ? (
    <Tooltip
      arrow
      title={uploadStates.find(x => x === true) ? "Please wait, while we are saving your answers" : ""}
    >
      <Box>
        {((withErrors + unattempted) > 0) ?
          <Box
            className={`${classes.endInterviewButton} ${classes.EndButton} ${disabledEnd ? classes.disabledEnd : null}`}
            onClick={disabledEnd ? null : toggleDialogOpen}
            style={disabledEnd ? { cursor: "not-allowed", } : {}}
            id='last-tip-assessment'
          >
            <CloseIcon color={'primary.main' } fontSize="small" className='btnContent' />
            <Typography variant='h6-medium' color={ disabledEnd ? 'neutral.clr-600' : 'primary.main'} className='btnContent'>
              End Session
            </Typography>
          </Box>
          :
          <Box
            className={
              `
                ${classes.endInterviewButton} ${classes.submitButton} 
                ${disabledEnd ? classes.disabledEnd : null} 
                ${((unattempted+withErrors) === 0 && !disabledEnd )? classes.popupButton : null}
              `
            }
            onClick={disabledEnd ? null : toggleDialogOpen}
            style={disabledEnd ? { cursor: "not-allowed", } : {}}
          >
            <img width='18px' height='18px' src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/submitassessment_icon.svg' alt='sumit button' />
            <Typography variant='h6-medium' color='primary.main' className='btnContent'>
              Submit Assessment
            </Typography>
          </Box>
        }
        <ConfirmationDialog
          open={openDialog}
          message={dialogMessage}
          onSecondaryAction={(withErrors + unattempted > 0) ? () => endAssessment(false) : toggleDialogOpen}
          onPrimaryAction={(withErrors + unattempted > 0) ? toggleDialogOpen : () => endAssessment(false)}
          onClose={toggleDialogOpen}
          primaryAction={(withErrors + unattempted > 0) ? "Go Back" : "Yes"}
          secondaryAction={(withErrors + unattempted > 0) ? "Yes" : "No"}
          primaryActionVariant="error"
          title="Finish Interview"
        />
      </Box>
    </Tooltip>
  ) : null;
}

export default EndInterview;