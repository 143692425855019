import Client from "./_client";

export const getDomains = async (domainFilters = {}) => {
  let url = "/interviewDomain";

  Object.keys(domainFilters).forEach((key) => {
    if (domainFilters[key]) url += `${key}=${domainFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get domains. Please try again."
    );
  }

  return result?.data;
};
