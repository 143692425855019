import { Session } from "utils";
import Client from "./_client";

export const getRandomQuestionsByTemplateId = async (templateId) => {
  const url = `/interviewQuestion/random/${templateId}?interviewTime=${1800}`;

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get the questions. Please try again."
    );
  }

  return result?.data;
};

export const generateFollowupQuestions = async (templates = []) => {
  const url = `/interviewQuestion/generate/followup-questions`;

  const result = await new Client({
    path: url,
    payload: {
      studentId: Session.userId,
      templates,
      performanceWeightage: Session?.getClientMetadata()?.performanceWeightage
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get the questions. Please try again."
    );
  }

  return result?.data;
};

export default getRandomQuestionsByTemplateId;
