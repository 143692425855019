import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import OnboadingHeader from 'components/OnboadingHeader';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        padding: "2em",
        borderRadius: 8,
        maxWidth: 800,
        width: '60%',
        [theme.breakpoints.down("md")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1em",
            width: "90%",
        },
    },
    goBackButton: {
        marginLeft: -1,
        marginTop: "1em"
    },
    readMoreText: {
        cursor: "pointer",
        color: "#02569D"
    }
}));

function PrivacyPolicy() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [readMoreClick, setReadMoreClick] = useState(false);

    const handleGoBack = () => {
        navigate(-1);
    }

    const handleReadMoreClick = () => {
        setReadMoreClick(true);
    }

    return (
        <>
            <OnboadingHeader />
            <Box className={classes.container}>
                <Typography variant="h6" mb="0.5em">
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1" >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer nec, tempus nunc praesent arcu quisque. Odio imperdiet leo
                    venenatis volutpat scelerisque cras etiam. Neque nam sed amet accumsan.
                    Tempus tempus sed in mattis. Mattis vel pellentesque augue massa mauris
                    mauris. Cursus aenean in sed cursus eu integer.
                    Pellentesque faucibus quam nam blandit semper praesent arcu.
                    Sed lorem a volutpat in rhoncus a, turpis consectetur sed.
                    Aliquet aliquet leo sed ultricies sed integer in magna laoreet.
                    Viverra velit sed laoreet quis sed diam semper leo. Sed duis varius
                    tempus habitasse magna. Amet, maecenas elementum amet commodo.
                    Sed nunc enim ut id porta. Eu arcu et nam sed pulvinar.
                </Typography>
                {!readMoreClick ? (<Typography
                    variant="body1"
                    onClick={handleReadMoreClick}
                    className={classes.readMoreText}
                >
                    Read more
                </Typography>) :
                    (<Typography variant="subtitle1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer nec, tempus nunc praesent arcu quisque. Odio imperdiet leo
                        venenatis volutpat scelerisque cras etiam. Neque nam sed amet accumsan.
                        Tempus tempus sed in mattis. Mattis vel pellentesque augue massa mauris
                        mauris. Cursus aenean in sed cursus eu integer.
                        Pellentesque faucibus quam nam blandit semper praesent arcu.
                        Sed lorem a volutpat in rhoncus a, turpis consectetur sed.
                        Aliquet aliquet leo sed ultricies sed integer in magna laoreet.
                        Viverra velit sed laoreet quis sed diam semper leo. Sed duis varius
                        tempus habitasse magna. Amet, maecenas elementum amet commodo.
                        Sed nunc enim ut id porta. Eu arcu et nam sed pulvinar.
                    </Typography>)}
                <CustomButton
                    variant="text"
                    onClick={handleGoBack}
                    className={classes.goBackButton}
                >
                    Go Back
                </CustomButton>
            </Box>
        </>
    );
}

export default PrivacyPolicy;
