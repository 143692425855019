import React from "react";
import { useNavigate } from "react-router-dom";

import ConfirmationDialog from "dialogs/ConfirmationDialog";

import { useLoading } from "./LoadingProvider";

import { overwriteSession } from "services/auth";

import Session from "utils/Session";
import theme from "theme";

export const SingleSessionProviderContext = React.createContext({
  _sessionDialog: false,
  _setSessionDialog: () => { },
  _browserInfo: "",
  _setBrowserInfo: (value) => { },
  _showDuplicateSessionDialog: (message, onConfirm, onCancel) => { },
});

export const SingleSessionProvider = ({ children }) => {
  const [_sessionDialog, _setSessionDialog] = React.useState(false);
  const [_browserInfo, _setBrowserInfo] = React.useState("");
  const [callbacks, setCallbacks] = React.useState({
    onConfirm: () => {
      console.log("DEFAULT");
      _setSessionDialog(false);
    },
    onCancel: () => {
      console.log("DEFAULT");
      _setSessionDialog(false);
    },
  });

  const _showDuplicateSessionDialog = (message, onConfirm, onCancel) => {
    _setSessionDialog(true);
    _setBrowserInfo(message);
    setCallbacks({
      onConfirm: () => {
        _setSessionDialog(false);
        onConfirm();
      },
      onCancel: () => {
        _setSessionDialog(false);
        onCancel();
      },
    });
  };

  return (
    <SingleSessionProviderContext.Provider
      value={{
        _sessionDialog,
        _setSessionDialog,
        _browserInfo,
        _setBrowserInfo,
        _showDuplicateSessionDialog,
      }}
    >
      <ConfirmationDialog
        open={_sessionDialog}
        title={<span style={{ color: theme.palette.danger.main}}>Duplicate Session</span>}
        message={_browserInfo}
        onSecondaryAction={callbacks.onCancel}
        onPrimaryAction={callbacks.onConfirm}
        primaryAction="Yes"
        secondaryAction="No"
        primaryActionVariant="error"
      />

      {children}
    </SingleSessionProviderContext.Provider>
  );
};

export const useServices = () => {
  const { _showDuplicateSessionDialog } = React.useContext(
    SingleSessionProviderContext
  );
  const navigate = useNavigate();
  const loading = useLoading();

  const handleDialogClosed = () => {
    Session.clearSession();
    loading.hide();
    navigate("/error");
  };

  const callService = async (service, ...params) => {
    return await new Promise(async (resolve, reject) => {
      try {

        const result = await service(...params);

        if (result?.name === "DuplicateSession") {
          loading.hide();
          _showDuplicateSessionDialog(
            `Already logged in on ${result.browser}, ${result.os}.
            Do you wish to login here?`,
            async () => {
              await overwriteSession();

              const result = await service(...params);

              resolve(result);
            },
            handleDialogClosed
          );
        } else {
          resolve(result);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  return { callService };
};
