import React from 'react';
import { debounce } from 'lodash';
import { useField } from 'formik';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import MUITextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "8px 0",
    borderRadius: "4px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "12px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "4px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: "#E4E3E8",
      borderRadius: "4px",
      fontSize: 12,
      fontWeight: "normal",
      '& fieldset': {
        borderRadius: "4px",
        borderColor: "transparent",
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "4px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  },
  largeFieldRoot: {
    fontSize: 15,
    fontWeight: 600,
    borderRadius: "4px",
    boxShadow: '0px 0px 4px 0px #00000040',
    "& .MuiOutlinedInput-input": {
      fontSize: '15px !important',
      fontWeight: 600,
      height: 16,
      borderRadius: '4px',
      backgroundColor: "white !important",
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        WebkitTextFillColor: 'black',
        WebkitTextSizeAdjust: 'inherit',
      },
    },
    "& .MuiInputLabel-root": {
      fontSize: "15px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 15,
      fontWeight: 600,
      borderRadius: "4px",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      height: 50,
      backgroundColor: "white",
      borderRadius: "4px",
      fontSize: 15,
      fontWeight: 600,
      '& fieldset': {
        borderRadius: "4px",
        borderColor: "transparent",
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: 15,
      },
      '&:hover fieldset': {
        border: '3px solid #21649D',
        borderRadius: "4px",
        fontSize: 15,
        fontWeight: 600,
      },
      '&.Mui-focused fieldset': {
        borderRadius: "4px",
        fontSize: 15,
        fontWeight: 600,
        border: 'none',
        borderBottom: '4px solid #02569D',
      },
    },
  },
  largeFieldLabel: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0.5,
    color: 'black',
    marginBottom: 6,
  },
  error: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
    color: '#E51300',
    marginTop: 5,
  }
}));

export function ShadowTextField(props) {
  const classes = useStyles();

  return (<Box width="100%">
    <Typography variant="body1" marginTop="1em">
      {props.title}
    </Typography>
    <MUITextField
      className={classes.root}
      variant="filled"
      fullWidth
      InputProps={{ disableUnderline: true }}
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        ...(props.sx ? { ...props.sx } : {})
      }}
      {...props}
    />
  </Box>
  );
}

export function DebouncedTextField({
  onChange,
  startIcon,
  endIcon,
  InputProps,
  delay = 500,
  ...props
}) {
  const handleInput = debounce(onChange, delay);

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: startIcon ? (
          <SearchOutlinedIcon />
        ) : null,
        endAdornment: endIcon ? (
          <SearchOutlinedIcon />
        ) : null,
      }}
      onInput={handleInput}
    />
  );
}

export function FormTextField(props) {
  const [field, meta] = useField(props);

  return (
    <TextField
      error={!!(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{ ...field, ...props.InputProps }}
      {...props}
    />
  );
}

export default function TextField(props) {
  const classes = useStyles();

  return (
    <>
      <MUITextField
        className={classes.root}
        size='small'
        variant="outlined"
        spellCheck="false"
        fullWidth
        sx={{
          minWidth: 180,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        {...props}
      />
    </>
  );
}

export function LargeTextField(props) {
  const classes = useStyles();

  return (
    <Box >
      <Typography className={classes.largeFieldLabel}>
        {props.label || ''}
      </Typography>
      <MUITextField
        className={classes.largeFieldRoot}
        variant="outlined"
        spellCheck="false"
        placeholder={props.placeholder || 'placeholder'}
        fullWidth
        sx={{
          minWidth: 180,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        {...props}
        label=''
      />
      {props.error ?
        <Typography className={classes.error}>
          {props.error}
        </Typography> :
        null}
    </Box>
  );
}