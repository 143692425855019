import React, { useRef } from "react";

const TWO_PI = Math.PI * 2;
const HALF_PI = Math.PI * 0.5;
let viewWidth = 400, viewHeight = 400, ctx, timeStep = 1 / 60;
let particles = [], loader, exploader, phase = 0;

class Point {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }
}

class Particle {
  constructor(p0, p1, p2, p3) {
    this.p0 = p0;
    this.p1 = p1;
    this.p2 = p2;
    this.p3 = p3;

    this.time = 0;
    this.duration = 3 + Math.random() * 2;
    this.color = "#" + Math.floor(Math.random() * 0xffffff).toString(16);

    this.w = 2;
    this.h = 2;

    this.complete = false;
    this.x = 0; // Added for clarity, but make sure to set this properly during usage
    this.y = 0; // Added for clarity, but make sure to set this properly during usage
    this.r = 0; // Added for clarity, but make sure to set this properly during usage
    this.sy = 0; // Added for clarity, but make sure to set this properly during usage
  }

  update() {
    this.time = Math.min(this.duration, this.time + timeStep); // Assuming timeStep is defined elsewhere

    var f = Ease.outCubic(this.time, 0, 1, this.duration); // Assuming Ease and cubeBezier functions are defined elsewhere
    var p = cubeBezier(this.p0, this.p1, this.p2, this.p3, f); // Assuming cubeBezier function is defined elsewhere

    var dx = p.x - this.x;
    var dy = p.y - this.y;

    this.r = Math.atan2(dy, dx) + HALF_PI; // Assuming HALF_PI is defined elsewhere
    this.sy = Math.sin(Math.PI * f * 10);
    this.x = p.x;
    this.y = p.y;

    this.complete = this.time === this.duration;
  }

  draw() {
    ctx.save(); // Assuming ctx is defined elsewhere
    ctx.translate(this.x, this.y);
    ctx.rotate(this.r);
    ctx.scale(1, this.sy);

    ctx.fillStyle = this.color;
    ctx.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);

    ctx.restore();
  }
}

class Loader {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.r = 24;
    this._progress = 0;
    this.complete = false;
  }

  reset() {
    this._progress = 0;
    this.complete = false;
  }

  set progress(p) {
    this._progress = p < 0 ? 0 : p > 1 ? 1 : p;
    this.complete = this._progress === 1;
  }

  get progress() {
    return this._progress;
  }

  draw() {
    ctx.fillStyle = "transparent"; // Assuming ctx is defined elsewhere
    ctx.beginPath();
    ctx.arc(
      this.x,
      this.y,
      this.r,
      -HALF_PI,
      TWO_PI * this._progress - HALF_PI
    ); // Assuming HALF_PI and TWO_PI are defined elsewhere
    ctx.lineTo(this.x, this.y);
    ctx.closePath();
    ctx.fill();
  }
}

class Exploader {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.startRadius = 24;
    this.time = 0;
    this.duration = 0.4;
    this.progress = 0;
    this.complete = false;
  }

  reset() {
    this.time = 0;
    this.progress = 0;
    this.complete = false;
  }

  update() {
    this.time = Math.min(this.duration, this.time + timeStep); // Assuming timeStep is defined elsewhere
    this.progress = Ease.inBack(this.time, 0, 1, this.duration); // Assuming Ease.inBack is defined elsewhere
    this.complete = this.time === this.duration;
  }

  draw() {
    ctx.fillStyle = "transparent"; // Assuming ctx is defined elsewhere
    ctx.beginPath();
    ctx.arc(
      this.x,
      this.y,
      this.startRadius * (1 - this.progress),
      0,
      TWO_PI
    ); // Assuming TWO_PI is defined elsewhere
    ctx.fill();
  }
}

const Ease = {
  inCubic: function (t, b, c, d) {
    t /= d;
    return c * t * t * t + b;
  },
  outCubic: function (t, b, c, d) {
    t /= d;
    t--;
    return c * (t * t * t + 1) + b;
  },
  inOutCubic: function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  },
  inBack: function (t, b, c, d, s) {
    s = s || 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
  },
};

function cubeBezier(p0, c0, c1, p1, t) {
  let p = new Point();
  let nt = 1 - t;

  p.x =
    nt * nt * nt * p0.x +
    3 * nt * nt * t * c0.x +
    3 * nt * t * t * c1.x +
    t * t * t * p1.x;
  p.y =
    nt * nt * nt * p0.y +
    3 * nt * nt * t * c0.y +
    3 * nt * t * t * c1.y +
    t * t * t * p1.y;

  return p;
}

export default function CelebrationAnimation({ start = false, children }) {
  const canvasRef = useRef(null);

  function initDrawingCanvas() {
    viewWidth = canvasRef.current.width;
    viewHeight = canvasRef.current.height;
    ctx = canvasRef.current.getContext("2d");

    createLoader();
    createExploader();
    createParticles();
  }

  function createLoader() {
    loader = new Loader(viewWidth * 0.5, viewHeight * 0.5);
  }

  function createExploader() {
    exploader = new Exploader(viewWidth * 0.5, viewHeight * 0.5);
  }

  function createParticles() {
    for (var i = 0; i < 128; i++) {
      var p0 = new Point(viewWidth * 0.5, viewHeight * 0.5);
      var p1 = new Point(Math.random() * viewWidth, Math.random() * viewHeight);
      var p2 = new Point(Math.random() * viewWidth, Math.random() * viewHeight);
      var p3 = new Point(Math.random() * viewWidth, viewHeight + 64);

      particles.push(new Particle(p0, p1, p2, p3));
    }
  }

  function update() {
    switch (phase) {
      case 0:
        loader.progress += 1 / 45;
        break;
      case 1:
        exploader.update();
        break;
      case 2:
        particles.forEach(function (p) {
          p.update();
        });
        break;
      default:
        console.error("Invalid update phase value:", phase);
        break;
    }
  }

  function draw() {
    ctx.clearRect(0, 0, viewWidth, viewHeight);

    switch (phase) {
      case 0:
        loader.draw();
        break;
      case 1:
        exploader.draw();
        break;
      case 2:
        particles.forEach(function (p) {
          p.draw();
        });
        break;
      default:
        console.error("Invalid draw phase value:", phase);
        break;
    }
  }

  function loop() {
    update();
    draw();

    if (phase === 0 && loader.complete) {
      phase = 1;
    } else if (phase === 1 && exploader.complete) {
      phase = 2;
    } else if (phase === 2 && checkParticlesComplete()) {
      // reset
      phase = 0;
      loader.reset();
      exploader.reset();
      particles.length = 0;
      createParticles();
    }

    requestAnimationFrame(loop);
  }

  function checkParticlesComplete() {
    for (var i = 0; i < particles.length; i++) {
      if (particles[i].complete === false) return false;
    }
    return true;
  }

  React.useEffect(() => {
    if (start) {
      initDrawingCanvas();
      requestAnimationFrame(loop);
    }
  }, [start]);

  return (
    <>
      <div
        style={{
          border: '1px solid black',
          width: 'fit-content',
          height: 'fit-content',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <canvas
          id="celeb-canvas" ref={canvasRef}
          style={{
            width: '100%', height: '100%',
            position: 'absolute', top: 0, left: 0, overflow: 'hidden', zIndex: 10
          }}
        >
        </canvas>
        {children}
      </div>
    </>
  );
}