import { Route, Routes, Outlet } from "react-router-dom";
import React from 'react';
import BlogPage from "components/BlogPage";
import BlogDetails from "components/BlogPage/BlogDetails";

export function Blog() {
    return (
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<BlogPage />} />
          <Route
            path="/:blogId"
            element={
              <React.Suspense fallback={<>...</>}>
                  <BlogDetails />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    );
}
export default Blog;