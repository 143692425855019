import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "components/TextField";
import PasswordField from "components/PasswordField";
import { useLoading } from "contexts/LoadingProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import { useServices } from "contexts/SingleSessionProvider";

import CustomButton from "components/CustomButton";

import { signupWithToken, signup } from "../services/signupService";
import { restoreSession } from "../services/auth";

import Session from "utils/Session";

import useNavigateWithClient from "hooks/useNavigateWithClient";
import OnboadingHeader from "components/OnboadingHeader";

const SignupValidationSchema = Yup.object({
    name: Yup.string("Enter your name").required("Required"),
    email: Yup
        .string("Enter your email")
        .email("Invalid email")
        .required("Required"),
    password: Yup
        .string('New password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

const useStyles = makeStyles((theme) => ({
    rightContainer: {
        height: "100%",
        width: "50%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "25vw",
        flexDirection: "column"
    },
    googleImg: {
        cursor: "pointer",
    },
    image: {
        height: "75%",
        margin: "auto",
        [theme.breakpoints.down(725)]: {
            position: "relative",
            height: "100%",
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
            maxHeight: 330
        }
    },
}));

function Registration({ checkToken }) {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const navigateWithClient = useNavigateWithClient();
    const loading = useLoading();
    const snackbar = useSnackbar();
    const service = useServices();


    const [clientToken, setClientToken] = React.useState("");

    const goToWelcome = () => navigateWithClient("/home");

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            name: ""
        },
        validationSchema: SignupValidationSchema,
        onSubmit: async (values) => {
            try {
                loading.show();

                const signupData = {
                    name: values.name,
                    email: values.email,
                    password: values.password,
                };

                if (!!clientToken) {
                    signupData.clientToken = clientToken;

                    await signupWithToken(signupData);

                    localStorage?.setItem("isSignedUp", true.toString());

                    navigateWithClient('/onboarding');
                } else {
                    signupData.activationUrl = process.env.REACT_APP_VERIFY_ACCOUNT_URL;

                    await signup(signupData);

                    sessionStorage.setItem("email", values.email);

                    navigate("/beta/activation");
                }
            } catch (error) {
                console.log(error);
                snackbar.error(error.message);
            } finally {
                loading.hide();
            }
        },
    });

    React.useEffect(() => {
        (async () => {
            try {
                loading.show();

                if (Session.isLoggedIn()) {
                    goToWelcome();
                    return;
                }

                try {
                    console.log("DEBUG::Trying to restore session");
                    await service.callService(restoreSession);
                    goToWelcome();
                    return;
                } catch (error) {
                    console.log("DEBUG::Failed to Restore session");
                }

                const clientToken = searchParams.get("ct");

                if (checkToken && !clientToken) {
                    navigate("/error");
                    return;
                }

                if (localStorage?.getItem("isSignedUp")) {
                    navigateWithClient("/login");
                    return;
                }

                setClientToken(clientToken);

            } catch (error) {
                console.log(error);
                navigate("/error");
            } finally {
                loading.hide();
            }
        })();
    }, []);

    const handleLogin = () => navigateWithClient("/login");

    return (
        <>
            <OnboadingHeader />
            <Box
                height="85vh"
                display="flex"
                flexDirection="row"
            >
                <Box height="100%" width="50%" pl={15} py={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography variant="h4" fontWeight="700" >
                            Create Account
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="column"
                            width="fit-content"
                            mt={2}
                            mb={3}
                        >
                            <TextField
                                autoFocus
                                placeholder="name"
                                style={{ "width": "25vw", "marginBottom": "20px" }}
                                id="name"
                                name="name"
                                title="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.errors.name}
                            />
                            <TextField
                                placeholder="email"
                                style={{ "width": "25vw", "marginBottom": "20px" }}
                                id="email"
                                name="email"
                                title="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.errors.email}
                            />

                            <PasswordField
                                placeholder="password"
                                style={{ "width": "25vw" }}
                                name="password"
                                title="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.errors.password}
                            />

                        </Box>


                        <Box className={classes.buttonContainer} mt={1} mb={2}>
                            <CustomButton
                                size="medium"
                                sx={{ height: "34px" }}
                                type="submit"
                            >
                                Sign Up
                            </CustomButton>
                        </Box>
                        <Typography variant="subtitle4">
                            Already have an account?
                            <Typography
                                variant="subtitle4"
                                onClick={handleLogin}
                                sx={{ ml: 1, color: "#02569D", cursor: "pointer" }}
                            >
                                Login
                            </Typography>
                        </Typography>
                    </form>
                </Box>

                <Box className={classes.rightContainer} >
                    <video
                        autoPlay
                        loop
                        muted
                        preload="auto"
                        className={classes.image}
                    >
                        <source
                            src="https://assets.languify.in/video/1.4.interview-walkthrough.mp4"
                            type="video/mp4"
                        />
                    </video>
                </Box>

            </Box>
        </>
    );
}

export default Registration;
