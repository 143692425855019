import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";
import Divider from '@mui/material/Divider';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=>({
  title: {
    padding: theme.spacing(5,5,2,5),
    display: 'flex', justifyContent: 'space-between'
  },
  list: {
    ...theme.typography['body01-semiBold'],
    fontStyle: 'italic',
    '&>*': {
      marginBottom: theme.spacing(1)
    }
  }
}));

const PaperProps = { style: { borderRadius: '8px', width: '500px' } }

function FullscreenPermissionDialog({
  open,
  onSuccess,
  remainingTime= false,
}) {
  const classes = useStyles();

  const grantPermission = () => {
    onSuccess();
  }

  const {min, sec} = React.useMemo(()=>{
    let min,sec;
    if(remainingTime){
      min = Math.floor((remainingTime / 1000 / 60) % 60);
      sec = Math.floor((remainingTime / 1000) % 60);
    }
    return {min, sec};
  }, [remainingTime]);

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      PaperProps={PaperProps}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.title}>
        {
          remainingTime ?
            <Typography variant='h5-medium' color='danger.clr-700'>
              Attention!
            </Typography>
            :
            <Typography variant='h5-medium'>
              Full screen permission
            </Typography>
        }
        {
          remainingTime ? 
            <Typography variant='h5-medium' color='danger.clr-700'>
              {min}:{sec} min
            </Typography>
            : null
        }
      </DialogTitle>
      <Divider sx={{ border: '1.2px solid #CCD4DE', margin: '0px 20px 0px 20px'}}/>
      <DialogContent sx={{ minWidth: 320, padding: '20px', paddingBottom: '0px' }}>
        <Typography variant='h6-regular' color='neutral.clr-900'>
        {
          remainingTime 
          ? "To attempt this further, you must be in full screen mode. Continue your attempt before time runs out!"
          : "Please allow us to switch to full screen mode, as the mock interview can only be attempted in this mode."
        }
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '20px' }}>
        <Box width='100%' my={1}>
          <CustomButton
            variant='contained'
            onClick={grantPermission}
            size='medium'
            sx={{ width: '100%' }}
          >
          {
            remainingTime
            ? "Return to full screen"
            : "Allow full screen mode"
          }
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default FullscreenPermissionDialog;