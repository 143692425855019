import { Typography } from "@mui/material";

export const Parameters = [
  {
      subCategory: "FILLER_WORDS",
      type: "array",
      idealRange: {min: 0, max: 5},
      title: "Filler words",
      data: { value: {} },
      info: "Extra words or sounds we often use to bridge gaps when we're speaking or thinking",
      tip: "Try to lessen your use of filler words such as 'um', 'uh', 'like', and others in your conversations",
      valueParser: (value) => {
        return (
            <span>
                {value}%
            </span>
        );
      },
      valueGetter: (data) => (data?.percentage || 0)
  },
  {
      subCategory: "JARGON_WORDS",
      type: "array",
      idealRange: {min: 10, max: 100},
      title: "Jargon words",
      data: { value: {} },
      tip: "Elevate your professional tone by integrating targeted jargon",
      info: "Specialized terms or expressions making communication more efficient within that specific context",
      valueParser: (value) => {
        return (
            <span>
                {value}%
            </span>
        );
      },
      valueGetter: (data) => (data?.percentage || 0)
  },
  {
      subCategory: "RARE_USED_WORDS",
      type: "array",
      idealRange: {min: 10, max: 100},
      title: "Rare words",
      data: { value: {} },
      tip: "Spice it up: Drop in rare words for a standout, classy vibe",
      info: "Uncommon terms that, when incorporated, add flair and uniqueness to your communication",
      valueParser: (value) => {
        return (
            <span>
                {value}%
            </span>
        );
      },
      valueGetter: (data) => (data?.percentage || 0)
    },
    {
      subCategory: "PAUSES",
      type: "linear",
      title: "Pauses",
      data: { value: 0, count: 0 },
      info: "Brief silences in conversation, often used to emphasize points or gather thoughts",
      tip: "Try to reduce the number and length of pauses in your speech for a smoother conversation flow",
      valueGetter: (data) => (data || 0)
  },
  {
      subCategory: "DISFLUENCY",
      type: "linear",
      idealRange: {min: 0, max: 5},
      title: "Disfluencies",
      data: { value: 0, count: 0 },
      tip: "Work on reducing speech disfluencies, such as stuttering or repeating words",
      info: "Interruptions or disruptions in speech, such as stuttering or repeating words",
      valueParser: (value) => {
        return (
            <span>
                {value}
            </span>
        );
      },
      valueGetter: (data) => (data?.value || 0)
  },
  {
      subCategory: "PACE",
      type: "meter",
      idealRange: {min: 100, max: 150},
      title: "Speaking Pace",
      data: { value: 0, count: 0, min: 0, max: 300 },
      getColor: (value) => {
        return (value < 100 ? '#EB8916' : (value <= 150 ? '#15C63C' : '#F80505'));
      },
      valueParser: (value) => {
          return (
            <>
              <Typography variant="body01-bold" >
                  {value}&nbsp;
              </Typography>
              <Typography variant="body01-bold" >
                  wpm
              </Typography>
            </>
          );
      },
      valueGetter: (data) => (data?.value || 0),
      info: "The speed at which someone speaks, which can impact the clarity and understanding",
      generateTip: (value) =>
      (value < 140 ? 'Focus on improving your speaking pace by practicing regularly'
          : (value < 240) ?
              "To maintain this range, continue practicing speaking at a comfortable speed" :
              "Continue practicing to maintain your pace and avoid rushing through your speech"
      ),
      legends: [
          { color: '#EB8916', label: 'Low Pace' },
          { color: '#15C63C', label: 'Ideal Pace' },
          { color: '#F80505', label: 'High Pace' }
      ]
  },
  {
      subCategory: "ENERGY",
      type: "meter",
      idealRange: {min: 1, max: 2},
      title: "Energy",
      data: { value: 0, count: 0, min: 0, max: 3.0 },
      getColor: (value) => {
        return (value < 1 ? '#EB8916' : (value <= 2 ? '#15C63C' : '#F80505'));
      },
      valueParser: (value) => {
          return (
              <span>
                  {(value < 1 ? 'Dull' : (value <= 2 ? 'Ideal' : 'High'))}
              </span>
          );
      },
      valueGetter: (data) => (data?.value || 0),
      info: "The level of enthusiasm, passion, or engagement conveyed through speech",
      generateTip: (value) => (
          value < 1 ? 'Practice speaking with a confident and assertive tone to project your message effectively'
              : (value < 2 ?
                  'Employ pauses to stress key points, aiding the interviewer in grasping information' :
                  'Use gestures and body movements to boost energy and connect with your interviewer'
              )
      ),
      legends: [
          { color: '#EB8916', label: 'Dull' },
          { color: '#15C63C', label: 'Ideal' },
          { color: '#F80505', label: 'High' }
      ]
  },
  {
      subCategory: "INFORMAL_WORDS",
      type: "array",
      title: "Informal words",
      data: { value: {} },
      tip: "Avoid using overly informal language in professional settings.",
      info: "Words or language that are more suitable for casual or social situations than professional settings",
      valueGetter: (data) => (data?.value || 0),
  },
];


export const DashboardTourSteps = [
  {
      id: "rank-and-average",
      title: "My Standing",
      message: "View your Rank, Overall Average & Batch Average to know where you are standing amongst your peers. ",
      skipText: null,
      action: "Got it",
      hideClose: false,
      data: {},
  },
  {
      id: "to-do-list",
      title: "Your To-Do List",
      message: "Find all the assessments for your batch in this section.",
      skipText: null,
      action: "Got it",
      hideClose: false,
      data: {
          templates: [
              {
                  name: "B1 - Assessment 4",
                  type: "assignment",
                  tags: [],
                  infos: [],
                  communicationAverage: 67,
                  contentAverage: 72,
                  attemptedBy: ['student1', 'student2'],
                  batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
              },
              {
                  name: "B2 - Assessment 3",
                  type: "assignment",
                  tags: [],
                  infos: [],
                  batch: { templateCount: 3, studentCount: 35, name: "Batch 2" }
              },
              {
                  name: "B1 - Assessment 2",
                  type: "practice",
                  tags: [],
                  infos: [],
                  batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
              },
              {
                  name: "B3 - Assessment 5",
                  type: "practice",
                  tags: [],
                  infos: [],
                  batch: { templateCount: 5, studentCount: 25, name: "Batch 3" }
              },
              {
                  name: "B4 - Assessment 11",
                  type: "assignment",
                  tags: [],
                  infos: [],
                  batch: { templateCount: 11, studentCount: 30, name: "Batch 4" }
              },
          ],
          batches: [{
              _id: "dummy_batch",
              name: "Batch",
              user: {
                  prevCommunication: 0,
                  prevContent: 0,
                  communication: 0,
                  content: 0
              },
          }]
      },
  },
  {
      id: "pathway-list",
      title: "Explore Your Journey",
      message: 'Start your unique Pathway by diving into your "My Work" list',
      skipText: null,
      action: "Got it",
      hideClose: false,
      data: {},
  },
  {
      id: "recent-attempts-analysis",
      title: "Your performace graph",
      message: "Analyze your performance over the last 10 assessments.",
      action: "Got it",
      hideClose: false,
      data: {
          attempts: []
      },
  },
  {
      id: "performance-analytics-menu",
      title: "Your speech performance",
      message: "Get cumulative insights of your speech performance for the recent 10 attempts.",
      action: "Got it",
      data: {}
  },
  {
      id: "replay-tutorial",
      title: "Replay Tutorial",
      message: "You can replay the tutorial for this page if you get stuck anywhere!",
      action: "Finish",
      data: {}
  }
];

export const TagLines = {
  1: "Champion of the Batch! Your exceptional performance sets the bar high. Keep shining and inspiring others!",
  2: "Thriving among the Elite! Your dedication and progress are commendable. Let's continue pushing boundaries!",
  3: "Keep Climbing Higher! Take more assessments to elevate your rank and reach new goals",
  4: "Unlock Your Potential! Assessments are the key to discovering your hidden talents",
  5: "Rise Above! Take more assessments to improve your skills and climb the ranks",
  6: "Turn the Tide! Assessments can help you bridge the gap and reach new heights"
}