import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  chipRoot: {
    borderRadius: theme.spacing(5),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.secondary['clr-100'],
    width: 'fit-content',
    display: 'flex', gap: theme.spacing(1)
  },
  clickableChip: {
    borderRadius: theme.spacing(5),
    textTransform: 'capitalize',
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.shades['clr-white-900'],
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-600'],
    boxShadow: '0px 0px 4px 0px #00000033',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50'],
    }
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    '&>*': {
      color: theme.palette.shades['clr-white-900']
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  disabledChip: {
    backgroundColor: theme.palette.neutral.disabled,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.neutral.disabled,
    }
  },
  disabledRootChip: {
    backgroundColor: `${theme.palette.neutral['clr-200']} !important`,
    color: `${theme.palette.neutral['clr-600']} !important`
  }
}
));

const Chip = ({ 
  content, color, bgColor, startIcon = null, endIcon = null, sx = {}, disabled,
  typographyVariant = 'body01-bold', ...props 
}) => {
  const classes = useStyles();

  return (
    <Box 
      className={`${classes.chipRoot} ${disabled ? classes.disabledRootChip : ''}`} 
      style={{ backgroundColor: bgColor, color: color, ...sx }} 
      {...props}
    >
      {
        startIcon && startIcon
      }
      <Typography variant={typographyVariant}>
        {content}
      </Typography>
      {
        endIcon && endIcon
      }
    </Box>
  )
}

export const ClickableChip = ({
  content, onClick = () => { }, selected = false, disabled=false, ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      className={
        `${classes.clickableChip} ${selected ? classes.selectedChip : ''}
          ${disabled ? classes.disabledChip : ''}
        `
        }
      onClick={ disabled ? undefined : onClick}
      {...props}
    >
      <Typography variant='body01-bold' color='neutral.clr-600'>
        {content}
      </Typography>
    </Box>
  )
}

export default Chip;
