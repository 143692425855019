import React from "react";
import Assessment from "components/Assessment";
import Error from "./Error";
import DeactivatedProfile from "./DeactivatedProfile";
import NotFound from "./NotFound";
import Registration from "./Registration";
import Login from "./Login";
import Welcome from "./Welcome";
import Onboarding from "./Onboarding";
import Home from "components/Home";
import Bookmark from "../components/Bookmark";
import Activation from "./Activation";
import ForgotPassword from "./ForgotPassword";
import MobileDeviceError from "./MobileDeviceError";
import PrivacyPolicy from "./PrivacyPolicy";
import PrivateRoute from "components/PrivateRoute";
import SessionHelper from "components/SessionHelper";
import ResetPassword from "./ResetPassword";
import VerifyAccount from "./VerifyAccount";
import Performance from "./Performance";
import Workspace from "./Workspace";
import Profile from "components/Profile";
import BatchInvite from "./BatchInvite";
import Blog from "./Blog";
import theme from "theme";
import ProctoringProvider from "contexts/ProctoringProvider";
import ErrorBoundary from "../components/ErrorBoundary";
import Pathway from "components/Pathway";
import Auth, { AuthForAssessmentLink } from "./Auth";
import UnsupportedBrowser, { UnsupportedDevice } from "./UnsupportedBrowser";

import {
  BrowserRouter, Navigate, Route, Routes,
} from "react-router-dom";
import {
  SnackbarProvider,
  LoadingProvider,
  SingleSessionProvider,
  ConnectivityProvider,
  TourProvider
} from "contexts";
import { ThemeProvider } from '@mui/material/styles';
import { StackSnackbarProvider } from "contexts/StackSnackbarProvider";
import Signup from "./Signup";
import InteractiveDemoPerformance from "./InteractiveDemo";
import PersonalisedInterview from "components/PersonalisedInterview";
import CreateInterview from "components/CreateInterview";

export default function Screens() {

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <SingleSessionProvider>
            <LoadingProvider>
              <TourProvider>
                <SnackbarProvider>
                  <StackSnackbarProvider>
                    <ProctoringProvider>
                      <ConnectivityProvider>
                        <SessionHelper>
                          <Routes>
                            <Route path=":client" >
                              <Route
                                index
                                element={<Navigate to='/guest/home' />}
                              />

                              <Route
                                path="home"
                                element={<PrivateRoute component={Home} />}
                              />

                              <Route
                                path="bookmark"
                                element={<PrivateRoute component={Bookmark} />}
                              />
                              <Route
                                path="profile"
                                element={<PrivateRoute component={Profile} />}
                              />
                              <Route
                                path="templates/*"
                                element={<PrivateRoute component={Performance} />}
                              />
                              <Route
                                path="performance/*"
                                element={<PrivateRoute component={Performance} />}
                              />
                              <Route
                                path="pathway/:id"
                                element={<PrivateRoute component={Pathway} />}
                              />
                              <Route
                                path="assessment"
                                element={<PrivateRoute component={Assessment} />}
                              />
                              <Route
                                path="onboarding"
                                element={<PrivateRoute component={Onboarding} />}
                              />
                              <Route
                                path="welcome"
                                element={<PrivateRoute component={Welcome} />}
                              />
                              <Route
                                path="register"
                                element={<Registration checkToken />}
                              />
                              <Route
                                path="blog/*"
                                element={<PrivateRoute component={Blog} />}
                              />
                              <Route
                                path="personalized-interview"
                                element={<PrivateRoute component={PersonalisedInterview} />}
                              />
                              <Route
                                path="personalized-interview/:id"
                                element={<PrivateRoute component={CreateInterview} />}
                              />

                              <Route path="login" element={<Login />} />
                              <Route path="signup" element={<Signup />} />
                              <Route path="auth" element={<Auth />} />
                              <Route path="auth/assessment-link" element={<AuthForAssessmentLink />} />
                              <Route path="activation" element={<Activation />} />
                            </Route>
                            <Route path="/batch/join" element={<BatchInvite />} />
                            <Route path="/signup" element={<Navigate to='/languify/signup' />} />
                            <Route path="/signin" element={<Navigate to='/languify/login' />} />
                            <Route path="verify-account" element={<VerifyAccount />} />
                            <Route path="privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                            <Route path="reset-password" element={<ResetPassword />} />
                            <Route
                              path="/interactive-demo/performance"
                              element={<InteractiveDemoPerformance />}
                            />
                            <Route path="/error" element={<Error />} />
                            <Route path="/error-admin" element={<DeactivatedProfile />} />
                            <Route path="/mobile-error" element={<MobileDeviceError />} />
                            <Route path="/unsupported-device" element={<UnsupportedDevice />} />
                            <Route path="/unsupported-browser" element={<UnsupportedBrowser />} />
                            <Route path="/workspace" element={<Workspace />} />
                            <Route path="/" element={<Navigate to='/guest/home' />} />
                            <Route path="*" element={<NotFound />} />
                          </Routes>
                        </SessionHelper>
                      </ConnectivityProvider>
                    </ProctoringProvider>
                  </StackSnackbarProvider>
                </SnackbarProvider>
              </TourProvider>
            </LoadingProvider>
          </SingleSessionProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}
