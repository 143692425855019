import * as React from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Collapse } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import CircleIcon from '@mui/icons-material/Circle';

import { useNavigateWithClient } from 'hooks';
import { useLoading, useSnackbar } from "../../contexts";
import { deleteBookmark, updateBookmark } from 'services';

const useStyles = makeStyles(theme=>({
  tab: {
    fontSize: 14,
    fontWeight: 600,
    width: 'fit-content',
    textTransform: 'capitalize',
    padding: 0, border: 'none',
    background: theme.palette.shades['clr-white-900'],
    color: theme.palette.primary.main,
    cursor: 'pointer',
    "&:hover": {
      borderBottom: '1px solid',
      borderColor: theme.palette.primary.main
    },
  },
  activeTab: {
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  save: {
    position: 'absolute',
    bottom: 15, right: 5,
    zIndex: 100, textTransform: 'capitalize',
    padding: theme.spacing(0.5)
  },
  textField: {
    width: "100%",
    backgroundColor: theme.palette.shades['clr-white-900'],
    borderRadius: theme.spacing(1),
    '& input': {
      color: theme.palette.primary.main,
    },
  },
  bookmarkIcon: { 
    cursor: "pointer", 
    fontSize: "20px", 
    color: theme.palette.secondary['clr-700'], 
  },
  tableRow: {
    height: "40px",
    "& td": {
      ...theme.typography['h6-medium'],
      borderBottom: "none"
    }
  },
}));

const BookmarkTabs = {
  "YOUR_ANSWER": { name: 'Your Response', id: 'yourAnswer' },
  "IDEAL_ANSWER": { questionType: 'technical', name: 'Ideal Response', id: 'idealAnswer' },
  "SAMPLE_ANSWER": { questionType: 'situational', name: 'Example Response', id: 'sampleAnswer' },
  "ADD_NOTES": { name: 'Add Notes', id: 'addNotes' },
};

const ExpandableTableRow = ({ 
  data, expand = false, index, onExpand = () => { }, onRowRemove = () => { } 
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const loading = useLoading();
  const navigateWithClient = useNavigateWithClient();
  const [activeTab, setActiveTab] = React.useState(BookmarkTabs.YOUR_ANSWER.id);
  const [activeContent, setActiveContent] = React.useState('');

  const { id, question, answer, attempt, assessment, date, pointsToRemember, questionType , isPersonalized , isMock } =
    React.useMemo(() => {
      return (
        {
          id: data?._id || "",
          questionNumber: data?.interviewQuestion?.serialNumber || 1,
          question: data?.interviewQuestion || "",
          answer: data?.interviewAnswer?.transcript || "",
          attempt: data?.attemptNumber || 1,
          assessment: data?.interviewTemplate?.name || "",
          date: dayjs(data?.attemptTemplate?.createdAt).format('DD/MM/YYYY') || "",
          pointsToRemember: data?.pointsToRemember || "",
          questionType: data?.interviewQuestion?.type || "",
          isPersonalized : data?.interviewTemplate?.type === 'personalized' 
                            ? data?.interviewTemplate?.personalizedTemplateDraftId 
                              ? false 
                              : true 
                            : false,
          isMock : data?.interviewTemplate?.type === 'personalized' 
                            ? data?.interviewTemplate?.personalizedTemplateDraftId 
                              ? true 
                              : false 
                            : false
        }
      )
    }, [data]);
  const [pointsToRememberState, setPointsToRememberState] = useState(pointsToRemember || "");

  const handleUpdateBookmark = async () => {
    loading.show();
    try {
      if (pointsToRememberState !== pointsToRemember) {
        await updateBookmark(id, { pointsToRemember: pointsToRememberState });
      }

      snackbar.success("Saved.");
    } catch (error) {
      console.error("Bookmark error :- ", error);
      snackbar.error('Unable to save, try again later');
    } finally {
      loading.hide();
    }
  };

  const removeBookmark = async () => {
    loading.show();
    try {
      await deleteBookmark(id);
      onExpand(-1);
      onRowRemove(id);

      snackbar.success("Removed.");
    } catch (error) {
      console.error("Bookmark error :- ", error);
      snackbar.error('Unable to remove, try again later');
    } finally {
      loading.hide();
    }
  };

  React.useEffect(() => {
    if (activeTab === BookmarkTabs.IDEAL_ANSWER.id) setActiveContent(question?.expectedAnswer);
    if (activeTab === BookmarkTabs.SAMPLE_ANSWER.id) setActiveContent(question?.expectedAnswer);
    if (activeTab === BookmarkTabs.YOUR_ANSWER.id) setActiveContent(answer);
  }, [activeTab]);

  return (
    <>
      <TableRow id="edit-notes-container" className={classes.tableRow}>
        <TableCell id="bookmark-questions" > 
          {isPersonalized 
            ? `${assessment} (Personalized Interview)` 
            : isMock 
              ? `${assessment} (Mock Interview)` 
              : assessment
          }
        </TableCell>
        <TableCell >{question?.question}</TableCell>
        <TableCell >{'Attempt ' + attempt}</TableCell>
        <TableCell >{date}</TableCell>
        <TableCell id="view-assessment-container" >
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            {!expand ?
              <KeyboardArrowDownOutlinedIcon
                id="edit-notes"
                cursor="pointer"
                onClick={() => onExpand(index)}
              />
              :
              <KeyboardArrowUpOutlinedIcon
                id="edit-notes"
                cursor="pointer"
                onClick={() => onExpand(-1)}
              />
            }
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: "0px", paddingTop: "0px" }} colSpan={5}>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' gap='4px'>
                <BookmarkIcon
                  onClick={removeBookmark}
                  className={classes.bookmarkIcon}
                  id="bookmark"
                />
                <Typography
                  style={{
                    cursor: 'pointer', ...theme.typography['h6-medium']
                  }}
                  onClick={e =>
                    navigateWithClient(
                      `/performance/${data?.interviewAttempt?._id}?question=${data.interviewQuestion._id}`
                    )}
                >
                  {question?.question}
                </Typography>
              </Box>

              <Box display='flex' gap={2}>
                {
                  Object.values(BookmarkTabs).map(({ name, questionType: qt, id }, i) => {
                    return (qt === questionType || qt === undefined) ?
                      <button
                        className={activeTab === id ? `${classes.tab} ${classes.activeTab}` : classes.tab}
                        key={i} onClick={() => setActiveTab(id)}
                      >
                        {name}&nbsp;
                        {
                          id===BookmarkTabs.ADD_NOTES.id && 
                          (pointsToRemember?.length || pointsToRememberState?.length)
                          ? <CircleIcon style={{color: '#FFC500', fontSize: '12px'}}/>
                          : null
                        }
                      </button> : null
                  })
                }
              </Box>
            </Box>

            <Box position='relative' style={{ padding: "10px 0px" }}>
              {
                (activeTab === BookmarkTabs.ADD_NOTES.id) ?
                  <>
                    <Button variant='contained' className={classes.save} onClick={handleUpdateBookmark}>
                      Save
                    </Button>
                    <TextField
                      className={classes.textField}
                      placeholder="Write your Important points to remember"
                      inputProps={{
                        style: {
                          ...theme.typography['body01-regular']
                        }
                      }}
                      multiline
                      rows={3}
                      value={pointsToRememberState}
                      onChange={e => setPointsToRememberState(e.target.value)}
                    /></> :
                  <TextField
                    className={classes.textField}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                    }}
                    placeholder="You skipped this question!"
                    inputProps={{
                      style: {
                        ...theme.typography['body01-regular']
                      }
                    }}
                    multiline
                    disabled={true}
                    rows={3}
                    value={activeContent}
                  />
              }
            </Box>

          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExpandableTableRow;