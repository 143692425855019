import React from 'react';
import shallow from 'zustand/shallow';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';

import useStore from "./assessmentStore";
import WhiteIconButton from 'components/WhiteIconButton';

const sx = {
    root: {
        position: 'absolute', display: "flex",
        width: "115%", justifyContent: "space-between", alignItems: 'center',
        filter: 'drop-shadow(0px 2px 7px rgba(2, 86, 157, 0.10))',
        top: '45%',
        zIndex: 10,
        left: -90,
    }
};

function ArrowNavigation(props) {
    const [next, prev, questionState] = useStore(state => [
        state.next, state.prev, state.questionState
    ], shallow);
    const isInterviewerSpeaking = useStore(state => state.isInterviewerSpeaking, shallow);

    const isRecording = "RECORDING" === questionState;

    return (
        <Box sx={sx.root} id="second-create-interview-tip">
            <WhiteIconButton 
              onClick={prev} 
              disabled={isRecording || isInterviewerSpeaking} style={{ width: 60, height: 60 }} 
            >
                <ArrowBackIcon color='primary' />
            </WhiteIconButton>
            <WhiteIconButton 
              onClick={next} 
              disabled={isRecording || isInterviewerSpeaking} style={{ width: 60, height: 60 }}
            >
                <ArrowForwardIcon color='primary' />
            </WhiteIconButton>
        </Box>
    );
}

export default ArrowNavigation;