import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import ConfirmationDialog from "dialogs/ConfirmationDialog";
import useAssessmentStore from './assessmentStore';
import QuestionNavigation from './QuestionNavigation';

const useStyles = makeStyles(theme=>({
    container: {
        backgroundColor: theme.palette.shades['clr-white-900'], 
        width: "100%",
        display: "flex", 
        flexDirection: "column",
        alignItems: "center",
    },
}));

function FooterSection() {
    const classes = useStyles();

    const endAssessment = useAssessmentStore(state => state.endAssessment);
    const questionState = useAssessmentStore(state => state.questionState);
    const questions = useAssessmentStore(state => state.questions);
    const questionsError = useAssessmentStore(state => state.questionsError);
    const uploadStates = useAssessmentStore(state => state.uploadStates);

    const [openDialog, setOpenDialog] = React.useState(false);

    const withErrors = questionsError.filter(e => e).length;
    const unattempted = questions.filter(q => !q.submitted).length;

    const dialogMessage = React.useMemo(() => {
        if (!openDialog) return "";

        if (withErrors > 0)
            return `${withErrors} answer(s) are not recorded properly, 
            Do you still want to submit?`;

        if (unattempted > 0)
            return (<>
                You have not attempted {unattempted} question(s).<br />
                {unattempted === questions.length && (<>
                    No answers recorded, this attempt will not be considered.
                    <br />
                </>
                )}
                Do you still want to submit?
            </>);

        return "Are you sure to end this interview?";
    }, [openDialog, withErrors, unattempted, questions]);

    const disableSubmit = React.useMemo(() => {
        return (
            questionState === "RECORDING" ||
            uploadStates.find(x => x === true)
        );
    }, [questionState, uploadStates]);

    return (
        <Box className={classes.container} >
            <QuestionNavigation />

            <ConfirmationDialog
                open={openDialog}
                message={dialogMessage}
                onPrimaryAction={() => endAssessment(false)}
                onSecondaryAction={() => setOpenDialog(false)}
                primaryAction={
                    (withErrors + unattempted > 0) ? "Submit anyway" : "Yes"
                }
                secondaryAction={
                    (withErrors + unattempted > 0) ? "Retake Questions" : "No"
                }
                secondaryActionType="contained"
                primaryActionType="outlined"
                primaryActionVariant="error"
                title="Finish Interview"
            />
        </Box>
    );
}

export default FooterSection;