import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const features = [
    {
        iconUrl: "https://assets.languify.in/images/question-answer-icon.svg",
        title: ["24x7 mock", "interview simulator"]
    },
    {
        iconUrl: "https://assets.languify.in/images/360-degree-icon.svg",
        title: ["360 Goal", "oriented learning"],
    },
    {
        iconUrl: "https://assets.languify.in/images/chart-icon.svg",
        title: ["Detailed", "progress tracking"]
    },
    {
        iconUrl: "https://assets.languify.in/images/list-icon.svg",
        title: ["Choose from", "100+ templates"]
    },
    {
        iconUrl: "https://assets.languify.in/images/games-icon.svg",
        title: ["Personalised", "games & resources"]
    },
    {
        iconUrl: "https://assets.languify.in/images/interview-settings.svg",
        title: ["Create custom", "interview templates"]
    },
];

const useStyles = makeStyles(theme => ({
    featureContainer1: {
        background: "transparent",
        display: "flex",
        width: "100%",
        [theme.breakpoints.up(725)]: {
            paddingLeft: "2em"
        }
    },
    featureContainer2: {
        display: "flex",
        width: "100%",
        [theme.breakpoints.up(725)]: {
            paddingRight: "2em"
        }
    },
    desktopElement: {
        [theme.breakpoints.down(725)]: {
            display: "none"
        }
    },
    mobileElement: {
        display: "none",
        [theme.breakpoints.down(725)]: {
            display: "flex"
        }
    },
    feature: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        margin: 8,
        flexGrow: 1,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 16px -3px rgba(0,0,0,0.25)",
        borderRadius: "16px",
        minHeight: 100,
        width: "10%",
        [theme.breakpoints.down(725)]: {
            width: "45%",
            minWidth: 130,
            minHeight: 100,
            borderRadius: 16
        }
    },
    featureIcon: {
        width: 35,
        height: 35,
        margin: "auto",
        [theme.breakpoints.down(725)]: {
            width: 25,
            height: 25
        }
    }
}));

function Feature({ iconUrl, title }) {
    const classes = useStyles();

    return (
        <Box className={classes.feature} >
            <Box width="30%" textAlign="center">
                <img
                    src={iconUrl}
                    alt={title}
                    className={classes.featureIcon}
                />
            </Box>
            <Box width="75%">
                {title.map((title, index) => (
                    <Typography fontSize={20} key={index} fontWeight="medium">
                        {title}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
}

function Features() {
    const classes = useStyles();

    return (
        <Box
            id="features"
            display='flex'
            flexDirection={'column'}
            justifyContent='center'
            width={'100%'}
            maxWidth={1200}
        >
            <Box className={`${classes.featureContainer1} ${classes.desktopElement}`} >
                {features.slice(0, 3).map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </Box>
            <Box className={`${classes.featureContainer2} ${classes.desktopElement}`} >
                {features.slice(3).map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </Box>
            <Box className={`${classes.featureContainer1} ${classes.mobileElement}`} >
                {features.slice(0, 2).map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </Box>
            <Box className={classes.mobileElement} display="flex" paddingTop="4.2em">
                {features.slice(2, 4).map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </Box>
            <Box className={classes.mobileElement} display="flex" marginTop="0.5em">
                {features.slice(4, 6).map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </Box>
        </Box>
    );
}

export default Features;