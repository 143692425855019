import React from 'react';
import Box from '@mui/system/Box';
import { useFormik } from 'formik';
import * as Yup from "yup";
import makeStyles from "@mui/styles/makeStyles";

import { CustomLoadingButton } from 'components/CustomButton';
import { useServices, useSnackbar } from 'contexts';
import { updateUserProfileById, getUserById } from "services";
import { Session } from 'utils';
import TextField from 'components/CustomFields';

const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex', 
    flexDirection: 'column', 
    gap: '12px'
  }
}));

const Schema = new Yup.ObjectSchema({
    name: Yup.string().required("Please fill your name"),
    email: Yup.string().email().required("Please fill your email address"),
    applyingJobRole: Yup.string(),
    applyingPosition: Yup.string(),
    applyingCompany: Yup.string(),
});

function PersonalDetails({ profile = {} }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const service = useServices();

    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: profile,
        validationSchema: Schema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const profile = {
                    name: values.name,
                    metadata: {
                        applyingJobRole: values.applyingJobRole,
                        applyingPosition: values.applyingPosition,
                        applyingCompany: values.applyingCompany,
                    },
                };

                await service.callService(updateUserProfileById, profile);

                snackbar.success('Saved profile successfully!');
            } catch (error) {
                snackbar.error('Uh Oh! Unable to update your profile');
            } finally {
              setLoading(false);
            }
        },
    });

    React.useEffect(() => {
        (async () => {
            try {

                let result = await service.callService(getUserById, Session.userId);

                const profile = {
                    name: result?.name,
                    email: result?.email,
                    applyingJobRole: result?.metadata?.applyingJobRole,
                    applyingPosition: result?.metadata?.applyingPosition,
                    applyingCompany: result?.metadata?.applyingCompany,
                };

                formik.setValues(profile)
            } catch (error) {
                snackbar.error('Uh Oh! Unable to load profile');
            } finally {
            }
        })();
    }, []);

    return (
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.root}>
            <TextField
              sx={{width: '300px'}}
              label='Full Name'
              name="name"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              error={!!formik.errors.name}
            />
            <TextField
              sx={{width: '300px'}}
              label='Email'
              name="email"
              value={formik.values.email || ""}
              disabled
            />
            <Box mt={2}>
              <CustomLoadingButton 
                type="submit"
                loading={loading}
              >
                Save Details
              </CustomLoadingButton>
            </Box>
            {/* <Field label="Aspiration">
                <FieldAutoComplete
                    endIcon
                    defaultValue={'625e615c927c0ffe617a72db'}
                    onChange={(e, value) => {
                        formik.setFieldValue("applyingJobRole", value)
                    }
                    }
                    name="jobRole"
                    className={""}
                    sx={{ width: "100%" }}
                />
            </Field>
            <Field label="Level">
                <FieldAutoComplete
                    endIcon
                    defaultValue={formik?.values?.applyingPosition}
                    onChange={(e, value) =>
                        formik.setFieldValue("applyingPosition", value)
                    }
                    name="designation"
                    className={""}
                    sx={{ width: "100%" }}
                />
            </Field>
            <Field label="Dream company">
                <FieldAutoComplete
                    endIcon
                    defaultValue={formik?.values?.applyingCompany}
                    onChange={(e, value) =>
                        formik.setFieldValue("applyingCompany", value)
                    }
                    name="company"
                    className={""}
                    sx={{ width: "100%" }}
                />
            </Field> */}
        </Box>
      </form>
    );
}

export default PersonalDetails;