import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import WrongBrowserImage from "assets/unsupported-browser.svg";
import OnboadingHeader from "components/OnboadingHeader";

import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { CopyButton } from "components/CustomButton";

const useStyles = makeStyles((theme) => ({
  container: { textAlign: 'center' },
  icon: { color: '#FFCC20', fontSize: '5rem', fontWeight: 800 },
  downloadButtons: {
    display: "flex", width: "100%", justifyContent: "center", gap: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: "column", justifyContent: "center", margin: "0px 16px",
      width: "calc(100% - 32px)", gap: 16,
    }
  },
  header: {
    width: '100%', height: 70, background: '#02569D',
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  title: {
    color: 'white', fontSize: 24, fontWeight: 600, letterSpacing: 1
  },
  subTitle: {
    fontSize: 24, fontWeight: 500, letterSpacing: 1, textAlign: 'left',

  },
  content: {
    fontSize: 16, fontWeight: 500, lineHeight: '20px',

  },
  img: {
    width: '100%', maxWidth: 350, margin: '14px 0px'
  },
  unsupportedContainer: {
    width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
  }, link: {
    fontSize: 12, fontWeight: 500, background: '#A8A8A8', padding: '0px 6px',
    borderRadius: 12, letterSpacing: 0.6,
  }
}));

export function UnsupportedDevice(props) {
  const classes = useStyles();
  const linkRef = useRef();

  return (
    <Box className={classes.unsupportedContainer} >
      <Box className={classes.header}>
        <Typography className={classes.title}>
          Unsupported Device
        </Typography>
      </Box>

      <img
        src='https://assets.languify.in/images/unsupported-img.png'
        alt="WrongBrowserImage"
        className={classes.img}
      />

      <Box width='100%' padding={2}>
        <Typography className={classes.subTitle}>
          Uh - Oh!
        </Typography>
        <br />

        <Typography className={classes.content}>
          We notice that you are attempting to log in from a mobile device.
        </Typography>
        <br />
        <Typography className={classes.content}>
          For the optimal experience, please access this site on your laptop or desktop.
        </Typography>
        <br />

        <Typography ref={linkRef} className={classes.link}>
          https://interview.languify.in/guest/login
          <CopyButton
            content={"https://interview.languify.in/guest/login"}
            title="Login URL"
            textRef={linkRef}
            iconProps={{ color: "primary" }}
          />
        </Typography>

      </Box>
    </Box>
  );
}

export function UnsupportedDeviceMessage({ message }) {
  const classes = useStyles();
  const linkRef = useRef();

  return (
    <Box className={classes.container}>
      <img src={WrongBrowserImage} alt="WrongBrowserImage" height="280" />
      <Typography
        variant="body2"
        margin='2em auto'
        fontSize={18}
        maxWidth={500}
      >
        {message}
      </Typography>
      <Typography
        color="primary" component="a"
        href=" https://interview.languify.in/guest/login"
        target={"_blank"}
        rel="referrer"
        variant="subtitle2"
        ref={linkRef}
      >
        https://interview.languify.in/guest/login
      </Typography>
      <CopyButton
        content={" https://interview.languify.in/guest/login"}
        title="Login URL"
        textRef={linkRef}
        iconProps={{ color: "primary" }}
      />
      <br /><br />
      <Box className={classes.downloadButtons}>
        <Button
          LinkComponent="a"
          href="https://www.google.com/chrome/"
          target="_blank"
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none", px: 2 }}
        >
          Download Google Chrome
        </Button>
        <Button
          LinkComponent="a"
          href="https://www.microsoft.com/en-us/edge"
          target="_blank"
          variant="outlined"
          sx={{ fontWeight: "bold", textTransform: "none", px: 2 }}
        >
          Download Microsoft Edge
        </Button>
      </Box>
    </Box>
  );
}

const message = <>
  To take the assessment please open the URL in <br />Google Chrome or Microsoft Edge.
</>;

function UnsupportedBrowser() {
  return (
    <>
      <OnboadingHeader />
      <UnsupportedDeviceMessage
        message={message}
      />
    </>
  );
}

export default UnsupportedBrowser;