import React from "react";
import Backdrop from "@mui/material/Backdrop";

import { useNavigate } from "react-router-dom";
import TourTip from "components/TourTip";

export const TourProviderContext = React.createContext({
    active: 0,
    play: false,
    setPlay: () => { },
    playTour: (steps = []) => { },
    steps: [],
    setSteps: () => { },
});

// const skipData = {
//     id: "tutorial-button",
//     title: "Tutorial",
//     message: "In case of any confusion, you can learn about the platform anytime by clicking on this icon.",
//     action: "Got it",
//     hideClose: true,
// };

export const TourProvider = ({ children }) => {
    const navigate = useNavigate();

    const [play, setPlay] = React.useState(false);
    const [active, setActive] = React.useState(0);
    const [skipped, setSkipped] = React.useState(false);
    const [steps, setSteps] = React.useState([]);
    
    const onHandleNext = () => {
      if (play) {
            if (steps[active + 1]) {
                setActive(a => {
                    const step = steps[a];

                    if (step.link) navigate(step.link);

                    return a + 1;
                });
            } else {
                if (steps[active].link) navigate(steps[active].link);
                setPlay(false);
                setActive(0);
            }
        }
        if (skipped) {
            setSkipped(false);
        }
    }

    const onHandleSkip = () => {
        setPlay(false);
        setActive(0);
        // setSkipped(true);
    }

    const playTour = (steps = []) => {
        if (Array.isArray(steps) && steps?.length > 0) setSteps(steps);
        setPlay(true);
    };

    return (
        <TourProviderContext.Provider
            value={{ play, setPlay, playTour, steps, setSteps, active }}
        >
            <Backdrop
                open={play}
                sx={{
                    backgroundColor: 'transparent',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                {play && (
                    <>
                        <TourTip
                            tip={steps[active]}
                            onSkip={onHandleSkip}
                            onNext={onHandleNext}
                        />
                    </>
                )}
            </Backdrop>
            {children}
        </TourProviderContext.Provider>
    );
};

export const useTour = () => {
    const navigate = useNavigate();

    const {
        _setPlay, _play, _setSteps, _setActive
    } = React.useContext(TourProviderContext);

    const show = React.useCallback((steps) => {
        if (Array.isArray(steps) && steps?.length > 0) _setSteps(steps);

        _setActive(0);

        _setPlay(true);
    }, [_setPlay, navigate, _setActive]);

    const hide = React.useCallback(() => _setPlay(false), [_setPlay]);

    const setSteps = React.useCallback((steps) => _setSteps(steps), [_setSteps]);

    return { show, hide, setSteps, state: _play };
};

export default TourProvider;

/**
 * Step Example
 * 
 *  {
    id: "welcome-to-languify-tip",
    title: "Welcome to Languify!",
    message: "We are excited to show you all the new features and improvements we have made to our website.",
    skipText: "No thanks, I want to explore it myself",
    action: "Let’s get started",
    link: "/batches",
    hideClose: true,
    data: {
      templates: [
        {
          name: "B1 - Assessment 4",
          type: "assignment",
          tags: [],
          infos: [],
          communicationAverage: 67,
          contentAverage: 72,
          attemptedBy: ['student1', 'student2'],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
        },
        {
          name: "B2 - Assessment 3",
          type: "assignment",
          tags: [],
          infos: [],
          batch: { templateCount: 3, studentCount: 35, name: "Batch 2" }
        },
        {
          name: "B1 - Assessment 2",
          type: "practice",
          tags: [],
          infos: [],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
        },
        {
          name: "B3 - Assessment 5",
          type: "practice",
          tags: [],
          infos: [],
          batch: { templateCount: 5, studentCount: 25, name: "Batch 3" }
        },
        {
          name: "B4 - Assessment 11",
          type: "assignment",
          tags: [],
          infos: [],
          batch: { templateCount: 11, studentCount: 30, name: "Batch 4" }
        },
      ],
    },
  },
 */