import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import { useLoading, useSnackbar } from 'contexts';

import { signUpWithGoogle, signIn } from 'services/auth';

function GoogleSignButton({ type, redirectPath = '/' }) {
    const loading = useLoading();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [scriptLoaded, setScriptLoaded] = React.useState(false);

    const handleCredentialResponse = React.useCallback(async (response) => {
        console.log("DEBUG::response from google");
        loading.show();
        try {
            if (!["signup", "signin"].includes(type)) {
                snackbar.error(
                    "Uh Oh! Something went wrong. Please refresh the page.",
                    true
                );
                console.error(
                    "DEBUG: Invalid type for GoogleSignButton -> ", type
                );
                return;
            }

            if (type === "signup") {
                await signUpWithGoogle(response.credential);
            }
            if (type === "signin") {
                await signIn({
                    googleIdToken: response.credential,
                    withGoogle: true,
                });
            }

            navigate(redirectPath);
            window.location.reload();
        } catch (error) {
            snackbar.error(error.message || 'Unable to log you in');
        } finally {
            loading.hide();
        }
    }, []);

    React.useEffect(() => {
        const googleScript = document.createElement("script");
        googleScript.src = "https://accounts.google.com/gsi/client";
        googleScript.async = true;
        googleScript.defer = true;
        document.head.appendChild(googleScript);
        googleScript.onload = () => setScriptLoaded(true);
    }, []);

    React.useEffect(() => {
        if (scriptLoaded) {
            console.log("DEBUG::GOOGLE_LOADED");
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleCredentialResponse,
            });
            window.google.accounts.id.renderButton(
                document.getElementById("google-button"),
                {
                    class: "g_id_signin",
                    type: "standard",
                    shape: "rectangular",
                    theme: "outlined",
                    text: `continue_with`,
                    size: "large",
                    logo_alignment: "center",
                    width: 300,
                }
            );
            window.google.accounts.id.prompt();
        } else {
            console.log("DEBUG::GOOGLE_NOT_LOADED");
        }
    }, [scriptLoaded, handleCredentialResponse, type]);

    return (
        <Box display="flex" justifyContent="center">
            <div
                id="google-button"
                data-context="signin"
                data-ux_mode="popup"
            />
        </Box>
    );
}

export default GoogleSignButton;