import Client from "./_client";

export const getSurveyQuestions = async (questionFor = 'STUDENT') => {
    const result = await new Client({
        path: `/surveyQuestions?questionFor=${questionFor}`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get survey questions. Please try again.');
    }

    return result.data.surveyQuestions;
}

export const saveUserSurvey = async (userSurvey) => {
    const result = await new Client({
        path: "/userSurvey",
        payload: {
            user: userSurvey.userId,
            answers: userSurvey.answers
        }
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to save survey. Please try again.');
    }

    return true;
}

export const getUserSurveyByUserId = async (userId) => {
    const result = await new Client({
        path: `/userSurvey?userId=${userId}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || 'Uh Oh! Unable to get survey. Please try again.');
    }

    return result.data.userSurveys;
}