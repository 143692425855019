import React from 'react';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';

import CustomButton from "components/CustomButton";
import OnboadingHeader from 'components/OnboadingHeader';
import useNavigateWithClient from "hooks/useNavigateWithClient";
import Session from 'utils/Session';
import Features from 'components/Features';

import { useSnackbar } from 'contexts/SnackbarProvider';

function Welcome() {
  const navigate = useNavigateWithClient();
  const snackbar = useSnackbar();

  React.useEffect(() => {
    const privacyPolicyAccepted = JSON.parse(localStorage?.getItem("pp-accepted"));

    if (privacyPolicyAccepted) handleContinueClick();
  }, []);

  const handleContinueClick = (e) => {
    localStorage?.setItem("pp-accepted", true.toString());
    if (Session.isLoggedIn()) {
      navigate("/home");
    } else {
      snackbar.error("You are not authorized to proceed further");
    }
  }

  return (
    <>
      <OnboadingHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width='100%'
        height={'85vh'}
      >
        <Box
          maxWidth={900}
          display='flex'
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Typography
            variant='h3'
            fontWeight={600}
          >
            Welcome to Languify!
          </Typography> <br />
          <Typography
            variant='h5'
            fontWeight={600}
          >
            Learn and upskill in your career everytime you take an assessment
          </Typography>
          <br /><br /><br />
          <CustomButton size='large' onClick={handleContinueClick} >
            Get started
          </CustomButton>
          <Typography fontSize={16} fontWeight={500} mt={2} mb={4}
          >
            By clicking get started, you agree to our  &nbsp;
            <a
              target={'_blank'}
              href='https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf'
              style={{ color: '#02569D' }}
              rel="noreferrer"
            >terms and conditions</a>
          </Typography>
        </Box>
        <Features />
        <br /><br />
        <img
          src='https://assets.languify.in/images/powered-by-lang-colored.svg'
          alt='powered by languify'
        />
      </Box>
    </ >
  );
}

export default Welcome;
