import * as Yup from "yup";

export const profileDetails = [
    {
        label: "Interested Domain",
        name: "interestedDomain",
        optionField: "domain",
        type: "select",
    },
    {
        label: "Current Aspiration",
        name: "applyingJobRole",
        optionField: "jobRole",
        type: "select",
    },
    {
        label: "Company",
        name: "applyingCompany",
        optionField: "company",
        type: "select",
    },
    {
        label: "Level/ Position",
        name: "applyingPosition",
        optionField: "designation",
        type: "select",
    },
    {
        label: "Future goal",
        name: ["futureJobRole", "futureCompany"],
        optionField: ["designation", "company"],
        type: ["select", "select"],
    },
];

export const personalDetails = [
    {
        label: "Name",
        name: "name",
        optionField: "name",
        type: "text",
    },
    {
        label: "Email Id",
        name: "email",
        optionField: "email",
    },
    {
        label: "Current Job Profile",
        name: ["currentJobRole", "currentCompany"],
        optionField: ["jobRole", "company"],
        type: ["select", "select"],
    },
    {
        label: "Qualification",
        name: "qualification",
        optionField: "qualification",
        type: "select",
    },
];

export const metadataFields = [
    "interestedDomain",

    "currentJobRole",
    "currentCompany",
    "currentPosition",

    "futureJobRole",
    "futureCompany",

    "applyingJobRole",
    "applyingCompany",
    "applyingPosition",

    "qualification",
];

export const ExperienceLevels = [
    { id: "INTERNSHIP", label: "Internship", },
    { id: "ENTRY_LEVEL", label: "Entry Level", },
    { id: "MID_LEVEL", label: "Mid Level", },
    { id: "MANAGEMENT", label: "Management", },
    { id: "SENIOR_MANAGEMENT", label: "Senior Management", },
];

export const JobDescriptionConfirmationConfig = {
    type: "JOB_DESCRIPTION",
    title: "Delete Job Description",
    message: "Are you sure?",
    primaryAction: "Yes",
    secondaryAction: "Cancel",
};

export const ResumeConfirmationConfig = {
    type: "RESUME",
    title: "Delete Resume",
    message: "Are you sure?",
    primaryAction: "Yes",
    secondaryAction: "Cancel",
    primaryActionVariant: "error",
};

export const PersonalDetailsSchema = new Yup.ObjectSchema({
    name: Yup.string().required("Please fill your name"),
    email: Yup.string().email().required("Please fill your email address"),
    applyingJobRole: Yup.string(),
    applyingPosition: Yup.string(),
    applyingCompany: Yup.string(),
});

export const ResumeUploadSteps = {
    UPLOAD: 'upload',
    PROGRESS: 'progress',
    SAVE: 'save',
  };

export const PersonalizedAssesmentSteps = {
    LANDING_SCREEN : {
        _id : 'LANDING_SCREEN'
    },
    ANALYZING_JD : {
        _id : 'ANALYZING_JD'
    },
    EXTRACTING_RESUME : {
        _id : 'EXTRACTING_RESUME'
    },
    AI_MAGIC : {
        _id : 'AI_MAGIC'
    },
    GENERATING_QUESTIONS : {
        _id : 'GENERATING_QUESTIONS'
    },
    INTERVIEW_SET : {
        _id : 'INTERVIEW_SET'
    },
    LETS_GO : {
        _id : 'LETS_GO'
    },
    COUNTDOWN : {
        _id : 'COUNTDOWN'
    }
}
