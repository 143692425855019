import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Skeleton from '@mui/material/Skeleton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/styles';

import StartAssessmentDialog from "dialogs/StartAssessmentDialog";
import StatusChip from "./Chip";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "contexts";
import { getTwoDigitNo } from "utils";
import { templateTypes } from "./PerformanceReview/constants";
import { useNavigateWithClient } from "hooks";

const notesIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/notesIcon.svg";
const refreshIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/refreshIcon.svg";
const timerIcon = "https://languify-assets.s3.ap-south-1.amazonaws.com/images/timerIcon.svg";

const useStyles = makeStyles(theme => ({
  cardContainer: {
    position: "relative",
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between',
    width: "405px",
    height: "210px",
    boxShadow: "0px 0px 2px 0px #000000BF",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    flex: "0 0 auto",
    transition: "box-shadow 10ms linear",
    "&:hover": {
      boxShadow: "0px 0px 8px 0px #1961BF4D",
    },
    position: 'relative',
    overflow: 'hidden',
  },
  disabledCard: {
    backgroundColor: theme.palette.neutral.disabled,
    cursor: 'default',
    '&:hover': {
      boxShadow: '0px 0px 2px 0px #000000BF',
    },
    '&>*': {
      color: `${theme.palette.neutral['clr-600']} !important`
    }
  },
  cardContent: {
    display: "flex", flexDirection: "column", justifyContent: "flex-end", zIndex: '2'
  },
  title: {
    ...theme.typography['h4-semiBold'],
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '365px'
  },
  subtitle: {
    ...theme.typography['body02-bold'],
    textTransform: "capitalize",
    color: theme.palette.neutral['clr-600']
  },
  content: {
    ...theme.typography['body01-semiBold'],
    color: theme.palette.neutral['clr-700'],
    display: "flex",
    alignItems: "center"
  },
  templateDetails: {
    display: 'flex', justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  circularColorPads: {
    position: 'absolute',
    top: '0px',
    right: '0px',

  },
}));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'transparent'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral['clr-800'],
    color: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 10,
    maxWidth: 250,
    ...theme.typography['body01-bold'],
  },
  [`&.${tooltipClasses.popper}`]: {
    top: '-170px !important',
    left: '60px !important'
  },
}))

const TemplateToolTip = ({ component, placement = 'top', children, positionStyle = {}, ...props }) => {
  return (
    <Box sx={{ position: 'relative', }}>
      <BootstrapTooltip title={component} positionStyle={positionStyle} placement={placement} {...props}>
        {children}
      </BootstrapTooltip>
    </Box>
  )
}

function formateAverageTime(time) {
  if (!time) return null;
  return time < 60 ? `${getTwoDigitNo(time)} Secs` : `${getTwoDigitNo(Math.floor(time / 60))} Mins`;
}

export function TemplateCardSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      <Box display="flex">
        <Skeleton variant="rectangular" width="20%" height='30px' style={{ borderRadius: '20px' }} />
      </Box>
      <Box className={classes.cardContent}>
        <Box>
          <Skeleton variant="rectangular" width="90%" height='40px' />
        </Box>
        <Box mt='10px'>
          <Skeleton variant="rectangular" width="100%" height='20px' />
        </Box>
      </Box>
    </Box>
  );
}

export function TemplateCard({
  template, onClick, defaultTemplate, disabled, bgColor = '',
  cardTile = 'https://languify-assets.s3.ap-south-1.amazonaws.com/images/circularPads.svg',
  personalizedIntreview,
  onExpire = () => { }, ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const navigateWithClient = useNavigateWithClient();

  const [expire, setExpire] = useState('Expires Soon');
  const [expireSoon, setExpireSoon] = useState(false);

  // Determine which template to use based on personalizedIntreview
  const currentTemplate = personalizedIntreview ? template.draft : template;

  const handleCardClick = useCallback((e) => {
    if (personalizedIntreview) {
      if (template._id) {
        onClick(template);
      } else {
        navigateWithClient(`/personalized-interview/${currentTemplate._id}`)
      }
    } else {
      onClick(currentTemplate);
    }
  },
    [currentTemplate, personalizedIntreview]
  );

  const countDownTimer = (startTime, endTime) => {
    const timeDifference = endTime.diff(startTime, 'second');
    let remainingTime = timeDifference;
    let timeout = null;

    if (timeDifference > 0) {
      timeout = setInterval(() => {
        if (remainingTime <= 0) {
          clearInterval(timeout);
          setExpireSoon(false);
          onExpire(currentTemplate._id);
          console.log("Template expired!");
        } else {
          const hours = Math.floor(remainingTime / 3600);
          const minutes = Math.floor((remainingTime % 3600) / 60);
          const seconds = remainingTime % 60;

          setExpire(`Expires in ${String(hours).padStart(2, '0')}:${String(minutes)
            .padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
          remainingTime--;
        }
      }, 1000);
    } else {
      if (timeout) clearInterval(timeout);
      setExpireSoon(false);
      onExpire(currentTemplate._id);
      console.log("Template expired!");
    }
  }

  useEffect(() => {
    const { publishOn, expiresOn, thresholdDateForExpire } = currentTemplate;

    if (publishOn && expiresOn) {

      if (dayjs(expiresOn).year() !== 9999) {
        const startDate = dayjs(publishOn);
        const endDate = dayjs(expiresOn);

        const completedDate = dayjs();

        if (completedDate.isSame(endDate, "day")) {
          if (completedDate.isAfter(thresholdDateForExpire)) {
            countDownTimer(completedDate, endDate);
          }
        } else {
          if (completedDate.isAfter(thresholdDateForExpire)) {
            const remainingDays = endDate.diff(startDate, "day") - completedDate.diff(publishOn, "day");
            setExpire(
              (remainingDays === 1) ?
                `Expires in ${remainingDays} day` :
                `Expires in ${remainingDays} days`
            );
          }
        }
      }
    }
  }, [defaultTemplate, currentTemplate]);

  useEffect(() => {
    if (defaultTemplate) {
      setExpireSoon(defaultTemplate.expiresSoon);
    }
  }, [defaultTemplate]);

  return (
    <TemplateToolTip
      placement='bottom'
      component={currentTemplate?.type === 'personalized' ? currentTemplate?.data?.INTERVIEW_DETAILS?.name : currentTemplate?.name || ""}
      disableHoverListener={currentTemplate?.type === 'personalized' ? currentTemplate?.data?.INTERVIEW_DETAILS?.name.length < 28 : currentTemplate?.name.length < 28}
      positionStyle={{ position: 'absolute', top: '-10px' }}
    >
      <Box
        className={`${classes.cardContainer} ${disabled ? classes.disabledCard : ''}`}
        onClick={disabled ? undefined : handleCardClick}
        sx={{ backgroundColor: bgColor, ...props }}
      >
        <Box className={classes.circularColorPads}>
          <img src={cardTile} alt="crc" />
        </Box>
        <Box display="flex">
          {
            (currentTemplate.new) ?
              <StatusChip
                content={"New"}
                color={theme.palette.tertiary['clr-700']}
                bgColor={theme.palette.tertiary['clr-100']}
                disabled={disabled}
              />
              :
              <Box>
                {
                  (expireSoon)
                    ? <StatusChip
                      content={expire}
                      color={theme.palette.danger['clr-700']}
                      bgColor={theme.palette.danger['clr-100']}
                      disabled={disabled}
                    />
                    : (currentTemplate.maxAllowedAttempts === -1) ?
                      <StatusChip
                        content={'Unlimited Attempts'}
                        color={theme.palette.secondary['clr-900']}
                        disabled={disabled}
                      />
                      :
                      (currentTemplate.type !== templateTypes.ASSIGNMENT) ?
                        <StatusChip
                          content={`Total Attempts: ${getTwoDigitNo(currentTemplate.maxAllowedAttempts)}`}
                          color={theme.palette.secondary['clr-900']}
                          disabled={disabled}
                        />
                        : null
                }
              </Box>
          }
        </Box>

        <Box className={classes.cardContent}>
          <Box mb={theme.spacing(2)}>
            <Typography className={classes.subtitle}>
              {currentTemplate?.type === 'personalized' ? 'Mock Interview' : currentTemplate?.type || ""}
            </Typography>
            <Typography className={classes.title}>
              {currentTemplate?.type === 'personalized' ? currentTemplate?.data?.INTERVIEW_DETAILS?.name : currentTemplate?.name || ""}
            </Typography>
          </Box>
          <Box className={classes.templateDetails}>
            {personalizedIntreview ? (
              <></>
            ) : (
              <>
                <Typography className={classes.content}>
                  <img src={notesIcon} alt="notes" />&nbsp;
                  {
                    (currentTemplate?.type === 'follow_up') ? '05'
                      : getTwoDigitNo(currentTemplate?.metadata?.numberOfQuestions || 0)
                  } Questions
                </Typography>
                <Typography className={classes.content}>
                  <img src={timerIcon} alt="timer" />&nbsp;
                  {
                    (currentTemplate?.type === 'follow_up') ?
                      '5-10 mins' :
                      formateAverageTime(currentTemplate?.metadata?.testTime) || "NA"
                  }
                </Typography>
              </>
            )}
            <Typography className={classes.content}>
              <img src={refreshIcon} alt="refresh" />&nbsp;
              {template._id && personalizedIntreview
                ? template.totalAttemptsCount === 0 ? 'No Attempts' : `${getTwoDigitNo(template.totalAttemptsCount)}x Attempted`
                : (currentTemplate?.attempts === undefined || currentTemplate?.attempts === 0)
                  ? 'No Attempts'
                  : `${getTwoDigitNo(currentTemplate.attempts)}x Attempted`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TemplateToolTip>
  );
}

export default function TemplateList({
  defaultTemplates = [], templates = [], onTemplateExpires, loader, disabled, bgColor, cardTile,
  isPersonalizedInterview
}) {
  const snackbar = useSnackbar();
  const navigateWithClient = useNavigateWithClient();
  const [selectedBatch, setSelectedBatch] = useState(null);

  const [template, setTemplate] = useState();
  const [searchParams,] = useSearchParams();

  React.useEffect(() => {
    const batch = sessionStorage.getItem("selectedBatch");
    setSelectedBatch(batch);
  }, [])

  React.useEffect(() => {
    const aid = searchParams.get('aid');
    const pid = searchParams.get('pid');

    if (!templates.length) return;

    if (pid) {
      const _template = templates.find(({ draft = {} }) => draft?._id === pid);

      if (isPersonalizedInterview && _template && !_template?._id) {
        navigateWithClient(`/personalized-interview/${_template?.draft._id}`);
      }
    }

    if (aid) {
      const _template = templates.find(({ _id }) => _id?.toString() === aid);

      if (_template?.maxAllowedAttempts >= 0 && _template?.attempts >= _template?.maxAllowedAttempts) {
        snackbar.error("Attempt limit reached for this assessment link")
        return;
      }

      if (_template) setTemplate(_template);
    }

  }, [searchParams, selectedBatch, templates.length]);

  return (
    <>
      {loader ?
        Array.from(new Array(3)).map((d, i) => {
          return <TemplateCardSkeleton key={i} />
        })
        :
        templates.map((template, index) => (
          <TemplateCard
            onExpire={onTemplateExpires}
            template={template}
            defaultTemplate={
              defaultTemplates.length === templates.length ?
                defaultTemplates[index] :
                null
            }
            onClick={(t) => setTemplate(t)}
            key={index}
            disabled={disabled}
            bgColor={bgColor}
            cardTile={cardTile}
            personalizedIntreview={isPersonalizedInterview}
          />
        ))
      }
      <StartAssessmentDialog
        onClose={() => setTemplate(undefined)}
        open={!!template}
        template={template}
        clientMock={isPersonalizedInterview ? true : false}
      />
    </>
  );
}
