import { styled } from '@mui/styles';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const Input = styled('input')({
    display: 'none',
});

const styles = {
    label: {

        background: "#E4E3E8",
        borderRadius: 8,
        cursor: "pointer",
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: 32,
        marginBottom: 32,
        display: "flex",
        justifyContent: "center",
    }
};

function ImageUploadButton(props) {
    return (
        <label
            htmlFor="icon-button-file"
            style={styles.label}
        >
            <Input accept="image/*" id="icon-button-file" type="file" {...props} />
            <PhotoCamera color="primary" />
        </label>
    );
}

export default ImageUploadButton;