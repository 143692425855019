import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TourStartDialog from "dialogs/TourStartDialog";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CustomCard, { CustomCardSkeleton } from "./Card";
import { TourProviderContext, useServices, useSnackbar } from "contexts";
import { getblogs } from "services/blogService";
import { getClientPrefix } from "utils";
import AppShell, { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SearchTextField } from "components/CustomFields";
import { FilterChips } from "components/Filter";
import Pagination from "components/Pagination";
import ImageViewer from "components/ImageViewer";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: theme.spacing(5),
  },
  pagination: {
    display: "flex", justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  heroSection: {
    height: '200px',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    width: '100%',
  },
}));

const filters = [
  { field: 'ALL', type: 'bool', label: 'All' },
  { field: 'FLUENCY', type: 'bool', label: 'Fluency' },
  { field: 'WORD_CHOICE', type: 'bool', label: 'Vocabulary' },
  { field: 'DELIVERY', type: 'bool', label: 'Vocal Delivery' },
  { field: 'CONFIDENCE', type: 'bool', label: 'Confidence' },
  { field: 'SIMILARITY', type: 'bool', label: 'Similarity' },
  { field: 'RELEVANCE', type: 'bool', label: 'Relevance' },
  { field: 'LOGIC_FLOW', type: 'bool', label: 'Logic flow' },
];

function BlogPage() {
  const classes = useStyles();
  const clientPrefix = getClientPrefix();
  const service = useServices();
  const snackbar = useSnackbar();

  const { setSteps } = useContext(TourProviderContext);
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cards, setCards] = useState([]);
  const [page, setPage] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(1);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);

  const getBlogs = async () => {
    try {
      setLoading(true);

      let blogFilters = {
        orderBy: "createdAt",
        pageNumber: page + 1,
        pageSize: 9,
        includeTotal: true,
        category: category
      };

      if (search) blogFilters.title = search

      const { blogs, total } = await service.callService(
        getblogs, blogFilters
      );

      setCards(blogs);
      setTotalBlogs(total);
    } catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to get the Blogs");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBlogs();
  }, [page, category, search]);

  const handleFilterChange = ({ field, value }) => {
    setPage(0);
    if (field === filters[0].field) {
      setCategory(null);
    } else {
      setCategory(field);
    }
  };

  // useEffect(() => {
  //   if (!localStorage.getItem('v2.0-blog-tour')) {
  //     localStorage.setItem('v2.0-blog-tour', true.toString());
  //     setSteps(BlogTourSteps);
  //     setTimeout(() => setOpenWelcomeDialog(true), 2000);
  //   }
  // }, []);

  return (
    <AppShell headerLabel="Blogs">
      {/* hero section */}
      <WhiteBox sx={{ position: 'relative', padding: '4px 40px !important' }}>
        <Box className={classes.heroSection}>
          <Typography variant="h2-medium" color='primary.clr-300'>
            Improve your performance with <br />our Blogs!
          </Typography>
          <Box style={{ position: 'absolute', right: '30px', bottom: '0px' }}>
            <ImageViewer
              src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/blogs-hero.svg"
              alt="book-&-person"
              height="150px" width="350px"
            />
          </Box>
        </Box>
      </WhiteBox>

      <WhiteBox>
        <WhiteBoxHeader
          heading="Enhance Your Skills"
          subheading="Here you will find curated blogs to help you become a better communicator"
        >
          <SearchTextField
            sx={{ width: '300px' }}
            placeholder="Search blogs"
            onChange={(e) => setSearch(e.target.value)}
          />
        </WhiteBoxHeader>

        {
          !cards.length ? null :
            <FilterChips
              title="Topic-based filters"
              filters={filters}
              appliedFilters={category === null ? { 'ALL': true } : { [category]: true }}
              onChange={handleFilterChange}
            />
        }

        {/* blog cards */}
        <Box className={classes.cardGrid} id="all-blog-cards-container" mt={8}>
          {
            loading ?
              Array.from(new Array(6)).map((card, i) => {
                return <CustomCardSkeleton key={i} />
              })
              :
              cards.map((card, index) => (
                <Link
                  to={`/${clientPrefix}/blog/${card?._id}`}
                  key={index}
                >
                  <CustomCard
                    image={card?.imageUrl}
                    title={card?.title}
                    category={card?.category}
                    createdAt={card?.createdAt}
                  />
                </Link>
              ))}
        </Box>
        {totalBlogs > 9 ?
          <Box className={classes.pagination}>
            <Pagination
              count={Math.ceil(totalBlogs / 9)}
              page={page}
              setPage={setPage}
            />
          </Box>
          : null
        }
      </WhiteBox>
      <TourStartDialog
        open={openWelcomeDialog}
        onClose={setOpenWelcomeDialog}
        title="Introducing Blogs!!"
        description="Introducing all new feature to view more detailed tips and tricks to improve your communication skills & core concepts. Let's take a quick tour of Blogs and see how it can help you land your dream job!"
      />
    </AppShell>
  );
}

export default BlogPage;
