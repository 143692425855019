import * as React from 'react';
import Box from '@mui/material/Box'
import makeStyles from "@mui/styles/makeStyles";
import { Link, Typography } from '@mui/material';
import lodash from 'lodash';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import ShadowBox from 'components/ShadowBox';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import { useNavigateWithClient } from 'hooks';
import { Session, getTwoDigitNo } from 'utils';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme => ({
  headContent: {
    display: 'flex', gap: theme.spacing(4),
  },
  button: {
    textTransform: 'none', padding: theme.spacing(1,2),
  },
  content: {
    padding: theme.spacing(2,1), 
    position: 'relative'
  },
  assessmentCard: {
    borderRadius: theme.spacing(1), 
    padding: theme.spacing(3,6),
    height: '104px',
    borderLeft: '10px solid',
    borderColor: theme.palette.primary['clr-300'],
    display: 'flex', justifyContent: 'space-between'
  },
  cardRow: {
    display:'flex', flexDirection: 'column', justifyContent:'center', 
    gap: theme.spacing(2)
  },
  cardDetails: {
    display: 'flex', flexWrap: 'wrap', 
    gap: theme.spacing(1),
    '& > *': { 
      display: 'flex', gap: theme.spacing(2),
    },
  },
  cardSpan: {
    color: theme.palette.primary.main
  },
  requestBtn: {
    textTransform: 'none', 
    padding: theme.spacing(1,3),
    backgroundColor: theme.palette.success['clr-700'], 
    color: theme.palette.shades['clr-white-900'],
    '&:hover': {
      backgroundColor: theme.palette.success['clr-500'], 
      color: theme.palette.shades['clr-white-900'],
    }
  },
  link: { 
    ...theme.typography['body01-link'],
    color: theme.palette.primary['clr-300'],
    cursor: 'pointer' 
  }
}));

const AssessmentCardSkeleton = () => {
  const classes = useStyles();

  return (
    <ShadowBox className={classes.assessmentCard} style={{ gap: '10px'}}>
      <Skeleton variant="rectangular" width="50%" height={40} />
      <Skeleton variant="rectangular" width="50%" height={40} />
    </ShadowBox>
  )
}

const AssessmentCard = ({ 
  data, setTemplate, handleIncreaseAttempts, userPathwayMap, module, loading=false, 
  ...props 
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigateWithClient = useNavigateWithClient();

  const getColor=(score, totalAttempts)=>{
    if(!totalAttempts) return theme.palette.primary['clr-300'];
    else if(score>75) return theme.palette.success['clr-500'];
    else if(score>50 && score <75) return theme.palette.warning['clr-300'];
    else if(score<50) return theme.palette.danger['clr-500'];
  }

  const {maxAllowedAttempts, increasedAttempts} = React.useMemo(()=>{
    const increasedAttempts = 
      userPathwayMap?.modulesAnalytics?.[module._id]?.[data?._id]?.increasedAttempts ?? 0;

    return (
      {
        maxAllowedAttempts: ((data?.maxAllowedAttempts || 0) + increasedAttempts),
        increasedAttempts: increasedAttempts
      }
    );
  },[data, userPathwayMap, module]);
  
  return (
    loading ? <AssessmentCardSkeleton/> :
      <ShadowBox 
        className={classes.assessmentCard} 
        style={{borderColor: getColor(data?.bestScore, data?.totalAttempts)}}
      >
        <Box className={classes.cardRow}>
          <Typography variant='h6-medium' color='primary.clr-300'>{ data?.name }</Typography>
          <Box className={classes.cardDetails}>
            <Box mr='10px'>
              <Typography variant='body01-semiBold' color='neutral.clr-800'>Attempts: &nbsp;
                <span className={classes.cardSpan}>
                  {getTwoDigitNo(data?.totalAttempts)}/{getTwoDigitNo(maxAllowedAttempts)}
                </span>
              </Typography>
              <Typography variant='body01-semiBold' color='neutral.clr-800'>Total questions: &nbsp;
                <span className={classes.cardSpan}>{getTwoDigitNo(data?.metadata?.numberOfQuestions)}</span>
              </Typography>
            </Box>
            <Box>
              <Typography variant='body01-semiBold' color='neutral.clr-800'>Best score: &nbsp;
                <span className={classes.cardSpan}>
                  { data?.totalAttempts>0 ? getTwoDigitNo(data?.bestScore) : "--" }
                </span>
              </Typography>
              <Typography variant='body01-semiBold' color='neutral.clr-800'>Last score: &nbsp;
                <span className={classes.cardSpan}>
                  { data?.totalAttempts>0 ? getTwoDigitNo(data?.lastScore) : "--" }
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.cardRow} mt='5px'>
          {
            data?.totalAttempts > 0 ? 
            <>
              {
                !data?.totalAttempts < maxAllowedAttempts
                ? <CustomButton size='small' variant='outlined' onClick={()=>setTemplate(data)}>
                    Practice&nbsp;Again
                  </CustomButton> 
                : !increasedAttempts && module?.masterModule 
                  ? <CustomButton className={classes.requestBtn} onClick={()=>handleIncreaseAttempts(data)}>
                      Request&nbsp;Attempts
                    </CustomButton> 
                  : null
              }
              <Link underline="always" className={classes.link} 
                onClick={() => navigateWithClient(`/performance/assessment/${data?._id}`)}
              >
                View&nbsp;performance
              </Link>
            </>
            :
            <CustomButton size='small' variant='contained' onClick={()=>setTemplate(data)}> 
              Attempt&nbsp;Now 
            </CustomButton>
          }
        </Box>
      </ShadowBox>
  )
}


const Assessments = ({ 
  templates, module, activeModule, handleIncreaseAttempts, userPathwayMap, loading=false, 
  ...props 
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [template, setTemplate] = React.useState();

  const allTemplates = React.useMemo(()=>{
    return lodash.orderBy(templates, ["createdAt", "_id"], ["asc", "asc"]);
  },[templates]);

  return (
    <WhiteBox id='module-assessments-container' style={{height: '100%'}}>
      <WhiteBoxHeader
        heading='Assessments'
        subheading={<>
          <Box className={classes.headContent}>
            <Typography variant='body01-bold' color='neutral.clr-600'>
              Module Score: &nbsp;
              <span style={{ color: theme.palette.primary.main }}>
                { 
                  module?.moduleAverage ? `${module?.moduleAverage}%` : "--"
                }
              </span>
            </Typography>
            <Typography variant='body01-bold' color='neutral.clr-600'>
              Assessments completed: &nbsp;
              <span style={{ color: theme.palette.primary.main }}>
                {getTwoDigitNo(module?.templatesCompleted)}/{getTwoDigitNo(module?.templates?.length)}
              </span>
            </Typography>
          </Box>
        </>}
      />

      <Divider/>

      <Box className={classes.content} style={{ height: '85%', overflow: 'auto' }}>
          {
            (Array.from((allTemplates && allTemplates.length > 0) 
              ? allTemplates : new Array(6)))?.map((template,i)=>(
              <AssessmentCard 
                key={i}
                module={module}
                data={template} 
                setTemplate={setTemplate} 
                handleIncreaseAttempts={handleIncreaseAttempts}
                userPathwayMap={userPathwayMap}
                loading={loading}
              />
            ))
          }
      </Box>
      <StartAssessmentDialog
        onClose={() => setTemplate(undefined)}
        open={!!template}
        template={{...template, expiresOn: Session.user?.metadata?.accessExpiresOn}}
      />
    </WhiteBox>
  )
}

export default Assessments;