import { HighlightCategoriesMap } from "components/PerformanceReview/constants";
import lodash, { isArray } from 'lodash';
import { SUB_CATEGORIES } from "./features";

const TargetedSubCategory = {
  FLUENCY: ['FILLER_WORDS', 'REPETITION', 'FILLER_PHRASES'],
  JARGON_WORDS: ['JARGON_WORDS'],
  VOCABULARY: ['INFORMAL_WORDS', 'RARE_USED_WORDS'],
}

export const calculateErrorCount = (parameters) => {
  let totalErrors = {
    FLUENCY: 0,
    JARGON_WORDS: 0,
    VOCABULARY: 0,
  };

  let positionExist = false;
  
  for (let i = 0; i < parameters.length; i++) {
    const parameter = parameters[i];
    const category = parameter?.category;
    const subCategory = parameter?.subCategory;
    if(Array.isArray(parameter?.value) && parameter.value[0]?.positions){
      positionExist = true;
    }
  
    (Array.isArray(parameter?.value) ? parameter?.value : []).forEach((data) => {
      totalErrors[subCategory === SUB_CATEGORIES.JARGON_WORDS ? subCategory : category] 
        += data?.positions?.length;
    });
  }

  return {totalErrors, positionExist};
}

export const getAllPositions = (parameters, category) => {
  parameters = parameters.filter(parameter => 
      TargetedSubCategory[category]?.includes(parameter.subCategory))
      .map(parameter => ({
        ...HighlightCategoriesMap[parameter.subCategory],
        ...parameter
    }));

  let separatedParameters = [];
  parameters?.forEach(item => {
    (isArray(item?.value) ? item?.value : [])?.forEach(data=>{
      data.positions?.forEach(position => {
        let newItem = {...item, positions: position, value: data};
        separatedParameters.push(newItem);
      });
    })
  });

  separatedParameters = lodash.sortBy(separatedParameters, item => item.positions?.start);

  let highlightingPositons =[];

  if(separatedParameters?.length>0){
    separatedParameters.forEach((parameter,i)=>{
      const newPos = parameter.positions;
      let overlapped = false;
      highlightingPositons.forEach((previous, index)=>{
        if(newPos?.start > previous.positions?.start && newPos?.end < previous.positions?.end){
          overlapped = true;
          let filteredPara = highlightingPositons.filter((data, i)=> i !== index);
          let overlappingPara = highlightingPositons.filter((data,i)=> i===index);
          
          filteredPara.push(
            {...overlappingPara[0] , positions: [previous.positions?.start, newPos?.start]},
            {...parameter, positions: [newPos?.start, newPos?.end]},
            {...overlappingPara[0] , positions: [newPos?.end, previous.positions?.end]},
          );

          highlightingPositons = filteredPara;
        }
      });
      if(!overlapped){
        highlightingPositons.push({...parameter, positions: [newPos?.start, newPos?.end]});
      }
    })
  }else{
    highlightingPositons = [];
  }

  return highlightingPositons;
}


export const splitParagraphWithHighLights = (positions, question) => {
  if (!positions || !question || !question.transcript) {
    return [];
  }

  const splitArray = [];
  const { transcript } = question;
  let lastPositionEnd = 0;

  positions.forEach((highlight, index) => {
    const [start, end] = highlight.positions;

    if (start > lastPositionEnd) {
      splitArray.push({ transcript: transcript.substring(lastPositionEnd, start-1) });
    }

    splitArray.push({ transcript: transcript.substring(start-1, end), ...highlight });

    lastPositionEnd = end;

    if (index === positions.length - 1 && end < transcript.length) {
      splitArray.push({ transcript: transcript.substring(end) });
    }
  });

  if (positions.length === 0) {
    splitArray.push({ transcript });
  }

  return splitArray;
}