import { useEffect, useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { makeStyles } from '@mui/styles'
import {
    Slider, Button, Typography, Box, Dialog, DialogContent
} from '@mui/material'

import CustomDialogTitle from "components/CustomDialogTitle";
import ImageUploadButton from 'components/Profile/ImageUploadButton'
import { useServices, useLoading, useSnackbar } from 'contexts'

import { getCroppedImg, getRotatedImage } from 'utils'

import { updateProfilePicture } from "services";

const useStyles = makeStyles(theme => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

function ProfileUploadDialog({
    open,
    onClose = () => { },
}) {
    const classes = useStyles();
    const loading = useLoading();
    const service = useServices();
    const snackbar = useSnackbar();

    const [imageSrc, setImageSrc] = useState(null);
    const [imageType, setImageType] = useState("");
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            loading.show();
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation,
                {},
                imageType
            );

            const result = await service.callService(updateProfilePicture, croppedImage);

            onClose(result.profileUrl);
        } catch (e) {
            console.error(e);
            snackbar.error("Uh Oh! Unable to upload photo. Please try again");
        } finally {
            loading.hide();
        }
    }, [imageSrc, croppedAreaPixels, rotation]);

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setImageType(file.type);

            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl);
        }
    }

    useEffect(() => {
        if (open) {
            setImageSrc(null);
            setCrop({ x: 0, y: 0 });
            setRotation(0);
            setZoom(1);
            setCroppedAreaPixels(null);
            setImageType("");
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <CustomDialogTitle onClose={onClose}>Edit your profile</CustomDialogTitle>
            <DialogContent >
                <div>
                    {imageSrc ? (
                        <>
                            <div className={classes.cropContainer}>
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={1 / 1}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div className={classes.controls}>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Rotation
                                    </Typography>
                                    <Slider
                                        track={false}
                                        value={rotation}
                                        defaultValue={0}
                                        min={-180}
                                        max={180}
                                        step={0.1}
                                        aria-labelledby="Rotation"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, rotation) => setRotation(rotation)}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <ImageUploadButton onChange={onFileChange} />
                    )}
                </div>
                <Box className={classes.actions} >
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={showCroppedImage}
                    >
                        Save
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{ ml: 1 }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ProfileUploadDialog;
