import * as React from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';

import Module, { CertificateCard } from './Module';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';

const Modules = ({ 
  modules, userPathwayMap, activeModule, setActiveModule, setOpenCertificate, loading,
  ...props
}) => {
  
  return (
    <WhiteBox>
      <WhiteBoxHeader
        heading='Modules'
        subheading='Pathway is divided into modules based on chapters'
      />
      <Divider/>
      <Box>

        <HorizontalScrollNavigator
          childrensLength={modules.length}
        >
          {
            [...(Array.from((modules && modules.length > 0) 
              ? modules : new Array(6))).map(((data, index)=> (
                <Module
                  data={data}
                  key={index}
                  moduleIndex={index}
                  activeModule={activeModule}
                  setActiveModule={setActiveModule}
                  loading={loading}
                />
                ))),
                <CertificateCard
                  moduleIndex={'last'}
                  activeModule={activeModule}
                  locked={ !userPathwayMap?.certificate }
                  setOpenCertificate={setOpenCertificate}
                  loading={loading}
                />
            ]
          }
        </HorizontalScrollNavigator>
      </Box>
    </WhiteBox>
  )
}

export default Modules;