import Client from "./_client";

export const getBlogsByScore = async (filters) => {
    let url = "/blog/score/?";

    Object.keys(filters).forEach((key) => {
        if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
    });

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! unable to get Blogs. Please try again"
        );
    }

    return result?.data;
};

export const getblogs = async (filters) => {
    let url = "/blog?";

    Object.keys(filters).forEach((key) => {
        if (filters[key]) url += `${key}=${filters[key]}&`;
    });

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! unable to get Blogs. Please try again"
        );
    }

    return result?.data;
};

export const getblogById = async (id) => {
    let url = `/blog/${id}`;

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! unable to get Bookmarks. Please try again"
        );
    }

    return result?.data;
};