import Session from "../utils/Session";
import Client from "./_client";

export const signupWithToken = async ({
  email, name, password, clientToken,
}) => {
  const result = await new Client({
    path: "/signup?withToken=true",
    payload: {
      email,
      name,
      password,
      clientToken,
      role: "STUDENT",
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to Sign Up. Please try again."
    );
  }
  else {
    Session.onCreateSession(result.data);
    return result.data;
  }
};

export const signup = async ({
  email, name, password, activationUrl,
}) => {
  const result = await new Client({
    path: "/signup",
    payload: {
      email,
      name,
      password,
      activationUrl,
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to Sign Up. Please try again."
    );
  }

  if (result.data.verified) Session.onCreateSession(result.data);

  return result.data;
};

export const signupWithOTP = async ({
  email, name, password, otpId, otp, client
}) => {
  const result = await new Client({
    path: "/signup?withOTP=true",
    payload: { email, name, password, otpId, otp, client },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to Sign Up. Please try again."
    );
  }

  Session.onCreateSession(result.data);

  return result.data;
};