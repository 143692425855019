import React from 'react'
import useStore from './PerformanceReview/store';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from 'chart.js';
import shallow from 'zustand/shallow';
import makeStyles from "@mui/styles/makeStyles";

import { round } from 'utils';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material'
import { DemoApplications } from './PerformanceReview/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
);

const useStyles = makeStyles(theme => ({
  root: {
    width: "80%",
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: 'center'
  },
  legendRoot: {
    marginRight: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  legend: {
    ...theme.typography['body02-bold'],
  },
  graphTitles: {
    ...theme.typography['body02-bold'],

  }
}));

const AttemptGraph = ({ recentAttempts, currentAttempt }) => {
  const classes = useStyles();
  const theme = useTheme();
  const attempts = (recentAttempts.filter((value, index) => index < 4)).reverse();
  const demoApplication = useStore(state => state.demoApplication, shallow);

  let chartOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
      }
    },
    scales: {
      yAxis: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
        },
      }
    },
  };

  React.useEffect(() => {
    chartOptions.plugins.tooltip.callbacks = {
      title: ([item]) => recentAttempts[item.dataIndex].name
    }
  })
  const labels = React.useMemo(() => {
    const _labels = attempts.map((x, i) => x.attemptNumber);
    const labelsLength = (demoApplication === DemoApplications.teachAndTrain ? 3 : 4)

    if (_labels.length < labelsLength) {
      for (let x = _labels.length; x < labelsLength; x++) _labels.push('');
    }

    return _labels;
  }, [attempts]);

  const datasets = React.useMemo(() => {
    const _datasets = [];
    _datasets.push({
      label: 'Speech',
      data: attempts.map(qs => round(qs.communication)),
      backgroundColor: attempts.map((value, index) => (
        value._id === currentAttempt ?
          theme.palette.tertiary['clr-600'] : theme.palette.tertiary['clr-100']
      )),
      borderRadius: 5
    });
    _datasets.push({
      label: 'Content',
      data: attempts.map(qs => round(qs.content)),
      backgroundColor: attempts.map((value, index) => (
        value._id === currentAttempt ?
          theme.palette.secondary['clr-700'] : theme.palette.secondary['clr-500']
      )),
      borderRadius: 5
    });
    return _datasets;
  }, [attempts]);

  return (
    <>
      <Box className={classes.root}>
        <Box width={"100%"} textAlign="right">
          <Box className={classes.legendRoot}>
            <Typography className={classes.legend} color='secondary.clr-700'>Speech</Typography>
            &nbsp;&nbsp;
            <Typography className={classes.legend} color='tertiary.main'>Content</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            className={classes.graphTitles}
            style={{ transform: "rotate(-90deg)" }}
          >
            Score
          </Typography>
          <div style={{ width: "100%" }}>
            <Bar
              options={chartOptions}
              data={{ labels, datasets }}
            />
          </div>
        </Box>
        <div>
          <Typography
            className={classes.graphTitles}
            style={{ fontWeight: "600" }}
          >
            Attempt No.
          </Typography>
        </div>
      </Box>
    </>
  )
}

export default AttemptGraph