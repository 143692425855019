import { Box, Typography } from "@mui/material";
import { Session } from "utils";

export default function OnboadingHeader({ clientLogoUrl }) {
    const { logoUrl, inprepLogoTransform: transform } =
        Session.getClientMetadata() || {};

    return (
        <Box
            height="15vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {
                logoUrl || clientLogoUrl ?
                    <img
                        src={logoUrl || clientLogoUrl}
                        alt={Session?.client?.name}
                        style={{
                            height: '60px',
                            width: 'auto',
                            transform:
                                `translate(${transform?.translate?.x || 0}px, 
                                        ${transform?.translate?.y || 0}px) 
                                        rotate(${transform?.rotation || 0}deg) 
                                        scale(${transform?.scale || 1})`
                        }}
                    />

                    :
                    <Typography variant="h4" color='primary'>
                        Welcome to InPrep
                    </Typography>

            }
        </Box>
    );
}