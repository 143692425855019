import { Box, Button, Typography } from "@mui/material";
import AppShell from "components/AppShell";

const RedirectUrl = "https://languify.in";

function MobileDeviceError() {
  return (
    <AppShell>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={5}
        boxSizing="border-box"
      >
        <img
          alt="error"
          src="https://assets.languify.in/images/error.svg"
        />
        <Typography variant="h5" align="center" p={1}>Unsupported Device</Typography>
        <Typography variant="body2" component="p" align="center">
          The information requested by you is not available on mobile browser.
          Please use a pc for accessing this information.
        </Typography>
        <Box m={4}>
          <a href={RedirectUrl} style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: 60,
              }}
            >
              Home
            </Button>
          </a>
        </Box>
      </Box>
    </AppShell>
  );
}

export default MobileDeviceError;