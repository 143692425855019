import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import useAssessmentStore from "./assessmentStore";


const useStyles = makeStyles(theme=>({
    seperator: {
      color: theme.palette.primary.main,
      fontWeight: "bolder",
      fontSize: 20,
      padding: theme.spacing(0,1.5)
    },
    timeDigit: {
      backgroundColor: theme.palette.shades['clr-white-900'],
      color: theme.palette.shades['clr-black-900'],
      padding: theme.spacing(1,2),
      borderRadius: 2,
      boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.45)',
      ...theme.typography['h5-semiBold']
    },
}));

function Seperator() {
    const classes = useStyles();
    return (
        <span className={classes.seperator}>:</span>
    );
}

function TimeDigit({ digit }) {
    const classes = useStyles();
    return (
        <span className={classes.timeDigit}>{digit}</span>
    );
}

function TotalTimer({ time, template, ...props }) {
    const endAssessment = useAssessmentStore(state => state.endAssessment);
    const setTimeInterval = useAssessmentStore(state => state.setTimeInterval);
    const isTimePaused = useAssessmentStore(state => state.isTimePaused);
    const setRemainingInterviewTime = useAssessmentStore(state => state.setRemainingInterviewTime);
    const pauseTimeRef= React.useRef({current: false});

    const [timeSlices, setTimeSlices] = React.useState(
        ['0', '0', ':', '0', '0', ':', '0', '0']
    );
    const [timeLeft, setTimeLeft] = React.useState(0);
    const timerRef = React.useRef(null);

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor(((total / 1000) / 60 / 60) % 24);
        setRemainingInterviewTime(total);
        return {
            total,
            hours,
            minutes,
            seconds
        };
    };

    const startTimer = (e) => {
        if(pauseTimeRef.current){
          e.setTime(e.getTime() + 1000);
        }
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimeSlices([
                ...(hours > 9 ? hours.toString().split("") : ["0", hours]),
                ":",
                ...(minutes > 9 ? minutes.toString().split("") : ["0", minutes]),
                ":",
                ...(seconds > 9 ? seconds.toString().split("") : ["0", seconds])
            ]);
            setTimeLeft(pauseTimeRef.current ? timeLeft : timeLeft - 1);
        } else {
            endAssessment(true);
        }
    };

    const clearTimer = (e) => {
        setTimeSlices(['0', '0', ':', '0', '0', ':', '0', '0']);
        setTimeLeft(time);

        if (timerRef.current){
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
        const id = setInterval(() => {
          startTimer(e);
        }, 1000);
        timerRef.current = id;
        setTimeInterval(id);
    };

    React.useEffect(() => {
      return () => {
          if (timerRef.current) {
              clearInterval(timerRef.current);
              console.log('Component unmounting, clearing timer:', timerRef.current);
          }
      };
    }, []);

    const getDeadLine = (time) => {
        const deadline = new Date(Date.now() + time * 60 * 1000);
        return deadline;
    };

    React.useEffect(() => {
      pauseTimeRef.current = isTimePaused;
    }, [isTimePaused]);

    React.useEffect(() => {
        clearTimer(getDeadLine(time));
    }, [time]);

    return timeSlices.length ? (
      <Box>
        <Typography variant='h5-semiBold' color='primary.main'>{template?.name}</Typography>
        <Box {...props} display="flex" alignItems="center" mt={2}>
          <Typography variant='h5-medium'>
              Time Remaining
          </Typography>
          <Box display='flex' alignItems='center' ml='10px'>
              {!(timeSlices[0] === '0' && timeSlices[1] === 0) &&
                  <>
                      <TimeDigit digit={`${timeSlices[0]}${timeSlices[1]}`} />
                      <Seperator />
                  </>
              }
              <TimeDigit digit={`${timeSlices[3]}${timeSlices[4]}`} />
              <Seperator />
              <TimeDigit digit={`${timeSlices[6]}${timeSlices[7]}`} />
          </Box>
        </Box>
      </Box>
    ) : null;
}

export default TotalTimer;