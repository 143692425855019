import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import dayjs from "dayjs";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import TemplateList from "components/TemplateList";
import CustomButton from "components/CustomButton";
import FollowupList from "./FollowupList";
import HorizontalScrollNavigator from "components/HorizontalScrollNavigator";
import PersonalizedInterviewList from "./PersonalizedInterviewList";
import Banner from "components/Banner";
import { useServices } from "contexts/SingleSessionProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import { getTodoTemplates } from "services";
import { Session, getTwoDigitNo, sortTodoList } from "utils";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SearchTextField } from "components/CustomFields";
import { FilterChips } from "components/Filter";


const useStyles = makeStyles(theme=>({
    noAssessmentDesc: {
      margin: theme.spacing(1,0),
    },
    noComponentRoot: {
      height:'100%', width:'100%', 
      display:'flex', justifyContent:'center', alignItems:'center',
      flexDirection: 'column', textAlign: 'center'
    },
    clearFilters: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(2),
      justifyContent: "center",
    },
    warningBox: {
      backgroundColor: theme.palette.warning['clr-100'],
      border: '1px solid',
      borderColor: theme.palette.warning['clr-300'],
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      width: 'fit-content',
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center'
    },
}));

const filters = [
  { field: 'expiresSoon', type: 'bool', label: 'Expires Soon', },
  { field: 'new', type: 'bool', label: 'New Published', },
  { field: 'assignment', type: 'bool', label: 'Assignment', },
  { field: 'practice', type: 'bool', label: 'Practice', },
];

export function NoResultComponent({ clearFilters, description='No assessments found' }) {
  const classes = useStyles();

  return (
    <Box className={classes.noComponentRoot}>
        <img
          height="120"
          src="https://assets.languify.in/images/assessment_illustration.svg"
          alt="Not-found"
        />
        <Typography className={classes.noAssessmentDesc} variant='h5-medium' color='neutral.clr-500'>
            {description}
        </Typography>
        {clearFilters && (
          <Box className={classes.clearFilters}>
              <CustomButton onClick={clearFilters}>
                  Clear filters
              </CustomButton>
          </Box>
        )}
    </Box>
  );
}

export default function TodoList({ batch, removedFromBatch }) {
    const theme = useTheme();
    const classes = useStyles();

    const service = useServices();
    const snackbar = useSnackbar();

    const [todoTemplates, setTodoTemplates] = useState([]);
    const [loader, setLoader] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        if (!batch) return;

        (async () => {
            try {
                setLoader(true);

                let todoTemplates =
                    await service.callService(getTodoTemplates, batch);

                todoTemplates.forEach((template)=>{
                  const { publishOn, expiresOn, attempts } = template;

                  template.new = false;
                  template.expiresSoon = false;

                  if (dayjs(expiresOn).year() === 9999) {
                    (!attempts) ? template.new = true : template.new = false
                    template.expiresSoon = false;
                  }else{
                    const startDate = dayjs(publishOn);
                    const endDate = dayjs(expiresOn);

                    const completedDate = dayjs();
                    const percentageValue = 0.16;

                    const timeElapsedInMinutes = endDate.diff(startDate, "minute");
                    const percentOfMinuteElapsed = percentageValue * timeElapsedInMinutes;
                    const thresholdDate = startDate.add(percentOfMinuteElapsed, 'minute');
                    const thresholdDateForExpire = endDate.subtract(percentOfMinuteElapsed, 'minute');
                    (completedDate.isBefore(thresholdDate) && !attempts) ? template.new = true : template.new = false;
                    
                    template.thresholdDateForExpire = thresholdDateForExpire;

                    if (completedDate.isSame(endDate, "day")) {
                      if (completedDate.isAfter(thresholdDateForExpire)) {
                        template.expiresSoon = true;
                      } else {
                        template.expiresSoon = false;
                      }
                    } else {
                      if (completedDate.isAfter(thresholdDateForExpire)) {
                        template.expiresSoon = true;
                      }
                    }
                  }
                });
                
                todoTemplates.sort(sortTodoList);

                if(
                  todoTemplates.length &&
                  Session.user.metadata?.isB2cUser &&
                  (todoTemplates[0]?.maxAllowedAttempts === todoTemplates[0]?.attempts)
                ){
                  setShowBanner(true)
                }

                setTodoTemplates(todoTemplates);
            } catch (error) {
                snackbar.error("Unable to load templates");
            } finally {
                setLoader(false);
            }
        })();
    }, [batch]);

    const handleFilterChange = ({ field, value }) => {
      setAppliedFilters({});
      setAppliedFilters((afs) => {
        const newFilters = { ...afs };

        if (value && !Object.keys(appliedFilters).includes(field)) newFilters[field] = value;
        else delete newFilters[field];

        return newFilters;
      });
      setSearchQuery("");
    };

    const isFilterApplied = Object.keys(appliedFilters).length;
    const filteredTemplates = todoTemplates.filter((t) =>
        (isFilterApplied === 0 ||
            ((t.type === 'practice' && appliedFilters.practice) ||
                (t.type === 'assignment' && appliedFilters.assignment) ||
                (t.expiresSoon && appliedFilters.expiresSoon) ||
                (t.new && appliedFilters.new))) &&
        (searchQuery === '' ||
            t.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleTemplateExpires = (templateId) => {
        setTodoTemplates(todoTemplates.filter(({ _id }) => (_id !== templateId)));
    }

    return (
      showBanner ? <Banner/> :
        <WhiteBox id="to-do-list-container" sx={{ position: 'relative'}}>
          <WhiteBoxHeader 
            id='to-do-list'
            heading="My Assessments" 
            sx={{ marginBottom: theme.spacing(4) }}
            subheading={
              <>
                Total : &nbsp;
                <span style={{color: theme.palette.primary.main}}>
                  {getTwoDigitNo(filteredTemplates.length)}
                </span>
              </>
            }
          >
            <SearchTextField
              placeholder="Search assessment..."
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={ 
                removedFromBatch || 
                (!filteredTemplates.length && !searchQuery.length) || 
                !todoTemplates.length
              }
            />
          </WhiteBoxHeader>

          <Divider/>

          {
            !(todoTemplates.length) ? null :
              <FilterChips
                title="Topic-based filters"
                filters={filters} 
                appliedFilters={appliedFilters}
                onChange={handleFilterChange}
                disabeled={
                  removedFromBatch || 
                  (!filteredTemplates.length && 
                    (!Object.keys(appliedFilters).length && searchQuery.length))
                  }
              />
          }

          <Box height={280}>
            {todoTemplates.length || loader ? (
              <HorizontalScrollNavigator
                NoResultComponent={NoResultComponent}
                NoResultComponentProps={{
                  description: isFilterApplied
                    ? "No assessment found for the selected filter!" 
                    : "No assessment found",
                  clearFilters: 
                    isFilterApplied 
                    ? () => setAppliedFilters({})
                    : undefined 
                
                }}
                childrensLength={filteredTemplates.length} scrollWidth={800}
              >
                <TemplateList
                    defaultTemplates={todoTemplates}
                    templates={filteredTemplates}
                    onTemplateExpires={handleTemplateExpires}
                    loader={loader}
                    disabled={removedFromBatch}
                />
              </HorizontalScrollNavigator>
            ) : (
              <NoResultComponent 
                description={
                  <>No assessment is published for your batch so far!<br/>once published you can see in this section</>
                }
              />
            )}
          </Box>

          <PersonalizedInterviewList batch={batch} removedFromBatch={removedFromBatch}/>

          <FollowupList batch={batch} removedFromBatch={removedFromBatch}/>

          {
            removedFromBatch ? 
            <Box className={classes.warningBox} mt={6}>
              <WarningAmberOutlinedIcon style={{ color: "#664100", fontSize: '20px' }}/>
              <Typography variant="body01-bold" color='warning.clr-700'>
                It seems you are removed from this batch, contact your institute or Admin for the access.
              </Typography>
            </Box>
            : null
          }

        </WhiteBox>
    );
}