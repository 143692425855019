import Session from "../utils/Session";
import Client from "./_client";
import { getDomains } from "./domainService";

export const getTemplateById = async (templateId, filters={}) => {
  let url = `/interviewTemplate/${templateId}?`;

  Object.keys(filters).forEach((key) => {
    if (filters[key]) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get template. Please try again."
    );
  } else {
    return result.data;
  }
};

export const getTemplateWithQuestionsById = async (templateId) => {
  let url = `/interviewTemplate/bulk/${templateId}`;
  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get template with questions. Please try again."
    );
  }

  return result.data;
};

export const saveTemplateWithQuestions = async (templateId, interviewTemplate, questions) => {
  let result;
  if (templateId) {
    result = await new Client({
      path: `/interviewTemplate/bulk/${templateId}`,
      payload: {
        interviewTemplate,
        questions
      },
    }).patch();
  } else {
    result = await new Client({
      path: `/interviewTemplate/bulk`,
      payload: {
        interviewTemplate: {
          ...interviewTemplate,
          user: Session.userId
        },
        questions
      },
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save template. Please try again."
    );
  } else {
    return result.data;
  }
};

export const saveTemplate = async (templateId, templateData) => {
  let result;
  if (templateId) {
    result = await new Client({
      path: `/interviewTemplate/${templateId}`,
      payload: {
        name: templateData.name,
        domain: templateData.domain,
        user: Session.userId,
      },
    }).put();
  } else {
    result = await new Client({
      path: `/interviewTemplate`,
      payload: {
        name: templateData.name,
        domain: templateData.domain,
        user: Session.userId,
      },
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save template. Please try again."
    );
  } else {
    return result.data;
  }
};

export const getTemplates = async (templateFilters = {}) => {
  let url = "/interviewTemplate?include=domain,user&";

  Object.keys(templateFilters).forEach((key) => {
    if (templateFilters[key]) url += `${key}=${templateFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get templates. Please try again."
    );
  }

  return result.data;
};

export const deleteTemplateById = async (templateId) => {
  const url = `/interviewTemplate/${templateId}`;

  const result = await new Client({
    path: url,
  }).delete();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to delete template. Please try again."
    );
  } else {
    return result?.data?.isDeleted;
  }
};

export const getTemplateFormOptions = async () => {
  const { interviewDomains } = await getDomains();

  return {
    domains: interviewDomains,
  };
};

export const getTodoTemplates = async (batch) => {
  const clientId = Session.clientId;

  const result = await new Client({
    path: `/interviewTemplate/todo?client=${clientId}&batch=${batch}`
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get todo templates. Please try again."
    );
  }

  return result.data;
};

export const getPersonolizedTodoTemplates = async (batch) => {

  let path = ''

  if(batch){
    path = `/interviewTemplate/personalizedTodoList?batch=${batch}`
  }else{
    path = `/interviewTemplate/personalizedTodoList`
  }

  const result = await new Client({
    path : path
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get todo templates. Please try again."
    );
  }

  return result.data;
}

export const getPersonolizedTemplatesAnalytics = async () => {

  let path = '/interviewTemplate/personalized-template-analytics'

  const result = await new Client({
    path : path
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get analytics. Please try again."
    );
  }

  return result.data;
}

export const createPersonalizedTemplate = async (data) => {
  let url = '/interviewTemplate/personalized-template';

  const result = await new Client({
      path: `${url}`,
      payload: data
  }).post();

  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to save personalized template. Please try again."
      );
  }

  return result?.data;
}

export const getPersonalizedInterview = async (draftFilters = {}) => {
  let url = `/interviewTemplate/personalized?`;
  Object.keys(draftFilters).forEach((key) => {
      if (draftFilters[key]) url += `${key}=${draftFilters[key]}&`;
  });
  const result = await new Client({
      path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message || "Uh Oh! Unable to get draft. Please try again."
      );
  }
  return result?.data;
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTemplateById,
  saveTemplate,
  getTemplates,
  deleteTemplateById,
  getTemplateFormOptions,
  getTodoTemplates,
  getPersonolizedTodoTemplates,
  createPersonalizedTemplate,
  getPersonalizedInterview
};
