import { useState, useMemo } from "react";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SimpleSelect } from "components/CustomSelectFields";
import { PerformanceGraph } from "components/PerformanceGraph";
import { getAverageScore } from "utils";
import { ScoreTypes, templateTypes } from "./constants";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import AttemptGraph from "components/AttemptGraph";
import shallow from "zustand/shallow";
import useStore from "./store";
import CustomButton from "components/CustomButton";
import StartAssessmentDialog from "dialogs/StartAssessmentDialog";

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  scoreGraphContainer: {
    width: '52%',
    minWidth: 500,
  },
  scoreGraphContainerFull: {
    width: '100%',
    minWidth: "100%",
    minHeight: "100%",
  },
  attemptGraphContainer: {
    width: '46%',
  },
  oneAttemptBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.shades["clr-black-900"],
    minWidth: '100%',
    opacity:'50%',
    borderRadius:theme.spacing(1)
  },
  innerBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:theme.spacing(2),
    minHeight:'265px',
    backgroundColor:theme.palette.shades["clr-white-900"],
    width:'100%',
    margin:theme.spacing(11,16.5)
  }
}));


function UnlockCard({template}) {
  const [startTemplate, setStartTemplate] = useState();

  return (
    <>
      <Box
        height='100%' padding={8} boxShadow='0px 0px 2px 0px #000000BF'
        borderRadius={1} backgroundColor='#424242BF' display='flex' justifyContent='center'
        alignItems='center'
      >
        <Box
          height={'100%'} backgroundColor='white' borderRadius={1} display='flex'
          flexDirection='column' justifyContent='center' alignItems='center' px={4} py={5} width={400}
        >
          <Typography variant='h6-medium' color='shades.clr-black-900' style={{ textAlign: 'center' }} >
            Want to see how you are performing in <br />
            each attempt?
          </Typography>

          <Typography variant='body01-medium' color='neutral.clr-800' my={4} >
            Take one more attempt to unlock this card
          </Typography>

          <img width={56} src='https://assets.languify.in/images/feedback-lock.svg' alt='Locked' />

          <CustomButton 
            variant="contained" 
            style={{ marginTop: '18px', width: '100%', }}
            onClick={() => setStartTemplate(template)}
          >
            Reattempt now
          </CustomButton>
        </Box>
      </Box>
      <StartAssessmentDialog
        onClose={() => setStartTemplate(undefined)}
        open={!!startTemplate}
        template={startTemplate}
      />
    </>
  );
}

function AttemptGraphContainer({ allAttempts = [] }) {
  const attemptId = useStore(state => state.attemptId);

  const recentAttempts = useMemo(() => {
    const { index } = allAttempts?.find((attempt) => attempt._id === attemptId) || {};

    return (
      allAttempts.length <= 4 ?
        allAttempts :
        allAttempts.slice(index, index + 4)
    );
  }, [allAttempts, attemptId]);

  return (
      <>
        <Typography variant='h6-medium' >
          Your attempts performance
          </Typography> <br />
          <Typography variant='body01-bold' color='neutral.clr-600' >
            Comparison of your recent {recentAttempts?.length} attempts scores
          </Typography>
          <Box mt={8}>
            <AttemptGraph
              recentAttempts={recentAttempts}
              currentAttempt={attemptId}
            />
        </Box>
      </>
  )
}

export default function GraphAnalysis(props) {
  const classes = useStyles();

  const allAttempts = useStore(state => state.allAttempts, shallow);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion, shallow);
  const questions = useStore(state => state.questions, shallow);
  const template = props.template || {};

  const [scoreType, setScoreType] = useState('overall');

  return (

    <WhiteBox id="attempts-performance-container">

      <WhiteBoxHeader
        id="attempts-performance"
        heading='Your attempts performance'
        subheading='Here you will find your detailed performance of your attempts'
      >
        <SimpleSelect
          label='Score type'
          options={ScoreTypes}
          onSelect={(e) => setScoreType(e.target.value)}
          value={scoreType}
          defaultValue={scoreType}
        />
      </WhiteBoxHeader>

      <Box className={classes.graphContainer} mt={8}>
        <Box 
          className={
            template.type === templateTypes.ASSIGNMENT 
            ? classes.scoreGraphContainerFull 
            : classes.scoreGraphContainer
          }
        >
          <PerformanceGraph
            hideHeader
            xLabel="Questions"
            onClick={setCurrentQuestion}
            labelPrefix="Q"
            data={questions?.map((question) => {
              const communication = question?.communication?.ratings?.OVERALL || 0;
              const content = question?.content?.ratings?.OVERALL || 0;
              return {
                communication,
                content,
                overall: getAverageScore(communication, content),
                _id: question._id,
              };
            })}
            tooltipCallbacks={(index) => {
              return (`Q${index + 1}. ${questions?.[index]?.question?.length <= 35 ?
                questions?.[index]?.question
                :
                questions?.[index].question?.slice(0, 35) + "..."
                }`)
            }}
            parameter={scoreType}
            aspectRatio={template.type === templateTypes.ASSIGNMENT ? 4 : 2}
          />
        </Box>

        <Box className={classes.attemptGraphContainer}>
          {
            template.type === templateTypes.ASSIGNMENT 
              ? null 
              : allAttempts?.length === 1 
                ? <UnlockCard template={template}/> 
                : <AttemptGraphContainer
                    allAttempts={allAttempts}
                    template={template}
                  />
          }
        </Box>
      </Box>
    </WhiteBox>
  );
}