import React from 'react';
import Box from '@mui/system/Box';
import makeStyles from "@mui/styles/makeStyles";

import ChangePassword from './ChangePassword';
import ProfilePicture from './ProfilePicture';
import PersonalDetails from './PersonalDetails';
import Resume from './Resume';
import AppShell, { WhiteBox, WhiteBoxHeader } from 'components/AppShell';


const useStyles = makeStyles(theme => ({
  profile: {
    display: "flex",
    boxShadow: '0px 0px 2px 0px #000000BF',
    padding: theme.spacing(6),
    gap: theme.spacing(10),
    paddingLeft: theme.spacing(12),
  },
  password: {
    display: "flex",
    boxShadow: '0px 0px 2px 0px #000000BF',
    padding: theme.spacing(6),
    gap: theme.spacing(10),
  },
}))

function Profile() {
  const classes = useStyles();

  return (
    <AppShell headerLabel='Profile'>
      <WhiteBox>
        <WhiteBoxHeader
          heading='Setup your student profile'
          subheading='These details will reflect in dashboard & other places'
        />
        <Box className={classes.profile}>
          <ProfilePicture />
          <PersonalDetails />
        </Box>
      </WhiteBox>
      {/* <InterviewSettings /> */}
     <Resume />
      <WhiteBox>
        <WhiteBoxHeader
          heading='Setup your student profile'
          subheading='These details will reflect in dashboard & other places'
        />
        <Box className={classes.password}>
          <Box width='220px'>
            <img
              src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/updatePass.png'
              alt='update password'
            />
          </Box>
          <ChangePassword />
        </Box>
      </WhiteBox>
    </AppShell>
  );
}

export default Profile;
