import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";

import { useSnackbar } from "contexts/SnackbarProvider";
import { useLoading } from "contexts/LoadingProvider";

import { FieldAutoComplete } from "components/AutoComplete";
import OnboadingHeader from "components/OnboadingHeader";
import CustomButton from "components/CustomButton";
import OtherFieldDialog from "dialogs/OtherFieldDialog";

import useNavigateWithClient from "hooks/useNavigateWithClient";

import Session from "utils/Session";

import { getFieldOptions, saveFieldOption } from "services/fieldOptionService";
import { getSurveyQuestions } from 'services/survey';
import { updateUserProfileById } from "services/userService";
import HorizontalScrollWrapper from "components/HorizontalScrollWrapper";
import OnboardingOptionCard from "components/Cards/OnboardingOptionCard";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles({
    button: {
        fontFamily: 'Montserrat',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "24px",
        color: "#646464",
        textTransform: "none"
    }
});

function RadioList({
    name,
    value,
    options = [],
    onChange,
}) {
    return options ? (
        <FormControl size="medium">
            <RadioGroup
                aria-labelledby="radio-buttons-group"
                name={name}
                value={value}
                onChange={onChange}
            >
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio />}
                        label={option}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    ) : null;
}

export default function Onboarding() {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const navigateWithClient = useNavigateWithClient();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const [onboardingQuestions, setOnboardingQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [open, setOpen] = useState(false);

    const question = onboardingQuestions?.[currentIndex];
    const { metadata } = question || {};

    const updateAnswers = (update) => {
        setAnswers((answers) => ({ ...answers, ...update }));
    };

    const setOptionsFields = (tempOnboardingQuestions) => {
        const promises = tempOnboardingQuestions.map(async (question) => {
            const { metadata, options: field } = question?.metadata;

            question.field = metadata;

            let fieldOptions = [];

            if (!!field) {
                const result = await getFieldOptions({
                    field,
                    user: [
                        Session.userId,
                        process.env.REACT_APP_LANGUIFY_ADMIN_USER_ID
                    ],
                });

                fieldOptions = result.fieldOptions;

                if (!question.hideOther) fieldOptions.push({
                    _id: 'other', option: "Other..."
                });

                return { ...question, options: fieldOptions };
            }
            return question;
        });
        return Promise.all(promises);
    };

    useEffect(() => {
        (async () => {
            try {
                loading.show();

                let tempOnboardingQuestions = await getSurveyQuestions(searchParams.get("for") || 'STUDENT');

                if (!tempOnboardingQuestions.length) {
                    navigateWithClient("/home");
                    return;
                }

                tempOnboardingQuestions = await setOptionsFields(tempOnboardingQuestions);

                setOnboardingQuestions(tempOnboardingQuestions);
            }
            catch (error) {
                console.log(error);
                navigateWithClient("/home");
                snackbar.error("Unable to get Onboarding Questions");
            } finally {
                loading.hide();
            }
        })();
    }, []);


    const handleOnSelect = async (e, option) => {
        if (option === "other") {
            setOpen(true);
            return;
        }

        updateAnswers({ [question.field]: option });

        setCurrentIndex((currentIndex) => currentIndex + 1);
    };

    const handleBack = () => setCurrentIndex((currentIndex) => currentIndex - 1);

    const handleSkip = () => navigateWithClient("/home");

    const toggleDialog = () => setOpen((open) => !open);

    const handleSave = async (value) => {
        if (value.trim().length) {
            const userOption = await saveFieldOption(null, {
                field: question.metadata.options,
                option: value,
                user: Session.getUser()._id,
            });

            updateAnswers({ [question.field]: userOption._id });

            setCurrentIndex((currentIndex) => currentIndex + 1);
        }
        toggleDialog();
    };

    useEffect(() => {
        if (onboardingQuestions.length && currentIndex === onboardingQuestions.length)
            (async () => {
                try {
                    await updateUserProfileById({ metadata: answers });

                    snackbar.success("Preferences saved successfully");

                    navigateWithClient("/home");
                } catch (error) {
                    console.log(error);
                    snackbar.error("Uh Oh! Unable to save your Preferences.");
                }
            })();
    }, [currentIndex]);


    return onboardingQuestions.length ? (
        <>
            <OnboadingHeader />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Typography variant="h2">
                    {question?.question}
                </Typography>

                {!!metadata?.title && (
                    <Typography variant="h4">
                        {metadata?.title}
                    </Typography>
                )}

                {question?.type === "singleChoice" && (
                    <FieldAutoComplete
                        startIcon
                        addDefaultOption={!question.hideOther}
                        name={metadata?.options}
                        defaultValue={answers?.[question?.field] || "na"}
                        onChange={handleOnSelect}
                        sx={{ margin: "10px 0px" }}
                        defaultOptions={question.options}
                    />
                )}

            </Box>

            {question?.type === "singleChoice" && (
                <HorizontalScrollWrapper>
                    {question?.options?.map(
                        (option, index) =>
                            <OnboardingOptionCard
                                key={index}
                                id={option._id}
                                content={option.option}
                                helperText={option?.helperText}
                                selected={answers?.[question?.field] || ""}
                                onSelect={handleOnSelect}
                            />
                    )}
                </HorizontalScrollWrapper>
            )}

            {question?.type === "radio" && (
                <Box mx={"20vw"} my={"10vh"}>
                    <RadioList
                        name={metadata?.metadata}
                        value={answers?.[question?.field]}
                        options={question?.options}
                        onChange={handleOnSelect}
                    />
                </Box>
            )}

            <Box display="flex" justifyContent="space-between">
                <CustomButton
                    variant="text"
                    onClick={handleBack}
                    disabled={currentIndex ? false : true}
                    className={classes.button} >
                    back
                </CustomButton>
                <CustomButton
                    variant="text"
                    onClick={handleSkip}
                    className={classes.button} >
                    Skip
                </CustomButton>
            </Box>
            <Box display="flex" justifyContent="center" mt={5}>
                <img src="https://assets.languify.in/images/powered-by-languify.svg"
                    alt="powered by languify"
                    width="120px"
                />
            </Box>
            <OtherFieldDialog
                open={open}
                onClose={toggleDialog}
                onSave={handleSave}
            />
        </>
    ) : null;
}