import React, {useState} from 'react'
import {makeStyles} from "@mui/styles";
import { Dialog , DialogTitle , Box, DialogContent, Typography, FormControl , FormHelperText } 
from '@mui/material'
import { Field, Form, Formik , useFormikContext } from 'formik';
import { useLoading, useSnackbar } from 'contexts';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';

import TextField from 'components/CustomFields';
import { CustomCheckbox } from 'components/CustomCheckbox';
import { CountryCodeSelect, SimpleSelect } from 'components/CustomSelectFields';
import { subscribe } from 'services/subscribeService';
import theme from 'theme';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=> ({
  dialogContainer: {
    borderRadius: '8px',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing(3,0,5,0),
    backgroundColor: theme.palette.neutral['clr-50'],
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '350px',
      margin: 0,
  }
  },
  subscribeContainer:{
    padding:theme.spacing(0,3)
  },
  headerContainer:{
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'center',
    height:'28px',
    borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
    paddingBottom:theme.spacing(2)
  },
  closeButton:{
    padding:0,
    display:'flex',
    justifyContent:'flex-end',
    width:'100%',
  },
  content:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(5),
    marginTop:theme.spacing(5),
    padding:0,
    height:'100%'
  },
  upperBox:{
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    display:'flex',
    justifyContent:'space-between',
    height:'100px',
    borderRadius:'4px',
    background:theme.palette.primary['clr-50']
  },
  startRight:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    padding:theme.spacing(1)
  },
  starLeft:{
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'flex-end',
    padding:theme.spacing(1)
  },
  upperText:{
    width:'202px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    padding:theme.spacing(1)
  },
  formFields:{
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    gap:theme.spacing(5),
  },
  buttonContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
  },
  confirmationRoot:{
    padding:0,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:theme.spacing(4),
  },
  responseDialogContainer:{
    padding:theme.spacing(0,3)
  },
  receivedImage:{
    maxWidth: '100%'
  },
  responseText:{
    textAlign:'center',
  },
  closeDialogContainer:{
    padding:theme.spacing(0,3)
  },
  closeBox:{
    display:'flex',
    flexDirection:'column',
    padding:0,
    gap:theme.spacing(5)
  },
  closeIcn:{
    cursor:'pointer',
    width:'20px',
  },
  closeTextBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:theme.spacing(2),
    marginTop:theme.spacing(5)
  },
  confirmationBox:{
    display:'flex',
    justifyContent:'space-between',
    gap:theme.spacing(3),
  },
  numberFieldBox:{
    display:'flex',
    flexDirection:'row',
    gap:theme.spacing(2),
    width:'100%',
  },
  label:{
    ...theme.typography['body01-bold'],
    marginBottom:theme.spacing(2),
  },
  phoneNumber:{
   backgroundColor: theme.palette.shades['clr-white-900'],
   width:'290px'
  },
  backDrop: {
    backdropFilter: "blur(10px)",
    backgroundColor:'#00000099'
  },
}));

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('Phone Number is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  reference: Yup.string().required('Select Option is required'),
});

const options = 
  [
    {_id:'1', name:'Instagram'},
    {_id:'2', name:'LinkdIn'},
    {_id:'3', name:'Ad'},
    {_id:'4', name:'Referral'},
    {_id:'5', name:'Google'},
    {_id:'6', name:'Other'}

  ];

  const FormikSimpleSelect = ({ name, options, ...props }) => {
    const { setFieldValue, values, errors, touched } = useFormikContext();
  
    const handleChange = (event) => {
      const selectedOption = options.find(option => option._id === event.target.value);
      setFieldValue(name, selectedOption ? selectedOption.name : '');
    };
  
    return (
      <FormControl required error={touched[name] && Boolean(errors[name])}>
        <SimpleSelect
          value={values[name] ? options.find(option => option.name === values[name])?._id : ''}
          onSelect={handleChange}
          options={options}
          style={{ backgroundColor: theme.palette.primary['clr-50'] }}
          {...props}
        />
        {touched[name] && errors[name] && (
          <FormHelperText style={{marginLeft:'0px' , fontSize:'12px'}}>
            {errors[name]}
          </FormHelperText>
        )}
      </FormControl>
    );
  };


export function ResponseSubscribeDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      classes={{ paper: classes.dialogContainer }}
    >
      <Box className={classes.responseDialogContainer}>
        <DialogContent className={classes.confirmationRoot}>
          <Box className={classes.receivedImage}>
            <img 
              src='https://assets.languify.in/images/AIRobot.png' 
              alt='received' 
              className={classes.receivedImage} />
          </Box>
          <Typography variant='h5-medium' color='neutral-clr-800' className={classes.responseText}>
            We’ve received your details successfully! You can now see your feedback report.
          </Typography>
          <Box className={classes.buttonContainer}>
            <CustomButton variant='contained' onClick={onClose} style={{ width: '100%' }}>
              Close
            </CustomButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export function CloseSubscribeDialog({ open, onClose }) {
  const classes = useStyles();

  const handleHomeScreen = () => {
    window.close();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      classes={{ paper: classes.dialogContainer }}
    >
      <Box className={classes.closeDialogContainer}>
        <DialogTitle sx={{ m: 0, p: 0 }}>
          <Box className={classes.headerContainer}>
            <Box
              aria-label="close"
            
              style={{ borderRadius: '50%' }}
              className={classes.closeButton}
            >
              <CloseIcon onClick={onClose} className={classes.closeIcn}  />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.closeBox}>
          <Box className={classes.closeTextBox}>
            <Typography variant='h6-medium'>Do you want to close this?</Typography>
            <Typography variant='body01-semiBold' color='neutral.clr-600'>
              You won’t be able to see your feedback report again!
            </Typography>
          </Box>
          <Box className={classes.confirmationBox}>
            <CustomButton variant='contained' onClick={handleHomeScreen} style={{ width: '100%' }}>
              Close
            </CustomButton>
            <CustomButton 
              variant='outlined' 
              onClick={onClose} 
              style={{ width: '100%', backgroundColor: theme.palette.shades['clr-white-900'] }}
            >
              Go Back
            </CustomButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

const SubscribeDialog = ({  open }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [countryCode, setCountryCode] = useState('91');

  React.useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  const handleClose = () => {
    setShowCloseDialog(true);
    
  };

  const handleFormSubmit = async (values) => {
    try{
      const formData = {
        name: values.name,
        email: values.email,
        application:'languify',
        metadata: {
          phoneNumber: countryCode + values.phoneNumber, 
          reference: values.reference,
          community: values.community
        }
      };

      const response = await subscribe(formData);
      if(response) {
        snackbar.success('Subscribed successfully');
        setDialogOpen(false);
        setShowResponseDialog(true);
      }
    } catch (error) {
      console.error(error);
      snackbar.error(error.message,true);
    }finally{
      loading.hide();
    }
  };

  const handleResponseDialogClose = () => {
    setShowResponseDialog(false);
  };

  const handleCloseDialogClose = () => {
    setShowCloseDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        maxWidth={'lg'}
        fullWidth={true}
        open={dialogOpen}
        classes={{ paper: classes.dialogContainer }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
      <Box className={classes.subscribeContainer}>
          <DialogTitle sx={{ m: 0, p: 0 }}>
          <Box className={classes.headerContainer}>
            <Box
              aria-label="close"
              style={{ borderRadius: '50%' }}
              className={classes.closeButton}
            >
              <CloseIcon onClick={handleClose} className={classes.closeIcn} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box className={classes.upperBox}>
            <Box className={classes.startRight}>
              <img 
                src='https://assets.languify.in/images/startGradientBlue.svg' 
                width={20} 
                height={20} 
                alt='startGradientBlue' 
              />
            </Box>
            <Box className={classes.upperText}>
              <Typography variant='h6-medium' color='primary.main'>
                Let us know you better & unlock your feedback.
              </Typography>
            </Box>
            <Box className={classes.starLeft}>
              <img 
                src='https://assets.languify.in/images/startGradientBlue.svg' 
                width={20} 
                height={20} 
                alt='startGradientBlue' 
              />
            </Box>
          </Box>
          <Formik
            initialValues={{
              name: '',
              phoneNumber: '',
              email: '',
              reference: '',
              community: true
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({errors, touched}) => (
              <Form>
              <Box className={classes.formFields}>
                <FormControl required>
                  <Field as={TextField} label='What’s your name?' placeholder='Jhon' name='name' 
                  style={{ backgroundColor: theme.palette.shades['clr-white-900']}} 
                    error={touched.name && errors.name}
                  />
                </FormControl>
              <FormControl required>
                 <label htmlFor="contact" className={classes.label}>
                    Contact No.
                 </label>
                 <Box className={classes.numberFieldBox}>   
                      <CountryCodeSelect
                        name="countryCode"
                        sx={{ width: '80px' }}
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                      />
                      <Field as={TextField} name='phoneNumber'
                        placeholder='000000000'
                        error={touched.phoneNumber && errors.phoneNumber}
                        style={{ backgroundColor: theme.palette.shades['clr-white-900']  }}
                        fullWidth
                      />
                    </Box>
              </FormControl>
                <FormControl required>
                  <Field as={TextField} label='Your email address' name='email' 
                  placeholder='Enter your email'
                  style={{ backgroundColor:  theme.palette.shades['clr-white-900'] }} 
                  error={touched.email && errors.email}
                  />
                </FormControl>
                <FormControl required>
                <FormikSimpleSelect
                      name='reference'
                      options={options}
                      label='How did you find us'
                    />
                </FormControl>
                <FormControl>
                  <Field as={CustomCheckbox} name='community' 
                  label='I want to be added to a community of like-minded individuals & experts.' />
                </FormControl>
                <Box className={classes.buttonContainer}>
                  <CustomButton variant='contained' type="submit" style={{ width: '100%' }}>
                    Submit
                  </CustomButton>
                </Box>
              </Box>
            </Form>
            )}
          </Formik>
        </DialogContent>
      </Box>
      </Dialog>

      {showResponseDialog && 
        <ResponseSubscribeDialog open={showResponseDialog} onClose={handleResponseDialogClose} />}
      {showCloseDialog && 
        <CloseSubscribeDialog open={showCloseDialog} onClose={handleCloseDialogClose} />}
    </>
  );
}

export default SubscribeDialog;
