import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme=>({
  tagRoot: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    width: 'fit-content',
    borderRadius: theme.spacing(1) ,
    padding: theme.spacing(0,1) ,
    boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  }
}))

export default function NewTag() {
  const classes = useStyles();
  return (
    <Box className={ classes.tagRoot }>
      <Typography fontSize={12} fontWeight={600} color='primary'>
        New
      </Typography>
    </Box>
  );
}