import { useRef } from 'react';
import Question from './Question';
import useStore from './store';
import TopNavigation from './TopNavigation';
import SampleAnswerForm from './SampleAnswerForm';
import PersonalizedFeedback from './PersonalizedFeedback';
import Toggle from 'components/Toggle';
import FeatureAccordion from './FeatureAccordion';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import { defaults } from './store';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Categories, ToggleOptions } from './constants';
import { LinkButton } from 'components/CustomButton';

const useStyles = makeStyles((theme) => ({
    analysisContainer: {
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-500'],
        borderRadius: '4px',
        backgroundColor: theme.palette.shades['clr-white-900'],
        padding: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(800)]: {
            flexDirection: 'column',
        }
    },
    leftPanel: {
        width: 'calc(100% - 400px)',
        [theme.breakpoints.down(800)]: {
            width: '100%',
        }
    },
    rightPanel: {
      position: 'relative',
      width: '400px',
      height: '440px',
      [theme.breakpoints.down(800)]: {
        minWidth: '270px',
        width: '100%',
        marginTop: '40px',
      },
      border: '1px solid #636F7F',
      borderRadius: theme.spacing(1),
    },
    rightPanelContent: {
      padding: theme.spacing(3),
      display: 'flex',
      width: '100%',
      height: '400px',
      paddingRight: theme.spacing(3),
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(4),
      overflowY: 'scroll',
    },
    parameterScrollBar: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 3,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      borderRadius: theme.spacing(0,0,1,1),
      backgroundColor: theme.palette.shades['clr-white-900'],
      padding: theme.spacing(2,1),
      boxShadow: "0px -3px 8px 0px #00000029",
    },
    parameterMenu: {
      width: '100%',
      display: 'flex',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        width: '0px',
        height: '0px',
      },
    },
    divider: {
        borderRight: '1px solid',
        color: theme.palette.neutral['clr-400'],
        margin: '0px 20px'
    },
    errorState: {
        borderRadius: '4px',
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-500'],
        width: '100%',
        height: '280px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '10px',
    },
    overallScores: {
      padding: theme.spacing(4,2.5),
      backgroundColor: theme.palette.primary['clr-50'],
      width: '100%',
      borderRadius: theme.spacing(1), textAlign: 'center'
    },
    navigatorRoot: {
      height: '100%',
      width: '25px',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    navigator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "25px",
        zIndex: 1,
        cursor: "pointer",
        color: theme.palette.primary.main,
        position: 'absolute',
    },
}));

const sx = {
  parameterMenuButton: {
    minWidth: 'fit-content', 
    padding: '4px 10px',
  }
}

const ParameterMenuBar = ({ features, selectedFeatureIndex, setSelectedFeatureIndex }) => {
  const classes = useStyles();
  const theme = useTheme();
  const scrollContainerRef = useRef();
  const selectedCategoryRef = useRef([]);

  const handleNavigate = (direction) => {
    scrollContainerRef.current.scrollBy({
        top: 0,
        left: direction === "left" ? -200 : 200,
        behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (selectedCategoryRef.length && selectedCategoryRef.current[selectedFeatureIndex]) {
      selectedCategoryRef.current[selectedFeatureIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [selectedFeatureIndex]);

  return (
    <Box className={classes.parameterScrollBar}>
      <Box className={classes.navigatorRoot}>
        <ChevronLeftIcon className={classes.navigator} style={{ left: 2}} onClick={()=> handleNavigate('left')}/>
      </Box>
      <Box className={classes.parameterMenu} ref={scrollContainerRef} >
      {
        features.map(({category},i)=>{
          return (
            <LinkButton
              key={i}
              buttonRef={(el) => selectedCategoryRef.current[i] = el}
              onClick={()=>setSelectedFeatureIndex(i)}
              style={{ 
                ...sx.parameterMenuButton, 
                backgroundColor: selectedFeatureIndex === i ? theme.palette.primary['clr-50'] : null
              }}
            >
              {
                Categories[category]
              }
            </LinkButton>
          )
        })
      }
      </Box>
      <Box className={classes.navigatorRoot}>
        <ChevronRightIcon className={classes.navigator} style={{ right: 2}} onClick={()=> handleNavigate('right')}/>
      </Box>
    </Box>
  )
}

function CategoriesAnalysis({ question, selectedCategory }) {
    const classes = useStyles();

    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
    const [overall, setOverall] = useState({content:0, speech: 0});
    const selectedCardRef = useRef([]);

    const features = useMemo(
        () => {
            const {
                communication: { ratings: _commRatings, tags: commTags },
                content: { ratings: _contRatings },
            } = question?.isError ? defaults.questions[0] : question || { communication: {}, content: {} };

            const commRatings = { ..._commRatings };
            const contRatings = { ..._contRatings };

            if (question?.type === "situational") {
                delete contRatings.SIMILARITY;
            }

            setOverall({
              content: contRatings.OVERALL,
              speech: commRatings.OVERALL,
            })

            delete commRatings.OVERALL;
            delete contRatings.OVERALL;

            if (selectedCategory.id === 'content') {

                return Object.keys(contRatings).map(
                    (key) => ({ category: key, score: contRatings[key], })
                );
            } else {

                const featureTagMap = commTags?.reduce((acc, commTag) => {

                    acc[commTag.category] = (acc[commTag.category]) ?
                        [...acc[commTag.category], { ...commTag }] :
                        [{ ...commTag }]

                    return acc;
                }, {});

                return Object.keys(commRatings).map(
                    (key) => ({
                        category: key,
                        score: commRatings[key],
                        tags: featureTagMap[key] || []
                    })
                );
            }
        },
        [question, selectedCategory]
    );

    const handleExpand = (index) => {
        (selectedFeatureIndex === index) ?
            setSelectedFeatureIndex(null) :
            setSelectedFeatureIndex(index);
    }

    useEffect(() => setSelectedFeatureIndex(0), [selectedCategory]);

    useEffect(() => {
      if (selectedFeatureIndex && selectedCardRef.current[selectedFeatureIndex]) {
        selectedCardRef.current[selectedFeatureIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, [selectedFeatureIndex]);

    return (
      <>
        <Box className={classes.overallScores}>
          <Typography variant='body01-bold'>
            {selectedCategory.label} score for this question: &nbsp;
            <span style={{ color: '#1961BF'}}>{overall[selectedCategory?.id]}/100</span>
          </Typography>
        </Box>
        {
          features.map((feature, index) => (
              <FeatureAccordion
                  key={index}
                  index={index}
                  itemRef={(el) => selectedCardRef.current[index] = el}
                  expanded={selectedFeatureIndex === index}
                  featureData={feature}
                  onExpand={handleExpand}
              />
          ))
        }
        <ParameterMenuBar 
          features={features} 
          selectedFeatureIndex={selectedFeatureIndex}
          setSelectedFeatureIndex={setSelectedFeatureIndex}
        />
      </>
    );
}

function ErrorState({ statusCode }) {
    const classes = useStyles();

    return (
        <Box className={classes.errorState} id='tips-for-improvement-container'>
            <img
                src='https://assets.languify.in/images/mic-disable.svg'
                alt='Not Recorded Error'
            />
            <Typography variant='h6-medium' color='neutral.clr-500' lineHeight={'26px'}>
                {
                    (statusCode === 301) ?
                        <>Having skipped this question, <br />
                            we are unable to provide you with personalized feedback.</> :
                        <>As your response is not recorded properly,
                            <br /> we are unable to provide you with personalized feedback.</>
                }

            </Typography>
        </Box>
    );
}

function Analysis(props) {
    const classes = useStyles();
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);
    const forceCategoryChange = useStore(state => state.forceCategoryChange);

    const [selectedCategory, setSelectedCategory] = useState({});

    return (
        <>
            <TopNavigation />
            <br />
            <Question interactiveDemo={props.interactiveDemo || false} />
            <br />
            {(activeQuestion?.isError) ?
                <ErrorState statusCode={activeQuestion.statusCode} /> :
                <Box className={classes.analysisContainer}>
                    <Box className={classes.leftPanel}>
                        <PersonalizedFeedback />
                    </Box>
                    <Box className={classes.divider}></Box>
                    <Box className={classes.rightPanel} id='parameters-feedback'>
                      <Box className={classes.rightPanelContent}>
                        <Toggle
                            options={ToggleOptions}
                            forceChange={forceCategoryChange}
                            width='100%' height='50px' br='4px'
                            onChange={setSelectedCategory}
                        />
                        <CategoriesAnalysis
                            question={activeQuestion}
                            selectedCategory={selectedCategory}
                        />
                      </Box>
                    </Box>
                </Box>
            }
            <br />

            <SampleAnswerForm />

        </>
    );
}

export default Analysis;