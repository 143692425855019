import { Session } from "utils";
import Client from "./_client";

export const getBatchInfoByToken = async (token) => {
    const result = await new Client({
        path: "/batch/token",
        payload: { token },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to log you in.");
    } else {
        return result.data;
    }
};

export const joinBatch = async (batchId, client) => {
    const result = await new Client({
        path: `/batch/${batchId}/join`,
        payload: {
            user: Session.userId,
            client,
        }
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to join the batch.");
    } else {
        return result.data;
    }
};

export const getUserEnrollments = async () => {
    const result = await new Client({
        path: `/batchUserMapping?user=${Session.userId}&include=batch`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to fetch batches.");
    } else {
        return result.data;
    }
};

export const getRecentTemplateAnalytics = async (id, filters) => {
    let url = `/batch/${id}/recent-template-analytics?pageSize=-99&user=${Session.userId}&type=all&`
    
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
    });
    const result = await new Client({
        path: url
    }).get();


    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to fetch batches.");
    } else {
        return result.data;
    }
};


export const getUserRank = async (filters) => {
    let url = "/batchUserMapping/batch-user-rank?";
    Object.keys(filters).forEach((key) => {
        if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
    });
    const result = await new Client({ path: url }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
        result.message || "Uh Oh! unable to get Ranks. Please try again"
        );
    }
    return result?.data;
};

export const getBatchesWithDomains = async () => {
  const url = `/batch/domain`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get job profiles. Please try again."
    );
  }

  return result.data;
}

export const updateBatchUserMapping = async (id,data) => {

  let result = await new Client({
      path: `/batchUserMapping/${id}`,
      payload: data,
      }).put();

      if (!(result.ok || result.code === 200)) {
      throw new Error(
          result.message ||
          "Uh Oh! Unable to update BatchUserMapping. Please try again."
      );
  }
  return result.data;
}