import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography, TextField } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import dayjs from 'dayjs';
import makeStyles from "@mui/styles/makeStyles";

import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { useServices } from 'contexts';
import { deleteResumeById, getResumes, updateResume } from 'services';
import { Session } from 'utils';
import { useSnackbar } from "contexts";
import CustomButton, { IconButton } from 'components/CustomButton';
import ConfirmationDialog from 'dialogs/ConfirmationDialog';
import ResumeUploadDialog from 'dialogs/ResumeUploadDialog';
import ImageViewer from 'components/ImageViewer';
import theme from 'theme';
import useStore from '../CreateInterview/store';

const useStyles = makeStyles(theme => ({
  resumeUploadHeading: {
    display: 'flex',
    flexDirection: 'row'
  },
  newChip: {
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.tertiary['clr-50'],
    border: '2px solid',
    borderColor: theme.palette.tertiary['clr-700'],
    borderRadius: '20px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  createResumeBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center'
  },
  createResumeContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  parentContainer:{
    display:'flex',
    gap:theme.spacing(10),
    width:'100%'
  },
  childContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(10)
  },
  uploadedResumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: '500px',
    overflowY: 'auto'
  },
  previewResumeContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%'
  },
  uploadedResumeBox: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    gap: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-400'],
    borderRadius: '4px',
    width: '492px',
    cursor: 'pointer', 
    transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
    '&.selected': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
    },
    '&:hover': {
      boxShadow: '0px 2px 8px 0px #00000029', 
    }
  },
  uploadedResumeDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%'
  },
  topHeadBox: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  head: {
    display: 'flex',
    flexDirection: 'row',
    gap:theme.spacing(2)
  },
  iconsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%"
  },
  editBox: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  deleteImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resumeName:{
    minWidth:'110px'
  },
  textWrap: {
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  textField: {
    '& .MuiInputBase-root': {
      padding: 0,
      paddingLeft:'4px',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      border:'1px dashed',
      borderColor: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: 'inherit',
    },
    width: '220px',
    minWidth: '100px',
    backgroundColor: 'transparent',
  },
  withoutResumeBox:{
    display:"flex",
    gap:theme.spacing(2),
    justifyContent:'center',
    alignItems:'center',
  },
  footHead: {
    width: '300px',
    height:'72px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  footHeadLast:{
    width: '250px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  }
}));

export const NoResumeState = ({ 
  onCreateResume , nextStep, buttonText = 'Add New Resume' , buttonWidth = '234px' , 
  profilePageResume=false , clientMock }) => {
  const classes = useStyles();

  return (
    <Box className={classes.createResumeBox}>
      <Box className={classes.createResumeContent}>
        <img
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/createResume.png'
          alt='resume'
          width={156}
        />
        <Typography variant='h5-medium' color='neutral.main'>
          You do not have any resume!
        </Typography>
      </Box>
      <CustomButton
        variant='contained'
        size="medium"
        onClick={onCreateResume}
        sx={{
          minWidth:buttonWidth
        }}
      >
       {buttonText}
      </CustomButton>
      {(clientMock && !profilePageResume) && (
          <Box className={classes.withoutResumeBox}>
          <Typography onClick={nextStep} variant='body01-link' color='primary.clr-300' sx={{cursor:'pointer'}}>
            Continue without resume
          </Typography>
          <Typography variant='body01-medium' color='neutral.clr-500'>
            (Personalization is not possible)
          </Typography>
        </Box>
      )}
    </Box>
  )
}


export const ResumeDetailBox = ({ 
  resumes, setRefresh, height='305px', selectedBox , onBoxClick,personalizedInterview, setSelectedBox, ...props 
}) => {
  const classes = useStyles();
  const service = useServices();
  const snackbar = useSnackbar();

  const setSelectedResumeDetails = useStore((store) => store.setSelectedResumeDetails);
  const setParsedData = useStore((store) => store.setParsedData);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [editingResume, setEditingResume] = useState(null);
  const [editedName, setEditedName] = useState(""); 

  const handleTrashClick = (resume) => {
    setSelectedResume(resume);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedResume(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedResume) {
      try {
        await service.callService(deleteResumeById, selectedResume._id);
        setRefresh(true);
        setDeleteDialogOpen(false);
        setSelectedResumeDetails("")
        setParsedData("")
        setSelectedBox(null)
      } catch (error) {
        console.error('Failed to delete resume:', error);
        snackbar.showSnackbar(error.message, "error", true);
      } finally {
        handleDialogClose();
      }
    }
  };

  const handleEditClick = (resume) => {
    setEditingResume(resume);
    setEditedName(resume.name);
  };

  const handleEditSave = async () => {
    if (editingResume) {
      try {
        await service.callService(updateResume, editingResume._id, { name: editedName });
        setRefresh(true);
        setEditingResume(null);
      } catch (error) {
        console.error('Failed to update resume name:', error);
        snackbar.showSnackbar(error.message, "error", true);
      }
    }
  };

  const handleEditCancel = () => {
    setEditingResume(null);
  };


  return (
    <Box className={classes.uploadedResumeContainer} sx={{ maxHeight:{ height}, ...props }}>
      {resumes.map((resume, index) => (
        <Box
          key={index}
          className={`${classes.uploadedResumeBox} ${selectedBox === index ? 'selected' : ''}`}
          onClick={() => onBoxClick(index)}>
          <img
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/resumeUploaded.png'
            alt='resume'
          />
          <Box className={classes.uploadedResumeDetailsBox}>
            <Box className={classes.topHeadBox}>
              <Box className={classes.head}>
                <Typography
                  className={classes.resumeName} variant='body01-medium' color='neutral.clr-900'>
                  Resume name:
                </Typography>
                {editingResume === resume ? (
                  <TextField
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    variant="outlined"
                    size="small"
                    autoFocus
                    className={classes.textField}
                    inputProps={{ maxLength: 24 }}
                  />
                ) : (
                  <Typography
                    className={classes.textWrap} variant='body01-semiBold'>
                    {resume.name}
                  </Typography>
                )}
              </Box>
              <Box className={classes.iconsBox}>
                {editingResume === resume ? (
                  <Box className={classes.editBox}>
                    <IconButton
                      sx={{
                        padding: `${theme.spacing(0.5, 0.75)} !important`
                      }}
                      onClick={handleEditSave}
                    >
                      <Check
                        sx={{
                          width: '16px',
                          height: '16px'
                        }} />
                    </IconButton>
                    <IconButton
                      variant='outlined'
                      sx={{
                        padding: `${theme.spacing(0.5, 0.75)} !important`
                      }}
                      onClick={handleEditCancel}
                    >
                      <Clear
                        sx={{
                          width: '16px',
                          height: '16px'
                        }} />
                    </IconButton>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <img
                        src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/editResume.svg'
                        alt='edit'
                        onClick={() => handleEditClick(resume)}
                      />
                    </Box>
                    <Box>
                      <img
                        src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/trashResume.svg'
                        alt='trash'
                        onClick={() => handleTrashClick(resume)}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Divider variant='light' />
            <Box className={classes.head}>
              <span>
                <Typography variant='body01-medium' color='neutral.clr-900'>
                  Created on:
                </Typography>
              </span>&nbsp;
              <span>
                <Typography variant='body01-semiBold'>
                  {dayjs(resume.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </span>
            </Box>
            <Box className={classes.head}>
              <span >
                <Typography variant='body01-medium' color='neutral.clr-900'>
                  Last used for:
                </Typography>
              </span>&nbsp;
              <span className={classes.footHeadLast}>
                <Typography variant='body01-semiBold'>
                  {resume?.lastUsedForTemplate?.name || 'NA'}
                </Typography>
              </span>
            </Box>
          </Box>
        </Box>
      ))}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onPrimaryAction={handleConfirmDelete}
        onSecondaryAction={handleDialogClose}
        title={<Box color='danger.clr-700'>Are you sure you want to delete?</Box>}
        message={<Box className={classes.deleteImage}>
                    <ImageViewer
                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/Delete_Resume_Dialog.png'
                      alt='delete'
                      width={170}
                      height={170}
                    />
                  </Box>}
        secondaryAction="Go back"
        primaryAction="Delete"
        primaryActionType="contained"
        secondaryActionType="outlined"
        buttonSize='medium'
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "648px", 
            },
          },
        }}
      />
    </Box>
  );
};


const ResumeDetails = ({ resumes, setRefresh,  onCreateResume }) => {
  const classes = useStyles();

  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedResumeDetails, setSelectedResumeDetails] = useState({
    name: "",
    createdOn: "",
    lastUsedFor: ""
  });

  const handleBoxClick = (index) => {
    const selectedResume = resumes[index];
    setSelectedBox(index);
    setSelectedResumeDetails({
      name: selectedResume?.name,
      createdOn: dayjs(selectedResume?.createdAt).format('DD/MM/YYYY'),
      lastUsedFor: selectedResume?.lastUsedFor
    });
  };

  return (
   <Box className={classes.parentContainer}>
     <Box className={classes.childContainer}>
      <ResumeDetailBox 
        resumes={resumes} 
        setRefresh={setRefresh} 
        selectedBox={selectedBox}
        onBoxClick={handleBoxClick}
        setSelectedBox={setSelectedBox}
        />
      <CustomButton
        variant='contained'
        size="medium"
        onClick={onCreateResume}
      >
        Add New Resume
      </CustomButton>
    </Box>
    <Box className={classes.previewResumeContainer}></Box>
   </Box>
  );
};

const Resume = () => {
    const classes = useStyles();
    const service = useServices();

    const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [refresh , setRefresh] = useState(true)

    useEffect(() => {
      if(refresh){
        fetchResume();
        setRefresh(false)
      }
    }, [refresh]);

    async function fetchResume() {
      try {
        const response = await service.callService(getResumes, { user: Session.userId,include: ['interviewTemplate'] });
        const sortedResumes = response.resumes.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));
        setResumes(sortedResumes);
      } catch (error) {
        console.error(error);
      }
    }
  
    const handleCreateResumeClick = () => {
      setUploadDialogOpen(true);
    };

    const handleUploadDialogClose = () => {
      setUploadDialogOpen(false);
    };

  return (
    <WhiteBox>
        <WhiteBoxHeader
          heading={
            <Box className={classes.resumeUploadHeading}>My Resume&nbsp;
              <Box className={classes.newChip}>
                <Typography variant='body01-bold' color='tertiary.clr-700'>New</Typography>
              </Box>
            </Box>
          }
          subheading='Create and save resumes for mock interview practice'
        />
        {resumes.length > 0 ? (
          <ResumeDetails 
            resumes={resumes} 
            setRefresh={setRefresh} 
            onCreateResume={handleCreateResumeClick}
            />
        ) : (
          <NoResumeState onCreateResume={handleCreateResumeClick} profilePageResume={true}/>
        )}
        <ResumeUploadDialog 
          openDialog={isUploadDialogOpen} 
          onClose={handleUploadDialogClose} 
          setRefresh={setRefresh}
          />
    </WhiteBox>
  );
}

export default Resume;
