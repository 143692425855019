import React from 'react';

import CheckIcon from '@mui/icons-material/CheckBox';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MicIcon from '@mui/icons-material/Mic';
import StartRecordingIcon from '@mui/icons-material/RadioButtonChecked';
import ErrorIcon from '@mui/icons-material/ReportProblem';
import StopRecordingIcon from '@mui/icons-material/StopCircle';
import DeviceIcon from '@mui/icons-material/Tv';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import CustomButton from 'components/CustomButton';
import TextField from 'components/TextField';
import {
    checkPermission,
    getSpeed,
    isMobile,
    subscribeToAudioLevel
} from 'utils';
import { postFeedback } from 'services';
import { useSnackbar } from 'contexts';


function SideMenu({ selected, setSelected }) {

    const handleListItemClick = (event, index) => {
        setSelected(index);
    };

    return (
        <Box width={200}>
            <DialogTitle sx={{ fontSize: 18 }}>
                Troubleshoot & Settings
            </DialogTitle>
            <List>
                <ListItemButton
                    selected={selected === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                >
                    <ListItemIcon>
                        <MicIcon />
                    </ListItemIcon>
                    <ListItemText primary="Microphone" />
                </ListItemButton>

                <ListItemButton
                    selected={selected === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                >
                    <ListItemIcon>
                        <DeviceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Device" />

                </ListItemButton>

                <ListItemButton
                    selected={selected === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                >
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Questions" />

                </ListItemButton>
            </List>
        </Box>
    );
}

function MicPermission({ granted, setGranted }) {

    React.useEffect(() => {
        checkPermission('microphone').then(setGranted).catch(console.error);
    }, [setGranted]);

    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color={granted ? '#028C5B' : "#FF2D55"}
                fontSize={16}
                fontWeight={500}
                mb={1}
            >
                {granted ? (
                    <CheckIcon fontSize='small' />
                ) : (
                    <ErrorIcon fontSize='small' />
                )}
                &nbsp;&nbsp;Microphone Permission
            </Typography>
            <Typography variant='body1' fontWeight={500} component="div">
                {granted ?
                    <>Microphone permission is granted</> :
                    <>Microphone permission is <b>not granted</b>. Provide permission by following these steps:</>
                }
                <ol>
                    {!granted && (
                        <>
                            <li>Click on the lock icon next to the website's URL in the address bar.</li>
                            <li>Select the <b>"Site settings"</b> option from the dropdown menu.</li>
                            <li>In the <b>"Permissions"</b> section, find the "Microphone" option and click on it.</li>
                            <li>Select <b>"Allow"</b> from the dropdown menu.</li>
                            <li>Refresh the website page and the microphone should now be enabled.</li>
                        </>
                    )}
                </ol>
            </Typography>
        </>
    );
}

function MicTest({ granted }) {
    const [tested, setTested] = React.useState(false);
    const [level, setLevel] = React.useState(0);
    const [recording, setRecording] = React.useState();
    const audioElement = React.useRef(null);
    const mediaRecorder = React.useRef(null);
    const stream = React.useRef(null);

    React.useEffect(() => {
        if (!navigator.mediaDevices?.enumerateDevices || !granted) {
            console.log("enumerateDevices() not supported or permission not granted");
        } else {
            // List cameras and microphones.
            navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    devices.forEach((device) => {
                        if (
                            device.kind === 'audioinput' &&
                            device.deviceId === "default"
                        ) {
                            setTested(device.label);
                        }
                    });
                })
                .catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                    setTested(false);
                });

            let audioIN = { audio: true };
            // audio is true, for recording

            // Access the permission for use
            // the microphone
            navigator.mediaDevices.getUserMedia(audioIN)
                .then(function (result) {
                    stream.current = result;

                    subscribeToAudioLevel(stream.current, setLevel);

                }).catch(console.error);

            return () => stream.current?.getTracks()?.forEach(t => t.stop());
        }
    }, [granted]);


    const handleClick = React.useCallback(() => {
        if (recording) {
            console.log("State: ", mediaRecorder.current.state);
            // stop if recording
            if (mediaRecorder.current.state === 'recording')
                mediaRecorder.current.stop();
        } else {
            // Pause if playing
            audioElement.current?.pause();
            // Start Recording
            mediaRecorder.current = new MediaRecorder(stream.current);

            const audioChunks = [];

            mediaRecorder.current.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorder.current.addEventListener("stop", () => {
                const audioBlob = new Blob(audioChunks);
                const audioUrl = URL.createObjectURL(audioBlob);
                audioElement.current = new Audio(audioUrl);
                console.log("Playing audio...")
                audioElement.current.play();
            });

            setTimeout(() => {
                console.log("State: ", mediaRecorder.current.state);
                if (mediaRecorder.current.state === 'recording') {
                    mediaRecorder.current.stop();
                    setRecording(r => !r);
                }
            }, 10 * 1000); // auto stop in 10 secs

            mediaRecorder.current?.start();

        }
        setRecording(r => !r);
    }, [recording]);

    return (
        <>
            <Divider light />
            <Typography fontSize={16} fontWeight={500} my={1}>
                Microphone in Use
            </Typography>
            {granted && (
                <>
                    <Box display="flex" alignItems="center" gap={1}>
                        <TextField
                            disabled
                            value={tested || ""}
                            size="small"
                        />
                        <CustomButton
                            style={{ minWidth: 120 }}
                            size="small"
                            onClick={handleClick}
                            startIcon={recording ? <StopRecordingIcon /> : <StartRecordingIcon />}
                        >
                            {recording ? "Recording" : "Test Mic"}
                        </CustomButton>
                    </Box>
                    <LinearProgress value={level} variant='determinate' />
                    <br />
                </>
            )}
            <Typography variant='body1' fontWeight={500} component="div">
                Here are the steps to change the microphone being used:
                <ol>
                    <li>Click on the lock icon in the address bar next to the website's URL.</li>
                    <li>Select <b>"Site settings"</b> from the dropdown menu.</li>
                    <li>In the <b>"Permissions"</b> section, find <b>"Microphone"</b> and click on it.</li>
                    <li>Click on the dropdown menu next to <b>"Microphone"</b> and select a different microphone.</li>
                    <li>If the microphone you want to use isn't listed, click on <b>"Manage devices"</b> to add or remove microphones from the list of available devices.</li>
                    <li>Close the settings tab when you're done.</li>
                </ol>
            </Typography>
        </>
    );
}

function Microphone() {
    const [granted, setGranted] = React.useState(false);

    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color="#02569D"
                fontSize={20}
                fontWeight={600}
            >
                <MicIcon fontSize='small' />&nbsp;Microphone Settings
            </Typography>
            <br />
            <MicPermission granted={granted} setGranted={setGranted} />
            <MicTest granted={granted} />
        </>
    );
}

function BrowserCompatibility() {
    const [compatible, setCompatible] = React.useState(true);

    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color={compatible ? '#028C5B' : "#FF2D55"}
                fontSize={16}
                fontWeight={500}
                mb={1}
            >
                {compatible ? (
                    <CheckIcon fontSize='small' />
                ) : (
                    <ErrorIcon fontSize='small' />
                )}
                &nbsp;&nbsp;Browser Compatibility
            </Typography>
            <Typography variant='body1' fontWeight={500}>
                {compatible ?
                    <>You're using a compatible browser</> :
                    <>This test is only compatible with the <b>Chrome browser</b>. Please close this tab and open on chrome browser.</>
                }
            </Typography>
        </>
    );

}

function DeviceCompatibility() {
    const [compatible, setCompatible] = React.useState(true);

    React.useEffect(() => {
        const mobile = isMobile();
        setCompatible(!mobile);
    }, []);

    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color={compatible ? '#028C5B' : "#FF2D55"}
                fontSize={16}
                fontWeight={500}
                mb={1}
            >
                {compatible ? (
                    <CheckIcon fontSize='small' />
                ) : (
                    <ErrorIcon fontSize='small' />
                )}
                &nbsp;&nbsp;Device Compatibility
            </Typography>
            <Typography variant='body1' fontWeight={500}>
                {compatible ? (
                    <>You're using a compatible device</>
                ) : (
                    <>This test is only compatible with <b>desktop devices</b>. Please close this tab and open on desktop.</>
                )}
            </Typography >
        </>
    );

}

function NetworkCompatibility() {
    const [good, setGood] = React.useState(true);

    React.useEffect(() => {
        getSpeed(({ mbps }) => {
            setGood(mbps >= 256)
        });
    }, []);

    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color={good ? '#028C5B' : "#FF2D55"}
                fontSize={16}
                fontWeight={500}
                mb={1}
            >
                {good ? (
                    <CheckIcon fontSize='small' />
                ) : (
                    <ErrorIcon fontSize='small' />
                )}
                &nbsp;&nbsp;Network Strength
            </Typography>
            <Typography variant='body1' fontWeight={500}>
                {good ? (
                    <><span style={{ color: "#028C5B" }}>Strong</span> network strength.</>
                ) : (
                    <><span style={{ color: "#FF2D55" }}>Poor</span> network strength. Try adjusting your network connection</>
                )}
            </Typography >
        </>
    );

}

function Device() {
    return (
        <>
            <Typography
                display="flex"
                alignItems="center"
                color="#02569D"
                fontSize={20}
                fontWeight={600}
            >
                <DeviceIcon fontSize='small' />&nbsp;&nbsp;Device Preferences
            </Typography>
            <br />
            <BrowserCompatibility />
            <br />
            <DeviceCompatibility />
            <br />
            <NetworkCompatibility />
        </>
    );
}

const CommonQuestionAnswers = [
    {
        q: "How to give Microphone test?",
        a: "To take the microphone test, please repeat the weekdays one by one clearly while being close to the microphone. After saying each day aloud, wait for some time until the words turn green. If all the words turn green, you will automatically proceed to the next step.",
    },
    {
        q: "What should I do if the words don't turn green after I speak into the microphone?",
        a: "Try speaking more clearly and making sure that your microphone is properly positioned. You can also try adjusting the microphone settings to improve the recording quality.",
    },
    {
        q: "How can I determine if my device is compatible for taking the test?",
        a: "For optimal test-taking experience, we recommend using a desktop device and a Chrome browser.",
    },
];

function Questions() {
    const snackbar = useSnackbar();
    const [expanded, setExpanded] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const query = formData.get("query");

        setLoading(true);

        postFeedback(4, query, "TROUBLESHOOT_SETTINGS")
            .then((result) => {
                snackbar.success("Successfully sent your query!");
                e.target.reset();
            }).catch((e) => {
                snackbar.error("Unable to send your query, Please try again!");
            }).finally(() => setLoading(false));
    };

    return (
        <Box>
            <Typography
                display="flex"
                alignItems="center"
                color="#02569D"
                fontSize={20}
                fontWeight={600}
            >
                <HelpIcon fontSize='small' />&nbsp;Common Questions
            </Typography>
            {CommonQuestionAnswers.map(({ q, a }, i) => (
                <Accordion
                    key={i}
                    elevation={0}
                    expanded={expanded === i} onChange={handleChange(i)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{q}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{a}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
            <br /><br />
            <Typography
                display="flex"
                alignItems="center"
                color="#02569D"
                fontSize={18}
                fontWeight={600}
            >
                Query still not resolved?
            </Typography>
            <Typography>
                Please describe your issue below and our customer support team will reach out to you shortly.
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    required
                    multiline
                    rows={3}
                    name="query"
                    inputProps={{ minLength: 20, maxLength: 200 }}
                    placeholder="Type your query here..."
                />
                <CustomButton type="submit" size="small" disabled={loading}>
                    {loading ? "Submitting..." : "Submit Query"}
                </CustomButton>
            </form>
        </Box>
    );
}

function TroubleshootDialog({ open, onClose }) {
    const [selected, setSelected] = React.useState(0);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="md"
            PaperProps={{ style: { padding: 8 } }}
        >
            <Box display="flex" alignItems="stretch" minHeight={500} >
                <SideMenu selected={selected} setSelected={setSelected} />
                <Divider flexItem variant="fullWidth" orientation='vertical' light />
                <Box flexGrow={1} minWidth={500} maxWidth={540}>
                    <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                        <IconButton size="small" onClick={onClose}>
                            <Close fontSize="small" />
                        </IconButton>
                    </div>
                    <Box px={2} py={1}>
                        {selected === 0 && <Microphone />}
                        {selected === 1 && <Device />}
                        {selected === 2 && <Questions />}
                    </Box>
                </Box>
            </Box>
        </ Dialog>
    );
}

export default TroubleshootDialog;