import Client from "./_client";

export const getResumeById = async (resumeId) => {
  const url = `/resume/${resumeId}`;

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get resume. Please try again."
    );
  }

  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};

export const saveResume = async (resumeData) => {
  let result;
    result = await new Client({
      path: `/resume`,
      payload: {
        name: resumeData.name,
        url: resumeData.url,
        parsedData: resumeData.parsedData,
        user: resumeData.user,
      },
    }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save resume. Please try again."
    );
  }
  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};
export const updateResume = async (resumeId, resumeData) => {
  let result;

    result = await new Client({
      path: `/resume/${resumeId}`,
      payload: resumeData,
    }).put();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save resume. Please try again."
    );
  }
  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};

export const getResumes = async (resumeFilters = {}) => {
  let url = `/resume?`;

  Object.keys(resumeFilters).forEach((key) => {
    if (resumeFilters[key]) url += `${key}=${resumeFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Unable to get resumes. Please try again."
    );
  }

  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result.data;
  }
};

export const deleteResumeById = async (resumeId) => {
  const url = `/resume/${resumeId}`;

  const result = await new Client({
    path: url,
  }).delete();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to delete resume. Please try again."
    );
  }
  if (result.name === "DuplicateSession") {
    return result;
  } else {
    return result?.data?.isDeleted;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getResumeById, saveResume, getResumes, deleteResumeById };
