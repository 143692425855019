import React from "react";
import Box from "@mui/material/Box";

import OnboadingHeader from "components/OnboadingHeader";
import CustomButton from "components/CustomButton";
import InfoMessage from "components/InfoMessage";

import { useLoading } from "contexts/LoadingProvider";

import Session from "utils/Session";

const Message = "Your account has been deactivated by the admin, please contact your administrator.";

function DeactivatedProfile() {
  const loading = useLoading();

  loading.hide();

  const handleBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  return (
    <>
      <OnboadingHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={3}
      >
        <img
          alt="error"
          src="https://assets.languify.in/images/error.svg"
        />
        <a href={`mailto::${Session.client ? Session.client.email : "info@languify.in"}`} style={{ textDecoration: 'none' }} >
          <CustomButton variant="contained" >Contact your Administrator/Client</CustomButton>
        </a>
        <CustomButton variant="text" onClick={handleBack} >
          Go Back
        </CustomButton>
        <InfoMessage variant="error">{Message}</InfoMessage>
      </Box>
    </>
  );
};

export default DeactivatedProfile;