import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CenterFlexBox from 'components/CenterFlexBox';

import { Box, IconButton, Typography } from '@mui/material';
import { formatDate } from 'utils';

export function ImageProctorDialog({
  open,
  onClose,
  proctoringRecords = []
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'md'} style={{ borderRadius: 16 }}
    >
      <DialogContent>
        <Typography variant='h4-medium' color='neutral.clr-900' >
          Face Proctor
        </Typography>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 14, top: 14 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText>
          <Box display='flex' flexWrap='wrap' gap={3} maxWidth={630} mt={4}>
            {
              proctoringRecords.map((proctoringRecord, index) => (
                (proctoringRecord.type === 'IMAGE_PROCTOR') ?
                  <Box key={index} style={{
                    width: 255, boxShadow: '0px 0px 4px 0px #00000026', borderRadius: 4,
                    display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <img
                      width={240}
                      src={proctoringRecord.location}
                      alt="capturedImage"
                    />
                    <CenterFlexBox justifyContent='flex-start' mt={2}>
                      <AccessTimeIcon fontSize='small' /> &nbsp;
                      <Typography variant='body01-regular' color='neutral.clr-900' >
                        Timestamp :
                        {formatDate(proctoringRecord.timestamp, 'DD-MM-YYYY, hh:mm:ss a')}
                      </Typography>
                    </CenterFlexBox>
                  </Box> : null
              ))
            }
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ImageProctorDialog;