import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import VideoPreview from 'components/Assessment/VideoPreview';
import ArrowNavigation from 'components/Assessment/ArrowNavigation';
import QuestionSection from 'components/Assessment/QuestionSection';
import Controls from 'components/Assessment/Controls';

const useStyles = makeStyles({
    middle: {
        zIndex: 1,
        backgroundColor: "white",
        width: '1200px',
        height: "480px",
        padding: '35px',
        borderRadius: 16,
        boxShadow: '0px 4px 8px 0px rgba(2, 86, 157, 0.20)',
        display: "flex",
        gap: 50,
        position: 'relative'
    },
    rightSeciton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 25,
        paddingBottom: 25,
        paddingRight: 25,
    },
    leftSection: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        paddingTop: 25
    }
});

function MiddleSection(props) {
    const classes = useStyles();

    return (
        <Box className={classes.middle}>
            <ArrowNavigation />
            <Box className={classes.leftSection}>
                <VideoPreview />
            </Box>
            <Box className={classes.rightSeciton}>
                <QuestionSection />
                <Controls />
            </Box>
        </Box>
    );
}

export default MiddleSection;