import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

import { ClickableChip } from './Chip';

const useStyles = makeStyles(theme => ({
    filterTagsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        width: "fit-content",
        gap: theme.spacing(2),
    },
    tag: {
        height: 40,
        borderRadius: theme.spacing(1),
    },
    filter: {
        margin: theme.spacing(5, 0),
    },
    filterChips: {
      display: 'flex', 
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
      overflow: 'auto',
      padding: theme.spacing(1,0),
      '&::-webkit-scrollbar': {
        height: '4px'
      },
    },
}));

export const FilterChips = ({ 
  title = '', filters = [], onChange, appliedFilters, disabeled=false 
}) => {
    const classes = useStyles();

    const handleFilterChange = (filter) => (v, option) => {
        const isSelect = filter.type === 'select';

        if (isSelect && !option) return;

        const value = isSelect ? option.id : v;

        onChange({ ...filter, value });
    };

    return (
        <Box className={classes.filter}>
          <Typography variant='h6-medium'>{title}</Typography>
          <Box className={classes.filterChips}>
              {
                filters.map((filter, i) => {
                    return (
                        <ClickableChip
                            key={i}
                            content={filter.label}
                            onClick={handleFilterChange(filter)}
                            selected={appliedFilters[filter.field]}
                            disabled={disabeled}
                        />
                    );
                })
              }
          </Box>
        </Box>
    )
}
