import * as React from 'react';
import { Box } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles";
import Divider from '@mui/material/Divider';

import { getTwoDigitNo } from 'utils';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2,0), 
    overflowY: 'hidden', 
    width: '100%',
    overflow: 'hidden', 
    display: 'flex', flexDirection: 'column', 
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
}));

const Introduction = ({ setOpenIntroduction, module, activeModule, ...props}) => {
  const classes = useStyles();

  return (
    <WhiteBox id={'module-intro-container'} style={{ height: '100%'}}>
      <WhiteBoxHeader
        heading='Quick revision sheet'
        subheading={<>Module- {getTwoDigitNo(activeModule+1)}</>}
      />

      <Divider/>

      <Box className={classes.content} id='module-intro'>
        <Box width='360px' height='203px'>
          <img style={{ height: '100%', width: '100%'}} 
            src={module?.introDocumentLinks?.[0]?.thumbnail} alt='thumbnail'
          />
        </Box>
        <CustomButton size='medium' onClick={()=> setOpenIntroduction(true)} variant='outlined'>
          Read Now
        </CustomButton>
      </Box>
    </WhiteBox>
  )
}

export default Introduction;