import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import useAssessmentStore from './assessmentStore';

const useStyles = makeStyles(theme=>({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        color: '#828282'
    },
    questions: {
        display: 'flex',
        justifyContent: "center",
        height: 70,
        alignItems: 'center',
        color: 'black',
        '&::-webkit-scrollbar': {
            width: 0, height: 0
        },
    },
    item: {
        transition: "all 100ms ease-in-out",
        border: '2px solid',
        padding: "8px !important",
        height: "fit-content",
        width: "fit-content",
        margin: 4,
        minWidth: 45,
        "&:hover": {
            boxShadow: '0px 0px 8px 1px #003E8C4D'
        },
        "&:active": {
            transition: 'none'
        },
        ...theme.typography['h6-semiBold']
    },
    active: {
        border: 'none',
        backgroundColor: `${theme.palette.primary['clr-500']}`,
        color: `${theme.palette.shades['clr-white-900']} !important`,
        "&:hover": {
            boxShadow: '0px 0px 8px 1px #003E8C4D'
        },
        "&:active": {
            transition: 'none'
        }
    },
    uploadingButton: {
        margin: 4,
        borderRadius: 4,
        padding: "10px !important",
        width: 45,
        height: 45,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: "not-allowed",
        transition: "all 500ms ease-in-out",
        border: "1px solid lightgrey",
        position: 'relative',
        ...theme.typography['h6-semiBold']
    },
    tooltip: {
        fontFamily: 'Inter',
        fontWeight: 600,
    },  
}));

export function UploadingPageButton({ progress = 0, children }) {
    const classes = useStyles();

    return (
        <Tooltip arrow title={"Uploading your answer..."} >
            <Box className={classes.uploadingButton}>
                <Box position='absolute' width={'100%'} bottom={0} >
                    <LinearProgress
                        variant={progress === 100 ? "indeterminate" : "determinate"}
                        value={progress}
                        size={34}
                        color='primary'
                    />
                </Box>
                {children}
            </Box>
        </Tooltip>
    );
}

function PageButton({
    color, bgColor, selected, onClick, serialNo, disabled, uploading, progress, submitted
}) {
    const classes = useStyles();
    const ref = React.useRef();
    const theme = useTheme();

    return uploading ? (
        <UploadingPageButton progress={progress}>
            Q{serialNo}
        </UploadingPageButton>
    ) : (
        <Button
            ref={ref}
            style={{ 
              color: selected ? theme.palette.shades['clr-white-900'] 
                      : (submitted ? disabled ? '#4E5661' : color : theme.palette.shades['clr-black-900']), 
              borderColor: selected ? theme.palette.shades['clr-white-900'] : disabled ? '#E1E3E5' : color, 
              backgroundColor: selected ? (submitted ? color : theme.palette.primary.main) 
                                : disabled ? '#E1E3E5' : bgColor 
            }}
            className={selected ? `${classes.item} ${classes.active}` : classes.item}
            disabled={disabled}
            onClick={onClick}
        >
            Q{serialNo}
        </Button>
    );
}

function QuestionNavigation() {
    const classes = useStyles();
    const theme = useTheme();

    const uploadStates = useAssessmentStore(state => state.uploadStates);
    const uploadProgresses = useAssessmentStore(state => state.uploadProgresses);
    const questionsError = useAssessmentStore(state => state.questionsError);
    const questions = useAssessmentStore(state => state.questions);
    const currentQuestion = useAssessmentStore(state => state.currentQuestion);
    const questionState = useAssessmentStore(state => state.questionState);
    const isInterviewerSpeaking = useAssessmentStore(state => state.isInterviewerSpeaking);
    const setCurrentQuestion = useAssessmentStore(state => state.setCurrentQuestion);

    const getState = React.useCallback((page) => {
        const result = { color: theme.palette.neutral["clr-300"], bgColor: theme.palette.shades["clr-white-900"] };

        result.uploading = uploadStates[page];
        result.progress = uploadProgresses[page];

        const error = questionsError[page];

        if (error !== undefined) {
            if (error){
              result.color = theme.palette.danger["clr-700"];
              result.bgColor = theme.palette.danger["clr-100"];
            } 
            else{
              result.color = theme.palette.success["clr-700"];
              result.bgColor = theme.palette.success["clr-100"];
            } 
        }

        return result;

    }, [questionsError, uploadStates, uploadProgresses]);

    return (
        <Box className={classes.root}>
            <Box className={classes.questions} id='interviewQuestions'>
                {questions.map((question, index) => {
                    const { uploading, progress, color, bgColor } = getState(index);
                    return (
                        <PageButton
                            key={index}
                            selected={index === currentQuestion}
                            serialNo={index + 1}
                            disabled={("RECORDING" === questionState) || isInterviewerSpeaking}
                            onClick={() => setCurrentQuestion(index)}
                            color={color}
                            bgColor={bgColor}
                            uploading={uploading}
                            progress={progress}
                            submitted={question.submitted}
                        />
                    )
                })}
            </Box>
        </Box>
    );
}

export default QuestionNavigation;