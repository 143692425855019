import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import { FilterChips } from "components/Filter";
import { useServices } from "contexts/SingleSessionProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import { getTodoPathways } from "services/pathwayService";
import PathwayList from "components/PathwayList";
import HorizontalScrollNavigator from "components/HorizontalScrollNavigator";
import { NoResultComponent } from "./TodoList";
import AddPathwayDialog from "dialogs/AddPathwayDialog";
import CustomButton from "components/CustomButton";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { getTwoDigitNo } from "utils";
import { SearchTextField } from "components/CustomFields";

const filters = [
    { field: 'new', type: 'bool', label: 'New', },
    { field: 'completed', type: 'bool', label: 'completed', },
];

export default function PathwayTodoList({ batch }) {
    const theme = useTheme();

    const service = useServices();
    const snackbar = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [todoPathways, setTodoPathways] = useState([]);
    const [openAddPathway, setOpenAddPathway] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    const fetchPathways = async () => {
      if (!batch._id) return;
      try {
        setLoading(true);

        const todo = await service.callService(getTodoPathways, batch._id);
        if(!todo.length){
          setOpenAddPathway(true);
        }

        setTodoPathways(todo.map(
          (userPathwayMap) => {

            const data = {
              ...userPathwayMap, ...userPathwayMap.pathway,
              new: (dayjs().diff(dayjs(userPathwayMap?.publishOn), 'day') <= 7)
            }
            delete data.pathway;

            return data;
          }
        ));
      } catch (error) {
          console.error(error);
          snackbar.error("Unable to load pathways");
      } finally {
          setLoading(false);
      }
    }

    useEffect(() => {
      fetchPathways();
    }, [batch]);

    const handleFilterChange = ({ field, value }) => {
      setAppliedFilters({});
      setAppliedFilters((afs) => {
        const newFilters = { ...afs };

        if (value && !Object.keys(appliedFilters).includes(field)) newFilters[field] = value;
        else delete newFilters[field];

        return newFilters;
      });
      setSearchQuery("");
    };

    const isFilterApplied = Object.keys(appliedFilters).length;
    const filteredPathways = todoPathways.filter((t) =>{
      return (
        isFilterApplied === 0 ||
        ((t.new && appliedFilters.new) || (t.modulesCompleted===t.totalModules && appliedFilters.completed))
        ) &&
        (searchQuery === '' || t.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    });
    
    return (
      <>
        <WhiteBox id="pathway-list-container">
          <WhiteBoxHeader 
            id="pathway-list"
            heading="My Pathways" 
            sx={{ marginBottom: theme.spacing(4) }}
            subheading={
              <>
                Total Pathways: &nbsp;
                <span style={{color: theme.palette.primary.main}}>
                  {getTwoDigitNo(filteredPathways.length)}
                </span>
              </>
            }
          >
            <Box display='flex' gap={theme.spacing(4)}>
              <SearchTextField
                placeholder="Search pathway..."
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={(!filteredPathways.length && !searchQuery.length) || !todoPathways.length}
              />
              <CustomButton
                ml={2}
                variant="contained" 
                size='medium'
                style={{textTransform: 'capitalize', height: '40px', marginLeft: theme.spacing(2)}}
                onClick={()=> setOpenAddPathway(true)}
                startIcon={<AddRoundedIcon />}
              >
                Add New Pathway
              </CustomButton>
            </Box>
          </WhiteBoxHeader>

          <Divider/>

          {
            !(todoPathways.length) ? null :
              <FilterChips
                title="Topic-based filters"
                filters={filters} 
                appliedFilters={appliedFilters}
                onChange={handleFilterChange} 
                disabeled={
                  !filteredPathways.length && 
                  (!Object.keys(appliedFilters).length && searchQuery.length)
                }
              />
          }

          <Box height={280}>
            {todoPathways?.length ? (
              <>
                <HorizontalScrollNavigator
                  NoResultComponent={ NoResultComponent }
                  NoResultComponentProps={{ 
                    description: isFilterApplied ? 'No pathways found for the applied filters!' : 'No pathways found!', 
                    clearFilters:  isFilterApplied ? () => setAppliedFilters({}) : undefined
                    }}
                  childrensLength={filteredPathways.length}
                >
                  <PathwayList
                    pathways={filteredPathways}
                    batch={batch}
                    loading={loading}
                  />
                </HorizontalScrollNavigator>
              </>
            ) : (
              <NoResultComponent description="No Pathways found"/>
            )}
          </Box>
        </WhiteBox>
        <AddPathwayDialog 
          openDialog={openAddPathway} 
          batch={batch}
          onClose={setOpenAddPathway}
          onSubmit={()=>{fetchPathways()}}
        />
      </>
    );
}