import * as React from 'react';
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { LargeTextField } from './TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "8px 0",
        borderRadius: "6px",
        fontSize: 12,
        '& > *': {
            fontSize: 12,
        },
        "& .MuiFilledInput-root": {
            fontSize: 12,
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            '& > *': {
                padding: 10
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#E4E3E8",
            borderRadius: "6px",
            fontSize: 12,
            '& fieldset': {
                borderRadius: "6px",
                borderColor: "transparent",
                fontWeight: "400",
                fontStyle: "normal",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: 12,
            },
            '&:hover fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
            },
            '&.Mui-focused fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
            },
        },
    },
}));

function PasswordField(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const classes = useStyles();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            {...props}
            type={showPassword ? "text" : "password"}
            size='small'
            variant="outlined"
            spellCheck="false"
            fullWidth
            className={classes.root}
            sx={{
                minWidth: 180,
                borderRadius: "6px",
                ...(props.sx ? { ...props.sx } : {})
            }}
            InputProps={{
                endAdornment: (
                    < InputAdornment position="end" >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="small"
                        >
                            {showPassword ?
                                <VisibilityOff fontSize="small" /> :
                                <Visibility fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    );
};

export function LargePasswordField(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const classes = useStyles();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <LargeTextField
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="small"
                        >
                            {showPassword ?
                                <VisibilityOff fontSize="small" /> :
                                <Visibility fontSize="small" />}
                        </IconButton>
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
            {...props}
        />
    );
};

export function CustomShadowPasswordField(props) {
    const classes = useStyles();

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box width="100%">
            <Typography variant="body1" marginTop="1em">
                {props.title}
            </Typography>
            <TextField
                {...props}
                className={classes.root}
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end" >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    ...props.InputProps,
                }}
                sx={{
                    color: "#9C9C9C",
                    minWidth: 120,
                    ...(props.sx ? { ...props.sx } : {})
                }}
            />
        </Box>
    );
}

export default PasswordField;